import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { SettingsStore } from './stores/settings.store'
import { SettingsService } from './services/settings.service'
import { FlightPreferencesActions } from './actions/flight-preferences.actions'
import { FlightPreferencesStore } from './stores/flight-preferences.store'
import { BaseSettingsStore } from './stores/base.store'
import { EmergencyContactStore } from './stores/emergency-contact.store'
import { EmergencySettingsActions } from './actions/emergency-settings.actions'
import { EmployeeInformationActions } from './actions/employee-information.actions'
import { EmployeeInformationStore } from './stores/employee-information.store'
import { HomeAddressStore } from './stores/home-address.store'
import { HomeAddressActions } from './actions/home-address.actions'
import { BusinessAddressStore } from './stores/business-address.store'
import { BusinessAddressActions } from './actions/business-address.actions'
import { PersonalInformationActions } from './actions/personal-information.actions'
import { PersonalInformationStore } from './stores/personal-information.store'
import { TravelPreferencesActions } from './actions/travel-preferences.actions'
import { TravelPreferencesStore } from './stores/travel-preferences.store'
import { TravelDocumentsActions } from './actions/travel-documents.actions'
import { TravelDocumentsStore } from './stores/travel-documents.store'
import { ProfileActions } from './actions/profile.actions'
import { AccountInformationActions } from './actions/account-information.actions'
import { PaymentInformationStore } from './stores/payment-information.store'
import { PaymentInformationService } from './services/payment-information.service'
import { PaymentInformationActions } from './actions/payment-information.action'
import { HotelPreferencesStore } from './stores/hotel-preferences.store'
import { HotelPreferencesActions } from './actions/hotel-preferences.actions'
import { CarRentalPreferencesStore } from './stores/car-rental-preferences.store'
import { CarRentalPreferencesActions } from './actions/car-rental-preferences.actions'
import { SettingsActions } from './actions/settings.actions'
import { BaseSettingsService } from './services/base.service'
import { FieldSettingsStore } from './stores/field-settings'
import { NotificationsStore } from './stores/notifications.store'
import { NotificationsService } from './services/notifications.service'
import { NotificationsActions } from './actions/notifications.actions'
import { DelegateStore } from './stores/delegate.store'
import { DelegateService } from './services/delegate.service'
import { DelegateActions } from './actions/delegate.actions'
import { PasswordStore } from './stores/password.store'
import { PasswordActions } from './actions/password.actions'
import { TravelPreferencesAdditionalInformationActions } from './actions/travel-preferences-additional-information.actions'
import { TravelPreferencesAdditionalInformationStore } from './stores/travel-preferences-additional-information.store'

export const { useModuleContext: useSettingsContext } = hookContextFactory({
  baseSettingsStore: BaseSettingsStore,
  emergencyContactStore: EmergencyContactStore,
  emergencySettingsActions: EmergencySettingsActions,
  employeeInformationActions: EmployeeInformationActions,
  employeeInformationStore: EmployeeInformationStore,
  homeAddressStore: HomeAddressStore,
  homeAddressActions: HomeAddressActions,
  businessAddressStore: BusinessAddressStore,
  businessAddressActions: BusinessAddressActions,
  personalInformationEditModalActions: PersonalInformationActions,
  personalInformationStore: PersonalInformationStore,
  travelPreferencesActions: TravelPreferencesActions,
  travelPreferencesStore: TravelPreferencesStore,
  travelDocumentsActions: TravelDocumentsActions,
  travelDocumentsStore: TravelDocumentsStore,
  profileActions: ProfileActions,
  accountInformationActions: AccountInformationActions,
  paymentInformationStore: PaymentInformationStore,
  paymentInformationService: PaymentInformationService,
  paymentInformationActions: PaymentInformationActions,
  hotelPreferencesStore: HotelPreferencesStore,
  hotelPreferencesActions: HotelPreferencesActions,
  flightPreferencesStore: FlightPreferencesStore,
  flightPreferencesActions: FlightPreferencesActions,
  carRentalPreferencesStore: CarRentalPreferencesStore,
  carRentalPreferencesActions: CarRentalPreferencesActions,
  settingsActions: SettingsActions,
  settingsStore: SettingsStore,
  settingsService: SettingsService,
  baseSettingsService: BaseSettingsService,
  fieldSettingsStore: FieldSettingsStore,
  notificationsStore: NotificationsStore,
  notificationsService: NotificationsService,
  notificationsActions: NotificationsActions,
  delegateStore: DelegateStore,
  delegateService: DelegateService,
  delegateActions: DelegateActions,
  passwordStore: PasswordStore,
  passwordActions: PasswordActions,
  travelPreferencesAdditionalInformationActions: TravelPreferencesAdditionalInformationActions,
  travelPreferencesAdditionalInformationStore: TravelPreferencesAdditionalInformationStore,
})
