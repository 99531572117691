import { observer } from 'mobx-react-lite'
import { PurchaseReservationError } from '@etta/components/purchase-reservation-error'
import { PurchaseReservationSuccess } from '@etta/components/purchase-reservation-success'
import { PaymentDynamicSiteMessages } from '@etta/screens/payment-summary/payment-dynamic-site-messages'
import { BookingAnimationContainer } from '@etta/screens/payment-summary/booking-animation-container'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import { useSegmentStatusSplit } from '@etta/screens/payment-summary/use-segment-status-split'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useNewTripConfirmation } from '../trip-confirmation/hooks'
import { TripConfirmation } from '../trip-confirmation'
import { TravelFusionLinksLayout } from './layout'

export const TravelFusionLinksWrapper = observer(function TravelFusionLinksWrapper() {
  const { travelFusionLinks, paymentSummary, travelerInfo } = useCheckoutContextSelector(
    (state) => state,
  )

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewCheckoutPageLayoutEnabled } = featureFlagsStore.flags

  const {
    onBack,
    getTermsFormatType,
    areTermsLoading,
    areTermsError,
    termsAndConditions,
    startOverModalSlot,
    isShowDelegateName,
  } = travelFusionLinks

  const {
    onSubmit,
    onDone,
    onAnotherTrip,
    onShareTripsDetail,
    tripCost,
    flightDetails,
    hotelDetails,
    carRentalDetails,
    dynamicSiteMessages,
    isBooked,
    bookingId,
    isPostBooking,
    tripBookedType,
    autoCancellationTime,
    pnrNumber,
    onReset,
    newFlightDetails,
    newHotelDetails,
    newCarDetails,
    newRailDetails,
    isHoldFlow,
    isBookTripLoading,
    isBookTripError,
    confirmationNumbersData,
  } = paymentSummary

  const { newFlights, newCarRentals, newHotels } = useSegmentStatusSplit({
    flightDetails,
    hotelDetails,
    carRentalDetails,
    isPostBooking: false,
  })

  const tripConfirmationProps = useNewTripConfirmation({
    confirmationType: tripBookedType,
    autoCancellationTime: autoCancellationTime,
    totalRate: tripCost?.totalWithUnusedTicket,
    recordLocator: pnrNumber,
    onDone,
    onAnotherTrip,
    onShareTripsDetail,
    onReset,
    flightDetails: newFlightDetails,
    hotelDetails: newHotelDetails,
    carDetails: newCarDetails,
    railDetails: newRailDetails,
  })

  if (isBookTripError) {
    return <PurchaseReservationError onDone={onDone} />
  }

  if (isBookTripLoading) {
    return <BookingAnimationContainer isPostBookingFlow={isPostBooking} />
  }

  if (isBooked) {
    if (isNewCheckoutPageLayoutEnabled && !bookingId) {
      return <TripConfirmation {...tripConfirmationProps} />
    }
    return (
      <PurchaseReservationSuccess
        isStationTicketMachineDisabled={
          travelerInfo.railPreferences.value.isStationTicketMachineDisabled
        }
        onDone={onDone}
        onAnotherTrip={onAnotherTrip}
        onShareTripsDetail={onShareTripsDetail}
        flights={newFlights}
        carRentals={newCarRentals}
        hotels={newHotels}
        dynamicSiteMessages={dynamicSiteMessages}
        dynamicSiteMessagesSlot={
          <PaymentDynamicSiteMessages dynamicSiteMessages={dynamicSiteMessages} />
        }
        isTripOnHold={false}
        isPostBooking={false}
        confirmationNumbersData={confirmationNumbersData}
      />
    )
  }

  return (
    <>
      <TravelFusionLinksLayout
        payNow={tripCost?.payNow}
        termsAndConditions={termsAndConditions}
        estimatedCost={tripCost?.total.secondary || tripCost?.total.primary}
        dueTodayCost={tripCost?.payNow?.subTotal.secondary || tripCost?.payNow?.subTotal.primary}
        isShowDelegateName={isShowDelegateName}
        isLoading={areTermsLoading}
        isDisabled={areTermsLoading || areTermsError}
        isError={areTermsError}
        isHoldFlow={isHoldFlow}
        onSubmit={onSubmit}
        onBack={onBack}
        handleGetTermsFormatType={getTermsFormatType}
      />

      {startOverModalSlot}
    </>
  )
})
