import { Service, Inject } from '@etta/di'
import { AlertAdapter } from '@etta/modules/alert/infra/alert.adapter'
import { AlertStore } from '@etta/modules/alert/interface/stores/alert.store'
import { DEFAULT_ALERTS_QUANTITY } from '../../core/constants/default-alerts-quantity'

@Service()
export class AlertService {
  constructor(
    @Inject()
    private readonly alertStore: AlertStore,
    @Inject()
    private readonly alertAdapter: AlertAdapter,
  ) {}

  async loadAlert() {
    this.alertStore.setIsLoading(true)
    this.alertStore.setAlerts([])
    this.alertStore.setAlertsCount(null)
    this.alertStore.setIsError(false)

    const result = await this.alertAdapter.getAlerts({ pageSize: DEFAULT_ALERTS_QUANTITY })

    result.match({
      Ok: ({ messages, totalAlerts }) => {
        this.alertStore.setAlerts(messages)
        this.alertStore.setAlertsCount(totalAlerts)
        this.alertStore.setIsLoading(false)
      },
      Err: () => {
        this.alertStore.setIsError(true)
        this.alertStore.setIsLoading(false)
      },
    })
  }
}
