import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { useScreenType, ScreenType } from '@fiji/modes'
import { Container, ModalHeader, ResetFilterBlock } from './car-rental-filters-modal-styled'
import { useCarRentalFilters } from './use-car-rental-filters'
import { ListSection } from './list-section'
import { Footer } from './footer'
import { ResetLink } from './reset-link'

const i18Base = 'CarRentalFilters'

export const CarRentalFiltersContent = observer(function CarRentalFiltersContent() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const {
    isValuesEqual,
    values,
    sortedFilters,
    handlers,
    handleClearAllFilters,
    handleApplyFilters,
    handlersClear,
    handleResetToDefaultFilters,
    isDefaultFilterCompanySettingsExist,
    isResetToDefaultFiltersButtonEnabled,
  } = useCarRentalFilters()

  const resetLink = (
    <ResetLink
      onClick={handleResetToDefaultFilters}
      isDisabled={!isResetToDefaultFiltersButtonEnabled}
    />
  )

  return (
    <>
      <ModalHeader>
        <Block isFlexBlock isFullWidth>
          {t(`${i18Base}.Title`)}
          {!isMobile && isDefaultFilterCompanySettingsExist && (
            <Block marginLeft={8} isFlexBlock isFullWidth>
              {resetLink}
            </Block>
          )}
        </Block>
      </ModalHeader>
      <Modal.Body>
        {isMobile && isDefaultFilterCompanySettingsExist && (
          <ResetFilterBlock>{resetLink}</ResetFilterBlock>
        )}
        <Container>
          <ListSection
            title={t('CarRentalFilters.SectionTitles.Fuel')}
            classesList={sortedFilters.fuel}
            values={new Set(values.fuelType)}
            onChange={handlers.setFuelType}
            onClearSection={handlersClear.clearFuelType}
          />
          <ListSection
            title={t('CarRentalFilters.SectionTitles.Transmission')}
            classesList={sortedFilters.transmission}
            values={new Set(values.transmission)}
            onChange={handlers.setTransmission}
            onClearSection={handlersClear.clearTransmission}
            type="CarRentalFilters.ListSection.Transmission"
          />

          <ListSection
            title={t('CarRentalFilters.SectionTitles.CarClass')}
            titleLess={t('CarRentalFilters.Buttons.ShowLessCarClasses')}
            titleMore={t('CarRentalFilters.Buttons.ShowMoreCarClasses')}
            classesList={sortedFilters.carClass}
            values={new Set(values.carClass)}
            onChange={handlers.setCarClass}
            onClearSection={handlersClear.clearCarClass}
          />
          <ListSection
            title={t('CarRentalFilters.SectionTitles.Supplier')}
            titleLess={t('CarRentalFilters.Buttons.ShowLessSuppliers')}
            titleMore={t('CarRentalFilters.Buttons.ShowMoreSuppliers')}
            classesList={sortedFilters.supplier}
            values={new Set(values.vendors)}
            onChange={handlers.setVendor}
            onClearSection={handlersClear.clearVendors}
          />
        </Container>
      </Modal.Body>
      <Footer
        isApplyDisabled={isValuesEqual}
        onApplyClick={handleApplyFilters}
        onClearClick={handleClearAllFilters}
      />
    </>
  )
})
