import { Inject, Service } from '@etta/di'
import { SearchRideHailStore } from '@etta/modules/ride-hail/interface/store/search-ride-hail.store'
import { ExpenseCodePage, RenderState } from '@etta/modules/ride-hail/interface/types'
import { ExpenseFlowStore } from '@etta/modules/ride-hail/interface/store/expense-flow.store'
import { EnterCustomExpenseCodeStore } from '@etta/modules/ride-hail/interface/store/enter-custom-expense-code.store'

@Service()
export class EnterCustomExpenseCodeService {
  constructor(
    @Inject()
    private rideHailSearchStore: SearchRideHailStore,
    @Inject()
    private expenseFlowStore: ExpenseFlowStore,
    @Inject()
    private enterCustomExpenseCodeStore: EnterCustomExpenseCodeStore,
  ) {}

  goBack() {
    this.enterCustomExpenseCodeStore.dropStore()
    this.expenseFlowStore.setCurrentPage(ExpenseCodePage.SelectExpenseCode)
  }

  handleContinue() {
    if (this.expenseFlowStore.expenseConfig.memoEnabled) {
      this.expenseFlowStore.setCurrentPage(ExpenseCodePage.AddExpenseCode)
      return
    }

    this.rideHailSearchStore.setRenderState(RenderState.review)
    this.expenseFlowStore.setShouldCloseExpenseCodeModal(true)
  }

  handleCustomExpenseCodeValueChanged(value: string) {
    if (value.length > this.enterCustomExpenseCodeStore.maxCustomCodeLength) {
      return
    }

    this.enterCustomExpenseCodeStore.setCustomExpenseCodeValue(value)
    this.enterCustomExpenseCodeStore.setIsContinueButtonDisabled(!value)
  }
}
