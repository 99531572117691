import type { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'

type Props = {
  isMessageTruncated: boolean
  onClickShowMore: (e: MouseEvent<HTMLButtonElement>) => void
}

const i18nBase = 'DynamicSiteMessage'

export function DsmDesktopShowMoreButton({ isMessageTruncated, onClickShowMore }: Props) {
  const { t } = useTranslation()

  if (!isMessageTruncated) {
    return null
  }

  return (
    <Block marginTop={8} data-tracking-id="dsm-show-more-button">
      <Button variant="text" size="small" onClick={onClickShowMore} paddings={false}>
        {t(`${i18nBase}.ShowMore`)}
      </Button>
    </Block>
  )
}
