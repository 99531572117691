import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Container, NavItem, NavText } from './mobile-navigation-styled'
import { useMobileNavigation } from './use-mobile-navigation'

const i18Base = 'TripPlanner.MainNavigation'

export function MobileNavigation() {
  const { t } = useTranslation()

  const { navigationItems } = useMobileNavigation()

  return (
    <Container aria-label={t(i18Base + '.Title')}>
      {navigationItems.map((item) => (
        <NavItem
          key={item.title}
          to={item.to}
          isActive={item.isActive}
          aria-label={item.title}
          data-tracking-id={`${item.trackingId}-button`}>
          <Icon name={item.icon} size="normal" />
          <NavText aria-hidden data-tracking-id={`${item.trackingId}-text`}>
            {item.title}
          </NavText>
        </NavItem>
      ))}
    </Container>
  )
}
