import { Text } from '@etta/ui/text'
import { LowerCaseWrapper } from './rail-title-styled'

type Props = {
  originTime: string
  destinationTime: string
}

export function RailTitle({ originTime, destinationTime }: Props) {
  return (
    <LowerCaseWrapper data-tracking-id="origin-to-destination-time-text">
      <Text
        variant="title2"
        color="mainText"
        data-tracking-id={'origin-time-text'}
        textTransform="lowercase">
        {originTime}
      </Text>
      {' - '}
      <Text
        variant="title2"
        color="mainText"
        data-tracking-id={'destination-time-text'}
        textTransform="lowercase">
        {destinationTime}
      </Text>
    </LowerCaseWrapper>
  )
}
