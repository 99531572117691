import type { Maybe } from 'fnscript'
import { Error as NestedError, Just, Nothing } from 'fnscript'
import type {
  CalculateFlightEmissionsBatchFlightInput,
  CalculateFlightEmissionsBatchFlightSegmentInput,
} from '@fiji/graphql/types'
import { FLIGHT_ERROR_MESSAGES, isEmptyString, SKIP_VALIDATION_ERROR } from '../common'

type FlightCompareSegments = {
  maybeOriginAirportCode: Maybe<string>
  maybeDestinationAirportCode: Maybe<string>
}

type ValidationArgs = FlightCompareSegments & {
  flights: CalculateFlightEmissionsBatchFlightInput[]
}

const ERROR_MESSAGE = 'CalculateFlightEmissionsBatch Error'

export function airEmissionsQueryExecuteValidation({
  flights,
  maybeOriginAirportCode,
  maybeDestinationAirportCode,
}: ValidationArgs): Maybe<NestedError> {
  if (!flights || flights.length === 0) {
    return Just(NestedError.new(SKIP_VALIDATION_ERROR))
  }

  const maybeFlightCompareSegmentsErrors = validateFlightCompareSegments({
    maybeOriginAirportCode,
    maybeDestinationAirportCode,
  })
  const maybeFlightsError = validateTcFlights(flights)

  if (maybeFlightsError.isValue() || maybeFlightCompareSegmentsErrors.isValue()) {
    return Just(
      NestedError.new(
        `${ERROR_MESSAGE}: ${!maybeFlightsError.isNothing() && maybeFlightsError.getValue()} ${
          !maybeFlightCompareSegmentsErrors.isNothing() &&
          maybeFlightCompareSegmentsErrors.getValue()
        }`,
      ),
    )
  }

  return Nothing()
}

function validateFlightCompareSegments({
  maybeDestinationAirportCode,
  maybeOriginAirportCode,
}: FlightCompareSegments) {
  const compareSegmentErrors: NestedError[] = []

  if (maybeDestinationAirportCode.isNothing() || maybeDestinationAirportCode.getValue() === '') {
    const error = NestedError.new(FLIGHT_ERROR_MESSAGES.DESTINATION_AIRPORT_CODE)
    compareSegmentErrors.push(error)
  }

  if (maybeOriginAirportCode.isNothing() || maybeOriginAirportCode.getValue() === '') {
    const error = NestedError.new(FLIGHT_ERROR_MESSAGES.ORIGIN_AIRPORT_CODE)
    compareSegmentErrors.push(error)
  }

  if (compareSegmentErrors.length > 0) {
    const validationError = NestedError.new(compareSegmentErrors.map((e) => e.text()).join(';'))

    return Just(
      NestedError.new(`failed to validate hotel compare object;${validationError.getMessage()}`),
    )
  }

  return Nothing()
}

export function validateTcFlights(flights: CalculateFlightEmissionsBatchFlightInput[]) {
  const flightErrors: NestedError[] = []

  flights.forEach((flight, idx) => {
    const maybeSegmentErrors = validateTcFlightSegments(flight.segments)

    if (maybeSegmentErrors.isValue()) {
      flightErrors.push(
        NestedError.new(
          `failed to validate flight with index: ${idx};${maybeSegmentErrors.getValue()}`,
        ),
      )
    }
  })

  if (flightErrors.length > 0) {
    const validationError = NestedError.new(flightErrors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}

function validateTcFlightSegments(segments: CalculateFlightEmissionsBatchFlightSegmentInput[]) {
  const segmentErrors: NestedError[] = []

  segments.forEach((segment) => {
    if (isEmptyString(segment.originAirportCode)) {
      const error = NestedError.new(FLIGHT_ERROR_MESSAGES.ORIGIN_AIRPORT_CODE)
      segmentErrors.push(error)
    }

    if (isEmptyString(segment.destinationAirportCode)) {
      const error = NestedError.new(FLIGHT_ERROR_MESSAGES.DESTINATION_AIRPORT_CODE)
      segmentErrors.push(error)
    }

    if (!segment.flightNumber) {
      const error = NestedError.new(FLIGHT_ERROR_MESSAGES.FLIGHT_NUMBER)
      segmentErrors.push(error)
    }

    if (isEmptyString(segment.departureDateTime)) {
      const error = NestedError.new(FLIGHT_ERROR_MESSAGES.DEPARTURE_DATE_TIME)
      segmentErrors.push(error)
    }

    if (isEmptyString(segment.class)) {
      const error = NestedError.new(FLIGHT_ERROR_MESSAGES.CLASS)
      segmentErrors.push(error)
    }
  })

  if (segmentErrors.length > 0) {
    const validationError = NestedError.new(segmentErrors.map((e) => e.text()).join(';'))

    return Just(
      NestedError.new(`failed to validate flight segment args;${validationError.getMessage()}`),
    )
  }

  return Nothing()
}
