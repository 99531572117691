import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { Document360SupportArticleByIdDocument } from '@fiji/graphql/hooks'
import type {
  Document360SupportArticleByIdInput,
  Document360SupportArticleByIdQuery,
  Document360SupportArticleByIdQueryVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class TravelSupportDataProvider extends GraphqlDataProvider {
  getDocument360SupportArticleById(input: Document360SupportArticleByIdInput) {
    return this.client.query<
      Document360SupportArticleByIdQuery,
      Document360SupportArticleByIdQueryVariables
    >({
      query: Document360SupportArticleByIdDocument,
      variables: {
        input,
      },
    })
  }
}
