import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Container } from './amenity-styled'

type Props = {
  value?: string | number
  icon: IconNames
}
export function Amenity({ value, icon }: Props) {
  if (!value) {
    return null
  }

  return (
    <Container tabIndex={-1}>
      <Icon name={icon} size="small" aria-hidden />
      {value}
    </Container>
  )
}
