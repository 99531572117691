import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Container } from '../rail-delivery-guide-modal-styled'
import { ItemBlock } from '../item-block'

const i18nBase = 'RailDeliveryOption.UsageGuideModal.'

export function EticketGuideView() {
  const { t } = useTranslation()

  return (
    <Container>
      <Block>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'EticketGuideSubTitle')}
        </Text>
      </Block>
      <Block marginTop={30}>
        <ItemBlock iconName="mailFilledPWA" itemText={t(i18nBase + 'EticketEmailGuide')} />
        <ItemBlock iconName="printPWA" itemText={t(i18nBase + 'EticketPrintGuide')} />
        <ItemBlock iconName="appPWA" itemText={t(i18nBase + 'EticketAppGuide')} />
      </Block>
    </Container>
  )
}
