import styled, { css } from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { KeyboardNavigationStyle } from '@fiji/style'

export const SwapLocationIcon = styled(Icon).attrs(() => ({
  name: 'returnFlightArrowPWA',
  size: 'normal',
  color: 'mainText',
}))`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`

export const Wrapper = styled.button<{ disabled?: boolean; isCentered?: boolean }>`
  display: flex;
  margin-left: auto;
  ${({ isCentered }) =>
    isCentered &&
    css`
      margin-right: auto;
    `}
  max-width: 32px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.background};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
  align-items: center;
  padding: 0 3px;
  cursor: pointer;

  ${(props) =>
    !props.disabled &&
    css`
      &:not(:active) ${SwapLocationIcon} {
        transition: transform 0.2s;
      }
      &:active ${SwapLocationIcon} {
        transform: rotate(180deg);
      }
    `}
  ${KeyboardNavigationStyle}
`
