import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { LeftIconWrapper } from '@etta/ui/button/button-components'
import { subHeadStrong, KeyboardNavigationStyle } from '@fiji/style'

export const DelegateToggle = styled(Button)<{ isVisible: boolean }>`
  border-radius: 48px;
  padding: 10px 14px;
  margin-right: 12px;
  position: relative;
  display: ${(p) => (p.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.mainText1};
  ${subHeadStrong};
  ${LeftIconWrapper} {
    margin-right: 4px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 30px;
    right: -12px;
    top: calc(50% - 16px);
    background: ${({ theme }) => theme.colors.borderLight};
  }
  ${KeyboardNavigationStyle}
`
