import {
  PinContainer,
  MarkerBorder,
  Marker,
} from './mobility-map-current-location-pin-label-styled'

type Props = {
  isAnimate: boolean
}

export function MobilityMapCurrentLocationPinLabel({ isAnimate }: Props) {
  return (
    <PinContainer>
      <MarkerBorder diameter={'100'} opacity={0.08} isVisible={!isAnimate}>
        <MarkerBorder diameter={'64'} opacity={0.16} isVisible={!isAnimate}>
          <Marker />
        </MarkerBorder>
      </MarkerBorder>
    </PinContainer>
  )
}
