import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Props } from '../types'
import {
  Container,
  LabelGroup,
  Label,
  Value,
  InputButtonContent,
  ToggleIconButtonWrapper,
  ToggleIconButton,
  TextToggleButton,
  DateInputButton,
  TimeInputButton,
} from './date-time-selector-mobile-styled'

const i18nBase = 'Mobility.NewSearchForm.'

export function DateTimeSelectorMobile({
  date,
  time,
  isNow,
  onToggleNow,
  onDateClick,
  onTimeClick,
}: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <DateInputButton onClick={onDateClick}>
        <InputButtonContent>
          <LabelGroup>
            <Icon size={16} name="calendarPWA" />
            <Label>{t(i18nBase + 'Date')}</Label>
          </LabelGroup>
          <Value state={isNow ? 'disabled' : date ? 'value' : 'placeholder'}>
            {date ? date : t(i18nBase + 'Select')}
          </Value>
        </InputButtonContent>
      </DateInputButton>
      <TimeInputButton onClick={onTimeClick}>
        <InputButtonContent style={{ opacity: isNow ? 0 : 1, width: 'calc(100% - 102px)' }}>
          <LabelGroup>
            <Icon size={16} name="timePWA" />
            <Label>{t(i18nBase + 'Time')}</Label>
          </LabelGroup>
          <Value state={time ? 'value' : 'placeholder'}>
            {time ? time : t(i18nBase + 'Select')}
          </Value>
        </InputButtonContent>
        <ToggleIconButtonWrapper>
          <ToggleIconButton
            aria-checked={!!isNow}
            isToggle={isNow}
            onClick={(e) => {
              e.stopPropagation()
              onToggleNow && onToggleNow(!isNow)
            }}>
            <Icon size={16} name="clockPWA" />
            <TextToggleButton variant="footnoteMedium">
              {isNow ? t(i18nBase + 'Now') : t(i18nBase + 'Later')}{' '}
            </TextToggleButton>{' '}
            <Icon size={20} name="chevronDownPWA" />
          </ToggleIconButton>
        </ToggleIconButtonWrapper>
      </TimeInputButton>
    </Container>
  )
}
