import { Action, Inject } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { LocationState } from '../types'
import { UserActivationPaginationStore } from '../stores/user-activation-pagination.store'

@Action()
export class UserActivationPaginationAction {
  constructor(
    @Inject() private readonly historyService: HistoryService<LocationState>,
    @Inject() private readonly historyStore: HistoryStore<LocationState>,
    @Inject() private readonly userActivationPaginationStore: UserActivationPaginationStore,
  ) {}

  initialCreatePassword() {
    const currentStepType = this.userActivationPaginationStore.shouldRenderedCreatePassword
      ? 'create-password'
      : 'user-information'
    this.historyService.replace(this.historyStore.pathname, {
      currentStepType: currentStepType,
    })
  }
}
