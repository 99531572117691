import type { FlightSafetyCheckDetails } from '@fiji/types'
import { FlightSafetyCheckLayout } from './layout'

type Props = {
  detail: FlightSafetyCheckDetails
}

export function FlightSafetyCheckItem({ detail }: Props) {
  const { imageUrl, headline, description, url } = detail

  return (
    <FlightSafetyCheckLayout
      detail={detail}
      isAirlineName={!!(headline && !description && !url && !imageUrl)}
      isDisclaimer={!!(!headline && description && !url && !imageUrl)}
      isSubtitle={!!(headline && !description && url && !imageUrl)}
    />
  )
}
