import { FlightDetailsHeaderLayout } from './layout'
import type { Props } from './types'

export function FlightDetailsHeader({
  title,
  airlinesLogos,
  flightLabels,
  flightStopsLabel,
  headerRef,
  onClose,
}: Props) {
  return (
    <FlightDetailsHeaderLayout
      title={title}
      airlinesLogos={airlinesLogos}
      flightLabels={flightLabels}
      flightStopsLabel={flightStopsLabel}
      headerRef={headerRef}
      onClose={onClose}
    />
  )
}
