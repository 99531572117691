import { SsoTypeVariantEnum } from '@etta/modules/auth/core/enums/sso-type-variant.enum'
import { SsoVariant } from '@fiji/graphql/types'

export class SsoVariantDataMapper {
  static toSsoTypeVariantEnum(ssoVariant: SsoVariant): SsoTypeVariantEnum {
    switch (true) {
      case ssoVariant === SsoVariant.Both:
        return SsoTypeVariantEnum.Both
      case ssoVariant === SsoVariant.Sso:
        return SsoTypeVariantEnum.Sso
      case ssoVariant === SsoVariant.Standard:
        return SsoTypeVariantEnum.Standard
      default:
        return SsoTypeVariantEnum.Standard
    }
  }
}
