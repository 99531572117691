// eslint-disable-next-line import/no-restricted-paths
import type { GazooMembership } from '@fiji/graphql/hooks'
import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { SuggestedHomeAirportEntity } from '../../core/entities/suggested-home-airport.entity'
import type { VendorEntity } from '../../core/entities/available-vendors.entity'
import type { AirTravelPreferenceInputValueObject } from '../../core/value-objects/air-travel-preference-input.value-object'
import type { SpecialRequestEntity } from '../../core/entities/available-special-requests.entity'
import { ValidatorMaker } from '../../validators/validator-maker'
import { FieldSettingsStore } from './field-settings'

@Store()
export class FlightPreferencesStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  isSaveButtonDisabled = true

  toggle = new Toggle()
  airLoyaltyProgramActionToggle = new Toggle()
  airLoyaltyProgramRemoveToggle = new Toggle()

  suggestedHomeAirports: SuggestedHomeAirportEntity[] = []
  availableAirVendors: VendorEntity[] | null = null
  flightAvailableSpecialRequests: SpecialRequestEntity[] | null = null

  airLoyaltyProgram: GazooMembership = {
    vendorCode: '',
    number: '',
  }
  isEditExistingProgramMode: boolean = false
  isSuggestedHomeAirportsLoading: boolean = false
  isAvailableAirVendorsLoading: boolean = false
  isAvailableFlightSpecialRequestsLoading: boolean = false

  flightPreferencesValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makeFlightPreferencesValidator()

  setFlightPreferencesValue<T extends keyof AirTravelPreferenceInputValueObject>(
    field: T,
    value: AirTravelPreferenceInputValueObject[T],
  ) {
    this.flightPreferencesValidator.onFieldChange(field, value)
  }

  setFlightPreferencesInput(input: AirTravelPreferenceInputValueObject) {
    this.setFlightPreferencesValue('homeAirport', input.homeAirport)
    this.setFlightPreferencesValue('mealPreference', input.mealPreference)
    this.setFlightPreferencesValue('seatPreference', input.seatPreference)
    this.setFlightPreferencesValue('airSpecialRequest', input.airSpecialRequest)
    this.setFlightPreferencesValue('noteToArranger', input.noteToArranger)
    this.setFlightPreferencesValue('airMembership', input.airMembership)
  }

  setSuggestedHomeAirports(suggestedHomeAirports: SuggestedHomeAirportEntity[]) {
    this.suggestedHomeAirports = suggestedHomeAirports
  }

  resetSuggestedHomeAirports() {
    this.suggestedHomeAirports = []
  }

  setIsSuggestedHomeAirportsLoading(isLoading: boolean) {
    this.isSuggestedHomeAirportsLoading = isLoading
  }

  get suggestedHomeAirportsOptions() {
    const { homeAirport } = this.flightPreferencesValidator.values

    if (!this.suggestedHomeAirports.length && homeAirport) {
      return [
        {
          value: homeAirport,
          label: homeAirport,
          metadata: {
            address: undefined,
            airportName: undefined,
          },
        },
      ]
    }

    return this.suggestedHomeAirports.map(({ airportCode, airportName, name, address }) => ({
      value: airportCode || '',
      label: name || '',
      metadata: {
        address: `${address?.city}${address?.stateCode ? ', ' + address.stateCode : ''}`,
        airportName: airportName && airportCode ? `${airportName} (${airportCode})` : '',
      },
    }))
  }

  get isViewingExistingMembership(): Boolean {
    const { vendorCode } = this.airLoyaltyProgram
    const airMemberships = this.flightPreferencesValidator.values.airMembership as GazooMembership[]
    return !!airMemberships.find((item) => item.vendorCode === vendorCode)
  }

  setAirLoyaltyProgram(loyaltyProgram: GazooMembership) {
    this.airLoyaltyProgram = loyaltyProgram
  }

  resetAirLoyaltyProgram() {
    this.airLoyaltyProgram = {
      vendorCode: '',
      number: '',
    }
  }

  setAvailableAirVendors(availableVendors: VendorEntity[] | null) {
    this.availableAirVendors = availableVendors
  }

  setFlightAvailableSpecialRequests(specialRequests: SpecialRequestEntity[] | null) {
    this.flightAvailableSpecialRequests = specialRequests
  }

  setIsEditExistingProgramMode(isEditMode: boolean) {
    this.isEditExistingProgramMode = isEditMode
  }

  setIsAvailableAirVendorsLoading(isLoading: boolean) {
    this.isAvailableAirVendorsLoading = isLoading
  }

  setIsAvailableFlightSpecialRequestsLoading(isLoading: boolean) {
    this.isAvailableFlightSpecialRequestsLoading = isLoading
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
