import type { Rate } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import { useFlightDetails } from './use-flight-details'

type Props = {
  flightDirection: string
  flightRate?: Rate
  feesAndTaxesRate?: Rate
  totalRate?: Rate
  transactionFeeRate?: Rate
  unusedTicketRate?: Rate | null
  unusedTicketAmount?: Rate | null
  unusedTicketForfeiture?: Rate | null
  serviceFeeRate?: Rate | null
}

export function FlightDetailsPrice({
  flightDirection,
  flightRate,
  feesAndTaxesRate,
  totalRate,
  transactionFeeRate,
  unusedTicketRate,
  unusedTicketAmount,
  unusedTicketForfeiture,
  serviceFeeRate,
}: Props) {
  const { totalRateTitle, priceDetailsList } = useFlightDetails({
    flightDirection,
    flightRate,
    feesAndTaxesRate,
    totalRate,
    transactionFeeRate,
    unusedTicketRate,
    unusedTicketAmount,
    unusedTicketForfeiture,
    serviceFeeRate,
  })

  return <PriceDetails priceDetailsList={priceDetailsList} totalCost={totalRateTitle} />
}
