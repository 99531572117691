import { GalleryOpener } from './hotel-details-photos-styled'
import { ImageContent } from './image-content'

type Props = {
  backgroundImage?: string
  photosNumber: number
  onClick: () => void
  alt: string
}

export function GalleryLink({ backgroundImage, photosNumber, onClick, alt }: Props) {
  return (
    <GalleryOpener>
      <ImageContent
        skin="gallery-link"
        src={backgroundImage}
        photosNumber={photosNumber}
        onClick={onClick}
        alt={alt}
      />
    </GalleryOpener>
  )
}
