import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  GetCarDetailsDocument,
  GetCarRentalMultiVendorLocationsDocument,
  GetCarRentalsDocument,
  GetCarRulesDocument,
} from '@fiji/graphql/hooks'
import type {
  GetCarRentalMultiVendorLocationsQuery,
  GetCarRentalMultiVendorLocationsQueryVariables,
  GetCarRentalsQuery,
  GetCarRentalsQueryVariables,
  GetCarDetailsQuery,
  GetCarDetailsQueryVariables,
  GetCarRulesQuery,
  GetCarRulesQueryVariables,
} from '@fiji/graphql/hooks'

@DataProvider()
export class CarRentalDataProvider extends GraphqlDataProvider {
  getCarRentalsAbortController: AbortController | null = null
  getCarDetailsAbortController: AbortController | null = null

  getCarRentalLocations(input: GetCarRentalMultiVendorLocationsQueryVariables['input']) {
    return this.client.query<
      GetCarRentalMultiVendorLocationsQuery,
      GetCarRentalMultiVendorLocationsQueryVariables
    >({
      query: GetCarRentalMultiVendorLocationsDocument,
      variables: {
        input,
      },
    })
  }

  getCarRentalsWithAbortController(
    input: GetCarRentalsQueryVariables['input'],
    forceUpdate?: boolean,
  ) {
    const controller = new AbortController()
    this.getCarRentalsAbortController?.abort()
    this.getCarRentalsAbortController = controller

    return this.client.query<GetCarRentalsQuery, GetCarRentalsQueryVariables>({
      query: GetCarRentalsDocument,
      variables: { input },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  getCarDetailsWithAbortController(input: GetCarDetailsQueryVariables, forceUpdate?: boolean) {
    const controller = new AbortController()
    this.getCarDetailsAbortController?.abort()
    this.getCarDetailsAbortController = controller

    return this.client.query<GetCarDetailsQuery, GetCarDetailsQueryVariables>({
      query: GetCarDetailsDocument,
      variables: input,
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  getCarRules(input: GetCarRulesQueryVariables) {
    return this.client.query<GetCarRulesQuery, GetCarRulesQueryVariables>({
      query: GetCarRulesDocument,
      variables: input,
    })
  }
}
