import type { PropsWithChildren, ReactNode } from 'react'
import { useFijiLoader } from './use-fiji-loader'

export function FijiLoader({ children, loaderSlot }: PropsWithChildren<{ loaderSlot: ReactNode }>) {
  const { isDeemHeaderLoading } = useFijiLoader()

  if (isDeemHeaderLoading) {
    return <>{loaderSlot}</>
  }

  return <>{children}</>
}
