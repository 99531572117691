import { observer } from 'mobx-react-lite'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { CancelTripModal } from '@etta/components/cancel-trip-modal/cancel-trip-modal'
import { EditTripNameDialog } from '@etta/components/edit-trip-name-dialog/edit-trip-name-dialog'
import { AirSeatMapModal } from '@etta/screens/trip-flight/flight-details/components'
import { useAirSeatMapData } from '@etta/screens/trip-flight/flight-details/use-flight-details'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripDetailsDynamicSiteMessages } from '@etta/screens/trip-details/details/layout/common'
import { Block } from '@etta/ui/block'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { WarningCancelRoundFlightModal } from '@etta/components/cancel-trip-modal/warning-cancel-round-flight-modal'
import { MultipleHotelProvidersDialog } from '@etta/modules/multiple-hotel-providers/ui/multiple-hotel-providers-dialog'
import { TripTrainOpenReturnModal } from '@etta/components/trip-train-open-return-modal'
import { RailPermittedStationsModal } from '@etta/components/rail-permitted-stations'
import { RailTicketRestrictions } from '@etta/components/rail-ticket-restrictions'
import { RulesAndRestrictionsModal } from '../../etta-components/rules-and-restrictions/rules-and-restrictions-modal'
import { TripDetailsModal } from './trip-details-modal'
import { DetailsFooter, DetailsHeader } from './components'
import { RefCode } from './ref-code'
import { TripDetailsLayout } from './layout'
import { useTripDetailsContextSelector } from './trip-details-provider'
import { Details } from './details'
import { PrintItinerary } from './print-itinerary'
import { ETicketReceipt } from './e-ticket-receipt'

export const TripDetailsContent = observer(function TripDetailsContent() {
  const { headerRef, scrollContainerRef, anchorRef } = useHeaderScroll()
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const {
    isTripOnHold,
    tripDetails,
    tripName,
    editNameToggle,
    handleUpdateTripName,
    handleBackButtonClick,
    tripEmissionsResult,
    areTripSegmentsEmissionsLoading,
    trainCancelModalTitle,
    trainCancelModalDescription,
    isTripInProgress,
    actionType,
    multipleHotelProvidersTripDialog,
    handleClickMultipleHotelProviders,
    handleBackMultipleHotelProviders,
    permittedStationsToggle,
    ticketRestrictionsToggle,
    isTicketRestrictionsOpen,
    isPermittedStationsOpen,
    eTicketViewStateParams,
    handleDownloadETicket,
    rulesAndRestrictionsToggle,
    itineraryRules,
    showRulesAndRestrictions,
    printItineraryToggle,
    eTicketReceiptToggle,
    onOpenETicketReceipt,
    selectedETicketIndex,
    travelerName,
    printItineraryContentRef,
    eTicketReceiptContentRef,
    printItineraryContent,
    printETicketReceipt,
  } = useTripDetailsContextSelector((state) => state)
  const { postBookingTripStore } = usePostBookingContext()
  const airSeatMapData = useAirSeatMapData()

  return (
    <>
      <TripDetailsLayout
        currentDelegatedUser={tripDetails.currentDelegatedUser}
        isShowDelegateName={tripDetails.isShowDelegateName}
        scrollContainerRef={scrollContainerRef}
        onContinueBooking={tripDetails.handleModify}
        onETicketsDownloading={handleDownloadETicket}
        onOpenRulesAndRestrictions={rulesAndRestrictionsToggle.handleOpen}
        showRulesAndRestrictions={showRulesAndRestrictions}
        onOpenETicketReceipt={onOpenETicketReceipt}
        headerSlot={
          <>
            <DetailsHeader
              tripName={tripName}
              headerRef={headerRef}
              onEdit={editNameToggle.handleOpen}
              onShare={tripDetails.handleShare}
              onBackButtonClick={handleBackButtonClick}
              onOpenPrintItinerary={printItineraryToggle.handleOpen}
            />
            <div ref={anchorRef} />
          </>
        }
        contentSlot={
          <Details
            bookingRefSlot={
              <Block paddingHorizontal={16}>
                <RefCode
                  tKey={
                    brandConfigurationStore.isAppleTheme ? 'AppleTravelReference' : 'RecordLocator'
                  }
                  tVal="recordLocator"
                  reference={postBookingTripStore.pnrNumber}
                />
                <RefCode
                  tKey={'DeemRef'}
                  tVal="bookingRef"
                  reference={postBookingTripStore.trip.bookingRef}
                  showSeparator={false}
                />
                {postBookingTripStore.trainConfirmationNumber && (
                  <RefCode
                    tKey={'RailConfirmationNumber'}
                    tVal="railConfirmationNumber"
                    reference={postBookingTripStore.trainConfirmationNumber}
                    showSeparator={false}
                  />
                )}
                {postBookingTripStore.appliedRailCard && (
                  <RefCode
                    tKey={'AppliedRailCard'}
                    tVal="appliedRailCard"
                    reference={postBookingTripStore.appliedRailCard}
                    showSeparator={false}
                  />
                )}
              </Block>
            }
            dynamicSiteMessagesSlot={<TripDetailsDynamicSiteMessages />}
            onRetry={tripDetails.handleRefetchRequest}
            onShare={tripDetails.handleShare}
            onModify={tripDetails.handleModify}
            onETicketsDownloading={handleDownloadETicket}
            tripEmissionsResult={tripEmissionsResult}
            onSeatMapOpen={airSeatMapData.airSeatMapToggle.onOpen}
            areTripSegmentsEmissionsLoading={areTripSegmentsEmissionsLoading}
            isTripInProgress={isTripInProgress}
            onOpenRulesAndRestrictions={rulesAndRestrictionsToggle.handleOpen}
            showRulesAndRestrictions={showRulesAndRestrictions}
            printETicketReceipt={printETicketReceipt}
            printItineraryContent={printItineraryContent}
            travelerName={travelerName}
          />
        }
        footerSlot={
          <DetailsFooter isOnHold={isTripOnHold} onContinueBooking={tripDetails.handleModify} />
        }
      />
      <EditTripNameDialog
        onTripNameUpdate={handleUpdateTripName}
        isOpen={editNameToggle.isOpen}
        value={tripName}
        onClose={editNameToggle.handleClose}
      />
      <TripDetailsModal
        onSeatMapOpen={airSeatMapData.airSeatMapToggle.onOpen}
        isTripInProgress={isTripInProgress}
      />
      <TripTrainOpenReturnModal />
      <RailTicketRestrictions
        isVisible={isTicketRestrictionsOpen}
        onClose={ticketRestrictionsToggle.handleClose}
        isPostBooking={true}
      />
      <RailPermittedStationsModal
        isVisible={isPermittedStationsOpen}
        onClose={permittedStationsToggle.handleClose}
        isPostBooking={true}
      />

      {airSeatMapData.isSeatMapDataAvailable && (
        <AirSeatMapModal
          segments={airSeatMapData.airSeatMapSegments}
          chosenAirSeatMapLegId={airSeatMapData.chosenAirSeatMapLegId}
          onClose={airSeatMapData.airSeatMapToggle.onClose}
          isOpen={airSeatMapData.airSeatMapToggle.isOpen}
          tripName={tripName}
        />
      )}
      <ViewStateModal viewState={tripDetails.viewState} onDone={tripDetails.onCloseViewState} />
      <ViewStateModal
        viewState={eTicketViewStateParams.viewState}
        error={eTicketViewStateParams.error}
        onDone={eTicketViewStateParams.onClose}
      />
      <CancelTripModal
        tripTitle={tripName}
        trainCancelTitle={trainCancelModalTitle}
        trainErrorDescription={trainCancelModalDescription}
        isTripInProgress={isTripInProgress}
      />

      <WarningCancelRoundFlightModal />

      <MultipleHotelProvidersDialog
        isOpen={multipleHotelProvidersTripDialog.isOpen}
        actionType={actionType}
        onClick={handleClickMultipleHotelProviders}
        onClose={multipleHotelProvidersTripDialog.handleClose}
        onBack={handleBackMultipleHotelProviders}
      />

      <RulesAndRestrictionsModal
        isOpen={rulesAndRestrictionsToggle.isOpen}
        onClose={rulesAndRestrictionsToggle.handleClose}
        itineraryRules={itineraryRules}
      />

      <PrintItinerary
        trip={tripDetails.trip}
        isOpen={printItineraryToggle.isOpen}
        onClose={printItineraryToggle.handleClose}
        travelerName={travelerName}
        handlePrint={printItineraryContent}
        printableContentRef={printItineraryContentRef}
      />

      <ETicketReceipt
        trip={tripDetails.trip}
        isOpen={eTicketReceiptToggle.isOpen}
        onClose={eTicketReceiptToggle.handleClose}
        travelerName={travelerName}
        handlePrint={printETicketReceipt}
        printableContentRef={eTicketReceiptContentRef}
        selectedETicketIndex={selectedETicketIndex}
      />
    </>
  )
})
