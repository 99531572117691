import { MediaLayout } from '@etta/ui/media-layout'
import { SelectExpenseCodeMobile } from './mobile'
import type { LayoutProps } from './types'

export function SelectExpenseCodeLayout(props: LayoutProps) {
  // TODO: Replace desktop layout
  return (
    <MediaLayout
      mobileSlot={<SelectExpenseCodeMobile {...props} />}
      desktopSlot={<SelectExpenseCodeMobile {...props} />}
    />
  )
}
