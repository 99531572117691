import styled from 'styled-components'
import { footnoteStrong, UnstyledButton } from '@fiji/style'

export const AddNewCardContainer = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 14px 0;
`

export const CardIconWrapper = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background};
  overflow: hidden;
  min-width: 38px;
`

export const AddNewCardTitle = styled.div`
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.mainText};
  margin-bottom: 3px;
  ${footnoteStrong};
`
