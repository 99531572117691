import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { ColorPalette } from '@fiji/types'
import type { Rate } from '@fiji/graphql/types'
import type { Props } from './types'

type DetailsPrice = {
  title: string
  value: string
  color: keyof ColorPalette
}

export function useRailDetailsPrices({ rate, totalCost, taxes }: Props) {
  const { t } = useTranslation()
  const i18Base = 'RailDetailsModal.PriceDetails'

  const getPriceDetail = useCallback(
    (translationKey: string, rateValue?: Rate | null): DetailsPrice | null => {
      if (!rateValue) {
        return null
      }

      return {
        title: t(`${i18Base}.${translationKey}`),
        value: getMainCost(rateValue),
        color: 'bodyText',
      }
    },
    [t],
  )

  return useMemo(() => {
    const { mainCost: estTotalCost } = formatRate(totalCost, {
      morpheme: 'none',
    })

    const priceDetailsList = [
      getPriceDetail('TrainFare', rate),
      getPriceDetail('Taxes', taxes),
    ].filter(Boolean)

    return {
      priceDetailsList,
      estTotalCost,
    }
  }, [getPriceDetail, rate, taxes, totalCost])
}

function getMainCost(rate: Rate) {
  return formatRate(rate, { morpheme: 'none' }).mainCost
}
