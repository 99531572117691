import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import type { PreferencesUK, PreferencesEU } from '@etta/screens/trip-review-seat-preferences/types'
import type { SeatPreferences } from '@fiji/graphql/types'
import { SeatReservationType } from '@fiji/graphql/types'
import { getLabelFromValue } from './get-label-from-vallue'

type Props = {
  appliedPreferencesUK: PreferencesUK
  appliedPreferencesEU: PreferencesEU
  preferenceOptions: SeatPreferences
  currentSeatReservationType: SeatReservationType[]
  isUpdatedSeatPreferences: boolean
}

const i18nBase = 'RailDetailsModal.FaresDetails.'

export function useSelectedSeatsLabel({
  appliedPreferencesUK,
  appliedPreferencesEU,
  preferenceOptions,
  currentSeatReservationType,
  isUpdatedSeatPreferences,
}: Props) {
  const { t } = useTranslation()

  const seatPositionsUK = useMemo(
    () =>
      getLabelFromValue({
        value: appliedPreferencesUK.seatPositions,
        preference: preferenceOptions.seatPositions,
      }),
    [appliedPreferencesUK.seatPositions, preferenceOptions.seatPositions],
  )

  const seatDirectionsUK = useMemo(
    () =>
      getLabelFromValue({
        value: appliedPreferencesUK.seatDirections,
        preference: preferenceOptions.seatDirections,
      }),
    [appliedPreferencesUK.seatDirections, preferenceOptions.seatDirections],
  )

  const carriageTypesUK = useMemo(
    () =>
      getLabelFromValue({
        value: appliedPreferencesUK.carriageTypes,
        preference: preferenceOptions.carriageTypes,
      }),
    [appliedPreferencesUK.carriageTypes, preferenceOptions.carriageTypes],
  )

  const seatFacilitiesUK = useMemo(
    () =>
      appliedPreferencesUK.seatFacilities.map((item) =>
        getLabelFromValue({ value: item, preference: preferenceOptions.seatFacilities }),
      ),
    [appliedPreferencesUK.seatFacilities, preferenceOptions.seatFacilities],
  )

  const isSeatRequested = useMemo(
    () => [
      appliedPreferencesUK.isSeatRequestedOnDepart,
      appliedPreferencesUK.isSeatRequestedOnReturn,
    ],
    [appliedPreferencesUK.isSeatRequestedOnDepart, appliedPreferencesUK.isSeatRequestedOnReturn],
  )

  const seatReserved = useCallback(
    (index: number) => {
      if (currentSeatReservationType[index] === SeatReservationType.Mandatory) {
        return t(i18nBase + 'Seat.Optional.WillReserved')
      }

      if (
        currentSeatReservationType[index] === SeatReservationType.Optional &&
        isUpdatedSeatPreferences
      ) {
        return isSeatRequested[index]
          ? t(i18nBase + 'Seat.Optional.WillReserved')
          : t(i18nBase + 'Seat.Optional.WillNotReserved')
      }

      return ''
    },
    [currentSeatReservationType, isSeatRequested, isUpdatedSeatPreferences, t],
  )

  const isSeatReservationRequired = (
    reservationType: SeatReservationType,
    index: number,
    isSeatRequested: (boolean | null)[],
  ) => {
    return (
      (isSeatRequested[index] || reservationType === SeatReservationType.Mandatory) &&
      reservationType !== SeatReservationType.None
    )
  }

  const formatSeatDetailsUK = (
    index: number,
    seatPositionsUK: string | undefined,
    seatDirectionsUK: string | undefined,
    carriageTypesUK: string | undefined,
    seatFacilitiesUK: (string | undefined)[],
  ) => {
    return [
      seatReserved(index),
      seatPositionsUK,
      seatDirectionsUK,
      carriageTypesUK,
      ...seatFacilitiesUK,
    ]
      .filter(Boolean)
      .join(', ')
  }

  const selectedSeatsLabelUK = currentSeatReservationType.map((reservationType, index) =>
    isSeatReservationRequired(reservationType, index, isSeatRequested)
      ? formatSeatDetailsUK(
          index,
          seatPositionsUK,
          seatDirectionsUK,
          carriageTypesUK,
          seatFacilitiesUK,
        )
      : seatReserved(index),
  )

  const seatPositionsEU = useMemo(
    () =>
      getLabelFromValue({
        value: appliedPreferencesEU.seatPositions,
        preference: preferenceOptions.seatPositions,
      }),
    [appliedPreferencesEU.seatPositions, preferenceOptions.seatPositions],
  )

  const seatDirectionsEU = useMemo(
    () =>
      getLabelFromValue({
        value: appliedPreferencesEU.seatDirections,
        preference: preferenceOptions.seatDirections,
      }),
    [appliedPreferencesEU.seatDirections, preferenceOptions.seatDirections],
  )

  const selectedSeatsLabelEU =
    [
      seatPositionsEU,
      seatDirectionsEU,
      appliedPreferencesEU.sitNear.seat
        ? t(i18nBase + 'SeatNumber', { number: appliedPreferencesEU.sitNear.seat })
        : '',
      appliedPreferencesEU.sitNear.carriage
        ? t(i18nBase + 'CarriageNumber', { number: appliedPreferencesEU.sitNear.carriage })
        : '',
    ]
      .filter(Boolean)
      .join(', ') || ''

  const currentSelectedSeatsLabel = selectedSeatsLabelEU.length
    ? [selectedSeatsLabelEU]
    : selectedSeatsLabelUK

  return {
    currentSelectedSeatsLabel,
  }
}
