import type { AdaptiveRail } from '@etta/components/rail-details/types'
import { RailTravelRouteDetails } from '@etta/components/rail-travel-route-details/rail-travel-route-details'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { CollapsedView } from '../collapsed-view'
import { Container } from './expanded-view-styled'

type Props = {
  rail: AdaptiveRail
}

export function ExpandedView({ rail }: Props) {
  const { adaptedSegments } = rail

  return (
    <>
      <CollapsedView rail={rail} />
      <Container data-tracking-id="rail-details-legend-expanded">
        {adaptedSegments.map((segment, index) => {
          const {
            id,
            arrival,
            departure,
            railLogo,
            railTime,
            trainId,
            carrierName,
            transportationMode,
          } = segment

          const isNextSegmentTrain =
            adaptedSegments[index + 1] &&
            adaptedSegments[index + 1].transportationMode === RailTransportationModeEnum.Train

          return (
            <RailTravelRouteDetails
              key={id}
              stops={segment.stops}
              travelTime={railTime}
              changeDuration={segment.changeDuration}
              carrierName={carrierName}
              railLogo={railLogo}
              trainNumber={trainId}
              departure={departure}
              arrival={arrival}
              transportationMode={transportationMode || RailTransportationModeEnum.Unspecified}
              isFirstSegment={index === 0}
              isLastSegment={index === adaptedSegments.length - 1}
              isNextSegmentTrain={isNextSegmentTrain}
            />
          )
        })}
      </Container>
    </>
  )
}
