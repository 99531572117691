import type { RideHailLocation } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'

export function mapToRideHailLocation(place: Place, address?: string): RideHailLocation {
  if (place.latitude && place.longitude) {
    return {
      geocode: { lat: place.latitude, long: place.longitude },
      address: place.name,
    }
  }

  return { address: address ? address : place.name }
}
