export default function () {
  return (
    <svg width="94" height="90" viewBox="0 0 94 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="47.4999" r="42.4999" fill="#ECEDEE" />
      <path
        d="M42.3959 65.2727C42.3429 65.202 42.2851 65.1246 42.2227 65.0408C41.8588 64.5526 41.3387 63.8467 40.7143 62.9771C39.4651 61.2374 37.8005 58.845 36.1368 56.2318C34.4719 53.617 32.8138 50.7902 31.5735 48.1804C30.3237 45.5504 29.5342 43.2125 29.5342 41.5505C29.5342 34.7113 35.2453 29.1893 42.3959 29.1893C49.5464 29.1893 55.2576 34.7113 55.2576 41.5505C55.2576 43.2125 54.4681 45.5504 53.2182 48.1804C51.978 50.7902 50.3198 53.617 48.655 56.2318C46.9912 58.845 45.3266 61.2374 44.0775 62.9771C43.4531 63.8467 42.9329 64.5526 42.5691 65.0408C42.5067 65.1246 42.4488 65.202 42.3959 65.2727ZM35.8219 41.5482C35.8219 45.1174 38.7378 47.8946 42.3959 47.8946C46.054 47.8946 48.9698 45.1173 48.9698 41.5482C48.9698 37.9791 46.0539 35.2018 42.3959 35.2018C38.7353 35.2018 35.8219 37.9816 35.8219 41.5482Z"
        fill="#101820"
        stroke="#101820"
      />
      <path
        d="M94 19C94 29.4934 85.4917 38 75 38C64.5063 38 56 29.4934 56 19C56 8.50664 64.5063 0 75 0C85.4917 0 94 8.50664 94 19Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.0954 19.1674C90.0954 27.4114 83.4122 34.0951 75.166 34.0951C66.9215 34.0951 60.2383 27.4114 60.2383 19.1674C60.2383 10.9217 66.9215 4.23798 75.166 4.23798C83.4122 4.23798 90.0954 10.9217 90.0954 19.1674Z"
        fill="#ECEDEE"
      />
      <path
        d="M83.7292 26.7308L83.7304 26.732C83.8495 26.8499 83.9168 27.0144 83.9168 27.2157C83.9168 27.6279 83.6279 27.9164 83.2322 27.9164C83.0165 27.9164 82.8388 27.8457 82.6989 27.7139L78.3409 23.3867L77.9096 22.9585L77.4012 23.2915C76.2838 24.0235 74.9414 24.452 73.5081 24.452C69.6017 24.452 66.4172 21.2872 66.4172 17.4343C66.4172 13.5815 69.6017 10.4167 73.5081 10.4167C77.4146 10.4167 80.5991 13.5815 80.5991 17.4343C80.5991 18.9311 80.1227 20.3191 79.3142 21.4493L78.9433 21.9678L79.3952 22.4175L83.7292 26.7308ZM67.0091 17.4343C67.0091 20.9841 69.9421 23.8796 73.5081 23.8796C77.0741 23.8796 80.0071 20.9841 80.0071 17.4343C80.0071 13.8953 77.075 10.9891 73.5081 10.9891C69.9413 10.9891 67.0091 13.8953 67.0091 17.4343Z"
        fill="#27313B"
        stroke="#27313B"
        strokeWidth="1.50037"
      />
    </svg>
  )
}
