import type { ReactNode } from 'react'
import { Block } from '@etta/ui/block'

type Props = {
  isHoldFlow?: boolean
  isHoldButtonVisible?: boolean
  holdButtonSlot: ReactNode
  buttonSlot: ReactNode
}

export function FooterButtons({
  holdButtonSlot,
  isHoldButtonVisible,
  buttonSlot,
  isHoldFlow,
}: Props) {
  if (isHoldFlow) {
    return <>{holdButtonSlot}</>
  }

  return (
    <>
      {buttonSlot}
      {isHoldButtonVisible && <Block marginTop={15}>{holdButtonSlot}</Block>}
    </>
  )
}
