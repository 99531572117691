import { Inject, Service } from '@etta/di'
import { TravelPreferencesAdapter } from '../../infra/travel-preferences/travel-preferences.adapter'
import { TravelPreferencesStore } from '../stores/travel-preferences.store'
import { UserProfileService } from './get-user-profile.service'

@Service()
export class UpdateTravelPreferencesService {
  constructor(
    @Inject() private travelPreferencesAdapter: TravelPreferencesAdapter,
    @Inject() private travelPreferencesStore: TravelPreferencesStore,
    @Inject() private readonly userProfileService: UserProfileService,
  ) {}

  async update(): Promise<boolean> {
    const {
      homeAirport,
      mealPreference,
      seatPreference,
      airSpecialRequest,
      noteToArranger,
      airMembership,
    } = this.travelPreferencesStore.validators.flightPreferenceValidator.values

    const {
      amenity,
      membership: hotelMembership,
      specialRequest: hotelSpecialRequest,
    } = this.travelPreferencesStore.validators.hotelPreferencesValidator.values

    const {
      carClass,
      membership: carRentalMembership,
      specialRequest: carRentalSpecialRequest,
    } = this.travelPreferencesStore.validators.carrentalPreferencesValidator.values

    const updateCalls = [
      this.travelPreferencesAdapter.updateAir({
        homeAirport: homeAirport || undefined,
        mealPreference: mealPreference ?? undefined,
        seatPreference: this.travelPreferencesStore.isShowAirSeatPreference
          ? seatPreference
          : undefined,
        airSpecialRequest,
        noteToArranger: noteToArranger || undefined,
        airMembership,
      }),
      this.travelPreferencesAdapter.updateHotel({
        amenity,
        hotelMembership,
        hotelSpecialRequest,
      }),
      this.travelPreferencesAdapter.updateCarRental({
        carClassWithNone: this.getValueOrNone(carClass) ?? undefined,
        carRentalMembership,
        carRentalSpecialRequest,
      }),
    ]

    if (this.travelPreferencesStore.validators.additionalInformationValidator?.values) {
      updateCalls.push(
        this.userProfileService.saveUserProfileTravelPreferences(
          this.travelPreferencesStore.validators.additionalInformationValidator.values,
        ),
      )
    }

    const results = await Promise.all(updateCalls)

    return results.every((v) => v.isOk())
  }

  private getValueOrNone<T>(value: T | '') {
    if (value === '') {
      return 'NONE'
    }
    return value
  }
}
