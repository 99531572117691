import styled from 'styled-components'
import { footnoteMedium, footnoteStrong } from '@fiji/style'

export const Section = styled.section`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
`

export const Rows = styled.div`
  margin-top: 12px;
`

export const Title = styled.div`
  ${footnoteStrong};
  color: ${(props) => props.theme.colors.mainText};
  margin-bottom: 4px;
`

export const PaymentContainer = styled.div`
  ${footnoteStrong};
  color: ${(props) => props.theme.colors.mainText};
  margin-top: 24px;
  display: flex;
`

export const PaymentTitle = styled.div`
  flex: 1;
`

export const PaymentDetails = styled.div`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  margin-top: 5px;
  margin-right: 4px;
`
