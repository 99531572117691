import styled from 'styled-components'

export const Container = styled.div`
  padding: 12px 15px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`
