import type { ReactNode } from 'react'
import { Container } from './route-container-styled'

type Props = {
  children: ReactNode
  isAnimationAvailable: boolean
}

export function RouteContainer({ children, isAnimationAvailable }: Props) {
  return <Container isAnimationAvailable={isAnimationAvailable}>{children}</Container>
}
