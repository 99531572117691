import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const PayableSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0px 16px 0px;
`

export const SectionDescription = styled.span`
  color: ${({ theme }) => theme.colors.mainText1};

  ${footnoteMedium};
`
