import { useMemo } from 'react'
import type {
  AvailableSegmentsValueObject,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { SupportedActionsV2Fragment } from '@fiji/graphql/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'

type Resolution = {
  canAddFlight: boolean
  canAddHotel: boolean
  canAddCarRental: boolean
  canAddTrain: boolean
}

type Props = {
  segments: TripSegmentValueObject[]
  availableSegments?: AvailableSegmentsValueObject | null
  supportedActions?: SupportedActionsV2Fragment | null
  isPostPurchase?: boolean
  isMultiCity?: boolean
}

export function useResolveTripReviewAddButtons({
  segments = [],
  availableSegments,
  supportedActions,
  isPostPurchase,
  isMultiCity,
}: Props) {
  const { segmentTypeCheckActions, activeTripStore } = useReviewTripContext()

  const {
    featureFlags: { isAddTrainToItineraryEnabled },
  } = useFeatureFlags()
  const isFlightInTrip = segments.some(segmentTypeCheckActions.isFlightSegment)
  const isTrainInTrip = segments.some(segmentTypeCheckActions.isTrainSegment)
  const { isMaximumCarRentalQuantity, isMaximumHotelQuantity } = activeTripStore

  return useMemo<Resolution>(() => {
    const isEmpty = !segments.length

    const canAddFlight =
      !!availableSegments?.canAddFlight &&
      (isEmpty || (!isFlightInTrip && !isTrainInTrip)) &&
      !isPostPurchase &&
      !isMultiCity

    const canAddHotel = supportedActions
      ? !!supportedActions?.allowAddHotel
      : !!availableSegments?.canAddHotel && (isEmpty || !isMaximumHotelQuantity)

    const canAddCarRental = supportedActions
      ? !!supportedActions?.allowAddCar
      : !!availableSegments?.canAddCarRental && (isEmpty || !isMaximumCarRentalQuantity)

    const canAddTrain =
      !!isAddTrainToItineraryEnabled &&
      (isEmpty || (!isFlightInTrip && !isTrainInTrip)) &&
      !isPostPurchase

    return {
      canAddFlight,
      canAddHotel,
      canAddCarRental,
      canAddTrain,
    }
  }, [
    availableSegments,
    isMaximumCarRentalQuantity,
    isMaximumHotelQuantity,
    isAddTrainToItineraryEnabled,
    isFlightInTrip,
    isPostPurchase,
    isTrainInTrip,
    segments.length,
    supportedActions,
    isMultiCity,
  ])
}
