import { useState } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import type { RideHailAirportPickupFlightValueObject } from '@etta/modules/ride-hail/core/value-objects/ride-hail-airport-pickup.value-object'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export function useChangeFlightModal({ onClose }: Props) {
  const { rideHailAirportPickupActions } = useRideHailSearchContext()

  const [
    selectedFlight,
    setSelectedFlight,
  ] = useState<RideHailAirportPickupFlightValueObject | null>(null)

  const onConfirm = (flight: RideHailAirportPickupFlightValueObject) => {
    setSelectedFlight(flight)
  }

  const selectFlight = () => {
    rideHailAirportPickupActions.handleSelectFlight(selectedFlight)
    setSelectedFlight(null)
    onClose()
  }

  const onDiscard = () => {
    setSelectedFlight(null)
    onClose()
  }

  return {
    onConfirm,
    selectFlight,
    selectedFlight,
    onDiscard,
  }
}
