import styled from 'styled-components'

export const Content = styled.div`
  padding: 18px 32px 18px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.colors.white};
`

export const ConfirmButtonWrapper = styled.div`
  width: 40%;
`
