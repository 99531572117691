import type { Rate, Money } from '@fiji/graphql/types'

export function getPriceDifference(itineraryCosts?: Rate | null, bookedCosts?: Rate | null) {
  if (!itineraryCosts || !bookedCosts) {
    return {
      totalPriceChange: null,
      isTotalPriceChangePositive: null,
    }
  }

  const primaryFromItinerary = itineraryCosts.primary
  const primaryFromBooking = bookedCosts.primary
  const secondaryFromItinerary = itineraryCosts.secondary
  const secondaryFromBooking = bookedCosts.secondary
  const secondaryMatch = checkCurrencyMatch(secondaryFromItinerary, secondaryFromBooking)
  const primaryMatch = checkCurrencyMatch(primaryFromItinerary, primaryFromBooking)

  if (secondaryMatch) {
    return {
      totalPriceChange: {
        primary: {
          amount: Math.abs(secondaryMatch),
          currency: secondaryFromItinerary!.currency,
        },
      },
      isTotalPriceChangePositive: !!(secondaryMatch > 0),
    }
  }

  if (primaryMatch) {
    return {
      totalPriceChange: {
        primary: {
          amount: Math.abs(primaryMatch),
          currency: primaryFromItinerary!.currency || '',
        },
      },
      isTotalPriceChangePositive: !!(primaryMatch > 0),
    }
  }

  return {
    totalPriceChange: null,
    isTotalPriceChangePositive: null,
  }
}

function checkCurrencyMatch(a?: Money | null, b?: Money | null): number | null {
  if (!a || !b) {
    return null
  }

  if (a.currency === b.currency) {
    return Math.round(a.amount - b.amount)
  }

  return null
}
