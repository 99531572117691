import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Menu = styled.div<{ isForSearchHeader: boolean; isVisible: boolean }>`
  position: absolute;
  top: 0;
  width: 486px;
  height: 460px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  z-index: 3;
  overflow: hidden;
  ${({ isForSearchHeader }) =>
    isForSearchHeader &&
    css`
      min-width: 300px;
    `}
`

export const List = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`
