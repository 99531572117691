import { MediaLayout } from '@etta/ui/media-layout'
import type { ModalTitleLayoutProps } from './types'
import { ModalTitleMobile } from './modal-title-mobile'
import { ModalTitleDesktop } from './modal-title-desktop'

export function ModalTitleLayout(props: ModalTitleLayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<ModalTitleMobile {...props} />}
      desktopSlot={<ModalTitleDesktop {...props} />}
    />
  )
}
