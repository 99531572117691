import { observer } from 'mobx-react-lite'
import { DelegateSwitchLayout as DelegateSwitchLayoutType } from '@fiji/enums'
import { useDelegateSwitch } from './hooks'
import { DelegateSwitchLayout } from './layout'
import type { DelegateSwitchProps } from './types'

export const DelegateSwitch = observer(function DelegateSwitch({
  layout = DelegateSwitchLayoutType.Explore,
  as,
}: DelegateSwitchProps) {
  const {
    delegatedUsers,
    delegatedUsersWithoutCustomer,
    customerId,
    selectedDelegate,
    delegateModalToggle,
    exitModalToggle,
    handleCloseSwitchErrorModal,
    handleDelegateItemClick,
    handleSelectDelegateMobile,
    handleSelectDelegateDesktop,
    handleExitFromDelegateMode,
    isDelegatesExist,
    isUserInfoUpdating,
    isDelegatesLoading,
    isUserInDelegatedMode,
    viewState,
    fullName,
    delegateGuestTitle,
  } = useDelegateSwitch()

  return (
    <DelegateSwitchLayout
      as={as}
      layout={layout}
      fullName={fullName}
      delegatedUsers={delegatedUsers}
      delegatedUsersWithoutCustomer={delegatedUsersWithoutCustomer}
      customerId={customerId}
      selectedDelegate={selectedDelegate}
      viewState={viewState}
      isDelegatesExist={isDelegatesExist}
      isDelegatesLoading={isDelegatesLoading}
      isUserInfoUpdating={isUserInfoUpdating}
      isUserInDelegatedMode={isUserInDelegatedMode}
      isDelegateModalOpen={delegateModalToggle.isOpen}
      isDelegateExitModalOpen={exitModalToggle.isOpen}
      handleDelegateModalClose={delegateModalToggle.handleClose}
      handleDelegateModalOpen={delegateModalToggle.handleOpen}
      handleDelegateItemClick={handleDelegateItemClick}
      handleCloseSwitchErrorModal={handleCloseSwitchErrorModal}
      handleSelectDelegateDesktop={handleSelectDelegateDesktop}
      handleSelectDelegateMobile={handleSelectDelegateMobile}
      handleDelegateExitModalClose={exitModalToggle.handleClose}
      handleDelegateExitModalOpen={exitModalToggle.handleOpen}
      handleExitFromDelegateMode={handleExitFromDelegateMode}
      delegateGuestTitle={delegateGuestTitle}
    />
  )
})
