import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { CarRentalDetailsLocation } from '@etta/components/car-rental-details-location/car-rental-details-location'
import { VendorDetails } from '../../../vendor-details'
import type { TripCarRentalLayoutProps } from '../../types'
import { Details } from './details'
import { useTripCarRentalDesktop } from './use-trip-car-rental-desktop'
import {
  ModalHeader,
  HeadingWrapper,
  HeadingSeparator,
  ContentWrapper,
  TravelerWrapper,
  ModalHeaderWrapper,
} from './trip-car-rental-desktop-styled'

export function TripCarRentalDesktop({
  carRental,
  tripType,
  onClose,
  tripCarTravelerSlot,
  ecoCheckSlot,
  headlineSlot,
  priceSlot,
}: TripCarRentalLayoutProps) {
  const {
    scrollContainerRef,
    headerRef,
    anchorRef,
    headerColor,
    carRentalTitle,
    carImageUrl,
    locations,
    onAddressClick,
    closeIconAriaLabel,
  } = useTripCarRentalDesktop({ carRental, tripType })

  return (
    <Modal.Body ref={scrollContainerRef}>
      <ModalHeaderWrapper isMobile backgroundColor="transparent">
        <ModalHeader ref={headerRef} color={headerColor}>
          {headlineSlot}
          <IconButton
            aria-label={closeIconAriaLabel}
            icon="CloseFilledDarkPWA"
            size="medium"
            onClick={onClose}
          />
        </ModalHeader>
      </ModalHeaderWrapper>
      <HeadingWrapper color={headerColor}>
        <HeadingSeparator />
        <Details carRental={carRental} carImageUrl={carImageUrl} carRentalTitle={carRentalTitle} />
      </HeadingWrapper>
      <div ref={anchorRef} />
      <ContentWrapper>
        <TravelerWrapper>{tripCarTravelerSlot}</TravelerWrapper>
        <Block marginTop={16}>
          <CarRentalDetailsLocation locations={locations} onAddressClick={onAddressClick} />
        </Block>
        <Block marginTop={16}>
          <VendorDetails vendor={carRental.vendor} carRental={carRental} />
        </Block>
        {ecoCheckSlot && ecoCheckSlot}
        {priceSlot}
      </ContentWrapper>
    </Modal.Body>
  )
}
