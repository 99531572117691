/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'

import type { FieldsMap, ValidationScheme } from './types'

type Defaults = Record<string, any>

export function useInitialFields<T extends ValidationScheme = ValidationScheme>(
  defaults: Defaults,
  scheme: T,
  initialValue?: unknown,
) {
  const initialFields = useMemo(() => {
    return Object.keys(scheme).reduce((acc, key) => {
      const valueFromDefaults = defaults[key]
      const usedValue = initialValue === undefined ? valueFromDefaults : initialValue
      acc[key] = usedValue === undefined ? '' : usedValue
      return acc
    }, {} as FieldsMap)
  }, [scheme])

  return initialFields as Record<keyof T, any>
}
