import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { InfoWrapperLeftIndent, TextWrapperNoSeparator } from '../cancel-trip-modal-styled'

type Props = {
  rideHailSegment?: ReactNode
}

export function SidebarDescriptiveActions({ rideHailSegment }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Block marginTop={4} marginBottom={10}>
        <InfoWrapperLeftIndent>
          <Icon name="infoOutlinePWA" color="bodyText" size="small" />
          <TextWrapperNoSeparator>
            <Text variant="subHeadMedium" color="bodyText">
              {t('PostBooking.TripDetails.CancelTripModal.NonRefundable')}
            </Text>
          </TextWrapperNoSeparator>
        </InfoWrapperLeftIndent>
      </Block>
      {rideHailSegment}
    </>
  )
}
