import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'
import { CarRentalHeaderDesktop } from './car-rental-desktop-header'

export function CarRentalSearchResultsDesktop({ contentSlot }: LayoutProps) {
  return (
    <ScreenDesktop>
      <ScreenDesktop.Header as={'header'}>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <CarRentalHeaderDesktop />
      <ScreenDesktop.Content as={'main'} maxWidth={992} backgroundColor="background">
        {contentSlot}
      </ScreenDesktop.Content>
    </ScreenDesktop>
  )
}
