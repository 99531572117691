export default function () {
  return (
    <svg
      width="100"
      height="85"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill="currentColor" />
      <path
        d="M100 22.8789C100 33.2101 91.6234 41.5853 81.2938 41.5853C70.9623 41.5853 62.5874 33.2101 62.5874 22.8789C62.5874 12.5477 70.9623 4.17249 81.2938 4.17249C91.6234 4.17249 100 12.5477 100 22.8789Z"
        fill="#EFEFF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1555 23.0437C96.1555 31.1603 89.5755 37.7407 81.4568 37.7407C73.3397 37.7407 66.7598 31.1603 66.7598 23.0437C66.7598 14.9254 73.3397 8.34497 81.4568 8.34497C89.5755 8.34497 96.1555 14.9254 96.1555 23.0437Z"
        fill="currentColor"
      />
      <path
        d="M87.9615 29.0146L87.9627 29.0158C88.0186 29.0712 88.0566 29.153 88.0566 29.2693C88.0566 29.4983 87.9053 29.6415 87.6969 29.6415C87.5714 29.6415 87.4768 29.6021 87.4023 29.5326L84.0327 26.1868L83.6014 25.7586L83.0931 26.0916C82.2554 26.6404 81.2491 26.9616 80.1746 26.9616C77.2449 26.9616 74.8591 24.5879 74.8591 21.7028C74.8591 18.8177 77.2449 16.444 80.1746 16.444C83.1042 16.444 85.4901 18.8177 85.4901 21.7028C85.4901 22.8241 85.1333 23.8635 84.5279 24.7098L84.157 25.2283L84.6088 25.678L87.9615 29.0146ZM74.9772 21.7028C74.9772 24.5441 77.3237 26.8585 80.1746 26.8585C83.0254 26.8585 85.3719 24.5441 85.3719 21.7028C85.3719 18.8699 83.0263 16.5471 80.1746 16.5471C77.3228 16.5471 74.9772 18.8699 74.9772 21.7028Z"
        fill="white"
        stroke="white"
        strokeWidth="1.50037"
      />
      <path
        d="M61.3908 28.26V24.051H56.948L47.1272 33.8719L29.5899 28.9615C27.9531 28.4938 26.0825 28.9615 24.9133 30.1306L23.978 31.0659L40.5799 40.4191L33.7989 49.0708L30.7591 47.434C29.5899 46.7325 28.187 46.9663 27.2516 47.9017L25.8487 49.3046L31.9282 53.0459L35.6695 59.1255L37.0725 57.7225C38.0078 56.7872 38.2416 55.3842 37.774 54.2151L36.371 51.1753L45.0227 44.8619L54.1421 61.6976L55.3112 60.5285C56.7142 59.1255 57.1819 57.2549 56.4804 55.618L51.5699 38.5485L61.3908 28.26Z"
        fill="white"
      />
    </svg>
  )
}
