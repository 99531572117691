import { featureFlagsSelector } from '@fiji/store/feature-flags'
import { useAppSelector } from '@fiji/store'

export function useFeatureFlags() {
  const featureFlags = useAppSelector(featureFlagsSelector)

  return {
    featureFlags: featureFlags.values,
  }
}
