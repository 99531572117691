import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { ButtonContainer, ApplyButton, ClearButton } from './rail-filters-styled'

const i18Base = 'RailFilters'

export const Footer = observer(function Footer() {
  const { t } = useTranslation()

  const { railFiltersStore, railFilterAction } = useRailContext()
  return (
    <ButtonContainer>
      <ClearButton onClick={railFilterAction.onClearAll} btnType="tertiary">
        {t(i18Base + '.ClearAll')}
      </ClearButton>
      <ApplyButton
        disabled={railFiltersStore.isFiltersEqual}
        onClick={railFilterAction.onApplyChangedFilters}>
        {t(i18Base + '.ApplyFilters')}
      </ApplyButton>
    </ButtonContainer>
  )
})
