import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import type { Rate } from '@fiji/graphql/hooks'
import { getPriceDifference } from './helpers'

type Props = {
  isTripExist: boolean
  bookingId?: string
  itineraryCosts?: Rate
  areSegmentsChangeable: boolean
  tripDetails?: TripEntity
}

export function useChangeTripDetails({
  bookingId,
  itineraryCosts,
  areSegmentsChangeable,
  tripDetails,
  isTripExist,
}: Props) {
  const bookedCosts = tripDetails?.tripCost?.total
  const { totalPriceChange, isTotalPriceChangePositive } = getPriceDifference(
    itineraryCosts,
    bookedCosts,
  )

  const supportedActions = isTripExist && bookingId ? tripDetails?.supportedActions : undefined

  const postPurchaseActions = {
    canChangeFlight: bookingId ? !!supportedActions?.isFlightChangeAllowed : areSegmentsChangeable,
    canChangeCarRental: bookingId
      ? !!supportedActions?.isCarRentalChangeAllowed
      : areSegmentsChangeable,
    canChangeHotel: bookingId ? !!supportedActions?.isHotelChangeAllowed : areSegmentsChangeable,
    canChangeCarrier: !!supportedActions?.isFlightChangeCarrierAllowed,
    canChangeFlightDestination: !!supportedActions?.isFlightChangeDestinationAllowed,
    canChangeTrain: bookingId ? !!supportedActions?.isTrainChangeAllowed : areSegmentsChangeable,
    canRemoveFlight: bookingId ? !!supportedActions?.isFlightCancelAllowed : areSegmentsChangeable,
    canRemoveCarRental: bookingId
      ? !!supportedActions?.isCarRentalCancelAllowed
      : areSegmentsChangeable,
    canRemoveHotel: bookingId ? !!supportedActions?.isHotelCancelAllowed : areSegmentsChangeable,
  }

  return {
    totalPriceChange,
    isTotalPriceChangePositive,
    postPurchaseActions,
    supportedActions,
  }
}
