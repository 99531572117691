import * as yup from 'yup'
import i18n from 'i18next'
import { getYear } from '@fiji/utils/dates/get-year'
import { getMonth } from '@fiji/utils/dates/get-month'
import type { Props } from '../types'
import { getBaseScheme } from './get-base-scheme'

const i18nBase = 'AdditionalInfoForm.'

export const getDatetimeScheme = ({ customField }: Props) => {
  let yupScheme = yup.mixed()

  if (!customField.optional) {
    yupScheme = yupScheme.test(
      'dateRequired',
      i18n.t(`${i18nBase}Errors.Required`),
      (value) => !!value,
    )
  }

  // TODO: remove silly condition regarding displaying something
  if (customField.dateTimeDisplay?.minYear && customField.dateTimeDisplay.displayYear) {
    yupScheme = yupScheme.test(
      'minYear',
      i18n.t(`${i18nBase}Errors.MinYear`, { number: customField.dateTimeDisplay.minYear }),
      (value) => {
        if (!value) {
          return true
        }

        if (typeof value === 'string') {
          const date = new Date(value)
          return getYear(date) >= customField.dateTimeDisplay!.minYear!
        }

        return getYear(value) >= customField.dateTimeDisplay!.minYear!
      },
    )
  }

  // TODO: remove silly condition regarding displaying something
  if (customField.dateTimeDisplay?.maxYear && customField.dateTimeDisplay.displayYear) {
    yupScheme = yupScheme.test(
      'maxYear',
      i18n.t(`${i18nBase}Errors.MaxYear`, { number: customField.dateTimeDisplay.maxYear }),
      (value) => {
        if (!value) {
          return true
        }

        if (typeof value === 'string') {
          const date = new Date(value)
          return getYear(date) <= customField.dateTimeDisplay!.maxYear!
        }

        return getYear(value) <= customField.dateTimeDisplay!.maxYear!
      },
    )
  }

  // TODO: remove silly condition regarding displaying something
  if (customField.dateTimeDisplay?.minMonth && customField.dateTimeDisplay.displayMonth) {
    yupScheme = yupScheme.test(
      'minMonth',
      i18n.t(`${i18nBase}Errors.MinMonth`, { number: customField.dateTimeDisplay.minMonth }),
      (value) => {
        if (!value) {
          return true
        }

        if (typeof value === 'string') {
          const date = new Date(value)
          return getMonth(date) >= customField.dateTimeDisplay!.minMonth!
        }

        return getMonth(value) >= customField.dateTimeDisplay!.minMonth!
      },
    )
  }

  // TODO: remove silly condition regarding displaying something
  if (customField.dateTimeDisplay?.maxMonth && customField.dateTimeDisplay.displayMonth) {
    yupScheme = yupScheme.test(
      'year',
      i18n.t(`${i18nBase}Errors.MaxMonth`, { number: customField.dateTimeDisplay.maxMonth }),
      (value) => {
        if (!value) {
          return true
        }

        if (typeof value === 'string') {
          const date = new Date(value)
          return getMonth(date) <= customField.dateTimeDisplay!.maxMonth!
        }

        return getMonth(value) <= customField.dateTimeDisplay!.maxMonth!
      },
    )
  }

  // TODO: remove silly condition regarding displaying something
  if (customField.dateTimeDisplay?.minDay && customField.dateTimeDisplay.displayDay) {
    yupScheme = yupScheme.test(
      'minDay',
      i18n.t(`${i18nBase}Errors.MinDay`, { number: customField.dateTimeDisplay.minDay }),
      (value) => {
        if (!value) {
          return true
        }

        if (typeof value === 'string') {
          const date = new Date(value)
          return date.getDate() >= customField.dateTimeDisplay!.minDay!
        }

        return value.getDate() >= customField.dateTimeDisplay!.minDay!
      },
    )
  }

  // TODO: remove silly condition regarding displaying something
  if (customField.dateTimeDisplay?.maxDay && customField.dateTimeDisplay.displayDay) {
    yupScheme = yupScheme.test(
      'maxDay',
      i18n.t(`${i18nBase}Errors.MaxDay`, { number: customField.dateTimeDisplay.maxDay }),
      (value) => {
        if (!value) {
          return true
        }

        if (typeof value === 'string') {
          const date = new Date(value)
          return date.getDate() <= customField.dateTimeDisplay!.maxDay!
        }

        return value.getDate() <= customField.dateTimeDisplay!.maxDay!
      },
    )
  }

  return getBaseScheme({ scheme: yupScheme, customField })
}
