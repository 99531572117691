import { useCallback } from 'react'
import { useAddCreditCardMutation, useEditCreditCardMutation } from '@fiji/graphql/hooks'
import type { CreditCardFormData } from '@etta/components/credit-card-modal/types'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { useViewState } from '@fiji/hooks/use-view-state'
import type { CreditCard } from '@fiji/graphql/types'
import { SINGLE_USE_CREDIT_CARD_ID } from '@fiji/constants'
import { usePurchaseInfoContext } from '@etta/modules/booking/interface'

type Args = {
  editCreditCardId?: string
  onAddOneTimeCard: (card: Partial<CreditCard>) => void
  onAddSuccess: () => void
  onEditSuccess: () => void
}

export function useCardMutations({
  editCreditCardId,
  onAddOneTimeCard,
  onAddSuccess,
  onEditSuccess,
}: Args) {
  const viewState = useViewState()
  const { purchaseInfoActions } = usePurchaseInfoContext()

  const [addCreditCard] = useAddCreditCardMutation({
    awaitRefetchQueries: true,
  })

  const handleAddCreditCard = useCallback(
    async function ({ creditCardData, billingAddress, isOneTimeUseCard }: CreditCardFormData) {
      if (isOneTimeUseCard) {
        const id = SINGLE_USE_CREDIT_CARD_ID
        onAddOneTimeCard({ ...creditCardData, id, billingAddress })
        return
      }

      viewState.onChangeViewState('saving')
      const response = await addCreditCard({
        variables: {
          input: { creditCardData, billingAddress },
        },
      })
      await purchaseInfoActions.fetchBillingFromPurchaseInfo()

      if (!response.data?.addCreditCard.success) {
        viewState.onChangeViewState('error')
        return
      }

      onAddSuccess()
      viewState.onChangeViewState('saved')
      await delayForSuccess()
      viewState.onChangeViewState('hidden')
    },
    [addCreditCard, onAddOneTimeCard, onAddSuccess, viewState, purchaseInfoActions],
  )

  const [editCreditCard] = useEditCreditCardMutation({
    awaitRefetchQueries: true,
  })

  const handleEditCreditCard = useCallback(
    async function handleEditCreditCard({
      creditCardData,
      billingAddress,
      isOneTimeUseCard,
    }: CreditCardFormData) {
      if (isOneTimeUseCard) {
        const id = SINGLE_USE_CREDIT_CARD_ID
        onAddOneTimeCard({ ...creditCardData, id, billingAddress })
        onEditSuccess()
        return
      }

      viewState.onChangeViewState('saving')
      const response = await editCreditCard({
        variables: {
          input: {
            creditCardId: `${editCreditCardId}`,
            creditCardData: {
              ...creditCardData,
              cardNumber: creditCardData.cardNumber.slice(-4),
            },
            billingAddress,
          },
        },
      })

      await purchaseInfoActions.fetchBillingFromPurchaseInfo()

      if (!response.data?.editCreditCard.success) {
        viewState.onChangeViewState('error')
        return
      }
      onEditSuccess()
      viewState.onChangeViewState('saved')
      await delayForSuccess()
      viewState.onChangeViewState('hidden')
    },
    [
      editCreditCard,
      editCreditCardId,
      onAddOneTimeCard,
      onEditSuccess,
      purchaseInfoActions,
      viewState,
    ],
  )

  return {
    viewState,
    handleAddCreditCard,
    handleEditCreditCard,
  }
}
