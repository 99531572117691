import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ServiceCategory } from '@fiji/graphql/types'
import { useDeleteSegments } from './use-delete-segments'

type Props = {
  itineraryId: string
  roomKey: string
  onCloseAfterward?: () => void
}

export function useDeleteHotel({ itineraryId, roomKey, onCloseAfterward }: Props) {
  const { tripHotelActions } = useReviewTripContext()

  const handleRequestRemoveHotel = async () => {
    const res = await tripHotelActions.handleRemoveRoom({ itineraryId, roomKey })
    if (res.isErr()) {
      throw new Error(res.getError().message)
    }
  }

  const { viewStateParams, handleDeleteSegment } = useDeleteSegments({
    serviceCategory: ServiceCategory.Hotel,
    onCloseAfterward,
    onRemoveSegment: handleRequestRemoveHotel,
  })

  return {
    handleRemoveHotel: handleDeleteSegment,
    viewStateParams,
  }
}
