export default function AppleMostPreferredIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.633 38H32.367C36.0824 38 38 36.0824 38 32.4184V11.5816C38 7.9176 36.0824 6 32.367 6H11.633C7.93472 6 6 7.90048 6 11.5816V32.4184C6 36.0824 7.93472 38 11.633 38ZM11.8555 34.5928C10.2804 34.5928 9.40717 33.771 9.40717 32.1102V11.8898C9.40717 10.229 10.2804 9.40717 11.8555 9.40717H32.1445C33.7025 9.40717 34.5928 10.229 34.5928 11.8898V32.1102C34.5928 33.771 33.7025 34.5928 32.1445 34.5928H11.8555ZM20.1766 30.107C20.7929 30.107 21.3237 29.7988 21.7004 29.2509L29.4222 17.2317C29.6447 16.8721 29.8502 16.4612 29.8502 16.0674C29.8502 15.2113 29.0968 14.6292 28.275 14.6292C27.7614 14.6292 27.2991 14.9374 26.9395 15.5024L20.1252 26.3917L16.9406 22.3681C16.5297 21.8373 16.1188 21.649 15.6051 21.649C14.7662 21.649 14.0984 22.3339 14.0984 23.1728C14.0984 23.5837 14.2525 23.9775 14.5436 24.3371L18.5843 29.2509C19.0465 29.8331 19.5431 30.107 20.1766 30.107Z"
        fill="currentColor"
      />
    </svg>
  )
}
