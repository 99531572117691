import { useMemo } from 'react'
import i18n from 'i18next'
import type { ComplianceMessage } from '@fiji/graphql/types'
import type { Alert } from '@etta/components/dynamic-site-messages/types'
import { generateId } from '@fiji/utils/generate-id'

type Props = {
  complianceMessages?: ComplianceMessage | null
}

export function useInfoAlerts({ complianceMessages }: Props) {
  const infos = useMemo(() => {
    return complianceMessages?.infos
  }, [complianceMessages])

  const infoAlerts: Alert[] = []
  infos?.forEach((message) => {
    if (!message) {
      return null
    }

    if (i18n.t(`ComplianceMessages.${message}`) === `ComplianceMessages.${message}`) {
      return null
    }

    infoAlerts.push({
      id: generateId('alert-for-info', message),
      header: '',
      text: i18n.t(`ComplianceMessages.${message}`, message),
    })
  })

  return { infoAlerts: complianceMessages ? infoAlerts : [] }
}
