import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'

export function TripCancellationDateMobile({ label }: LayoutProps) {
  return (
    <Text tabIndex={0} variant="subHeadStrong" color="error">
      {label}
    </Text>
  )
}
