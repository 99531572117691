import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebaseMessaging } from '@etta/worker/firebase'

const areNotificationsSupported = navigator.serviceWorker && window.PushManager

type Args = {
  locale: string
}

export function useNotifications({ locale }: Args) {
  const { t } = useTranslation()
  const errorMessage = t('Notifications.Error')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const registerFirebaseMessaging = useFirebaseMessaging({ locale })
  const requestPermission = useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      await registerFirebaseMessaging()
    } catch {
      setLoading(false)
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [errorMessage, registerFirebaseMessaging])

  useEffect(() => {
    if (!areNotificationsSupported) {
      return
    }
    if (Notification.permission !== 'granted') {
      return
    }

    requestPermission()
  }, [requestPermission])

  return {
    error,
    isLoading,
    requestPermission,
  }
}
