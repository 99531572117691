import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Room } from '@fiji/graphql/types'
import type { IconNames } from '@etta/ui/icon'

const SHORT_ROOMS_LIST_COUNT = 3

type Params = {
  rooms?: Room[]
  onRoomSelect?: (room: Room) => void
}

const i18Base = 'HotelDetails'

export function useHotelDetailsRooms({ rooms, onRoomSelect }: Params) {
  const [isShowMoreRooms, setShowMoreRooms] = useState(false)
  const { t } = useTranslation()
  const linkMoreRoomsText = isShowMoreRooms
    ? t(i18Base + '.HideMoreRooms')
    : t(i18Base + '.ShowMoreRooms')
  const linkMoreRoomsIcon: IconNames = isShowMoreRooms ? 'expandLessPWA' : 'expandMorePWA'
  const isMoreRoomsAvailable = rooms && rooms.length > SHORT_ROOMS_LIST_COUNT
  const visibleRooms = useMemo(() => {
    return isShowMoreRooms ? rooms : rooms?.slice(0, SHORT_ROOMS_LIST_COUNT)
  }, [isShowMoreRooms, rooms])

  const handleToggleMoreRooms = () => {
    setShowMoreRooms(!isShowMoreRooms)
  }
  const handleSelectRoom = (room: Room) => {
    if (!onRoomSelect) {
      return
    }
    onRoomSelect(room)
  }

  return {
    visibleRooms,
    handleToggleMoreRooms,
    linkMoreRoomsIcon,
    linkMoreRoomsText,
    isMoreRoomsAvailable,
    handleSelectRoom,
  }
}
