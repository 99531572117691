import styled from 'styled-components'
import { subHeadStrong } from '@fiji/style'

export const Title = styled.div`
  ${subHeadStrong};
  color: ${(p) => p.theme.colors.mainText};
  margin-right: 12px;
`

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`

export const InfoBlock = styled.div`
  display: block;
  padding: 16px 14px 22px 14px;
  background: ${(p) => p.theme.colors.background4};
  border-radius: 12px;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
  margin-top: 24px;
`
