import { RailSearchPage } from '@etta/screens/rail-search-page/rail-search-page'
import { RailLegType } from '@fiji/enums'
import { RailSearchResultPage } from '@etta/screens/rail-search-result-page/rail-search-result-page'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const railRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.rail.search,
      exact: true,
    },
    documentTitle: getDocumentTitle('Train Search'),
    transitionIndex: 4,
    isNavBarHidden: true,
    transitionGroup: 'slide',
    component: () => <RailSearchPage />,
  },
  {
    route: {
      path: ROUTES.rail.results({ railLegType: RailLegType.Departure }),
      exact: true,
    },
    documentTitle: getDocumentTitle('Train Search Results'),
    transitionIndex: 4.1,
    transitionGroup: 'slide',
    component: () => <RailSearchResultPage railLegType={RailLegType.Departure} />,
  },
  {
    route: {
      path: ROUTES.rail.results({ railLegType: RailLegType.Return }),
      exact: true,
    },
    documentTitle: getDocumentTitle('Train Search Results'),
    transitionIndex: 4.2,
    transitionGroup: 'slide',
    component: () => <RailSearchResultPage railLegType={RailLegType.Return} />,
  },
]
