import styled from 'styled-components'
import type { EdgeVertical } from './types'

export const HotelCardContainer = styled.div<{ edgeHorizontal: number }>`
  position: relative;
  width: 320px;
  height: 96px;
  left: ${(p) => p.edgeHorizontal - 120}px;
`

export const HotelCardContainerWrapper = styled.div<{ edgeVertical: EdgeVertical }>`
  position: absolute;
  ${(p) => {
    switch (p.edgeVertical) {
      case 'top':
        return 'bottom: 8px;'
      case 'bottom':
        return 'top: 38px;'
    }
  }}
  left: 0;
  z-index: 10;
`
