import { useCallback } from 'react'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { useAppDispatch } from '@fiji/store'
import { useCarSearchQuery } from '@fiji/hooks/search-queries/use-car-search-query/use-car-search-query'
import type { CarRentalSegment } from '@fiji/graphql/types'
import { updateCarRentalId } from '@fiji/store/segment-ids'
import { ROUTES } from '@fiji/routes'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { PostBookingAction } from '@fiji/enums'
import { convertToPlainTime, convertToPlace } from './utils'

type Props = {
  itineraryId: string
  handleCloseChangeModal: () => void
  carRental: CarRentalSegment
}

export function useChangeCarRental({ itineraryId, handleCloseChangeModal, carRental }: Props) {
  const { carRentalFilterActions } = useCarRentalSearchContext()
  const dispatch = useAppDispatch()

  const { navigateTo, queryParams } = useCarSearchQuery()
  const { bookingId } = queryParams

  const handleChangeCarRental = useCallback(() => {
    const pickUpDate = new Date(deleteTimezone(carRental.pickUpTime))
    const dropOffDate = new Date(deleteTimezone(carRental.dropOffTime))
    const pickUpLocationId = carRental.pickupLocationId || undefined
    const dropOffLocationId = carRental.dropoffLocationId || undefined

    dispatch(updateCarRentalId({ carRentalId: carRental.carId }))
    handleCloseChangeModal()
    const pickUpPlace = convertToPlace({
      address: carRental.pickUpLocation.address,
      location: carRental.carLocationType,
    })
    const dropOffPlace = convertToPlace({
      address: carRental.dropOffLocation.address,
      location: carRental.carLocationType,
      isDropoff: true,
    })

    const carTypes = carRentalFilterActions.lastCarQuickFiltersApplied

    navigateTo(ROUTES.carRental.results, {
      itineraryId,
      bookingId,
      pickUpDate,
      pickUpAddress: undefined,
      pickUpTime: convertToPlainTime(pickUpDate),
      pickUpPlace,
      dropOffDate,
      dropOffAddress: undefined,
      dropOffTime: convertToPlainTime(dropOffDate),
      dropOffPlace,
      pickUpLocationId,
      dropOffLocationId,
      carClass: carRental.carDetails.class ? [carRental.carDetails.class] : undefined,
      postBookingAction: PostBookingAction.Modify,
      carTypes,
    })
  }, [
    dispatch,
    handleCloseChangeModal,
    navigateTo,
    itineraryId,
    carRental,
    bookingId,
    carRentalFilterActions.lastCarQuickFiltersApplied,
  ])

  return {
    handleChangeCarRental,
  }
}
