import { Block } from '@etta/ui/block'
import { DescriptionWrapper } from './field-description-styled'

type Props = {
  description: string
}

export function FieldDescription({ description }: Props) {
  return (
    <Block marginBottom={10}>
      <DescriptionWrapper>{description}</DescriptionWrapper>
    </Block>
  )
}
