import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions, AirPreferenceExcerptInput } from '@fiji/graphql/types'
import { PhoneType } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import { useUserContext } from '@etta/modules/user'
import type { AirPreferenceExcerpt, ProfileOptions } from '../types'
import { useUserScheme } from './use-user-scheme'

type Args = {
  personalInfo: PersonalInfo
  onSubmit: (data: PersonalInfo, excerpt?: AirPreferenceExcerptInput | null) => void
  travelerOptions?: TravelerOptions | null
  preferenceExcerpt?: AirPreferenceExcerpt
  primaryTraveler?: PurchaseInfoTravelerValueObject
  profileOptions: ProfileOptions
}

export function usePurchaseInfo({
  personalInfo,
  onSubmit,
  travelerOptions,
  preferenceExcerpt,
  primaryTraveler,
  profileOptions,
}: Args) {
  const { userStore } = useUserContext()
  const { isGuest, guestPersonalInfo } = userStore

  const { scheme } = useUserScheme({
    personalInfo,
    travelerOptions,
    preferenceExcerpt,
    primaryTraveler,
    profileOptions,
    guestPersonalInfo,
    isGuest,
  })

  const { errors, submit, values, onFieldChange } = useValidation(scheme)

  const handleExcerptSubmit = () => {
    const { knownTravelerIssuingCountry, knownTravelerNumber, redressNumber } =
      preferenceExcerpt || {}
    const {
      knownTravelerIssuingCountry: inputCountry,
      knownTravelerNumber: inputTraveler,
      redressNumber: inputRedress,
    } = values || {}

    if (!preferenceExcerpt) {
      return null
    }
    if (
      knownTravelerIssuingCountry === inputCountry &&
      knownTravelerNumber === inputTraveler &&
      redressNumber === inputRedress
    ) {
      return null
    }
    return {
      knownTravelerNumber: inputTraveler,
      knownTravelerIssuingCountry: inputCountry,
      redressNumber: inputRedress,
    }
  }

  const handleSubmit = async () => {
    const submitData = await submit()
    if (!submitData.isValid) {
      return
    }
    // TODO: remove companyId and customerId from here and use them from graphql context.
    // Sync up with ios team about this
    const {
      firstName,
      lastName,
      middleName,
      gender,
      suffix,
      phone,
      phoneCountryCode,
      personalMobile,
      personalMobileCountryCode,
      title,
      dateOfBirth,
      email,
      street1,
      street2,
      city,
      country,
      zip,
      state,
    } = values || {}

    const input = {
      firstName,
      lastName,
      middleName,
      gender,
      suffix,
      title,
      dateOfBirth,
      email,
      companyId: personalInfo.companyId,
      customerId: personalInfo.customerId,
      phone: {
        number: phone,
        type: PhoneType.Mobile,
        countryCode: phoneCountryCode,
      },
      personalMobile: {
        number: personalMobile,
        type: PhoneType.Mobile,
        countryCode: personalMobileCountryCode,
      },
      address: {
        street1,
        street2,
        city,
        countryCode: country,
        postalCode: zip,
        stateCode: state,
      },
      knownTravelerNumbers: primaryTraveler?.knownTravelerNumbers,
      redressNumbers: primaryTraveler?.redressNumbers,
    }

    onSubmit(input, handleExcerptSubmit())
  }

  const initialDob = travelerOptions?.isDateOfBirthChangeable
    ? personalInfo.dateOfBirth
    : primaryTraveler?.dateBirth || personalInfo.dateOfBirth

  const initialGender = travelerOptions?.isGenderChangeable
    ? personalInfo.gender
    : primaryTraveler?.sex || personalInfo.gender

  const initialSuffix = travelerOptions?.isSuffixChangeable
    ? personalInfo.suffix
    : primaryTraveler?.suffix || personalInfo.suffix

  const initialEmail = travelerOptions?.isEMailChangeable
    ? personalInfo.email
    : primaryTraveler?.email || personalInfo.email

  const initialFirstName = travelerOptions?.isNameChangeable
    ? personalInfo.firstName
    : primaryTraveler?.firstName || personalInfo.firstName

  const initialMiddleName = travelerOptions?.isNameChangeable
    ? personalInfo.middleName
    : primaryTraveler?.middleInitial || personalInfo.middleName

  const initialLastName = travelerOptions?.isNameChangeable
    ? personalInfo.lastName
    : primaryTraveler?.lastName || personalInfo.lastName

  const initialAddress = travelerOptions?.isAddressChangeable
    ? personalInfo.address
    : primaryTraveler?.address || personalInfo.address

  const initialPhoneNumber = travelerOptions?.isPhoneNumberChangeable
    ? personalInfo.phone
    : primaryTraveler?.phone || personalInfo.phone

  const initialPersonalMobile = travelerOptions?.isPhoneNumberChangeable
    ? personalInfo.personalMobile
    : primaryTraveler?.personalMobile || personalInfo.personalMobile

  return {
    errors,
    values,
    onFieldChange,
    handleSubmit,
    initialDob,
    initialGender,
    initialSuffix,
    initialEmail,
    initialFirstName,
    initialMiddleName,
    initialLastName,
    initialAddress,
    initialPhoneNumber,
    initialPersonalMobile,
  }
}
