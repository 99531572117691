import { useRef } from 'react'
import type { Coords } from '@etta/modules/etta-map/core/coords'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'

type Props = {
  location: Coords
  children: JSX.Element
}

export function LocationMap({ location, children }: Props) {
  const defaultCenter = useRef(location)

  return (
    <EttaMap
      defaultZoom={14}
      defaultCenter={defaultCenter.current}
      center={location}
      options={{
        fullscreenControl: false,
        disableDoubleClickZoom: true,
        scrollwheel: false,
        draggable: false,
        draggableCursor: 'pointer',
        draggingCursor: 'default',
      }}>
      {children}
    </EttaMap>
  )
}
