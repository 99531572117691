import { useCallback, useMemo, useState } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { SelectSearchOptionType } from './types'

type Args = {
  options: SelectSearchOptionType[]
  onOptionSelect(value: SelectSearchOptionType['value']): void
  onChangeValue?(value: string): void
  value?: string
  defaultValue?: string
  isDisabled?: boolean
  optionLabelSource?: 'label' | 'value'
  customValueToShow?: string
  updateExternalInputValue?: (value: string | null | undefined) => void
  enablePaginationForOptions?: boolean
}

const PAGE_SIZE = 20 // ad hoc number for loyalty numbers pagniation

export function useSelectSearch({
  value,
  defaultValue = '',
  options,
  onOptionSelect,
  onChangeValue,
  isDisabled,
  customValueToShow,
  optionLabelSource = 'label',
  updateExternalInputValue,
  enablePaginationForOptions,
}: Args) {
  const [inputValue, setInputValue] = useState('')

  const [currentPage, setCurrentPage] = useState(1) // initial page number starts from 1 for calculation convenience

  const onChangeInput = useCallback(
    (val: string) => {
      const regex = new RegExp(/^[a-zA-Z0-9 \-]*$/)
      if (!regex.test(val) || val.length > 50) {
        return
      }

      setInputValue(val)
      updateExternalInputValue?.(val)
      onChangeValue && onChangeValue(val)
      setCurrentPage(1)
    },
    [onChangeValue, updateExternalInputValue],
  )

  const modalToggle = useTogglePopup()
  const hasInputValue = !!inputValue.length
  const isModalVisible = modalToggle.isOpen && !isDisabled

  const optionLabel = useMemo(() => {
    if (customValueToShow) {
      return customValueToShow
    }

    if (!options.length) {
      return defaultValue
    }
    return options.find((item) => item.value === value)?.[optionLabelSource] || ''
  }, [customValueToShow, defaultValue, options, value, optionLabelSource])

  const onSelect = useCallback(
    (option: SelectSearchOptionType) => {
      modalToggle.handleClose()
      onOptionSelect(option.value)
      setInputValue('')
    },
    [modalToggle, onOptionSelect],
  )

  const inputFilteredOptions: SelectSearchOptionType[] = useMemo(() => {
    if (!inputValue) {
      return options
    }
    const regex = new RegExp(`${inputValue}`, 'i')

    return options.filter((option) => regex.test(option.label))
  }, [inputValue, options])

  const filteredOptions: SelectSearchOptionType[] = useMemo(() => {
    if (enablePaginationForOptions) {
      return inputFilteredOptions.slice(0, currentPage * PAGE_SIZE)
    }

    return inputFilteredOptions
  }, [inputFilteredOptions, currentPage, enablePaginationForOptions])

  const handleOpen = useCallback(() => {
    setInputValue('')
    updateExternalInputValue?.(null)
    modalToggle.handleOpen()
  }, [modalToggle, updateExternalInputValue])

  const onFetchMoreInPagination = useCallback(() => {
    setCurrentPage((prev) => prev + 1)
  }, [])

  const hasNextPageInPagination = useMemo(() => {
    const remainingListSize = inputFilteredOptions.length - filteredOptions.length
    return !!enablePaginationForOptions && remainingListSize > 0
  }, [filteredOptions, inputFilteredOptions, enablePaginationForOptions])

  return {
    handleOpen,
    modalToggle,
    isModalVisible,
    hasInputValue,
    inputValue,
    optionLabel,
    filteredOptions,
    onChangeInput,
    onSelect,
    onFetchMoreInPagination,
    hasNextPageInPagination,
  }
}
