const RESTRICTED_ACTION_NAMES = ['click', 'change', 'submit']

export function trackAction(actionName: string, value?: Object) {
  if (process.env.NODE_ENV === 'development' && RESTRICTED_ACTION_NAMES.includes(actionName)) {
    throw new Error(
      'Currently, heap.track calls cannot be named click, change, submit as it clashes with internal variables.',
    )
  }

  window.heap?.track?.(actionName, value)
}
