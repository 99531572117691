import { useTranslation } from 'react-i18next'
import type { ViewState } from '@etta/ui/view-state-modal'

const i18Base = 'RailDetailsModal'

export const useDeleteRailTitle = (removeRailStateStatus: ViewState): string => {
  const { t } = useTranslation()

  if (removeRailStateStatus === 'saving') {
    return t(i18Base + '.RemovingTrain')
  }

  return t(i18Base + '.RemovedTrain')
}
