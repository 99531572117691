import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Dot, IconWrapper } from './rail-travel-additional-details-styled'

type Props = {
  platform?: string | null
  ecoInfo?: string | null
  railDuration: string
  travelChanges: number
  countStops: number
}

const i18nBase = 'RailDetails.'

export function RailTravelAdditionalDetails({
  platform,
  railDuration,
  travelChanges,
  ecoInfo,
  countStops,
}: Props) {
  const { t } = useTranslation()

  const platformLabel = t(i18nBase + 'AriaLabels.PlatformLabel', { number: platform })

  const platformText = t(i18nBase + 'Platform', { number: platform })

  const travelChangesLabel =
    travelChanges > 0
      ? t(i18nBase + 'Change', { count: travelChanges })
      : t(i18nBase + 'Stops.Nonstop')

  const hasCountStops = countStops >= 1

  const travelStopsLabel = hasCountStops ? t(i18nBase + 'Stops.Stop', { count: countStops }) : ''

  const journeyLabel = t(i18nBase + 'AriaLabels.StopsLabel', {
    duration: railDuration,
    stopsNumber: travelStopsLabel,
    changeNumber: travelChangesLabel,
    coNumber: ecoInfo,
  })

  return (
    <>
      <Block aria-label={journeyLabel} marginTop={15} isFlexBlock>
        <Text variant="footnoteMedium" color="mainText">
          {railDuration}
        </Text>

        <>
          {hasCountStops && (
            <>
              <Dot />
              <Text variant="footnoteMedium" color="mainText">
                {travelStopsLabel}
              </Text>
            </>
          )}{' '}
        </>

        <>
          <Dot />
          <Text variant="footnoteMedium" color="mainText">
            {travelChangesLabel}
          </Text>
        </>
      </Block>
      {!!platform && (
        <Block aria-label={platformLabel} marginLeft={-5} marginTop={15} isFlexBlock>
          <IconWrapper name="railPWA" size="small" />
          <Text variant="footnoteMedium" color="mainText">
            {platformText}
          </Text>
          <Block marginLeft={3}>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'Estimated')}
            </Text>
          </Block>
        </Block>
      )}
    </>
  )
}
