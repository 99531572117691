import type { MouseEventHandler } from 'react'
import type { IconColorPalette, IconSizes, IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Container } from './icon-button-components'
import type { Dimension } from './types'

type Props = {
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  icon: IconNames
  size?: IconSizes
  color?: IconColorPalette
  width?: Dimension
  height?: Dimension
  isDisabled?: boolean
  tabIndex?: number
  'aria-label'?: string
}

export function IconButton({
  className,
  onClick,
  icon,
  size,
  width = 32,
  height = 32,
  color,
  isDisabled,
  tabIndex,
  'aria-label': ariaLabel,
}: Props) {
  return (
    <Container
      type="button"
      tabIndex={tabIndex}
      className={className}
      width={width}
      height={height}
      onClick={onClick}
      disabled={isDisabled}
      aria-label={ariaLabel}>
      <Icon name={icon} size={size} color={color} />
    </Container>
  )
}
