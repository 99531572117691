import { useCallback, useEffect, useMemo, useState } from 'react'
import { useBodyOverflowed } from '@fiji/hooks/body/use-body-overflowed'
import { useCloseModalByBrowserBackButton } from '@fiji/hooks/use-close-modal-by-browser-back-button/use-close-modal-by-browser-back-button'
import { useFocusTrap } from '@fiji/hooks/use-focus-trap'
import { useFocusReturn } from '@fiji/hooks/use-focus-return'
import { screenMatcher, ScreenType } from '@fiji/modes'
import type { ModalContextType } from './modal-context'

type Props = {
  isVisible: boolean
  onClose: (e: boolean) => void
  onModalExited?: () => void
}

export function useModal({ isVisible, onClose, onModalExited }: Props) {
  useCloseModalByBrowserBackButton({ isOpen: isVisible, onClose })
  useBodyOverflowed(isVisible)

  const [isAvailable, updateAvailable] = useState(false)
  useEffect(() => {
    if (isVisible) {
      updateAvailable(isVisible)
    }
  }, [isVisible])

  const contextValue: ModalContextType = useMemo(() => {
    return {
      onClose: (value: boolean) => {
        onClose(value)
      },
    }
  }, [onClose])

  const isDesktop = screenMatcher.getScreenType() === ScreenType.Desktop
  const focusTrapRef = useFocusTrap({
    isActive: isVisible,
  })

  useFocusReturn({
    opened: isVisible,
    shouldReturnFocus: isDesktop,
  })

  const isShown = isVisible || isAvailable

  const onExited = useCallback(() => {
    if (onModalExited) {
      onModalExited()
    }
    updateAvailable(false)
  }, [onModalExited])

  const handleCloseOverlay = useCallback(() => {
    onClose(false)
  }, [onClose])

  return {
    handleCloseOverlay,
    onExited,
    isShown,
    contextValue,
    focusTrapRef,
  }
}
