import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates'
import { useIntervalTimer } from '@fiji/hooks/use-interval-timer'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMultiModalSearchContext } from '@etta/modules/multi-modal-search/interface/use-multi-modal-search-context'
import { checkComplexPickUp } from '@etta/screens/mobility-search-page/utils'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { MobilitySearchResultErrorType } from './mobility-search-result-error'

const i18nBase = 'Mobility.SearchResultPage'

export function useMobilitySearchResultDialog() {
  const { t } = useTranslation()

  const {
    searchRideHailActions,
    searchRideHailStore,
    rideHailAirportPickupStore,
  } = useRideHailSearchContext()

  const {
    featureFlagsStore: {
      flags: { isReserveAirportPickupEnabled, isMobilityMultiModalEnabled },
    },
  } = useFeatureFlagsContext()

  const { multiModalSearchActions } = useMultiModalSearchContext()

  const isOnDemand = searchRideHailStore.isOnDemandRide
  const {
    pickUpPlace,
    pickUpDate,
    pickUpTime,
    dropOffPlace,
  } = searchRideHailStore.searchRideHailForm
  const [shouldExecuteSearch, setShouldExecuteSearch] = useState<boolean>(true)
  const [isExpandedListRides, setIsExpandedListRides] = useState<boolean>(false)

  const pickUpName = pickUpPlace?.airportCode ?? (pickUpPlace?.name || '')
  const dropOffName = dropOffPlace?.airportCode ?? (dropOffPlace?.name || '')
  const formatedPickUpTime = useMemo(() => {
    if (isOnDemand) {
      const today = dateFormat(new Date(), 'MMM d')
      const nowLabel = t(`${i18nBase}.PickUpTimeNow`)
      return `${today}, ${nowLabel}`
    }

    if (!pickUpDate || !pickUpTime) {
      return ''
    }

    return dateFormat(
      new Date(pickUpDate).setHours(pickUpTime.hours, pickUpTime.minutes),
      'MMM d, h:mm aaa',
    )
  }, [pickUpDate, pickUpTime, isOnDemand, t])

  const complexPickUpType = useMemo(() => {
    const complexType = checkComplexPickUp(pickUpPlace, dropOffPlace)
    switch (complexType) {
      case 'airport':
        if (isReserveAirportPickupEnabled || isOnDemand) {
          return null
        }
        return MobilitySearchResultErrorType.AirportPickupOutOfPolicy
      case 'outside-US':
        return MobilitySearchResultErrorType.ErrorLocationUnavailable
      default:
        return null
    }
  }, [pickUpPlace, dropOffPlace, isReserveAirportPickupEnabled, isOnDemand])

  const refetch = () => {
    setShouldExecuteSearch(true)
  }

  useEffect(() => {
    if (complexPickUpType) {
      searchRideHailStore.setSearchRideHailError(complexPickUpType)
      setShouldExecuteSearch(false)
      return
    }

    if (!shouldExecuteSearch) {
      return
    }
    searchRideHailActions.loadSearchRideHail(isOnDemand)
    if (isMobilityMultiModalEnabled) {
      multiModalSearchActions.handleSearchMultiModal()
    }

    setShouldExecuteSearch(false)
  }, [
    shouldExecuteSearch,
    searchRideHailActions,
    complexPickUpType,
    searchRideHailStore,
    isOnDemand,
    multiModalSearchActions,
    isMobilityMultiModalEnabled,
  ])

  useIntervalTimer({
    intervalInSeconds: 60,
    isRunning: isOnDemand,
    handleOnExpire: refetch,
  })

  const arrivalFlight = rideHailAirportPickupStore.arrivalFlight

  const arrivalFlightLabel = rideHailAirportPickupStore.isCustomFlight
    ? `${t(i18nBase + '.LinkedFlight')} : ${arrivalFlight?.flightDesignator}`
    : `${arrivalFlight?.airlineName} · ${arrivalFlight?.flightDesignator}`

  const handleToggleViewMoreRides = () => {
    setIsExpandedListRides((current) => !current)
  }

  return {
    refetch,
    pickUpName,
    formatedPickUpTime,
    dropOffName,
    shouldShowArrivalFlight: arrivalFlight !== null,
    arrivalFlightLabel,
    complexPickUpType,
    isOnDemand,
    isExpandedListRides,
    handleToggleViewMoreRides,
  }
}
