import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  isDropOff?: boolean
  isPickUp?: boolean
}

const i18nBase = 'CarRentalOfficeSearch.Map'

export function useMarker({ isDropOff, isPickUp }: Props) {
  const { t } = useTranslation()
  const getMarkerText = useCallback(() => {
    if (isDropOff && isPickUp) {
      return t(`${i18nBase}.YourPickUpAndDropOffPoint`)
    }

    if (isDropOff) {
      return t(`${i18nBase}.YourDropOffPoint`)
    }

    if (isPickUp) {
      return t(`${i18nBase}.YourPickUpPoint`)
    }

    return null
  }, [isDropOff, isPickUp, t])

  return {
    activeMarkerText: getMarkerText(),
    isActive: !!isDropOff || !!isPickUp,
  }
}
