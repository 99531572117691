import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Separator } from '@etta/ui/separator'
import { headline } from '@fiji/style'

export const CheckboxWrapper = styled.div`
  margin-left: 26px;
`

export const BottomSeparator = styled(Separator)`
  margin-left: 16px;
  margin-top: 10px;
`

export const Footer = styled.footer`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ClearAllButton = styled(Button)`
  margin-right: 16px;
`

export const DescriptionContainer = styled.div`
  display: flex;
  padding: 18px 22px 12px 18px;
`

export const DescriptionIcon = styled.div`
  padding-right: 13px;
`

export const DescriptionBody = styled.div``

export const DescriptionTitle = styled.div`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
  padding-bottom: 8px;
`
