import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const ItemContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`

export const IconBlock = styled.div`
  width: 24px;
`

export const InfoBlock = styled.div`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const InfoLabel = styled(Text)`
  margin-top: 2px;
  max-width: 260px;
  white-space: pre-wrap;
`
export const TitleLabel = styled(Text)`
  display: flex;
  line-height: 21px;
  max-width: 202px;
`

export const PriceLabel = styled(Text)`
  line-height: 21px;
`
