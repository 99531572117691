import type { ReactElement } from 'react'
import { useContext, useRef } from 'react'
import type { PageProps } from '../../page'
import { PageSwitcherContext } from '../../page-switcher-context'

export default function useTransitionPage() {
  const context = useContext(PageSwitcherContext)
  const lastPageRef = useRef<ReactElement<PageProps> | null>()

  const isPageChanged = context?.activePageKey !== lastPageRef.current?.props.pageKey
  if (isPageChanged) {
    lastPageRef.current = context?.activePage
  }

  const lastPageValue = lastPageRef.current

  return {
    isPageChanged,
    transitionIndex: lastPageValue?.props.transitionIndex,
  }
}
