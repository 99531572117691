import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { MultiLayerCostAllocationUnit } from '@fiji/graphql/types'
import { Container, InlineIcon } from './option-styled'

type Props = {
  allocation: MultiLayerCostAllocationUnit
  onChange(allocation: MultiLayerCostAllocationUnit): void
  selectedAllocationId?: number | null
}

export function Option({ allocation, onChange, selectedAllocationId }: Props) {
  const isSelected = allocation.allocationId === selectedAllocationId
  return (
    <Container onClick={() => onChange(allocation)}>
      <Text variant="subHeadMedium" color="mainText">
        {allocation.name}
      </Text>
      {isSelected && <InlineIcon name="tickPWA" size="medium" color="primary" />}
      {(allocation.stats?.activeChildren || 0) > 0 && <Icon name="chevronRightPWA" size="small" />}
    </Container>
  )
}
