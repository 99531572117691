import { observer } from 'mobx-react-lite'
import { Radio } from '@etta/ui/radio'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { FilterSelectionRow } from './filters-styled'

type Props = {
  isModal?: boolean
}

export const HotelGroupFilter = observer(function HotelGroupFilter({ isModal = false }: Props) {
  const {
    hotelFiltersAction,
    hotelFiltersStore,
    hotelSearchResultsStore,
  } = useHotelSearchResultsContext()

  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  if (!hotelSearchResultsStore.filterOptions.groups || !hotelFiltersStore.selectedFilters.groupId) {
    return null
  }

  return (
    <>
      {hotelSearchResultsStore.filterOptions.groups.map(({ id, name }) => {
        const checked = hotelFiltersStore.isGroupIdSelected(id)
        return (
          <FilterSelectionRow
            key={id}
            withExtraSpace={isNewHotelExposedFiltersEnabled && !isMobile && !isModal}
            withBorder={!isModal}
            withSpace>
            <Radio
              data-tracking-id={`hotel-group-${id}`}
              value={name}
              isChecked={checked}
              onChange={() => hotelFiltersAction.handleSetFilter('groupId', id)}>
              {name}
            </Radio>
          </FilterSelectionRow>
        )
      })}
    </>
  )
})
