export default function RailIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.2768 12.5818C28.9924 12.5806 29.6788 12.8459 30.1822 13.3186C30.6857 13.7912 30.9643 14.4318 30.9556 15.0968V28.5763L28.0929 31.3576L32 36H28.9371L26.7291 33.5405H17.9678L15.9114 36H13L16.6636 31.3576L13.7414 28.6467V15.0968C13.7326 14.4274 14.0148 13.783 14.5242 13.3096C15.0335 12.8361 15.7269 12.5738 16.4472 12.5818H28.2768ZM20.3218 27.5703H16.4634L16.761 27.852C17.6581 28.7126 18.8919 29.1989 20.1811 29.1999H20.3218V27.5703ZM28.4013 27.5703H24.5429V29.1999H25.0028C26.0793 29.1955 27.1087 28.7896 27.8602 28.0733L28.4013 27.5703ZM28.331 15.0918H16.3931V21.6303C16.3931 22.5164 17.1659 23.2348 18.1193 23.2348H26.6047C27.5581 23.2348 28.331 22.5164 28.331 21.6303V15.0918ZM27.7844 8L30.9556 11.0077H28.1416L26.9294 9.94144H17.6756L16.5987 11.0077H13.7684L16.8043 8H27.7844Z"
        fill="currentColor"
      />
    </svg>
  )
}
