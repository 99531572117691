import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Button } from '@etta/ui/button'
import { MismatchedSegment } from './segments/mismatched-segment'
import { TripFlightSegment } from './segments/trip-flight-segment'
import type { FlightSegment, UnmatchedSegment } from './types'
import {
  InfoBlock,
  LoadingContainer,
  MismatchedSegmentsContainer,
  ConfirmButton,
} from './align-dates-modal.styled'
import LoadingIcon from './assets/loading-icon.gif'

const i18nBase = 'AlignDatesModal.'

type Props = {
  handleClose: () => void
  handleSubmit: (types: AlignTripTravelVerticalValueObject[]) => void
  isLoading: boolean
  handleSelect: (type: AlignTripTravelVerticalValueObject) => void
  tripFlightSegments: FlightSegment[]
  mismatchedSegments: UnmatchedSegment[]
  selectedTypes: Set<AlignTripTravelVerticalValueObject>
  flightStartDateTime: string
  lastFlightStartDateTime: string
}

export function AlignDatesModalContent({
  isLoading,
  handleClose,
  handleSubmit,
  handleSelect,
  mismatchedSegments,
  selectedTypes,
  tripFlightSegments,
  flightStartDateTime,
  lastFlightStartDateTime,
}: Props) {
  const { t } = useTranslation()
  const isMobile = useScreenType() === ScreenType.Mobile
  if (isLoading) {
    return (
      <LoadingContainer>
        <img src={LoadingIcon} alt="Loading" />
        <Text variant="body">{t(i18nBase + 'LoadingText')}</Text>
      </LoadingContainer>
    )
  }

  return (
    <Fragment>
      <Header withBorderBottom leftSlot={<Header.BackButton onClick={handleClose} />}>
        <Header.Title title={t(i18nBase + 'Header')} align="left" />
      </Header>
      <Modal.Body padding="normal">
        <InfoBlock>
          <Icon name="infoOutlinePWA" />
          <Text variant="subHeadMedium" color="mainText">
            {t(i18nBase + 'InfoText')}
          </Text>
        </InfoBlock>
        <MismatchedSegmentsContainer>
          {mismatchedSegments.map((segment, idx) => (
            <MismatchedSegment
              key={idx}
              type={segment.type}
              onSelect={handleSelect}
              isSelected={selectedTypes.has(segment.type)}
              startDateTime={flightStartDateTime}
              endDateTime={lastFlightStartDateTime}
            />
          ))}
        </MismatchedSegmentsContainer>
        {!isMobile && (
          <Block marginTop={20}>
            <ConfirmButton
              disabled={selectedTypes.size === 0}
              onClick={() => handleSubmit(Array.from(selectedTypes))}>
              {t(i18nBase + 'SubmitButton')}
            </ConfirmButton>
          </Block>
        )}
        <Block marginTop={40}>
          <Text isBlock variant="subHeadStrong" color="mainText">
            {t(i18nBase + 'SelectedTripTitle')}
          </Text>
        </Block>
        <Block marginTop={12}>
          {tripFlightSegments.map((flight, idx) => (
            <TripFlightSegment key={idx} {...flight} />
          ))}
        </Block>
      </Modal.Body>
      {isMobile && (
        <Modal.Footer padding="normal" withBorder>
          <Button
            disabled={selectedTypes.size === 0}
            onClick={() => handleSubmit(Array.from(selectedTypes))}
            fullWidth
            variant="solid">
            {t(i18nBase + 'SubmitButton')}
          </Button>
        </Modal.Footer>
      )}
    </Fragment>
  )
}
