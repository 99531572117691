import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { DetailsHeaderMobile } from './mobile'
import { DetailsHeaderDesktop } from './desktop'

export function DetailsHeaderLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<DetailsHeaderMobile {...props} />}
      desktopSlot={<DetailsHeaderDesktop {...props} />}
    />
  )
}
