import { Inject, Service } from '@etta/di'
import { HomeAirportService } from '@etta/modules/air-search/interface/services/home-airport.service'
import { GetBrandConfigurationService } from '@etta/modules/brand-configuration/interface/service/get-brand-config.service'
import { DisplayConfigurationService } from '@etta/modules/display-configuration/interface/services/display-configuration.service'
import { ZooKeeperConfigurationService } from '@etta/modules/display-configuration/interface/services/zookeeper-configuration.service'
import { GetTripsListService } from '@etta/modules/post-booking-list/interface/services/get-trips-list.service'
import { PreSearchService } from '@etta/modules/pre-search/interface/service/pre-search.service'
import { ActiveTripService } from '@etta/modules/review-trip/interface/services/active-trip.service'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { DelegateService as DelegateSettingService } from '@etta/modules/settings/interface/services/delegate.service'
import { PaymentInformationStore } from '@etta/modules/settings/interface/stores/payment-information.store'
import { TravelPolicyService } from '@etta/modules/travel-policy/interface/services/travel-policy.service'
import { TravelPreferencesService } from '@etta/modules/travel-preferences/interface/services/travel-preferences.service'
import { UnusedTicketService } from '@etta/modules/unused-tickets/interface/services/unused-ticket.service'

import { UserService } from '@etta/modules/user'

@Service()
export class DelegateService {
  constructor(
    @Inject() private readonly userService: UserService,
    @Inject() private readonly travelPreferencesService: TravelPreferencesService,
    @Inject() private readonly displayConfigurationService: DisplayConfigurationService,
    @Inject() private readonly getBrandConfigurationService: GetBrandConfigurationService,
    @Inject() private readonly delegateSettingService: DelegateSettingService,
    @Inject() private readonly homeAirportService: HomeAirportService,
    @Inject() private readonly travelPolicyService: TravelPolicyService,
    @Inject() private readonly zooKeeperConfigurationService: ZooKeeperConfigurationService,
    @Inject() private readonly preSearchService: PreSearchService,
    @Inject() private readonly getOrdersService: GetTripsListService,
    @Inject() private readonly activeTripService: ActiveTripService,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly paymentInformationStore: PaymentInformationStore,
    @Inject() private readonly unusedTicketService: UnusedTicketService,
  ) {}

  async loadDelegateContext() {
    await Promise.all([
      this.homeAirportService.reload(),
      this.getBrandConfigurationService.reload(),
      this.displayConfigurationService.refetchDisplayConfiguration(),
      this.userService.reloadUser(),
      this.travelPreferencesService.refetchTravelPreferences(),
      this.travelPolicyService.refetchTravelPolicy(),
      this.zooKeeperConfigurationService.refetchZooKeeperConfiguration(),
      this.preSearchService.loadSearchInitiateInfo(),
      this.unusedTicketService.forceGetUnusedTickets(),
    ])
    await this.delegateSettingService.getDelegates()
  }

  clearStates() {
    this.getOrdersService.dropAndReloadAllOrders()
    this.activeTripService.clearStore()
    this.reviewTripStore.dropTripStore()
    this.paymentInformationStore.setCreditCards(null)
  }
}
