export const DisplaySettingsFieldsSettings = {
  dateFormat: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  hourlyTimeFormat: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  timeZone: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  measurementUnit: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  currency: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  country: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}
