import type { TripDetailsTrainSegment } from '@fiji/graphql/types'
import { ProviderDetails, Headline, TrainDetails, TrainModes, JourneyDetails } from './components'
import { Container, Content } from './train-booking-info-styled'

type Props = {
  segment: TripDetailsTrainSegment
  trainIndex: number
  platform?: string | null
}

export function TrainBookingInfo({ segment, platform, trainIndex }: Props) {
  const segmentNumber = trainIndex + 1
  return (
    <Container>
      <Headline segmentNumber={segmentNumber} />
      <Content>
        <ProviderDetails
          carrierName={segment.carrierName}
          trainId={segment.trainId}
          carrierImage={segment.carrierImage}
        />
        <TrainDetails allocatedSeat={segment.allocatedSeat} platform={platform} />
        <JourneyDetails segment={segment} />
      </Content>
      <TrainModes segment={segment} />
    </Container>
  )
}
