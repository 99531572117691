import { Text } from '@etta/ui/text'
import type { RailCardItemProps } from '../../../types'
import { StyledItemBlock } from '../rail-card-group-styled'

export function RailCardItem({ card, handleCardClick }: RailCardItemProps) {
  if (!card.name) {
    return null
  }

  const onClick = () => handleCardClick(card)

  return (
    <StyledItemBlock
      aria-label={card.name}
      paddingHorizontal={20}
      paddingVertical={18}
      onClick={onClick}>
      <Text variant="subHeadMedium" color="mainText">
        {card.name}
      </Text>
    </StyledItemBlock>
  )
}
