import styled from 'styled-components'

export const AnimatedContainer = styled.div<{ isHidden: boolean }>`
  top: ${(p) => (p.isHidden ? '100%' : '0')};
  opacity: ${(p) => (p.isHidden ? '0' : '1')};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
  position: absolute;
  left: 0;
  display: block;
  visibility: ${(p) => (p.isHidden ? 'hidden' : 'visible')};
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.background};
`

export const HotelListWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const MapWrapper = styled.div<{ isMapVisible: boolean }>`
  visibility: ${(p) => (p.isMapVisible ? 'visible' : 'hidden')};
`
