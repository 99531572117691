import { SegmentType } from '@etta/core/enums'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

export function extractTypeAndCost(segment: SegmentPostBookingValueObject) {
  switch (segment.type) {
    case SegmentType.Hotel:
      return {
        type: SegmentType.Hotel,
        cost: {
          primary: segment.totalCost,
          secondary: null,
        },
      }
    case SegmentType.CarRental:
      return {
        type: SegmentType.CarRental,
        cost: {
          primary: segment.totalCost?.primary,
          secondary: segment.totalCost?.secondary || null,
        },
      }
    case SegmentType.Flight:
      return {
        type: SegmentType.Flight,
        cost: {
          primary: segment.totalCost?.primary,
          secondary: segment.totalCost?.secondary || null,
        },
      }
    default:
      return null
  }
}
