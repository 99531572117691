import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { CarServiceTripCost, Rate } from '@fiji/graphql/types'

type Args = {
  price: CarServiceTripCost
}

const i18nBase = 'PostBooking.CarServiceDetails'

export function usePrice({ price }: Args) {
  const { t } = useTranslation()
  const { total } = price
  const { mainCost: formattedTotalCost } = formatRate(total, { morpheme: 'none' })

  const getPriceDetail = useCallback(
    (rate: Rate, translationKey: string) => {
      return {
        title: t(`${i18nBase}.${translationKey}`),
        value: formatRate(rate, { morpheme: 'none' }).mainCost,
      }
    },
    [t],
  )

  const ratesAndTranslates = [
    [price.base, 'BaseRate'],
    [price.gratuity, 'Gratuity'],
    [price.meetAndGreet, 'MeetAndGreet'],
    [price.stops, 'StopsChange'],
    [price.parking, 'Parking'],
    [price.tolls, 'Tolls'],
    [price.surfaceTransport, 'STC'],
    [price.fuelSurcharge, 'FuelSurcharge'],
    [price.accountDiscount, 'Discount'],
    [price.extraService, 'ExtraServiceCharge'],
    [price.accountService, 'AccountServiceCharge'],
    [price.airportFees, 'AirportCharge'],
    [price.taxes, 'Taxes'],
  ].filter(([rate]) => rate) as [Rate, string][]

  const priceDetailsList = ratesAndTranslates.map(([rate, translationKey]) =>
    getPriceDetail(rate, translationKey),
  )

  return {
    priceDetailsList,
    formattedTotalCost,
  }
}
