import i18n from '@fiji/i18n'
import { Toggle } from '@etta/interface/services/toggle'
import { Validator } from '@etta/interface/services/validator'
import { NotificationsTopicEnum } from '@etta/modules/settings/core/enums/notifications-topic.enum'
import { Store } from '@etta/di'

const i18nBase = 'Settings.Notifications.Modal'
const i18nBaseErrors = 'Settings.Errors'

@Store()
export class NotificationsStore {
  isSaveButtonDisabled = true
  toggle = new Toggle()
  flightNotificationActionToggle = new Toggle()
  itineraryNotificationActionToggle = new Toggle()
  southwestNotificationActionToggle = new Toggle()
  notificationRemoveToggle = new Toggle()

  notificationTopic: NotificationsTopicEnum | null = null
  notificationIdEditMode: number | null = null
  isEmailFieldHidden: boolean = false
  isPhoneFieldHidden: boolean = false

  notificationsValidator = new Validator({
    email: Validator.scheme
      .string()
      .email(
        i18n.t(`${i18nBaseErrors}.InvalidEmailField`, { fieldName: i18n.t(`${i18nBase}.Email`) }),
      )
      .nullable()
      .default(''),
    phoneCountryCode: Validator.scheme.string().default(''),
    phoneNumber: Validator.scheme
      .string()
      .test(
        'correct-phone-number-length',
        i18n.t(`${i18nBaseErrors}.InvalidPhone`),
        (value) => !value || String(value).length >= 7,
      )
      .default(''),
  })

  get currentNotificationToggle() {
    switch (this.notificationTopic) {
      case NotificationsTopicEnum.FLIGHT:
        return this.flightNotificationActionToggle
      case NotificationsTopicEnum.ITINERARY:
        return this.itineraryNotificationActionToggle
      case NotificationsTopicEnum.SOUTHWEST:
        return this.southwestNotificationActionToggle
      default:
        return null
    }
  }

  setNotificationValue(field: 'email' | 'phoneCountryCode' | 'phoneNumber', value: string) {
    this.notificationsValidator.onFieldChange(field, value)
  }

  setNotificationIdEditMode(id: number | null) {
    this.notificationIdEditMode = id
  }

  setIsEmailFieldHidden(value: boolean) {
    this.isEmailFieldHidden = value
  }

  setIsPhoneFieldHidden(value: boolean) {
    this.isPhoneFieldHidden = value
  }

  setNotificationTopic(value: NotificationsTopicEnum | null) {
    this.notificationTopic = value
  }

  resetNotifications() {
    this.setNotificationValue('email', '')
    this.setNotificationValue('phoneCountryCode', '')
    this.setNotificationValue('phoneNumber', '')
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }

  resetState() {
    this.resetNotifications()
    this.setNotificationIdEditMode(null)
    this.setIsEmailFieldHidden(false)
    this.setIsPhoneFieldHidden(false)
    this.setNotificationTopic(null)
    this.setIsSaveButtonDisabled(true)
  }
}
