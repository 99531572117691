import styled from 'styled-components'
import { captionStrongCaps } from '@fiji/style/typography'
import { slideUp } from './slide-up-transition'

export const Container = styled.div<{
  isShown: boolean
}>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  display: ${(props) => (props.isShown ? 'block' : 'none')};
`

export const DialogContainerRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  ${slideUp};
`

export const DialogContainer = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  padding: 24px;
  width: 328px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 41px;
`

export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
`

export const Header = styled.div`
  ${captionStrongCaps};
  text-transform: uppercase;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  height: 36px;
`
