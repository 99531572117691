import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useGlobalModalsContext } from '@etta/modules/global-modals/interface/global-modals.context'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ROUTES } from '@fiji/routes'
import { useAppDispatch } from '@fiji/store'
import { updateFlightId, updateCarRentalId, updateHotelId } from '@fiji/store/segment-ids'
import { useReviewTripContext } from '@etta/modules/review-trip'

const i18Base = 'TripReview.TripExpiredDialog'

/*
 We don't handle decline event, since we disallow clicking outside/canceling modal.
 Once this modal appears, we only allow user to click confirmation action.
*/
const handleDecline = () => {}

export function useTripExpiredModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { globalModalsStore } = useGlobalModalsContext()
  const { tripExpiredModalToggle, isOnlyTripExpiredModalOpen } = globalModalsStore
  const tripExpiredModal = tripExpiredModalToggle
  const history = useHistory()
  const { reviewTripStore, tripActions } = useReviewTripContext()
  const { isTripRemovingLoading } = reviewTripStore
  const { removeTrip } = tripActions

  const handleConfirm = () => {
    tripExpiredModal.handleClose()
    removeTrip()
    history.replace(ROUTES.explore)
    dispatch(updateFlightId({ flightId: null }))
    dispatch(updateCarRentalId({ carRentalId: null }))
    dispatch(updateHotelId({ hotelId: null }))
  }

  const tripExpiredModalSlot = (
    <ConfirmationDialog
      title={t(`${i18Base}.Title`)}
      content={t(`${i18Base}.Description`)}
      isOpen={isOnlyTripExpiredModalOpen}
      onDecline={handleDecline}
      onConfirm={handleConfirm}
      confirmButtonText={t(`${i18Base}.ConfirmButtonText`)}
    />
  )

  return {
    tripExpiredModal,
    tripExpiredModalSlot,
    isTripRemovingLoading,
  }
}
