import { css } from 'styled-components'

export const TRANSITION_CLASS_NAME = 'MEETING_NOTCH'
export const TRANSITION_DURATION = 300
const TOP_HIDDEN_POSITION = '-35px'
const TOP_VISIBLE_POSITION = 0

export const transition = css`
  top: ${TOP_HIDDEN_POSITION};
  transition: top ${TRANSITION_DURATION}ms linear;

  &.${TRANSITION_CLASS_NAME}-enter,
    &.${TRANSITION_CLASS_NAME}-appear,
    &.${TRANSITION_CLASS_NAME}-exit-done,
    &.${TRANSITION_CLASS_NAME}-exit-active,
    &.${TRANSITION_CLASS_NAME}-exit {
    top: ${TOP_HIDDEN_POSITION};
  }

  &.${TRANSITION_CLASS_NAME}-enter-done, &.${TRANSITION_CLASS_NAME}-enter-active {
    top: ${TOP_VISIBLE_POSITION};
  }

  &.${TRANSITION_CLASS_NAME}-appear-done, &.${TRANSITION_CLASS_NAME}-appear-active {
    transition: none;
    top: ${TOP_VISIBLE_POSITION};
  }
`
