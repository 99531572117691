import { DataProvider } from '@etta/di'
import { GetCostSegmentsDocument, GetMultiLayerCostAllocationDocument } from '@fiji/graphql/hooks'
import type {
  GetCostSegmentsQuery,
  GetMultiLayerCostAllocationQuery,
  MultiLayerCostAllocationInput,
} from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class CostAllocationProvider extends GraphqlDataProvider {
  getCostSegments() {
    return this.client.query<GetCostSegmentsQuery>({
      query: GetCostSegmentsDocument,
      fetchPolicy: 'cache-first', // to be consistent with previous implementation
    })
  }

  getCostAllocations(input: MultiLayerCostAllocationInput) {
    return this.client.query<GetMultiLayerCostAllocationQuery>({
      query: GetMultiLayerCostAllocationDocument,
      variables: { input },
      fetchPolicy: 'cache-first',
    })
  }
}
