import { useState, useRef } from 'react'
import { ROUTES } from '@fiji/routes'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { usePreventBack, usePreventForward } from '../use-prevent-navigation'
import { useOpenNewLocation } from './use-open-new-location'

type Args = {
  isLoading: boolean
  isPreventBack: boolean
  isPreventForward: boolean
  shouldOpenExplore: boolean
  onPrevent: () => void
  bookingId?: string
  canOpenPostBookingTripInsteadOfExplore?: boolean
}

const DEFAULT_TIMEOUT_OPEN = 100
const restrictedMobileRoutes = [ROUTES.explore]
const restrictedDesktopRoutes = [ROUTES.explore, ROUTES.postBooking.trips(), ROUTES.support.main]

export function usePreventLocationNavigation({
  isLoading,
  isPreventBack,
  isPreventForward,
  shouldOpenExplore,
  onPrevent,
  bookingId,
  canOpenPostBookingTripInsteadOfExplore = true,
}: Args) {
  const [isBlocking, setIsBlocking] = useState(true)
  const newLocationRef = useRef(ROUTES.explore)
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const handlePrevent = (newLocation: string) => {
    newLocationRef.current = newLocation

    onPrevent()
  }

  usePreventBack({
    isBlocking: isBlocking && isPreventBack,
    onPrevent: handlePrevent,
  })

  usePreventForward({
    isBlocking: isBlocking && isPreventForward,
    restrictedRoutesMatch: isMobile ? restrictedMobileRoutes : restrictedDesktopRoutes,
    onPrevent: handlePrevent,
  })

  useOpenNewLocation({
    canOpen: (!isBlocking || shouldOpenExplore) && !isLoading,
    bookingId,
    newLocation: newLocationRef.current,
    canOpenPostBookingTripInsteadOfExplore,
  })

  const handleOpenNewLocation = () => {
    // need wait until prev state will applied, modal should be close for example
    // in other case flow will be broken
    setTimeout(() => {
      setIsBlocking(false)
    }, DEFAULT_TIMEOUT_OPEN)
  }

  return {
    handleOpenNewLocation,
  }
}
