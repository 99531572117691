import type { Place } from '@fiji/types'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import type { RideHailLocationValueObject, AccessPointValueObject } from '../../core/value-objects'
import { RideHailPlaceProvider } from '../../core/enum/ride-hail-place-provider.enum'

export function mapToRideHailLocation(
  place?: Place,
  address?: string,
  accessPoint?: AccessPointValueObject,
): RideHailLocationValueObject {
  const location: RideHailLocationValueObject = {}

  if (address) {
    location.address = address
  }

  if (place && place.latitude && place.longitude) {
    location.geocode = { lat: place.latitude, long: place.longitude }
    location.address = place.name
  }

  if (place && place.placeType === PlaceAutocompleteType.GooglePlaces) {
    location.place = {
      placeId: place.placeId,
      provider: RideHailPlaceProvider.GooglePlaces,
    }
  }

  if (accessPoint && accessPoint.geocode) {
    location.place = {
      placeId: accessPoint.id,
      provider: RideHailPlaceProvider.UberPlaces,
    }
    location.geocode = accessPoint.geocode
  }

  return location
}
