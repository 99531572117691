import type { MoneyValueObject, RateValueObject } from '@etta/core/value-objects'
import type { Money, Rate } from '@fiji/graphql/types'

const DEFAULT_CURRENCY = 'USD'

export class TripMoneyMapper {
  static toRateValueObject(rate: Rate): RateValueObject
  static toRateValueObject(rate?: Rate | null): RateValueObject | undefined
  static toRateValueObject(rate?: Rate | null): RateValueObject | undefined {
    if (!rate) {
      return
    }

    return {
      primary: TripMoneyMapper.toMoneyValueObject(rate.primary),
      secondary: TripMoneyMapper.toMoneyValueObject(rate.secondary),
    }
  }

  static toMoneyValueObject(money: Money): MoneyValueObject
  static toMoneyValueObject(money?: Money | null): MoneyValueObject | undefined
  static toMoneyValueObject(money?: Money | null): MoneyValueObject | undefined {
    if (!money) {
      return
    }
    return {
      amount: money.amount,
      currency: money.currency || DEFAULT_CURRENCY,
    }
  }
}
