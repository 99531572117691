import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetFlightFareRulesByTransactionIdDocument } from '@fiji/graphql/hooks'
import type {
  GetFlightFareRulesByTransactionIdQueryVariables,
  GetFlightFareRulesByTransactionIdQuery,
} from '@fiji/graphql/hooks'

@DataProvider()
export class FlightFareRulesDataProvider extends GraphqlDataProvider {
  handleLoadFlightFareRulesByTransactionId(
    input: GetFlightFareRulesByTransactionIdQueryVariables['input'],
  ) {
    return this.client.query<
      GetFlightFareRulesByTransactionIdQuery,
      GetFlightFareRulesByTransactionIdQueryVariables
    >({
      query: GetFlightFareRulesByTransactionIdDocument,
      variables: { input },
    })
  }
}
