import styled, { css } from 'styled-components'
import { headline, title2, subHeadMedium, subHeadStrong } from '@fiji/style'
import type { PreviousBookingContentSkin } from '../types'

const PREV_COSTS_MARGIN = 23

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText2};
  ${headline};
`

export const Container = styled.div<{ isAligningItemsStart?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(p) => {
    if (p.isAligningItemsStart) {
      return `align-items: flex-start;`
    }

    return ''
  }}
`

export const InfoTitle = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText};
  ${title2};
`

export const CostContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

export const SpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const PreviousBookingContent = styled.div<{ skin: PreviousBookingContentSkin }>`
  padding: 16px 20px 21px;
  margin: 0 -20px;
  border-bottom: 2px solid ${(p) => p.theme.colors.borderDark};
  ${(p) =>
    p.skin === 'narrow-desktop' &&
    css`
      padding: 0 0 18px;
      margin: 0 0 33px;
      border-bottom-width: 1px;
    `}
`

export const PreviousBookingToggle = styled.button<{
  isActive: boolean
  skin: PreviousBookingContentSkin
}>`
  background: none;
  border: none;
  width: 100%;
  ${subHeadMedium};
  padding: 0;
  text-align: left;
  display: flex;
  > span {
    width: 100%;
  }
  > div {
    transition: all 0.4s;
    transform: rotate(${(p) => (p.isActive ? '-180DEG' : '0')});
  }
  ${(p) => p.skin === 'narrow-desktop' && `${subHeadStrong}`}
`

export const PreviousBookingCosts = styled.div<{
  isActive: boolean
  heightRef: number
  childCount: number
}>`
  overflow: hidden;
  transition: all 0.4s;
  padding-bottom: ${(p) => (p.isActive ? 21 : 0)}px;
  max-height: ${(p) =>
    p.isActive && p.heightRef ? p.heightRef + p.childCount * PREV_COSTS_MARGIN : 0}px;
  > div > div {
    margin-top: ${PREV_COSTS_MARGIN}px;
  }
  button {
    display: none;
  }
`
