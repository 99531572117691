import * as R from 'ramda'
import { Store, Inject } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { SelectedCarFiltersValueObject } from '@etta/modules/car-rental-search/core/value-objects/selected-car-filters.value-object'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CarRentalFilterMapper } from '../mappers'
import type { CarRentalFilters } from '../types'
import { CarRentalStore } from './car-rental.store'

@Store()
export class CarFiltersStore {
  private static putUniqueValue<T>(currentValues: T[], value: T): T[] {
    const newValues = new Set(currentValues)
    if (newValues.has(value)) {
      newValues.delete(value)
    } else {
      newValues.add(value)
    }
    return Array.from(newValues)
  }

  constructor(
    @Inject()
    private carRentalStore: CarRentalStore,

    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  transmissionModal = new Toggle()
  carClassModal = new Toggle()
  supplierModal = new Toggle()
  fuelModal = new Toggle()

  selectedFilters: SelectedCarFiltersValueObject = {
    transmission: this.carRentalStore.queryParams.carTransmission ?? [],
    carClass: this.carRentalStore.queryParams.carClass ?? [],
    vendors: this.carRentalStore.queryParams.carVendor ?? [],
    fuelType: this.carRentalStore.queryParams.carFuel ?? [],
  }

  handlers = {
    setTransmission: this.setTransmission.bind(this),
    setCarClass: this.setCarClass.bind(this),
    setVendor: this.setVendor.bind(this),
    setFuelType: this.setFuelType.bind(this),
  }

  clearHandlers = {
    clearTransmission: this.clearTransmission.bind(this),
    clearCarClass: this.clearCarClass.bind(this),
    clearVendors: this.clearVendors.bind(this),
    clearFuelType: this.clearFuelType.bind(this),
    clearAllSelectedFilters: this.clearAll.bind(this),
  }

  selectionHandlers = {
    isTransmissionSelected: this.isTransmissionSelected.bind(this),
    isCarClassSelected: this.isCarClassSelected.bind(this),
    isSupplierSelected: this.isSupplierSelected.bind(this),
    isFuelTypeSelected: this.isFuelTypeSelected.bind(this),
  }

  // when adding new default company filters besides the car class, update this function
  get isDefaultFilterCompanySettingsExist(): boolean {
    const { displayConfiguration } = this.displayConfigurationStore
    const { defaultCarSize } = displayConfiguration

    return Boolean(defaultCarSize)
  }

  get isCurrentFiltersSameAsDefault(): boolean {
    const defaultFiltersConfiguration = this.getDefaultFiltersFromConfiguration()
    const defaultFilters = CarRentalFilterMapper.toSelectedCarFiltersValueObject(
      defaultFiltersConfiguration,
    )

    return R.equals(defaultFilters, this.selectedFilters)
  }

  getDefaultFiltersFromConfiguration(): CarRentalFilters {
    const { displayConfiguration } = this.displayConfigurationStore
    const { defaultCarSize } = displayConfiguration

    return {
      carClass: defaultCarSize ? [defaultCarSize] : [],
      carVendor: [],
      carTransmission: [],
      carFuel: [],
    }
  }

  get isShowClearFiltersInformer(): boolean {
    return this.isDefaultFilterCompanySettingsExist && this.isCurrentFiltersSameAsDefault
  }

  get isShowResetToDefaultFiltersButton(): boolean {
    return this.isDefaultFilterCompanySettingsExist && !this.isCurrentFiltersSameAsDefault
  }

  get numberOfAppliedFilters(): number {
    return Object.values(this.appliedFilters).reduce((a, b) => {
      return a + (b.length ? 1 : 0)
    }, 0)
  }

  get appliedFilters(): SelectedCarFiltersValueObject {
    return {
      transmission: this.carRentalStore.queryParams.carTransmission ?? [],
      carClass: this.carRentalStore.queryParams.carClass ?? [],
      vendors: this.carRentalStore.queryParams.carVendor ?? [],
      fuelType: this.carRentalStore.queryParams.carFuel ?? [],
    }
  }

  get isEqual() {
    return R.equals(this.selectedFilters, this.appliedFilters)
  }

  isTransmissionSelected(transmissionId: string): boolean {
    if (transmissionId === 'any') {
      return !this.selectedFilters.transmission.length
    }
    return this.selectedFilters.transmission.includes(transmissionId)
  }

  isCarClassSelected(carClassId: string): boolean {
    return this.selectedFilters.carClass.includes(carClassId)
  }

  isSupplierSelected(supplierId: string): boolean {
    return this.selectedFilters.vendors.includes(supplierId)
  }

  isFuelTypeSelected(fuelTypeId: string): boolean {
    return this.selectedFilters.fuelType.includes(fuelTypeId)
  }

  setSelectedFilters(filters: SelectedCarFiltersValueObject) {
    this.selectedFilters = filters
  }

  setTransmission(value: string[] | string) {
    if (Array.isArray(value)) {
      this.selectedFilters.transmission = value
      return
    }

    this.selectedFilters.transmission = CarFiltersStore.putUniqueValue<string>(
      this.selectedFilters.transmission,
      value,
    )
  }

  setCarClass(value: string[] | string) {
    if (Array.isArray(value)) {
      this.selectedFilters.carClass = value
      return
    }
    this.selectedFilters.carClass = CarFiltersStore.putUniqueValue<string>(
      this.selectedFilters.carClass,
      value,
    )
  }

  setVendor(value: string[] | string) {
    if (Array.isArray(value)) {
      this.selectedFilters.vendors = value
      return
    }
    this.selectedFilters.vendors = CarFiltersStore.putUniqueValue<string>(
      this.selectedFilters.vendors,
      value,
    )
  }

  setFuelType(value: string[] | string) {
    if (Array.isArray(value)) {
      this.selectedFilters.fuelType = value
      return
    }
    this.selectedFilters.fuelType = CarFiltersStore.putUniqueValue<string>(
      this.selectedFilters.fuelType,
      value,
    )
  }

  clearTransmission() {
    this.selectedFilters.transmission = []
  }

  clearCarClass() {
    this.selectedFilters.carClass = []
  }

  clearVendors() {
    this.selectedFilters.vendors = []
  }

  clearFuelType() {
    this.selectedFilters.fuelType = []
  }

  clearAll() {
    this.clearTransmission()
    this.clearCarClass()
    this.clearVendors()
    this.clearFuelType()
  }
}
