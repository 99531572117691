import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin: 32px 0;
  padding: 0 16px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  min-height: 62px;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 40px;
`

export const RightSide = styled.div`
  min-width: 320px;
`
