import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const Wrapper = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  border-top: 2px dashed ${(p) => p.theme.colors.borderDark};
  border-bottom: 2px dashed ${(p) => p.theme.colors.borderDark};
`
