import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { RailFareCardMobile } from './layout/mobile/rail-fare-card-mobile'
import { RailFareCardDesktop } from './layout/desktop/rail-fare-card-desktop'
import { RailFareCardDesktopShort } from './layout/desktop/rail-fare-card-desktop-short'

export const RailFareCard = observer(function RailFareCard({
  isShortDesktopFareCard,
  ...props
}: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailFareCardMobile {...props} />}
      desktopSlot={
        isShortDesktopFareCard ? (
          <RailFareCardDesktopShort {...props} />
        ) : (
          <RailFareCardDesktop {...props} />
        )
      }
    />
  )
})
