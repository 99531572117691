import { useState, useMemo } from 'react'
import { useCalculateFlightEmissionsBatchQuery } from '@fiji/graphql/hooks'
import type {
  CalculateFlightEmissionsBatchResultFlight,
  SearchFlightLeg,
} from '@fiji/graphql/types'
import type { Place, CabinClassType } from '@fiji/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { logValidationErrorToRollbar, SKIP_VALIDATION_ERROR } from '../common'
import { maybeGetDestinationAirportCode, maybeGetOriginAirportCode } from './get-airport-code'
import { mapToThrustCarbonFlight } from './map-to-thrust-carbon-flight'
import { airEmissionsQueryExecuteValidation } from './air-emissions-query-execute-validation'

type Arg = {
  flights: SearchFlightLeg[]
  originPlace?: Place
  destinationPlace?: Place
  cabinClass?: CabinClassType
}

export function useAirEmissions({ flights, originPlace, destinationPlace, cabinClass }: Arg) {
  const {
    featureFlags: { isSustainabilityEnabled },
  } = useFeatureFlags()

  const [existingFlightEmissions, setExistingFlightEmissions] = useState<
    CalculateFlightEmissionsBatchResultFlight[]
  >([])
  const [averageEmissionsTonnes, setAverageEmissionsTonnes] = useState(0)

  const newFlights = useMemo(
    () =>
      flights.filter((flight) => {
        if (
          !existingFlightEmissions.find(
            (existingFlight) => flight.legId === existingFlight.customRef,
          )
        ) {
          return flight
        }
      }),
    [flights, existingFlightEmissions],
  )

  const tcFlights = useMemo(
    () =>
      newFlights.map((flight) => {
        return mapToThrustCarbonFlight(flight, cabinClass)
      }),
    [newFlights, cabinClass],
  )

  const maybeOriginAirportCode = maybeGetOriginAirportCode(newFlights, originPlace)
  const maybeDestinationAirportCode = maybeGetDestinationAirportCode(newFlights, destinationPlace)

  const maybeAirValidationError = airEmissionsQueryExecuteValidation({
    flights: tcFlights,
    maybeOriginAirportCode,
    maybeDestinationAirportCode,
  })
  const isSkipValidationError =
    !maybeAirValidationError.isNothing() &&
    maybeAirValidationError.getValue().text() === SKIP_VALIDATION_ERROR

  if (!isSkipValidationError && !maybeAirValidationError.isNothing()) {
    logValidationErrorToRollbar(maybeAirValidationError, {
      flights: tcFlights,
      maybeOriginAirportCode,
      maybeDestinationAirportCode,
    })
  }

  const { loading } = useCalculateFlightEmissionsBatchQuery({
    variables: {
      input: {
        compare: [
          {
            originAirportCode: maybeOriginAirportCode.withDefault(''),
            destinationAirportCode: maybeDestinationAirportCode.withDefault(''),
          },
        ],
        flights: tcFlights,
      },
    },
    onCompleted: (data) => {
      if (data.calculateFlightEmissionsBatch.errors) {
        return
      }

      const result = [...existingFlightEmissions, ...data?.calculateFlightEmissionsBatch?.flights!]

      if (data?.calculateFlightEmissionsBatch.averageEmissionsTonnes) {
        setAverageEmissionsTonnes(data?.calculateFlightEmissionsBatch.averageEmissionsTonnes)
      }

      setExistingFlightEmissions(result)
    },
    skip: !isSustainabilityEnabled || isSkipValidationError || !maybeAirValidationError.isNothing(),
  })

  return {
    averageEmissionsTonnes,
    existingFlightEmissions,
    loading,
  }
}
