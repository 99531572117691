import { useTranslation } from 'react-i18next'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../../types'
import { useRtpEcoCheckModal } from '../../use-rtp-eco-check-modal'
import {
  RtpEcoCheckModalHeader,
  RtpEcoCheckModalImage,
  RtpEcoCheckModalMobileUpperBody,
  RtpEcoCheckModalMobileLowerBody,
} from './components'
import {
  DisclaimerMessageContainer,
  DisclaimerMessageText,
} from './rtp-eco-check-modal-mobile-styled'

export function RtpEcoCheckModalMobile({
  isVisible,
  onClose,
  tripEmissions,
  carRentalEmissions,
  hotelEmissions,
  flightEmissions,
  railEmissions,
  equivalences,
  isDisclamerMessageVisible,
}: LayoutProps) {
  const { showSummaryDeviationLevel, isHotelOnly } = useRtpEcoCheckModal({
    hotelEmissions,
    carRentalEmissions,
    flightEmissions,
    railEmissions,
  })
  const { t } = useTranslation()

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<RtpEcoCheckModalHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <RtpEcoCheckModalMobileUpperBody
          tripEmissions={tripEmissions}
          showSummaryDeviationLevel={showSummaryDeviationLevel}
        />
        <RtpEcoCheckModalImage deviationLevel={tripEmissions.deviationLevel} />
        <RtpEcoCheckModalMobileLowerBody
          carRentalEmissions={carRentalEmissions}
          flightEmissions={flightEmissions}
          railEmissions={railEmissions}
          hotelEmissions={hotelEmissions}
          isHotelOnly={isHotelOnly}
        />
        <EcoCheckEquivalences equivalences={equivalences} kgOfEmissions={tripEmissions.summaryKg} />
        {isDisclamerMessageVisible && (
          <DisclaimerMessageContainer>
            <Icon name="infoOutlinePWA" size="small" />
            <DisclaimerMessageText color="mainText" variant="captionMedium">
              {t('Accessibility.EcoCheck.EcoCheckDisclaimerMessage')}
            </DisclaimerMessageText>
          </DisclaimerMessageContainer>
        )}
      </Modal.Body>
    </Modal>
  )
}
