import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Swap } from '@etta/ui/swap'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { ModalDetails } from '@etta/components/modal-details/modal-details'
import { RideHailFlightDetails } from '@etta/modules/ride-hail/ui/review-flight-details/layout/mobile/ride-hail-flight-details'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import type { LayoutProps } from '../types'
import { ChangeFlightModalSkeleton } from './change-flight-modal-skeleton'
import {
  AddFlightButtonWrapper,
  ChangeFlightHeader,
  CloseButton,
  SelectFlightButtonWrapper,
  Wrapper,
} from './mobile-components'
import { useChangeFlightModal } from './use-change-flight-modal'

const i18nBase = 'Mobility.ChangeFlightModal'

export const ChangeFlightModalMobile = observer(function ChangeFlightModalMobile({
  onOpenAddFlightModal,
  onClose,
  isOpen,
}: LayoutProps) {
  const { t } = useTranslation()
  const { rideHailAirportPickupStore, searchRideHailActions } = useRideHailSearchContext()
  const { onConfirm, selectFlight, selectedFlight, onDiscard } = useChangeFlightModal({
    isOpen,
    onClose,
  })

  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={searchRideHailActions.openLeavingBookingFlowDialog}
      isAdaptivePosition>
      <Modal.Header isMobile withBorder>
        <ChangeFlightHeader>
          <Block isFullWidth paddingLeft={20}>
            <Text variant="headline" color="mainText">
              {t(`${i18nBase}.Header`)}
            </Text>
            <CloseButton
              icon="closeFilledPWA"
              size="medium"
              color="bodyText"
              onClick={onDiscard}
              aria-label="close"
            />
          </Block>
        </ChangeFlightHeader>
      </Modal.Header>
      <Modal.Body>
        <Block paddingHorizontal={20} paddingTop={16} paddingBottom={8}>
          <Text variant={'subHeadMedium'} color={'bodyText'}>
            {t(`${i18nBase}.Description`)}
          </Text>
        </Block>
        <Block marginTop={10} marginHorizontal={18} marginBottom={24}>
          <AddFlightButton
            onClick={onOpenAddFlightModal}
            isDisabled={rideHailAirportPickupStore.isLoading}
          />
        </Block>
        <Swap is={rideHailAirportPickupStore.isLoading} isSlot={<ChangeFlightModalSkeleton />}>
          {rideHailAirportPickupStore.arrivalFlightsList &&
            rideHailAirportPickupStore.arrivalFlightsList.map((flight, index) => (
              <Block paddingHorizontal={20} paddingBottom={24} key={index}>
                <Block paddingBottom={12}>
                  <Text variant={'captionStrongCaps'} color={'bodyText'}>
                    {dateFormat(new Date(deleteTimezone(flight.arrivalDate)), 'eeee, MMM d')}
                  </Text>
                </Block>
                <RideHailFlightDetails
                  flight={flight}
                  isWhiteBackground
                  isSelectable
                  isSelected={selectedFlight === flight}
                  onClick={onConfirm}
                />
              </Block>
            ))}
        </Swap>
      </Modal.Body>
      <Modal.Footer withBorder>
        <SelectFlightButtonWrapper>
          <Button
            btnType="primary"
            onClick={selectFlight}
            fullWidth
            disabled={rideHailAirportPickupStore.isLoading || !selectedFlight}>
            {t(`${i18nBase}.SelectFlight`)}
          </Button>
        </SelectFlightButtonWrapper>
      </Modal.Footer>
    </ModalDetails>
  )
})

type Props = {
  onClick: () => void
  isDisabled: boolean
}

function AddFlightButton({ onClick, isDisabled }: Props) {
  const { t } = useTranslation()
  return (
    <AddFlightButtonWrapper onClick={onClick} role="button" isDisabled={isDisabled}>
      <Wrapper>
        <Icon name="flightPWA" size={'medium'} />
        <Block paddingLeft={22}>
          <Text variant={'subHeadStrong'} color={'mainText'}>
            {t(`${i18nBase}.AddFlight`)}
          </Text>
        </Block>
      </Wrapper>
      <Icon name="addPWA" size={'medium'} />
    </AddFlightButtonWrapper>
  )
}
