import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import { Gender } from '@etta/core/enums'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import type { AirPreferenceExcerpt, ProfileOptions } from '../types'
import { resolveInitialValue } from './resolve-initial-value'
import { resolveInitialDOB } from './resolve-initial-dob'

type Args = {
  personalInfo: PersonalInfo
  guestPersonalInfo?: PersonalInfo | null
  travelerOptions?: TravelerOptions | null
  preferenceExcerpt?: AirPreferenceExcerpt
  primaryTraveler?: PurchaseInfoTravelerValueObject
  profileOptions: ProfileOptions
  bookingId?: string
  isGuest: boolean
}

export function mapDefaultPersonalInfo({
  personalInfo,
  guestPersonalInfo,
  travelerOptions,
  preferenceExcerpt,
  primaryTraveler,
  profileOptions,
  bookingId,
  isGuest,
}: Args) {
  let actualPersonalInfo = personalInfo
  const { redressNumber, knownTravelerNumber, knownTravelerIssuingCountry } =
    preferenceExcerpt || {}
  const { titles, genders } = profileOptions
  const { isNameChangeable, isGenderChangeable, isDateOfBirthChangeable } = travelerOptions || {}

  if (isGuest) {
    actualPersonalInfo = guestPersonalInfo ?? {
      firstName: '',
      lastName: '',
      companyId: 1,
      customerId: 1,
    }
  }

  const firstName = isNameChangeable ? actualPersonalInfo.firstName : primaryTraveler?.firstName
  const middleName = isNameChangeable
    ? actualPersonalInfo.middleName
    : primaryTraveler?.middleInitial
  const lastName = isNameChangeable ? actualPersonalInfo.lastName : primaryTraveler?.lastName
  const suffix = primaryTraveler?.suffix
  const gender = resolveInitialValue({
    options: genders,
    value: isGenderChangeable
      ? actualPersonalInfo.gender
      : primaryTraveler?.sex || actualPersonalInfo.gender,
  })

  const dateOfBirth = resolveInitialDOB({
    isChangeable: !!isDateOfBirthChangeable,
    dobFromProfile: actualPersonalInfo?.dateOfBirth,
    dobFromPurchase: primaryTraveler?.dateBirth,
  })

  if (isGuest && bookingId && primaryTraveler) {
    return {
      firstName,
      middleName,
      lastName,
      suffix,
      gender,
      dateOfBirth,
      title: resolveInitialValue({ options: titles, value: primaryTraveler.title }),
      address: primaryTraveler.address,
      phone: primaryTraveler.phone[0],
      personalMobile: primaryTraveler.personalMobile,
      email: primaryTraveler.email,
    }
  }

  if (isGuest && !bookingId) {
    return {
      firstName,
      middleName,
      lastName,
      suffix,
      gender: resolveInitialValue({ options: genders, value: Gender.Unspecified }),
      dateOfBirth: resolveInitialDOB({
        isChangeable: !!isDateOfBirthChangeable,
        dobFromProfile: actualPersonalInfo?.dateOfBirth,
        dobFromPurchase: null,
      }),
      title: resolveInitialValue({ options: titles, value: actualPersonalInfo.title }),
      address: actualPersonalInfo.address,
      phone: actualPersonalInfo.phone,
      personalMobile: actualPersonalInfo.personalMobile,
      email: actualPersonalInfo.email,
      redressNumber: '',
      knownTravelerNumber: '',
      knownTravelerIssuingCountry: '',
    }
  }

  return {
    firstName,
    middleName,
    lastName,
    suffix,
    gender,
    dateOfBirth,
    title: primaryTraveler?.title,
    address: actualPersonalInfo.address,
    phone: actualPersonalInfo.phone,
    personalMobile: actualPersonalInfo.personalMobile,
    email: actualPersonalInfo.email,
    redressNumber,
    knownTravelerNumber,
    knownTravelerIssuingCountry,
  }
}
