import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { LocationSectionMobile } from './layout/mobile'
import { LocationSectionDesktopNew } from './layout/desktop/location-section-desktop.new'

export const LocationSection = observer(function LocationSection(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<LocationSectionMobile {...props} />}
      desktopSlot={<LocationSectionDesktopNew {...props} />}
    />
  )
})
