import { makeAutoObservable, toJS } from 'mobx'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import type { CheckoutInfoResult } from '../../../core/value-objects/checkout-info.value-object'
import type { BillingPersistValueObject } from '../../../core/value-objects/checkout-info/billing-persist.value-object'

export class CheckoutInfoBilling {
  private billing: CheckoutInfoResult['billing'] = null

  private billingPersist = new ItineraryStorage<BillingPersistValueObject>('billingSelection', {
    carRental: null,
    air: null,
    hotel: null,
    rail: null,
  })

  constructor() {
    makeAutoObservable(this, { isPersistEmpty: false })
  }

  get billingData() {
    return this.billingPersist.values
  }

  get air() {
    return this.billing?.air
  }

  get hotel() {
    return this.billing?.hotel
  }

  get hotelValue() {
    return (
      this.billingPersist.values.hotel?.siteCard ?? this.billingPersist.values.hotel?.creditCard
    )
  }

  get carRental() {
    return this.billing?.carRental
  }

  get rail() {
    return this.billing?.rail
  }

  get isSingleUseCreditCardAllowed() {
    return this.billing?.isSingleUseCreditCardAllowed
  }

  isPersistEmpty() {
    return !Object.values(toJS(this.billingPersist.values)).some((value) => value !== null)
  }

  dropBillingData() {
    this.billing = null
    this.billingPersist.set({
      carRental: null,
      air: null,
      hotel: null,
      rail: null,
    })
  }

  setBillingData(billing: CheckoutInfoResult['billing']) {
    this.billing = billing
  }

  appendBillingValue<T extends keyof BillingPersistValueObject>(
    key: T,
    billing: BillingPersistValueObject[T],
  ) {
    this.billingPersist.set({
      ...this.billingPersist.values,
      [key]: billing,
    })
  }
}
