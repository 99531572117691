import type { MutableRefObject } from 'react'
import { observer } from 'mobx-react-lite'
import { StyledTextVariant } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { ListResultHeader } from '@etta/components/list-result-header/list-result-header'
import { CarRentalSort } from '@etta/components/car-rental-sort/car-rental-sort'
import type { CarRentalSortBy } from '@etta/modules/car-rental-search/core/enums'
import { Link } from '@etta/ui/link'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { useCarRentalHeaderBlock } from './use-car-rental-header-block'
import { FiltersBadgeWrapper } from './car-rental-header-block-styled'

type Props = {
  sortBy: CarRentalSortBy
  onChangeSortBy: (sortBy: CarRentalSortBy) => void
  headerRef: MutableRefObject<HTMLDivElement | null>
}

export const CarRentalHeaderBlock = observer(function CarRentalHeaderBlock({
  sortBy,
  onChangeSortBy,
  headerRef,
}: Props) {
  const {
    isMobile,
    handleResetToDefaultFilters,
    displayConfigurationStore,
    listResultHeaderTitle,
    resetToDefaultLinkLabel,
    filtersAppliedLabel,
    handleClearFilters,
    shouldDisplayFiltersNumberBadge,
    isDefaultFilterCompanySettingsExist,
    isShowResetToDefaultFiltersButton,
  } = useCarRentalHeaderBlock()

  return (
    <Block
      ref={headerRef}
      paddingHorizontal={16}
      paddingTop={24}
      paddingBottom={0}
      marginBottom={isMobile ? 8 : 0}
      textVariant={StyledTextVariant.title3}>
      <ListResultHeader
        title={listResultHeaderTitle}
        serviceFeeConfiguration={
          displayConfigurationStore.getServiceFeeConfiguration.carRentalFares
        }
        rightSlot={
          <>
            {!isMobile && isDefaultFilterCompanySettingsExist && isShowResetToDefaultFiltersButton && (
              <Link
                size="normal"
                onClick={handleResetToDefaultFilters}
                aria-label={resetToDefaultLinkLabel}>
                {resetToDefaultLinkLabel}
              </Link>
            )}
            {shouldDisplayFiltersNumberBadge && (
              <FiltersBadgeWrapper>
                <Text variant="linkSmall" color="mainText">
                  {filtersAppliedLabel}
                </Text>
                <Button
                  variant="text"
                  minWidth={0}
                  onClick={handleClearFilters}
                  paddings={false}
                  rightIcon={<Icon name="closePWA" size="normal" color="bodyText" />}
                />
              </FiltersBadgeWrapper>
            )}
          </>
        }
        sortSlot={
          displayConfigurationStore.isMod2FlowEnabled ? null : (
            <CarRentalSort sortBy={sortBy} onChangeSortBy={onChangeSortBy} />
          )
        }
      />
    </Block>
  )
})
