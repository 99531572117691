import type { ReactNode } from 'react'
import { SeatIcon } from '../../../../seat-icon'
import type { SeatList } from '../../seat-map-segment.types'
import {
  SeatRowContainerWrapper,
  OverWing,
  Wall,
  NearWindow,
  ExitSeat,
} from './seat-row-container-styled'
import { IconsContainer } from './seat-row-container-components'

type SeatRowContainerProps = {
  children: ReactNode
  seatList?: SeatList
  isEconomyClass: boolean
  areIconsAvailable?: boolean
  rowIndex: number
}

export function SeatRowContainer({
  children,
  seatList,
  areIconsAvailable = false,
  isEconomyClass,
  rowIndex,
}: SeatRowContainerProps) {
  const leftSeat = seatList?.[0]
  const rightSeat = seatList?.[seatList.length - 1]

  return (
    <SeatRowContainerWrapper data-tracking-id={`seat-row-${rowIndex >= 0 ? rowIndex : 'header'}`}>
      {leftSeat && (
        <Wall isOverWing={leftSeat.isOverWing} aria-hidden>
          <IconsContainer isAvailable={areIconsAvailable}>
            {leftSeat.isOverWing && <OverWing />}
            {leftSeat.isNearWindow && <NearWindow isEconomyClass={isEconomyClass} />}
            {leftSeat.isExitRowSeat && (
              <ExitSeat>
                <SeatIcon icon="exitHand" />
              </ExitSeat>
            )}
          </IconsContainer>
        </Wall>
      )}
      {children}
      {rightSeat && leftSeat && (
        <Wall isRight isOverWing={rightSeat.isOverWing} aria-hidden>
          <IconsContainer isAvailable={areIconsAvailable}>
            {rightSeat.isOverWing && <OverWing isRight />}
            {rightSeat.isNearWindow && <NearWindow isEconomyClass={isEconomyClass} />}
            {leftSeat.isExitRowSeat && (
              <ExitSeat isRight>
                <SeatIcon icon="exitHand" />
              </ExitSeat>
            )}
          </IconsContainer>
        </Wall>
      )}
    </SeatRowContainerWrapper>
  )
}
