import styled, { css } from 'styled-components'
import { screenSize } from '@fiji/style'
import { calloutMedium } from '@fiji/style/typography'
import { ScreenType } from '@fiji/modes'
import { ReactDatesOverridesCommon } from './react-dates-overrides'

export const CalendarContainer = styled.div<{ forceScreenType?: null | ScreenType }>`
  height: 100vh;
  overflow-y: auto;
  ${({ forceScreenType }) => {
    if (forceScreenType !== ScreenType.Mobile) {
      return css`
        @media (${screenSize.minTablet}) {
          height: auto;
        }
      `
    }
  }}

  ${ReactDatesOverridesCommon}

  @media (${screenSize.minMobile}) {
    .CalendarDay {
      ${calloutMedium}
    }
  }
`

export const Month = styled.div`
  font-weight: 600;
`
