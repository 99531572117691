import styled, { css } from 'styled-components'
import { buttonSmall, captionStrongCaps } from '@fiji/style'
import { glowingFragment } from '@fiji/style/animations'

const CLOSED_LABEL_HEIGHT = '1.8rem'

export const PlaceContainer = styled.div<{ isOpen?: boolean; isLoading?: boolean }>`
  ${({ isOpen, isLoading }) => css`
    display: inline-flex;
    width: 100%;
    text-align: left;
    cursor: pointer;
    opacity: ${isOpen === false ? 0.3 : 1};
    border: none;
    padding: 0 16px;

    ${isLoading &&
    css`
       {
        margin-top: 16px;
      }
    `};

    ${!isOpen &&
    css`
      ${TypeIndicator} {
        margin-top: ${CLOSED_LABEL_HEIGHT};
      }
    `};
  `};
`

export const TypeIndicator = styled.div`
  margin-left: -1px;
`

export const Description = styled.div`
  flex: 1;
  margin-left: 12px;
`

export const ClosedLabel = styled.span`
  ${captionStrongCaps};
  display: block;
  height: ${CLOSED_LABEL_HEIGHT};
  color: ${(p) => p.theme.colors.error};
`

export const Name = styled.div`
  ${buttonSmall};
  line-height: 1.46;
  margin-bottom: 2px;
  color: ${(p) => p.theme.colors.mainText};
  padding-right: 30px;
`

export const Distance = styled.span`
  color: ${(p) => p.theme.colors.bodyText};
  margin-left: 4px;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 19px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const LoadingImage = styled.div`
  height: 28px;
  width: 28px;
  margin-right: 3px;
  ${glowingFragment};
`

export const LoadingName = styled.div`
  height: 14px;
  width: 244px;
  margin-bottom: 8px;
  ${glowingFragment};
`

export const LoadingLogos = styled.div`
  height: 14px;
  width: 46px;
  ${glowingFragment};
`
