import type { FlightLegSubSegment } from '@fiji/graphql/types'
import { formatTripTime } from '@fiji/utils/format-trip-time'

type Result = {
  code: string
  name: string
  flightTime: string
}[]

export function getFlightStops(segments: FlightLegSubSegment[]): Result {
  if (segments.length === 1) {
    return []
  }

  return segments.slice(0, -1).map(({ arrival, flightTime }) => ({
    code: arrival.airportCode || '',
    name: arrival.airportName || '',
    flightTime: flightTime ? formatTripTime(flightTime) : '',
  }))
}
