import { TextField } from '@etta/ui/text-field'
import { DatePickerModal } from '@etta/ui/date-picker-modal'
import { HalfBlock } from '../../passport-form-styled'
import type { LayoutProps } from '../types'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

export function PassportFormDatePickerFieldDesktop({
  value,
  label,
  onFieldClick,
  helperText,
  isDisabled,
  isPickerOpen,
  date,
  onSelect,
  onPickerClose,
  variant,
}: LayoutProps) {
  return (
    <HalfBlock>
      <TextField
        value={value}
        label={label}
        onClick={onFieldClick}
        helperType="error"
        helperText={helperText}
        isDisabled={isDisabled}
      />
      <DatePickerModal
        isRange={false}
        isOpen={isPickerOpen}
        date={date}
        minDate={variant === 'expiration-date' ? tomorrow : undefined}
        maxDate={variant === 'issue-date' ? new Date() : undefined}
        onClose={onPickerClose}
        onSelect={(dates) => {
          onSelect(dates.date)
        }}
        withConfirmButton
        isOutsideRange={() => false}
        customElementHeight={540}
      />
    </HalfBlock>
  )
}
