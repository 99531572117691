import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { TripReviewSeatPreferencesRegion } from '../../region'
import type { LayoutProps } from '../../types'
import { useTripPreviewSeatPreferences } from '../../use-trip-review-seat-preferences'
import { ButtonContainer, RightBlock } from './trip-review-seat-preferences-desktop-styled'

const i18Base = 'TripReview.SeatPreferencesModal'

export function TripReviewSeatPreferencesDesktop({
  layout,
  appliedPreferences,
  preferenceOptions,
  seatReservationTypesArray,
  onPreferencesApply,
  isRoundTrip,
  isFirstClass,
  onClose,
  onSkip,
}: LayoutProps) {
  const { t } = useTranslation()
  const {
    onApply,
    onPreferencesChange,
    changedPreferences,
    isSubmitEnabled,
    isSubmitOptional,
  } = useTripPreviewSeatPreferences({
    layout,
    appliedPreferences,
    onPreferencesApply,
    preferenceOptions,
    seatReservationTypesArray,
  })

  return (
    <>
      <Modal.Title withBorder>{t(i18Base + '.Title')}</Modal.Title>
      <Modal.Body>
        <TripReviewSeatPreferencesRegion
          layout={layout}
          onPreferencesChange={onPreferencesChange}
          preferenceOptions={preferenceOptions}
          seatReservationTypesArray={seatReservationTypesArray}
          changedPreferences={changedPreferences}
          isRoundTrip={isRoundTrip}
          isFirstClass={isFirstClass}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          <Button onClick={onClose} variant="link">
            <Text variant="buttonNormal" color="primary">
              {t(i18Base + '.Cancel')}
            </Text>
          </Button>
          <RightBlock>
            {isSubmitOptional && (
              <Button onClick={onSkip} minWidth={12} btnType="tertiary">
                <Text variant="buttonNormal" color="mainText">
                  {t(i18Base + '.Skip')}
                </Text>
              </Button>
            )}
            <Button onClick={onApply} minWidth={21} disabled={!isSubmitEnabled}>
              <Text variant="buttonNormal" color="mainText2">
                {t(i18Base + '.Save')}
              </Text>
            </Button>
          </RightBlock>
        </ButtonContainer>
      </Modal.Footer>
    </>
  )
}
