import type { Assets } from '../fiji-loader/use-deem-header/types'

const FAVICON_ID = 'insert-favicon-id'

type Args = {
  assets?: Assets
  theme?: string
}

export function insertFavicon({ assets, theme }: Args) {
  if (!Array.isArray(assets)) {
    createLinkFaviconElement({ theme })
    return
  }

  const customFavicon = assets.find((asset: any) => asset.props && asset.props.rel === 'icon')
  if (!customFavicon) {
    createLinkFaviconElement({ theme })
    return
  }

  createLinkFaviconElement({ theme, customFavicon })
}

type FavIconArgs = {
  customFavicon?: JSX.Element
  theme?: string
}

function getFavIconProps({ theme, customFavicon }: FavIconArgs) {
  if (customFavicon) {
    return {
      rel: customFavicon.props.rel,
      type: customFavicon.props.type,
      href: customFavicon.props.href,
    }
  }
  if (theme === 'kan') {
    return {
      rel: 'shortcut icon',
      type: '',
      href: '/app/apple-favicon.ico',
    }
  }

  // create an html for this
  return {
    rel: 'shortcut icon',
    type: '',
    href: '/app/favicon.ico',
  }
}

function createLinkFaviconElement({ theme, customFavicon }: FavIconArgs) {
  // Ignore re-insert
  const existingLink = document.getElementById(FAVICON_ID) as HTMLLinkElement | null
  const { rel, href, type } = getFavIconProps({ theme, customFavicon })
  if (existingLink && existingLink.href === href) {
    return
  }
  const linkElement = document.createElement('link')
  linkElement.id = FAVICON_ID
  linkElement.rel = rel
  linkElement.type = type
  linkElement.href = href
  document.getElementsByTagName('head')[0].appendChild(linkElement)
}
