import styled from 'styled-components'
import { tripPlannerInputBck } from '@fiji/style'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'

export const Container = styled.div<{
  isTransparent: boolean
  isMaxWidth?: boolean
  verticalPadding: number
  horizontalPadding: number
}>`
  display: flex;
  align-items: center;
  ${({ verticalPadding, horizontalPadding }) => `
    padding: ${verticalPadding}px ${horizontalPadding}px;
  `}
  border-radius: 6px;
  background: ${(props) => (props.isTransparent ? 'transparent' : tripPlannerInputBck)};
  max-width: ${(props) => (props?.isMaxWidth ? '100%' : 'fit-content')};
  max-width: ${(props) => (props?.isMaxWidth ? '100%' : 'max-content')};
`

export const StyledBlock = styled(Block)`
  align-self: baseline;
`

export const StyledText = styled(Text)`
  display: contents;
`

export const TextBlock = styled(Block)<{ withLineBreak?: boolean }>`
  ${(props) => {
    if (props?.withLineBreak) {
      return `
        display: flex;
        flex-direction: column
      `
    }
  }};
`
