import { observer } from 'mobx-react-lite'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { Swap } from '@etta/ui/swap'
import { FlightSegment } from '../flight-segment'
import { FlightSegmentSkeleton } from '../flight-segment/flight-segment-skeleton'
import { FlightLegTitle } from './flight-leg-title'
import { Container, Content } from './flight-segment-list-styled'

export const FlightSegmentList = observer(function FlightSegmentList() {
  const { seatMapStore, seatMapPaginationStore, seatMapPaginationActions } = useSeatMapContext()
  const { handleSelectFlightCard } = seatMapPaginationActions
  const { isLoading, flightLegs, bookedSeats } = seatMapStore
  const { selectedLegIndex, selectedSegmentIndex } = seatMapPaginationStore

  function getSeatFeature(segmentId: string) {
    return bookedSeats.find((seat) => seat.segmentId === segmentId)?.feature
  }

  return (
    <Container>
      {flightLegs.map(({ legId, segments }, legIndex) => (
        <Content key={legId}>
          <FlightLegTitle legIndex={legIndex} />

          {segments.map((flight, segmentIndex) => (
            <Swap
              key={flight.id}
              is={isLoading}
              isSlot={
                <FlightSegmentSkeleton
                  isActive={legIndex === selectedLegIndex && segmentIndex === selectedSegmentIndex}
                />
              }>
              <FlightSegment
                flight={flight}
                seatFeature={getSeatFeature(flight.id)}
                isActive={legIndex === selectedLegIndex && segmentIndex === selectedSegmentIndex}
                onChange={() => {
                  handleSelectFlightCard({ segmentIndex, legIndex })
                }}
              />
            </Swap>
          ))}
        </Content>
      ))}
    </Container>
  )
})
