import { css } from 'styled-components'
import type { ButtonProps, MobileButtonVariantProps } from './button-props'
import { getColorsByType } from './get-colors-by-type'

export function getStylesByButtonVariant(variant: MobileButtonVariantProps) {
  const linkCss = css<ButtonProps>`
    background-color: transparent;
    color: ${({ theme, btnType }) =>
      btnType === 'tertiary' ? theme.colors.mainText : getColorsByType(btnType, theme).color};
    border: none;
    &:disabled {
      color: ${({ theme }) => theme.colors.bodyText2};
    }
  `
  switch (variant) {
    case 'solid':
      return css<ButtonProps>`
        background-color: ${({ theme, btnType }) => getColorsByType(btnType, theme).color};
        border-color: ${({ theme, btnType }) => getColorsByType(btnType, theme).color};
        color: ${({ theme, btnType }) =>
          btnType === 'tertiary' ? theme.colors.mainText : theme.colors.white};
        &:hover {
          background-color: ${({ theme, btnType }) => getColorsByType(btnType, theme).hoverColor};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.borderDark};
          border-color: ${({ theme }) => theme.colors.borderDark};
          color: ${({ theme }) => theme.colors.white};
        }
      `
    case 'outline':
      return css<ButtonProps>`
        background-color: ${({ theme }) => theme.colors.transparent};
        border-color: ${({ theme, btnType }) => getColorsByType(btnType, theme).color};
        color: ${({ theme, btnType }) =>
          btnType === 'tertiary' ? theme.colors.mainText : getColorsByType(btnType, theme).color};
        &:hover {
          color: ${({ theme, btnType }) =>
            btnType === 'tertiary'
              ? theme.colors.mainText1
              : getColorsByType(btnType, theme).hoverColor};
          border-color: ${({ theme, btnType }) => getColorsByType(btnType, theme).hoverColor};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.borderDark};
          color: ${({ theme }) => theme.colors.borderDark};
        }
      `
    case 'link':
      return linkCss
    case 'text':
      return css<ButtonProps>`
        ${linkCss};
        padding: 0;
        line-height: 1.2;
        &:focus {
          box-shadow: none;
        }
      `
    case 'outline-desktop':
      return css<ButtonProps>`
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.borderLight};
        color: ${({ theme }) => theme.colors.primary1};

        &:hover {
          color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.primary1};
          background-color: ${({ theme }) => theme.colors.primary1};
        }
      `
    case 'outline-white':
      return css<ButtonProps>`
        background-color: ${({ theme }) => theme.colors.transparent};
        border-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};

        &:hover {
          background-color: ${({ theme }) => theme.colors.transparent};
          border-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.white};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.transparent};
          border-color: ${({ theme }) => theme.colors.bodyText2};
          color: ${({ theme }) => theme.colors.bodyText2};
        }
      `
  }
}
