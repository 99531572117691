import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { RailSearchQueryType } from '@fiji/hooks/search-queries/use-rail-search-query/types'

@Store()
export class RailQueryParamsStore {
  railQueryParams = new QueryParams<RailSearchQueryType>(
    {},
    {
      caseStyle: 'kebab-case',
      arrayFields: ['selectedServiceClasses', 'departureSeats', 'railCards'],
    },
  )

  constructor(@Inject() private historyStore: HistoryStore) {}

  get searchId() {
    return this.railQueryParams.getQueryParams(this.historyStore.search).searchId
  }

  get bookingId() {
    return this.railQueryParams.getQueryParams(this.historyStore.search).bookingId
  }

  get originPlace() {
    return this.railQueryParams.getQueryParams(this.historyStore.search).originPlace
  }

  get destinationPlace() {
    return this.railQueryParams.getQueryParams(this.historyStore.search).destinationPlace
  }

  get itineraryId() {
    return this.railQueryParams.getQueryParams(this.historyStore.search).itineraryId
  }
}
