import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetAirEnhancedSeatMapQuery,
  GetAirEnhancedSeatMapQueryVariables,
} from '@fiji/graphql/hooks'
import { GetAirEnhancedSeatMapDocument } from '@fiji/graphql/hooks'

type GetEnhancedSeatMapArgs = {
  input: GetAirEnhancedSeatMapQueryVariables['input']
  forceUpdate?: boolean
}

@DataProvider()
export class SeatMapDataProvider extends GraphqlDataProvider {
  getEnhanchedSeatMap({ input, forceUpdate }: GetEnhancedSeatMapArgs) {
    return this.client.query<GetAirEnhancedSeatMapQuery, GetAirEnhancedSeatMapQueryVariables>({
      query: GetAirEnhancedSeatMapDocument,
      variables: {
        input,
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }
}
