import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { PreSearchCustomFields } from '@etta/components/pre-search-custom-fields/pre-search-custom-fields'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { useCarRentalSearchFormContext } from '@etta/modules/car-rental-search/interface'
import { useCarRentalSearch } from './hooks'
import { CarRentalSearchPageLayout } from './layout'
import type { Props } from './types'

export const CarRentalSearchPage = observer(function CarRentalSearchPage({ isBlockView }: Props) {
  const { getRecentSearchesActions } = useRecentSearchesContext()
  const { carSearchFormStore, carSearchButtonActions } = useCarRentalSearchFormContext()

  const pickUpDateToggle = useTogglePopup()
  const dropOffDateToggle = useTogglePopup()
  const pickUpTimeToggle = useTogglePopup()
  const dropOffTimeToggle = useTogglePopup()

  useEffect(() => {
    getRecentSearchesActions.getRecentCarRentalSearches()
    return () => carSearchButtonActions.clearValidationErrors()
  }, [getRecentSearchesActions, carSearchButtonActions])

  useEffect(() => {
    getRecentSearchesActions.getRecentCarRentalSearchesWithoutDates()
    return () => carSearchButtonActions.clearValidationErrors()
  }, [getRecentSearchesActions, carSearchButtonActions])

  const {
    values,
    errors,
    customFields,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    ...otherLayoutProps
  } = useCarRentalSearch()

  return (
    <>
      <CarRentalSearchPageLayout
        isBlockView={isBlockView}
        pickUpDateToggle={pickUpDateToggle}
        dropOffDateToggle={dropOffDateToggle}
        pickUpTimeToggle={pickUpTimeToggle}
        dropOffTimeToggle={dropOffTimeToggle}
        validationErrors={carSearchFormStore.validationErrors}
        {...otherLayoutProps}
      />

      <PreSearchCustomFields
        customFields={customFields}
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onBack={onPreSearchBack}
        isVisible={isOpenPreSearchCustomFields}
        onSubmit={onSubmitCustomFieldsHandler}
      />
    </>
  )
})
