import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Checkbox } from '@etta/ui/checkbox'
import { CostWrapper, Item, ItemContent, ItemTitle, ContentWrapper } from './sidebar-styled'

type Props = {
  value: string
  name: string | string[]
  cost: string
  dropOffName?: string | null
  pickUpDateTime?: string | null
}

const i18nBase = 'PostBooking.RideHailDetails.CancelRideHailToDestination'

export function SidebarRideHailSegment({ value, name, cost, dropOffName, pickUpDateTime }: Props) {
  const { t } = useTranslation()

  return (
    <ContentWrapper>
      <Checkbox
        value={value}
        label={
          <Item>
            <Icon name={'rideHailPWA'} size="medium" color="mainText" />
            <ItemContent>
              <ItemTitle>
                <Text variant="subHeadStrong" color="mainText" textTransform="capitalize">
                  {name}
                </Text>
              </ItemTitle>
              <Text variant="footnoteStrong" color="bodyText1" textOverflow="ellipsis-two-line">
                {t(i18nBase, {
                  destination: dropOffName,
                })}
              </Text>
              <Text
                variant="footnoteStrong"
                color="bodyText"
                data-tracking-id="cancel-ride-hail-date-price-text">
                {pickUpDateTime}
              </Text>
            </ItemContent>
          </Item>
        }
        checked
        isDisabled
        onChange={() => {}}
      />
      <CostWrapper>
        <Text variant="captionStrongCaps" isBlock data-tracking-id="cancel-trip-price-text">
          {cost}
        </Text>
      </CostWrapper>
    </ContentWrapper>
  )
}
