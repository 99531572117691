import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
`

export const FlightDetailsEcoCheckContainer = styled.div`
  padding-bottom: 16px;
`

export const NDCContainer = styled.div`
  margin: 20px 16px 0;
  background: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  display: flex;
  padding: 16px;
  gap: 6px;
`
