import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { SupportTravelPageMobile } from './mobile'
import { SupportTravelPageDesktop } from './desktop'

export function SupportTravelPageLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SupportTravelPageMobile {...props} />}
      desktopSlot={<SupportTravelPageDesktop {...props} />}
    />
  )
}
