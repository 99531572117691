import { Toggle } from '@etta/interface/services/toggle'
import { Store } from '@etta/di'
import type { UserValueObject } from '@etta/modules/user/core/value-objects/user.value-object'
import type { DelegateEntity } from '@etta/modules/settings/core/entities/delegate-entity'
import type { AvailableDelegatesEntity } from '../../core/entities/available-delegates.entity'

@Store()
export class DelegateStore {
  toggle = new Toggle()
  moreDelegatesToggle = new Toggle()
  removeDelegateToggle = new Toggle()
  addDelegateToggle = new Toggle()
  activeDelegateId: number | null = null
  activeDelegateType: 'delegate' | 'authorizer' | null = null
  initialUsers: UserValueObject[] = []
  filteredUsers: UserValueObject[] = []
  filteredUsersSearch: string = ''
  isDelegateAction: boolean = false
  usersSearch: string = ''
  availableDelegates: AvailableDelegatesEntity[] = []
  isAvailableDelegatesLoading = false
  isAvailableDelegatesError = false
  selectedDelegateForUpdate: AvailableDelegatesEntity | null = null

  delegates: DelegateEntity[] = []

  isDelegatesLoading = false
  isError = false

  setDelegates(delegates: DelegateEntity[]) {
    this.delegates = delegates
  }

  setAvailableDelegates(delegates: AvailableDelegatesEntity[]) {
    this.availableDelegates = delegates
  }

  setDelegatesLoading(isLoading: boolean) {
    this.isDelegatesLoading = isLoading
  }

  setSelectedDelegateForUpdate(delegate: AvailableDelegatesEntity | null) {
    this.selectedDelegateForUpdate = delegate
  }

  setIsAvailableDelegatesLoading(isLoading: boolean) {
    this.isAvailableDelegatesLoading = isLoading
  }

  setIsAvailableDelegatesError(isError: boolean) {
    this.isAvailableDelegatesError = isError
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setFilteredUsers(users?: UserValueObject[]) {
    this.filteredUsers = users || []
  }

  setInitialUsers(users?: UserValueObject[]) {
    this.initialUsers = users || []
  }

  setFilteredUsersSearch(input?: string) {
    this.filteredUsersSearch = input || ''
  }

  setActiveDelegateId(id: number | null) {
    this.activeDelegateId = id
  }

  setIsDelegateAction(is: boolean) {
    this.isDelegateAction = is
  }

  setUsersSearch(input: string) {
    this.usersSearch = input
  }

  setActiveDelegateType(type: 'delegate' | 'authorizer' | null) {
    this.activeDelegateType = type
  }
}
