import type { ReactElement, MouseEvent } from 'react'
import { Children, cloneElement } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useCarousel } from './use-carousel'
import { CarouselWrapper, SlideCounter } from './carousel-styled'
import { CarouselDots } from './components'

type Props = {
  withDots?: boolean
  withCounter?: boolean
  children: ReactElement[]
  previewImage?: ReactElement
  isCenterMode?: boolean
  centerPadding?: number
  currentSlide?: number
  onSlideChange?: (selected: number) => void
  infinite?: boolean
}

export function Carousel({
  children,
  withDots,
  withCounter,
  previewImage,
  isCenterMode,
  centerPadding,
  currentSlide,
  onSlideChange,
  infinite = true,
}: Props) {
  const {
    sliderRef,
    selectedNumber,
    handleBeforeChange,
    handleAfterChange,
    isDragging,
  } = useCarousel({
    currentSlide,
    onSlideChange,
  })
  const handleMapChildren = (child: ReactElement<{ onClick?: (ev: MouseEvent) => any }>) =>
    cloneElement(child, {
      ...child.props,
      onClick: (ev: MouseEvent) => {
        // # https://github.com/akiran/react-slick/issues/848#issuecomment-328194478
        if (isDragging) {
          return
        }

        child.props?.onClick?.(ev)
      },
    })

  return (
    <CarouselWrapper>
      {children.length ? (
        <Slider
          ref={sliderRef}
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
          dots={withDots}
          centerMode={isCenterMode}
          centerPadding={`${centerPadding}px`}
          appendDots={(dots: ReactElement[]) => <CarouselDots dots={dots} />}
          infinite={infinite}>
          {Children.map(children, handleMapChildren)}
        </Slider>
      ) : (
        previewImage
      )}
      {withCounter && children.length ? (
        <SlideCounter>
          {(currentSlide || selectedNumber) + 1}/{children.length}
        </SlideCounter>
      ) : null}
    </CarouselWrapper>
  )
}
