import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'

const i18Base = 'CarRentalFilters'
type Props = {
  onClick: () => void
  isDisabled: boolean
}

export function ResetLink({ onClick, isDisabled }: Props) {
  const { t } = useTranslation()
  const ariaLabel = isDisabled
    ? t(`${i18Base}.ResetToDefaultFiltersAccessibilityLabelDisabled`)
    : t(`${i18Base}.ResetToDefaultFiltersAccessibilityLabel`)

  return (
    <Link aria-label={ariaLabel} onClick={onClick} isDisabled={isDisabled}>
      {t(`${i18Base}.ResetToDefaultFilters`)}
    </Link>
  )
}
