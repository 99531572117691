import { Service, Inject } from '@etta/di'
import { DeviceClass } from '@etta/core/enums'
import { RailLegType, TimeRangeDirection, TimeRangeOption } from '@fiji/enums'
import { RailSearchFormStore } from '@etta/modules/rail-search-form/interface/stores/rail-search-form.store'
import { TripRailActions } from '@etta/modules/review-trip/interface/actions/trip-rail.actions'
import { TripActions } from '@etta/modules/review-trip/interface/actions/trip.actions'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { ROUTES } from '@fiji/routes'
import { HistoryService } from '@etta/interface/services/history.service'
import type { RailSearchQueryType } from '@fiji/hooks/search-queries'
import { RailTripType } from '@fiji/hooks/search-queries'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { TimeRange } from '@fiji/types'
import { RouteTypeEnum } from '@etta/modules/review-trip/core'
import type { PromisedResult } from '@etta/core/type-utils'
import type { RailFareValueObject } from '../../core/value-objects/rail-fare.value-object'
import type {
  CreateRailSearchInput,
  RailETicketInput,
  RailETicketTypeEnum,
} from '../../infra/types'
import { RailJourneyDirectionEnum, RailRouteTypeEnum } from '../../infra/types'
import { RailAdapter } from '../../infra/rail.adapter'
import type {
  RailFilterOptionsValueObject,
  RailFilterValueObject,
} from '../../core/value-objects/rail-search-result.value-object'
import { RailStore } from '../stores/rail.store'
import { FiltersStore } from '../stores/filters.store'
import { RailQueryParamsStore } from '../stores/rail-query-param.store'
import type { RailEticketsValueObject } from '../../core/value-objects/rail-e-tickets.value-object'
import type { RailETicketsErrorsInstances } from '../../core/errors/rail-e-tickets.errors'
import { RailQueryParamsService } from './rail-query-params.service'

@Service()
export class RailService {
  constructor(
    @Inject() private railAdapter: RailAdapter,
    @Inject() private railStore: RailStore,
    @Inject() private railFiltersStore: FiltersStore,
    @Inject() private railSearchFormStore: RailSearchFormStore,
    @Inject() private tripRailActions: TripRailActions,
    @Inject() private tripActions: TripActions,
    @Inject() private historyService: HistoryService,
    @Inject() private railQueryParamsService: RailQueryParamsService,
    @Inject() private queryParamsStore: RailQueryParamsStore,
  ) {}

  async getRailSearchResults(legType: RailLegType = RailLegType.Departure, page: number = 0) {
    const {
      originDate: searchDepartureDate,
      destinationDate: searchDestinationDate,
    } = this.railSearchFormStore.railForm
    this.railStore.setIsLoading(true)

    const input = this.getRailSearchInputByLegType(page, legType)

    const result = await this.railAdapter.getRailSearchResults(input, true)

    const searchDate =
      legType === RailLegType.Departure ? searchDepartureDate : searchDestinationDate

    result.match({
      Ok: (resp) => {
        this.railStore.setRailSearchResult(resp, searchDate)
        this.railStore.setCurrentPage(page)
        this.updateFilters(legType)
        this.railStore.setIsGetRailError(false)
        this.railStore.setIsLoading(false)
      },
      Err: () => {
        this.railStore.setRailSearchResult(null, undefined)
        this.railStore.setIsGetRailError(true)
        this.railStore.setIsLoading(false)
      },
    })
  }

  async selectFareSeats(
    railLegType: RailLegType,
    { tripId, tier, segments }: RailFareValueObject,
    bookingId?: string, // This is newly added field.
  ) {
    const { itineraryId, destinationPlace, railTripType } = this.railSearchFormStore.railSearchQuery
    const getRouteType = (railTrip: RailTripType) => {
      switch (railTrip) {
        case RailTripType.Round:
          return RouteTypeEnum.Return
        case RailTripType.OneWay:
          return RouteTypeEnum.Single
        default:
          return RouteTypeEnum.OpenReturn
      }
    }

    if (!tripId || !itineraryId) {
      return
    }

    this.railStore.setSelectRailStatus('loading')

    try {
      const addResponse = await this.tripRailActions.handleAddRail({
        key: tripId,
        itineraryId,
        tripName: `Trip to ${destinationPlace.name}`,
        direction:
          railLegType === RailLegType.Return
            ? RailJourneyDirectionEnum.Inward
            : RailJourneyDirectionEnum.Outward,
        routeType: getRouteType(railTripType),
      })

      if (addResponse.isErr()) {
        this.railStore.setSelectRailStatus('error')
        return
      }

      if (addResponse.getValue().isSoldOut) {
        this.railStore.setSelectRailStatus('sold-out')
        return
      }

      await this.tripActions.updateTrip()
      this.railStore.setSelectRailStatus('success')
      this.railStore.setRailDetailsClose()

      await delayForSuccess()

      this.railStore.setSelectRailStatus(null) // close success dialog before go to another page
      this.railStore.setCurrentPage(0)

      // Hardcoded Round trip type instead of importing GQL type RailTripType
      if (railLegType === RailLegType.Departure && railTripType === 'Round') {
        this.railQueryParamsService.navigateTo(
          ROUTES.rail.resultsWithParams({
            railLegType: RailLegType.Return,
            selectedFareTier: tier.id,
            selectedServiceClasses: segments?.map((segment) => segment.serviceClass),
            // filters: {}, // TODO: FRI-111 omit for now, check if this is required
          }),
        )
        return
      }

      this.historyService.push(ROUTES.reviewTrip.main({ itineraryId, bookingId }))
    } catch (e) {
      this.railStore.setSelectRailStatus('error')
    }
  }

  async createRailSearch() {
    this.railStore.setIsLoading(true)

    const input = this.getCreateRailSearchInput()

    const result = await this.railAdapter.createRailSearch(input)

    result.match({
      Ok: (result) => {
        this.railQueryParamsService.appendQueryParams({ searchId: result.id })
        this.railStore.setIsCreateRailSearchError(false)
        this.railStore.setIsLoading(false)
      },
      Err: () => {
        this.railStore.setIsCreateRailSearchError(true)
        this.railStore.setIsLoading(false)
      },
    })
  }

  removeItinerary() {
    this.tripActions.removeTrip()
    this.railQueryParamsService.appendQueryParams({ itineraryId: undefined })
  }

  async getETickets(
    itineraryId: string,
    type: RailETicketTypeEnum,
  ): PromisedResult<RailEticketsValueObject, RailETicketsErrorsInstances> {
    const input = {
      itineraryId,
      type,
    } as RailETicketInput

    return this.railAdapter.getETickets(input)
  }

  private getRailSearchInputByLegType(page: number, legType: RailLegType = RailLegType.Departure) {
    const isCustomTimeSearch =
      this.railSearchFormStore.railForm.destinationTime.id === TimeRangeOption.CustomTime ||
      this.railSearchFormStore.railForm.originTime.id === TimeRangeOption.CustomTime

    const takeoffLandingRanges = this.railFiltersStore.appliedFilters.destinationTimeRange?.start &&
      this.railFiltersStore.appliedFilters.destinationTimeRange?.end &&
      this.railFiltersStore.appliedFilters.originTimeRange?.start &&
      this.railFiltersStore.appliedFilters.originTimeRange?.end && [
        {
          landing: this.railFiltersStore.appliedFilters.destinationTimeRange,
          takeoff: this.railFiltersStore.appliedFilters.originTimeRange,
        },
      ]

    const inputByLegType =
      legType === RailLegType.Departure
        ? {
            direction: RailJourneyDirectionEnum.Outward,
            legPosition: 0,
            page,
          }
        : {
            direction: RailJourneyDirectionEnum.Inward,
            legPosition: 1,
            page,
          }

    return {
      searchId: this.queryParamsStore.searchId,
      sortBy: this.railStore.sortBy,
      stopFilters: this.railFiltersStore.appliedFilters.stops?.map(({ value }) => value),
      trainCompanyFilters: this.railFiltersStore.appliedFilters?.trainCompany?.map(
        ({ value }) => value,
      ),
      filterFareTiers: this.railFiltersStore.appliedFilters?.fareTier?.map(({ value }) => value),
      originStationFilters: this.railFiltersStore.appliedFilters?.origin?.map(({ code }) => code),
      destinationStationFilters: this.railFiltersStore.appliedFilters?.destination?.map(
        ({ code }) => code,
      ),
      excludeOutOfPolicy: this.railFiltersStore.appliedFilters?.excludeOutOfPolicy,
      deviceClass: DeviceClass.Mobile,
      takeoffLandingRanges,
      isCustomTimeSearch,
      ...inputByLegType,
    }
  }

  private updateFilters(legType: RailLegType = RailLegType.Departure) {
    // update initial filter which comes from API
    const filterFareTiers = this.railStore.railFilters?.fareTier?.filter(Boolean)
    filterFareTiers?.sort((a, b) => a.id - b.id)

    const timeRanges = takeoffLandingRanges({
      railLegType: legType,
      queryParams: {
        originTime: { id: TimeRangeOption.AnyTime, i18next: '' }, // handle hard coded time range
        destinationTime: { id: TimeRangeOption.AnyTime, i18next: '' },
      },
      filters: this.railStore.railFilters,
      isCustomTimeReplaced: this.railStore.isCustomTimeReplaced,
    })

    const nextState: RailFilterOptionsValueObject = {
      ...this.railFiltersStore.appliedFilters,
      fareTier: filterFareTiers,
      originTimeRange: timeRanges.takeoff,
      destinationTimeRange: timeRanges.landing,
    }

    this.railFiltersStore.setAppliedFilters(nextState)
    this.railFiltersStore.setChangedFilters(nextState)
  }

  private getCreateRailSearchInput(): CreateRailSearchInput {
    const getRouteType = (railTrip: RailTripType) => {
      switch (railTrip) {
        case RailTripType.Round:
          return RailRouteTypeEnum.Return
        case RailTripType.OneWay:
          return RailRouteTypeEnum.Single
        default:
          return RailRouteTypeEnum.OpenReturn
      }
    }

    const assignGeocode = ({ lat, long }: { lat?: number; long?: number }) =>
      lat && long ? { lat, long } : undefined

    const {
      originPlace,
      destinationPlace,
      originDate,
      destinationDate,
      originTime,
      destinationTime,
      railTripType,
      railCards,
    } = this.railSearchFormStore.railForm

    const roundTrip = railTripType === RailTripType.Round
    let railLegSearch = [
      {
        number: 0,
        destinationUniqueCode: destinationPlace.uniqueCode,
        originUniqueCode: originPlace.uniqueCode,
        destinationLocation: assignGeocode({
          lat: destinationPlace.latitude,
          long: destinationPlace.longitude,
        }),
        originLocation: assignGeocode({
          lat: originPlace.latitude,
          long: originPlace.longitude,
        }),
        departureDate: dateFormat(originDate, 'y-MM-dd'),
        timeRangeBy: originTime.timeRangeBy || 'DEPART',
        startTimeRange: originTime.startTimeRange,
        endTimeRange: originTime.endTimeRange,
        preferredTime: '',
        serviceClass: 'COACH',
        railCards: railCards ? { cards: railCards } : undefined,
      },
    ]
    if (roundTrip) {
      railLegSearch = railLegSearch.concat({
        number: 1,
        destinationUniqueCode: originPlace.uniqueCode,
        originUniqueCode: destinationPlace.uniqueCode,
        destinationLocation: assignGeocode({
          lat: originPlace.latitude,
          long: originPlace.longitude,
        }),
        originLocation: assignGeocode({
          lat: destinationPlace.latitude,
          long: destinationPlace.longitude,
        }),
        departureDate: dateFormat(destinationDate!, 'y-MM-dd'),
        timeRangeBy: destinationTime?.timeRangeBy || 'DEPART',
        startTimeRange: destinationTime?.startTimeRange,
        endTimeRange: destinationTime?.endTimeRange,
        preferredTime: '',
        serviceClass: 'COACH',
        railCards: railCards ? { cards: railCards } : undefined,
      })
    }

    const routeType = getRouteType(railTripType)

    return {
      railLegSearch,
      numberOfPassengers: 1,
      routeType,
    }
  }
}

type Props = {
  railLegType: RailLegType
  queryParams: Pick<RailSearchQueryType, 'originTime' | 'destinationTime'>
  filters?: RailFilterValueObject
  isCustomTimeReplaced?: boolean | null
}

type ValidateProps = {
  timeGap: {
    start: Date
    end: Date
  }
  selectedTime: TimeRange
}

export function takeoffLandingRanges({
  railLegType,
  queryParams,
  filters,
  isCustomTimeReplaced,
}: Props) {
  const selectedTime =
    railLegType === RailLegType.Departure ? queryParams.originTime : queryParams.destinationTime
  const timeRangeBy =
    railLegType === RailLegType.Departure
      ? queryParams.originTime.timeRangeBy
      : queryParams.destinationTime.timeRangeBy

  const takeoffTime = {
    start: filters?.originTimeRange?.start,
    end: filters?.originTimeRange?.end,
  }
  const landingTime = {
    start: filters?.destinationTimeRange?.start,
    end: filters?.destinationTimeRange?.end,
  }
  if (isCustomTimeReplaced) {
    return { takeoff: takeoffTime, landing: landingTime }
  }

  if (timeRangeBy === TimeRangeDirection.Departure && takeoffTime.start && takeoffTime.end) {
    return {
      takeoff: validatedTimeRange({
        timeGap: { start: takeoffTime.start, end: takeoffTime.end },
        selectedTime,
      }),
      landing: landingTime,
    }
  }

  if (timeRangeBy === TimeRangeDirection.Arrival && landingTime.start && landingTime.end) {
    return {
      takeoff: takeoffTime,
      landing: validatedTimeRange({
        timeGap: { start: landingTime.start, end: landingTime.end },
        selectedTime,
      }),
    }
  }
  return { takeoff: takeoffTime, landing: landingTime }
}

function setHour(date: Date, hour: number) {
  date.setHours(hour)
  date.setMinutes(0)
  return date
}

function validatedTimeRange({ timeGap, selectedTime }: ValidateProps) {
  const timeGapStart = timeGap.start
  const timeGapEnd = timeGap.end
  const timeStartHour = selectedTime.startTimeHours
  const timeEndHour = selectedTime.endTimeHours
  const isCustomTime = selectedTime.id === TimeRangeOption.CustomTime

  if (!timeStartHour || !timeEndHour) {
    return timeGap
  }

  if (isCustomTime) {
    const preFilledStart = validateCustomTime({
      selectedTime: timeStartHour,
      timeGap,
      isStart: true,
    })
    const preFilledEnd = validateCustomTime({ selectedTime: timeEndHour, timeGap })
    return { start: preFilledStart, end: preFilledEnd }
  }

  const preFilledStart =
    timeStartHour < timeGapStart.getHours() ? timeGapStart : setHour(timeGapStart, timeStartHour)
  const preFilledEnd =
    timeEndHour > timeGapEnd.getHours() ? timeGapEnd : setHour(timeGapEnd, timeEndHour)
  return { start: preFilledStart, end: preFilledEnd }
}

function validateCustomTime({
  selectedTime,
  timeGap,
  isStart,
}: {
  selectedTime: number
  timeGap: { start: Date; end: Date }
  isStart?: boolean
}) {
  const timeGapStart = timeGap.start.getHours()
  const timeGapEnd = timeGap.end.getHours()
  const isEndNextDay =
    timeGap.end.getDay() > timeGap.start.getDay() ||
    timeGap.end.getMonth() > timeGap.start.getMonth() ||
    timeGap.end.getFullYear() > timeGap.start.getFullYear()

  if (selectedTime <= timeGapStart) {
    return timeGap.start
  }
  if (selectedTime > timeGapStart && (selectedTime <= timeGapEnd || isEndNextDay)) {
    return setHour(isStart ? timeGap.start : timeGap.end, selectedTime)
  }
  if (selectedTime >= timeGapEnd) {
    return timeGap.end
  }
  return isStart ? timeGap.start : timeGap.end
}
