import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { SegmentType } from '@fiji/graphql/types'
import { useCarSearchQuery } from '@fiji/hooks/search-queries/use-car-search-query/use-car-search-query'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useAppDispatch } from '@fiji/store'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { ROUTES } from '@fiji/routes'
import { getQueryParamsRTP } from './get-query-params'

type Args = {
  itineraryId: string
  segments: TripSegmentValueObject[]
  bookingId?: string
}

export function useCarPlaceRTP({ itineraryId, segments, bookingId }: Args) {
  const { navigateTo } = useCarSearchQuery()
  const dispatch = useAppDispatch()

  const isDesktop = useScreenType() !== ScreenType.Mobile
  const {
    featureFlags: { isDesktopLayoutRTPFlowEnabled },
  } = useFeatureFlags()
  const { addSegmentModalsActions } = useReviewTripContext()

  const onAddCar = () => {
    if (isDesktop && isDesktopLayoutRTPFlowEnabled) {
      addSegmentModalsActions.addCar()
      return
    }
    const url = ROUTES.carRental.search
    const params = getQueryParamsRTP({
      segments,
      forType: SegmentType.CarRental,
    })

    dispatch(updateTripReviewStatus('update'))

    navigateTo(url, {
      itineraryId,
      pickUpLocationId: '',
      pickUpTime: params?.startTime,
      pickUpDate: params?.startDate,
      pickUpPlace: params?.from,
      dropOffLocationId: '',
      dropOffTime: params?.endTime,
      dropOffDate: params?.endDate,
      dropOffPlace: params?.to,
      bookingId,
      selectedTab: isDesktop ? undefined : SegmentType.CarRental,
    })
  }

  return {
    onAddCar,
  }
}
