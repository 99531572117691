import { useTranslation } from 'react-i18next'
import { FlightDetailsLegend } from '@etta/components/flight-details-legend/flight-details-legend'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { FlightDetailsEcoCheck } from '@etta/components/flight-details-eco-check/flight-details-eco-check'
import { UnusedTicketLabel } from '@etta/screens/review-trip-page/flight-details-modal/unused-ticket-label'
import { SegmentState, SegmentType } from '@fiji/graphql/types'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { WebfareDescription } from '@etta/components/air-badges/components/webfare/webfare-description'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { FlightDetailsHeader } from '../../flight-details-header'
import { FlightDetailsPrice } from '../../flight-details-price'
import { FlightDetailsAmenities } from '../../flight-details-amenities'
import { FlightSafetyCheck } from '../../flight-safety-check'
import { FlightDetailsFares } from '../../flight-details-fares'
import type { LayoutProps } from '../../types'
import {
  Container,
  FlightDetailsEcoCheckContainer,
  NDCContainer,
} from './flight-details-modal-mobile-styled'

const i18nBaseTooltip = 'AirSearchResults.Tooltip'

export function FlightDetailsModalMobile({
  flight,
  flightSafetyCheckDetails,
  adaptiveFlight,
  airlinesNames,
  flightSafetyChecks,
  flightCostSegment,
  isTripExist,
  isFlightFareAttributesError,
  isFlightSafetyCheckEnabled,
  isChangeAllowed,
  isFlightFareAttributesLoading,
  onClose,
  refetchFlightFareAttributes,
  onBaggageOpenClick,
  onSeatMapOpenClick,
  onDetailsClick,
  onChangeClick,
  onCancelClick,
  unusedTicket,
  flightEmission,
  isMultiCity,
  shouldShowAirportChangeBadge,
  isWebFare,
  isNDCFare,
  baggageInfo,
}: LayoutProps) {
  const { headerRef, scrollContainerRef } = useHeaderScroll()
  const { featureFlags } = useFeatureFlags()
  const { t } = useTranslation()

  return (
    <Modal.Body ref={scrollContainerRef}>
      <Modal.Header isMobile>
        <FlightDetailsHeader
          headerRef={headerRef}
          title={adaptiveFlight.headerTitle}
          flightLabels={adaptiveFlight.flightLabels}
          flightStopsLabel={adaptiveFlight.flightStopsLabel}
          airlinesLogos={adaptiveFlight.airlineLogos.square || []}
          onClose={onClose}
        />
      </Modal.Header>
      <Container>
        <Block paddingHorizontal={20}>
          <FlightDetailsLegend
            backgroundColor="background"
            flight={adaptiveFlight}
            withToggle
            flightLegSegments={flight.segments}
            shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
          />
        </Block>
        {isWebFare && (
          <Block paddingHorizontal={4}>
            <WebfareDescription />
          </Block>
        )}

        {isNDCFare && (
          <Block paddingHorizontal={4}>
            <NDCContainer>
              <Icon name="NDCFarePWA" size="small" />
              <Text variant="footnoteMedium" color="bodyText">
                {t(i18nBaseTooltip + '.NDCFare')}
              </Text>
            </NDCContainer>
          </Block>
        )}

        <Block marginTop={24} paddingHorizontal={20} />
        {featureFlags.isUnusedTicketsEnabled && (
          <Block paddingHorizontal={20}>
            <UnusedTicketLabel unusedTicketRate={unusedTicket?.original} />
          </Block>
        )}
        {isFlightSafetyCheckEnabled && (
          <Block paddingHorizontal={20}>
            <FlightSafetyCheck
              flightSafetyChecks={flightSafetyChecks}
              flightSafetyCheckDetails={flightSafetyCheckDetails}
              refetchSafetyChecks={refetchFlightFareAttributes}
              isSafetyChecksLoading={isFlightFareAttributesLoading}
              isSafetyChecksError={isFlightFareAttributesError}
            />
          </Block>
        )}
        <Block paddingHorizontal={20} marginBottom={16}>
          <FlightDetailsAmenities
            squareAirlinesLogos={adaptiveFlight.airlineLogos.square || []}
            airlinesNames={airlinesNames}
            flightLabels={adaptiveFlight.flightLabels}
            amenities={adaptiveFlight.generalAmenities}
          />
          <FlightDetailsFares
            onBaggageModalOpen={onBaggageOpenClick}
            flight={flight}
            onSeatMapOpen={onSeatMapOpenClick}
            baggageInfo={baggageInfo}
          />
          {flightEmission && (
            <FlightDetailsEcoCheckContainer>
              <FlightDetailsEcoCheck {...flightEmission} />
            </FlightDetailsEcoCheckContainer>
          )}
          <FlightDetailsPrice
            flightDirection={adaptiveFlight.headerTitle}
            flightRate={flightCostSegment?.base!}
            totalRate={flightCostSegment?.total}
            feesAndTaxesRate={flightCostSegment?.feesAndTaxes!}
            transactionFeeRate={flightCostSegment?.transactionFee!}
            serviceFeeRate={flightCostSegment?.serviceFee}
            unusedTicketRate={
              featureFlags.isUnusedTicketsEnabled ? unusedTicket?.original : undefined
            }
            unusedTicketAmount={
              featureFlags.isUnusedTicketsEnabled ? unusedTicket?.exchangedTicket : undefined
            }
            unusedTicketForfeiture={
              featureFlags.isUnusedTicketsEnabled ? unusedTicket?.residual : undefined
            }
          />
        </Block>

        <ActionButtons
          type={SegmentType.Flight}
          isCancel={!isTripExist || flight.segmentState === SegmentState.New}
          isChangeAllowed={isChangeAllowed && !isMultiCity}
          onDetailsClick={onDetailsClick}
          onChangeClick={onChangeClick}
          onCancelClick={onCancelClick}
        />
      </Container>
    </Modal.Body>
  )
}
