import styled, { css } from 'styled-components'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  margin-top: -32px;
`

export const Header = styled.div`
  padding: 16px 16px 12px;
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CarOutOfPolicy = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.bodyText};
`

export const AmenitiesContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  position: relative;
  padding: 8px 16px;
  cursor: pointer;
`

export const AmenitiesList = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: end;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      flex-direction: column;
      align-items: start;
    `};
`

export const AmenitiesItem = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
`

export const AmenitiesIcon = styled.div`
  margin-right: 10px;
`

export const ToggleButton = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
`

export const LogoImage = styled.img`
  max-width: 100px;
`

export const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  position: absolute;
  right: 16px;
  top: 10px;
  max-height: 46px;
`
