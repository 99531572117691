import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { FareWithFareAttributes } from '@fiji/types'
import type { IconNames } from '@etta/ui/icon'

type BadgeStore = {
  isVisible: boolean
  key: string
  title: string
  icon: IconNames
}

type Props = {
  isPreferred: boolean
  maxBadges?: number
  fare: FareWithFareAttributes
  isOutOfPolicy: boolean
}

const i18Base = 'SearchSegments.Badges'

export function useRailBadges({ fare, isOutOfPolicy, isPreferred, maxBadges }: Props) {
  const { t } = useTranslation()
  const { isRefundable } = fare

  const badges: BadgeStore[] = useMemo(() => {
    const tempBadges: BadgeStore[] = [
      {
        isVisible: isOutOfPolicy,
        key: 'out-of-policy',
        title: t(i18Base + '.OutOfPolicy'),
        icon: 'outOfPolicyPWA',
      },
      {
        isVisible: isPreferred,
        key: 'preferred',
        title: t(i18Base + '.Preferred'),
        icon: 'preferredPWA',
      },
      {
        isVisible: isRefundable !== null,
        key: isRefundable ? 'refundable' : 'non-refundable',
        title: isRefundable ? t(i18Base + '.Refundable') : t(i18Base + '.NonRefundable'),
        icon: isRefundable ? 'refundablePWA' : 'nonRefundablePWA',
      },
    ]

    const filteredBadges = tempBadges.filter((badge) => badge.isVisible)

    return maxBadges ? filteredBadges.slice(0, maxBadges) : filteredBadges
  }, [isOutOfPolicy, isPreferred, isRefundable, maxBadges, t])

  return { badges }
}
