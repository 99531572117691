import { DataProvider } from '@etta/di'
import type {
  GetSearchInitiateInfoQuery,
  GetSearchInitiateInfoQueryVariables,
} from '@fiji/graphql/hooks'
import { GetSearchInitiateInfoDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class SearchInitiateInfoDataProvider extends GraphqlDataProvider {
  getSearchInitiateInfo(input: GetSearchInitiateInfoQueryVariables['input']) {
    return this.client.query<GetSearchInitiateInfoQuery, GetSearchInitiateInfoQueryVariables>({
      query: GetSearchInitiateInfoDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
}
