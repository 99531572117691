import { isSameDay, isSameMonth } from 'date-fns'
import { dateFormat } from '../date-format'
import { isSameYear } from '../is-same-year'

type Props = {
  startDate?: Date
  endDate?: Date
  customDateFormat?: string
}

const DEFAULT_DATE_FORMAT = 'MMM d, yyyy'
const DEFAULT_DATE_FORMAT_WITHOUT_YEAR = 'MMM d'

export function getDateRangeTitle({
  startDate,
  endDate,
  customDateFormat = DEFAULT_DATE_FORMAT,
}: Props): string {
  if (!startDate && !endDate) {
    return ''
  }

  if (!startDate || !endDate) {
    return dateFormat((startDate || endDate)!, customDateFormat)
  }

  if (isSameDay(startDate, endDate)) {
    return dateFormat(startDate, customDateFormat)
  }

  const startDateFormat = isSameYear(startDate, endDate) ? 'MMM d' : customDateFormat
  const lastDateFormat = dateFormat(
    endDate,
    isSameMonth(startDate, endDate) ? 'd, yyyy' : customDateFormat,
  )
  return `${dateFormat(startDate, startDateFormat)} - ${lastDateFormat}`
}

export function getDateRangeTitleWithoutYear({
  startDate,
  endDate,
  customDateFormat = DEFAULT_DATE_FORMAT_WITHOUT_YEAR,
}: Props) {
  if (!startDate && !endDate) {
    return ''
  }

  if (!startDate || !endDate) {
    return dateFormat((startDate || endDate)!, customDateFormat)
  }

  if (isSameDay(startDate, endDate)) {
    return dateFormat(startDate, customDateFormat)
  }

  const startDateFormat = isSameYear(startDate, endDate) ? 'MMM d' : customDateFormat
  const lastDateFormat = dateFormat(
    endDate,
    isSameMonth(startDate, endDate) ? 'd' : customDateFormat,
  )
  return `${dateFormat(startDate, startDateFormat)} - ${lastDateFormat}`
}
