import type { Maybe } from 'fnscript'
import { Error as NestedError, Just, Nothing } from 'fnscript'
import type {
  CalculateRailEmissionsBatchRailInput,
  CalculateRailEmissionsBatchRailSegmentInput,
} from '@fiji/graphql/types'
import { isEmptyString, RAIL_ERROR_MESSAGES, SKIP_VALIDATION_ERROR } from '../common'

type RailCompareSegments = {
  maybeOriginStationCode: Maybe<string>
  maybeDestinationStationCode: Maybe<string>
}

type ValidationArgs = RailCompareSegments & {
  rails: CalculateRailEmissionsBatchRailInput[]
}

const ERROR_MESSAGE = 'CalculateRailEmissionsBatch query not executed'

export function railEmissionsQueryExecuteValidation({
  rails,
  maybeOriginStationCode,
  maybeDestinationStationCode,
}: ValidationArgs): Maybe<NestedError> {
  if (!rails || rails.length === 0) {
    return Just(NestedError.new(SKIP_VALIDATION_ERROR))
  }

  const maybeRailCompareSegmentsErrors = validateRailCompareSegments({
    maybeOriginStationCode,
    maybeDestinationStationCode,
  })
  const maybeRailsError = validateTcRails(rails)

  if (maybeRailsError.isValue() || maybeRailCompareSegmentsErrors.isValue()) {
    return Just(
      NestedError.new(
        `${ERROR_MESSAGE}: ${!maybeRailsError.isNothing() && maybeRailsError.getValue()} ${
          !maybeRailCompareSegmentsErrors.isNothing() && maybeRailCompareSegmentsErrors.getValue()
        }`,
      ),
    )
  }

  return Nothing()
}

function validateRailCompareSegments({
  maybeDestinationStationCode,
  maybeOriginStationCode,
}: RailCompareSegments) {
  const compareSegmentErrors: NestedError[] = []

  if (maybeDestinationStationCode.isNothing() || maybeDestinationStationCode.getValue() === '') {
    const error = NestedError.new(RAIL_ERROR_MESSAGES.DESTINATION_STATION_CODE)
    compareSegmentErrors.push(error)
  }

  if (maybeOriginStationCode.isNothing() || maybeOriginStationCode.getValue() === '') {
    const error = NestedError.new(RAIL_ERROR_MESSAGES.ORIGIN_STATION_CODE)
    compareSegmentErrors.push(error)
  }

  if (compareSegmentErrors.length > 0) {
    const validationError = NestedError.new(compareSegmentErrors.map((e) => e.text()).join(';'))

    return Just(
      NestedError.new(`failed to validate hotel compare object;${validationError.getMessage()}`),
    )
  }

  return Nothing()
}

export function validateTcRails(rails: CalculateRailEmissionsBatchRailInput[]) {
  const railErrors: NestedError[] = []

  rails.forEach((rail, idx) => {
    const maybeSegmentErrors = validateTcRailSegments(rail.segments)

    if (maybeSegmentErrors.isValue()) {
      railErrors.push(
        NestedError.new(
          `failed to validate rail with index: ${idx};${maybeSegmentErrors.getValue()}`,
        ),
      )
    }
  })

  if (railErrors.length > 0) {
    const validationError = NestedError.new(railErrors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}

function validateTcRailSegments(segments: CalculateRailEmissionsBatchRailSegmentInput[]) {
  const segmentErrors: NestedError[] = []

  segments.forEach((segment) => {
    if (isEmptyString(segment.departureDate)) {
      const error = NestedError.new(RAIL_ERROR_MESSAGES.DEPARTURE_DATE)
      segmentErrors.push(error)
    }

    if (isEmptyString(segment.destinationStationCode)) {
      const error = NestedError.new(RAIL_ERROR_MESSAGES.DESTINATION_STATION_CODE)
      segmentErrors.push(error)
    }

    if (!segment.originStationCode) {
      const error = NestedError.new(RAIL_ERROR_MESSAGES.ORIGIN_STATION_CODE)
      segmentErrors.push(error)
    }

    if (isEmptyString(segment.class)) {
      const error = NestedError.new(RAIL_ERROR_MESSAGES.CLASS)
      segmentErrors.push(error)
    }
  })

  if (segmentErrors.length > 0) {
    const validationError = NestedError.new(segmentErrors.map((e) => e.text()).join(';'))

    return Just(
      NestedError.new(`failed to validate rail segment args;${validationError.getMessage()}`),
    )
  }

  return Nothing()
}
