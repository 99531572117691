import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import type { FlightEmission } from '@fiji/types'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { PenaltyType } from '@fiji/enums'
import { RTPItineraryCard } from '@etta/components/rtp-itinerary-card/rtp-itinerary-card'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { getAirPenalty, shouldShowRefundableLabel } from '@fiji/utils/trip-review/air-data'
import { FlightDetailsModal } from '@etta/screens/review-trip-page/flight-details-modal'
import type {
  FlightSegmentEntity,
  TripCostSummaryValueObject,
} from '@etta/modules/review-trip/core'
import { AddBaggageModal } from '@etta/modules/booking'
import { ChangeFlightsDialog } from '@etta/components/change-flights-dialog'
import type { PriceChangeTypeValueObject } from '@etta/modules/air-search/core/enums/price-change-type.enum'
import type { PriceChangeValueObject } from '@etta/modules/air-search/core/value-objects/price-change.value-object'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useAirSegmentRtp } from './use-air-segment-rtp'

type Props = {
  segment: FlightSegmentEntity
  onOpenOOP: () => void
  outOfPolicyExp?: string | null
  isCheckoutActivated?: boolean
  tripCost: TripCostSummaryValueObject
  isTripExist: boolean
  itineraryId: string
  flightId: string
  flightSegments: FlightSegmentEntity[]
  isChangeAllowed: boolean
  isChangeCarrierAllowed?: boolean
  isChangeDestinationAllowed?: boolean
  isRemoveAllowed?: boolean
  airPriceChangeType?: PriceChangeTypeValueObject | null
  flightIndex: number
  isMultiCity: boolean
  airPriceChangeInfo?: PriceChangeValueObject | null
  flightEmission: FlightEmission
}

export const AirSegmentRTP = observer(function AirSegmentRTP({
  segment,
  onOpenOOP,
  outOfPolicyExp,
  isCheckoutActivated,
  tripCost,
  isTripExist,
  itineraryId,
  flightId,
  isChangeAllowed,
  isChangeCarrierAllowed,
  isChangeDestinationAllowed,
  isRemoveAllowed,
  flightSegments,
  airPriceChangeType,
  flightIndex,
  isMultiCity,
  airPriceChangeInfo,
  flightEmission,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'FlightDetailsModal.'
  const {
    featureFlags: { isRetailShoppingUIEnabled },
  } = useFeatureFlags()
  const {
    flightLeg,
    flightSegment,
    flightDetailsToggle,
    cabinClassLabel,
    flightLastSegment,
    formatTime,
    isChangeModalOpen,
    handleChangeAirLink,
    handleCloseModal,
    handleChangeFlights,
    handleSubmitModifyFlight,
    handleConfirmRemoveFlight,
    cardStatus,
    flightSafetyChecks,
    flightSafetyCheckDetails,
    flightSafetyCheckIcons,
    isFlightFareAttributesLoading,
    isFlightSafetyCheckEnabled,
    isFlightFareAttributesError,
    refetchFlightFareAttributes,
    cardActions,
    removalDialogToggle,
    removalViewState,
    removalTitle,
    bodyAriaLabel,
    handleDeleteFlight,
    onCloseFlightDetailsModal,
    operatedBy,
    isSeatMapAvailable,
    showLuggageOptions,
    addBaggageModalToggle,
    handleSelectBaggageOption,
    selectedBaggageOption,
    shouldShowAirportChangeBadge,
    shouldShowServiceFeeLabel,
    isWebFare,
    isNDCFare,
    returnFlightModal,
    isChangingReturnFlight,
    isChangingEntireFlight,
  } = useAirSegmentRtp({
    segment,
    isChangeAllowed,
    isChangeCarrierAllowed,
    isChangeDestinationAllowed,
    isRemoveAllowed,
    flightSegments,
    flightId,
    itineraryId,
    isTripExist,
    tripCost,
    flightIndex,
    isMultiCity,
  })

  const showRefundableLabel = shouldShowRefundableLabel(segment.segments)

  return (
    <>
      <RTPItineraryCard
        key={segment.uuid}
        flightLegSegments={segment.segments}
        isWebFare={isWebFare}
        shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
        data-tracking-id={`air-rtp-card_${segment.type}-${segment.position}`}
        cardStatus={cardStatus}
        serviceType={segment.type}
        isOutOfPolicy={!flightLeg.policy.isInPolicy}
        hasComplianceCode={!!flightLeg.policy.outOfPolicyComplianceCodes?.length}
        startDate={
          tripReviewFormatDate(TripReviewDateType.DayAndDate, flightSegment.departureDate)!
        }
        fullStartDate={new Date(flightSegment.departureDate)}
        longDateLabel={tripReviewFormatDate(
          TripReviewDateType.DayAndDate,
          flightSegment.departureDate,
        )}
        isPriceIncluded={!isRetailShoppingUIEnabled && isMultiCity ? flightIndex !== 0 : false}
        rate={flightLeg.totalCostRate}
        isCheckoutActivated={isCheckoutActivated}
        bodyAriaLabel={bodyAriaLabel}
        onDelete={handleDeleteFlight()}
        actions={cardActions}
        showSeatMapUnavailableBadge={!isSeatMapAvailable}
        titleSlot={
          <RTPItineraryCard.AirportTitle
            origin={flightSegment.departure.airportCode!}
            destination={
              flightLastSegment
                ? flightLastSegment.arrival.airportCode!
                : flightSegment.arrival.airportCode!
            }
          />
        }
        informationSlot={
          <>
            <RTPItineraryCard.Text
              aria-label={`${formatTime(flightLeg.departureDateTime)} ${formatTime(
                flightLeg.arrivalDateTime,
              )} `}>
              {formatTime(flightLeg.departureDateTime)} &mdash;{' '}
              {formatTime(flightLeg.arrivalDateTime)}
            </RTPItineraryCard.Text>
            <RTPItineraryCard.Text
              aria-label={`${flightSegment.name} ${flightSegment.flightNumber}`}
              data-tracking-id="flight-number-text">{`${flightSegment.name} ${flightSegment.flightNumber}`}</RTPItineraryCard.Text>
          </>
        }
        operatedBySlot={
          operatedBy && (
            <RTPItineraryCard.Text
              aria-label={t('FlightDetails.OperatedBy')}
              data-tracking-id={'flight-operated-by'}>
              {operatedBy}
            </RTPItineraryCard.Text>
          )
        }
        safetyCheckSlot={
          isFlightSafetyCheckEnabled &&
          flightSafetyCheckIcons && (
            <RTPItineraryCard.SafetyCheckIcons
              isLoading={isFlightFareAttributesLoading}
              safetyCheckIcons={flightSafetyCheckIcons}
            />
          )
        }
        unusedTicketSlot={<RTPItineraryCard.UnusedTicketTooltip />}
        onClick={flightDetailsToggle.handleOpen}
        onSelectReason={onOpenOOP}
        outOfPolicyExp={outOfPolicyExp}
        showAddBaggageModal={addBaggageModalToggle.handleOpen}
        selectedBaggageOption={selectedBaggageOption}
        showLuggageOptions={showLuggageOptions}
        changeLinkText={t('ReviewTripPWA.FlightCard.ChangeLink')}
        onChange={handleChangeAirLink}
        priceChangeType={airPriceChangeType}
        airPriceChangeInfo={airPriceChangeInfo}
        isNDCSegment={!!segment.isNDCFare}>
        <RTPItineraryCard.Text
          aria-label={cabinClassLabel}
          data-tracking-id={'flight-cabin-class-text'}>
          {cabinClassLabel}
        </RTPItineraryCard.Text>
        <RTPItineraryCard.Text
          aria-label={t(
            `ReviewTripPWA.Details.${flightLeg.flightStops > 1 ? 'Stops' : 'Nonstop'}`,
            {
              count: flightLeg.flightStops - 1,
            },
          )}
          data-tracking-id={'flight-stops-text'}>
          {t(`ReviewTripPWA.Details.${flightLeg.flightStops > 1 ? 'Stops' : 'Nonstop'}`, {
            count: flightLeg.flightStops - 1,
          })}
        </RTPItineraryCard.Text>
        {showRefundableLabel && (
          <RTPItineraryCard.Text
            aria-label={t(
              `ReviewTripPWA.Details.PenaltyType.${
                getAirPenalty(PenaltyType.NonRefundable, flightSegment.penaltyFees)
                  ? 'NonRefundable'
                  : 'Refundable'
              }`,
            )}
            data-tracking-id={'flight-penalty-text'}>
            {t(
              `ReviewTripPWA.Details.PenaltyType.${
                getAirPenalty(PenaltyType.NonRefundable, flightSegment.penaltyFees)
                  ? 'NonRefundable'
                  : 'Refundable'
              }`,
            )}
          </RTPItineraryCard.Text>
        )}
        {shouldShowServiceFeeLabel && (
          <RTPItineraryCard.Text
            aria-label={t('ReviewTripPWA.Details.ServiceFeeIncl')}
            data-tracking-id={'flight-service-fee-text'}>
            {t('ReviewTripPWA.Details.ServiceFeeIncl')}
          </RTPItineraryCard.Text>
        )}
      </RTPItineraryCard>
      <FlightDetailsModal
        isOpen={flightDetailsToggle.isOpen}
        onClose={onCloseFlightDetailsModal}
        flight={flightLeg}
        shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
        tripCost={tripCost}
        itineraryId={itineraryId}
        flightId={flightId}
        flightSegments={flightSegments}
        isTripExist={isTripExist}
        isChangeAllowed={isChangeAllowed}
        flightIndex={flightIndex}
        flightSafetyChecks={flightSafetyChecks}
        flightSafetyCheckDetails={flightSafetyCheckDetails}
        isFlightFareAttributesLoading={isFlightFareAttributesLoading}
        isFlightFareAttributesError={isFlightFareAttributesError}
        refetchFlightFareAttributes={refetchFlightFareAttributes}
        isFlightSafetyCheckEnabled={isFlightSafetyCheckEnabled}
        flightEmission={flightEmission}
        isMultiCity={isMultiCity}
        isWebFare={isWebFare}
        isNDCFare={isNDCFare}
      />
      <ChangeFlightsDialog
        onModify={handleSubmitModifyFlight}
        isChangingReturnFlight={isChangingReturnFlight}
        isChangingEntireFlight={isChangingEntireFlight}
        isOpen={returnFlightModal.isOpen}
        onClose={returnFlightModal.handleClose}
      />
      <ConfirmationDialog
        title={t(`${i18Base}ChangeFlights.Title`)}
        content={t(`${i18Base}ChangeFlights.InfoText`, { count: flightSegments.length })}
        isOpen={isChangeModalOpen}
        onDecline={handleCloseModal}
        onConfirm={handleChangeFlights}
        confirmButtonText={t(`${i18Base}ChangeFlights.ConfirmButtonText`)}
        declineButtonText={t(`${i18Base}ChangeFlights.DeclineButtonText`)}
      />
      <ConfirmationDialog
        isOpen={removalDialogToggle.isOpen}
        onDecline={removalDialogToggle.handleClose}
        onConfirm={handleConfirmRemoveFlight}
        title={t(`${i18Base}RemoveFlights`)}
        content={t(`${i18Base}RemoveFlightsExplanation`)}
        confirmButtonText={t(`${i18Base}RemoveConfirmButton`)}
        declineButtonText={t(`${i18Base}RemoveDeclineButton`)}
      />
      <AddBaggageModal
        selectedBaggage={selectedBaggageOption}
        isOpen={addBaggageModalToggle.isOpen}
        onClose={addBaggageModalToggle.handleClose}
        luggageOptions={segment.luggageOptions}
        handleSelectBaggageOption={handleSelectBaggageOption}
      />
      <ViewStateModal viewState={removalViewState} title={removalTitle} />
    </>
  )
})
