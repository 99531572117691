import styled, { css } from 'styled-components'
import { Text as TextUI } from '@etta/ui/text'
import { captionMedium, KeyboardNavigationStyle } from '@fiji/style'
import type { CheckboxHelperType } from './types'

export const Container = styled.div`
  display: block;
  padding: 8px 0 9px;
  user-select: none;
`

export const Label = styled.label`
  display: flex;
  position: relative;
  padding: 0 0 0 28px;
  cursor: pointer;
`

export const Minus = styled.div`
  height: 2px;
  width: 70%;
  border-radius: 2px;
  background-color: ${(p) => p.theme.colors.white};
`

export const Input = styled.input`
  position: absolute;
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  outline: none;
  opacity: 0;
`

export const IconContainer = styled.span<{ isFilled: boolean; isDisabled: boolean }>`
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  color: ${(p) => p.theme.colors.mainText2};
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  ${(p) => {
    switch (true) {
      case p.isDisabled:
        return css`
          border: 2px solid ${p.theme.colors.borderDark};
          color: ${p.theme.colors.background};
          background-color: ${p.theme.colors.borderDark};
        `
      case p.isFilled:
        return css`
          border: 2px solid ${p.theme.colors.primary3};
          background-color: ${p.theme.colors.primary3};
        `
      default:
        return css`
          border: 2px solid ${p.theme.colors.borderDark};
          background-color: transparent;
        `
    }
  }}

  &:hover {
    border: 2px solid ${(p) => p.theme.colors.primary3};
  }

  ${KeyboardNavigationStyle}
`

export const Text = styled(TextUI)``

export const HelperContainer = styled.div<{
  helperType?: CheckboxHelperType
  isHelperTextSpaceReserved?: boolean
}>`
  margin-top: ${({ isHelperTextSpaceReserved }) => (isHelperTextSpaceReserved ? '2px' : 0)};
  min-height: ${({ isHelperTextSpaceReserved }) => (isHelperTextSpaceReserved ? '20px' : 0)};
  color: ${(props) => {
    if (
      !props.helperType ||
      props.helperType === 'description' ||
      props.helperType === 'no-border'
    ) {
      return props.theme.colors.bodyText
    }

    return props.theme.colors[props.helperType]
  }};
  display: flex;
  align-items: center;
  ${captionMedium};
`
