import { SeatReservationType } from '@fiji/graphql/types'
import type { PreferencesUK } from '../../types'

type Props = {
  preferences: PreferencesUK
  seatReservationType?: SeatReservationType
  onPreferencesChange: (newPreferences: Partial<PreferencesUK>) => void
}

export function useTripReviewSeatPreferencesUK({ preferences, onPreferencesChange }: Props) {
  const onExtrasChange = (value: string) => {
    if (preferences.seatFacilities.includes(value)) {
      onPreferencesChange({
        seatFacilities: preferences.seatFacilities.filter((extra) => extra !== value),
      })
      return
    }
    onPreferencesChange({ seatFacilities: [...preferences.seatFacilities, value] })
  }

  const getReservationLabel = (seatReservationType?: SeatReservationType) => {
    if (seatReservationType === SeatReservationType.Optional) {
      return '.SeatReservationOptional'
    }

    if (seatReservationType === SeatReservationType.Mandatory) {
      return '.SeatReservationIncluded'
    }

    return '.SeatReservationNotAvailable'
  }

  return {
    onExtrasChange,
    getReservationLabel,
  }
}
