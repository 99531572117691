import { useTranslation } from 'react-i18next'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import type { Coords } from '@etta/modules/etta-map/core/coords'
import { Container } from './hotel-details-map-styled'
import { HotelDetailsMarker } from './hotel-details-marker'

type Props = {
  hotelCoords: Coords
  hotelName?: string
  handleAddressClick: () => void
}

export function HotelDetailsMap({ hotelCoords, hotelName, handleAddressClick }: Props) {
  const { t } = useTranslation()
  return (
    <Container
      tabIndex={0}
      aria-label={t('PostBooking.HotelDetails.ViewLocation')}
      aria-hidden
      data-tracking-id="map"
      onClick={handleAddressClick}>
      <EttaMap
        options={{
          draggable: false,
          clickableIcons: false,
          zoomControl: false,
          scaleControl: true,
          fullscreenControl: false,
        }}
        center={hotelCoords}>
        <HotelDetailsMarker hotelName={hotelName} />
      </EttaMap>
    </Container>
  )
}
