import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { StyledTextVariant } from '@fiji/enums'
import { ErrorContainer } from '@etta/ui/error-container'
import { ROUTES } from '@fiji/routes'
import { getTrackIdFromTitle } from '@fiji/utils/get-tack-id-from-title'
import { useSupportTravelContext } from '@etta/modules/support-travel/interface/use-support-travel.context'
import { SeeMoreSection } from './see-more-section'
import {
  ArrowRightIcon,
  Body,
  ArticlesListItem,
  ArticleTitle,
  ArrowRightIconContainer,
  NavLink,
  ErrorContainerWrapper,
} from './support-travel-body-styled'
import { SupportTravelBodySkeleton } from './support-travel-body-skeleton'

const i18Base = 'TripPlanner.Support.TravelSupport'
const i18BaseSupport = 'TripPlanner.Support.MainPage'

export const SupportTravelBody = observer(() => {
  const { t } = useTranslation()
  const { supportTravelStore, supportTravelAction } = useSupportTravelContext()
  const [activeId, setActiveId] = useState('')

  if (supportTravelStore.isLoading) {
    return (
      <Body data-tracking-id="support-skeleton-container">
        <SupportTravelBodySkeleton />
        <SeeMoreSection
          isPreview={supportTravelStore.isPreview}
          isShowOnDesktop={supportTravelStore.supportArticles.length > 6}
        />
      </Body>
    )
  }

  if (supportTravelStore.isError) {
    return (
      <ErrorContainerWrapper isPreview={supportTravelStore.isPreview}>
        <ErrorContainer
          title={t(i18Base + '.UnableToLoad')}
          reloadTitle={t(i18Base + '.TapToReload')}
          onReload={supportTravelAction.handleGetSupportArticles}
        />
      </ErrorContainerWrapper>
    )
  }

  return (
    <Body>
      {supportTravelStore.supportArticles.length ? (
        <>
          {supportTravelStore.supportArticles.map(({ id, title }) => (
            <li key={id} onClick={() => setActiveId(id)}>
              <NavLink to={ROUTES.support.article({ articleId: id })} isSelected={activeId === id}>
                <ArticlesListItem>
                  <ArticleTitle
                    data-tracking-id={`support-page_${getTrackIdFromTitle(title)}-link`}
                    textVariant={StyledTextVariant.body}>
                    {title}
                  </ArticleTitle>
                  <ArrowRightIconContainer>
                    <ArrowRightIcon />
                  </ArrowRightIconContainer>
                </ArticlesListItem>
              </NavLink>
            </li>
          ))}
          <SeeMoreSection
            isPreview={supportTravelStore.isPreview}
            isShowOnDesktop={supportTravelStore.supportArticles.length > 6}
            dataTrackingId="travel-support-articles-link"
          />
        </>
      ) : (
        <li>{t(`${i18BaseSupport}.NoArticles`)}</li>
      )}
    </Body>
  )
})
