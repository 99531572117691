import type { SeatMapRowValueObject } from '@etta/modules/seat-map/core/value-objects'

function getMostFrequent(arr: string[]) {
  const hashmap: { [key: string]: number } = arr.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1
    return acc
  }, {} as { [key: string]: number })
  return Object.keys(hashmap).reduce((a, b) => (hashmap[a] > hashmap[b] ? a : b))
}

export const getMostFrequentAisle = (seatsByCabinClass: SeatMapRowValueObject[]) => {
  const SEPARATOR = ','
  const aislesColumnList = seatsByCabinClass.map(({ seat: seatList }) =>
    seatList
      .reduce(
        (acc, { column, isNearAisle }) => (isNearAisle ? [...acc, column] : acc),
        [] as string[],
      )
      .join(SEPARATOR),
  )

  return getMostFrequent(aislesColumnList).split(SEPARATOR)
}
