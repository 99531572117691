import styled, { css } from 'styled-components'

const disabledStyle = css`
  filter: opacity(0.5);
`

const dot = css`
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 14px;
    width: 14px;
    transform: translate(30%, -30%);
    background-color: ${(p) => p.theme.colors.primary};
    border-radius: 50%;
    display: inline-block;
    border: 2px solid ${(p) => p.theme.colors.white};
  }
`

export const FilterButtonWrapper = styled.div<{ isFilterActive: boolean; isDisabled?: boolean }>`
  position: relative;
  margin-left: auto;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  display: flex;
  ${(p) => (p.isDisabled ? disabledStyle : null)}

  ${(p) => (p.isFilterActive ? dot : '')}
`
