import type { UserPreset } from '../types'

export const stage3: UserPreset[] = [
  {
    site: 'fijiweb',
    user: 'fijiweb',
    rcSession:
      '10450-035E195A81DFB11AF944152A6A825539-9D858131C3BA0860E0C8F98A6A25F93400AC986987C0B342AFC9BA14547F72402085532A4FA8E410E75C16894E522940C96C0B9F59181A1F6BE17D73E0C109EEF296FCE715363CBD02E3FF574DC9ADE86C4D3713B64E56444046A6CBAD1A1B97CD728EC78EB9529FE6C8D5C1017C842EAC911A0CC955FBF985B2D6154A87408E92009BED6044C5E294028D9EAD945909759EA93C891DAFB897075B93D3C9CC3D',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluVXNlciI6ZmFsc2UsImN1c3RvbWVySWQiOjEyMTQ4MjMxLCJsb2NhbGUiOiJlbi1VUyIsImltcGVyc29uYXRlZElkIjowLCJkZWxlZ2F0ZWRJZCI6MCwiY29tcGFueUlkIjoxNzMxODEwLCJwYXJ0bmVySWQiOjEsInVzZXJuYW1lIjoiZmlqaXdlYkBkZWVtLmNvbSIsImNyZWF0ZWRUaW1lIjoxNjI0MjYwNzUxMzQ5LCJjbHVzdGVyIjoiY2x1c3RlcmEiLCJzZXNzaW9uSWQiOiI4YmY5N2E4Ny0xNWI5LTRkMzctOGZmMi05ZmRhNDQzODY0ODUiLCJzZXNzaW9uVGltZW91dCI6MTgwMCwiYXV0aFRpY2tldCI6IjM3MzM2NTMwMzAzMTY0MzcyZDM5NjIzMTYxMmQzNDY2MzAzMDJkNjEzMjYzNjQyZDY2NjI2MjYyMzIzODM4MzY2MzM3MzE2MTNhN2MzYTMxMmUzMDIzMzEzNzMzMzEzODMxMzAiLCJlbnRpdGxlbWVudHMiOnsiY29va2llIjoiQ0FSU0VSVklDRTIgQ0FSU0VSVklDRV9SRVNWIEZMSUdIVF9TRUFSQ0ggVFJBVkVMIiwiZW50aXRsZW1lbnRzIjpbIkNBUlNFUlZJQ0UyIiwiVFJBVkVMIiwiRkxJR0hUX1NFQVJDSCIsIkNBUlNFUlZJQ0VfUkVTViJdfSwiaWF0IjoxNjI0MjYwNzUyLCJleHAiOjE5Mzk4MzY3NTJ9.hiNTvTprjQhan5JkCjHC-EofiFR2OsUV2TOZVOGMihM',
  },

  {
    site: 'fijiwebapollo',
    user: 'fijiweb',
    rcSession:
      '10410-1DDBC84DB988911419628D42BEA4D170-F1A46BF4E49A309BBE741763C334424872C14AC7F92B897CB3403356AB184D940456E74D438D8FC7D0AFBB72A8AC4FDCB414004CBE287578C784CFBE482CCA448D3049F5FB71BF04C11115F40450F08DA228594F8B56195308E24E578B4318CAB53716A3B4407C4B1489C6EB47B8490BB14BBDB76557EF95DE98B6AE1081A41197544F71EBC1CEA6DB5EEAA24B6F66F058E02DCDC2C633B9BFACBA34CFD2355E',
  },
  {
    site: 'fijiwebapollo',
    user: 'mobileuser_deem',
    rcSession:
      '10490-DA12C080D10850C40C40D77ABB7E5E8C-C28674C86BE3D9B5C737D5B9B34D6CE4ED088A42D7CF143882485570E9268B5A9198F490D0D9E2D948EB8DD387E42D89495603EA2C9E8B24B1B30D2D175A1B86B921FCF6B065FD869BA15762D404E7A69C46212CACBF7AEAE6BF61A91BE568DF7A7F88DBD7E08F4E3D61E88617FC4A7821C33D462500801F4F464C58308F8ABE53362589B5E6E0748029D2E5699BDDF641C4622AD64C3CF6A36DAE58138FBF3A',
  },
  {
    site: 'fijiwebapollo',
    user: 'fijisabre',
    rcSession:
      '10265-58921379CD9B28A67E4E8948CDB8E5F2-6E2AAF83833AFC3C6C49734337AED8053D037E8FCC76EA57476674D286C2AA607D3AD36D675B4C5827201FF735D8EDF611DA45CF721E34E440FB39D4CC16D91FCC24368AC750DB18C43CE3DFA632A99F6DC4BD67467D250F2639DB53F3FC4A9D2AEC2FC09B9BE72244E473CDE2D30027BBA0E9B772CA964A552FC0E366BA7FADE45E6046AFCB2449D3B4068DB5B0EE0E9901ADFC68E9CC49D66DCAE12D40DC94',
  },
  {
    site: 'fijiwebapollo',
    user: 'dhanush',
    rcSession:
      '10585-29E958D27ADD1560EEA521A3CC265602-A1EDEFA049A939E9BC163C435F579D0EF9561D5A5F111BBB27B1E84D55DAFF8CB9935A976B8315EEBDC2F35A8D45EEF75A457DB04FA07C78CDDD416352926A9AB1C8B97359FC19302C87E448097DCE4F2F16C8FF3D44EC03875FA64776FE631A21056FCD9B81B056E5D0FFF41316E4AA281B91C01AE67ADBD87C0324A5B4D06409F5F41D4E2B01ACE4FA765A4FC629A07F23685963DA08A7E5286B9FB2C99535',
  },

  {
    site: 'applefijitest',
    user: 'poorna',
    rcSession:
      '10265-58921379CD9B28A67E4E8948CDB8E5F2-6E2AAF83833AFC3C6C49734337AED8053D037E8FCC76EA57476674D286C2AA607D3AD36D675B4C5827201FF735D8EDF611DA45CF721E34E440FB39D4CC16D91FCC24368AC750DB18C43CE3DFA632A99F6DC4BD67467D250F2639DB53F3FC4A9D2AEC2FC09B9BE72244E473CDE2D30027BBA0E9B772CA964A552FC0E366BA7FADE45E6046AFCB2449D3B4068DB5B0EE0E9901ADFC68E9CC49D66DCAE12D40DC94',
  },

  {
    site: 'fijiwebmobi',
    user: 'fijiweb',
    rcSession:
      '10692-AC3692E4A2024219FBBEBB6B91852AB0-4E26F35469C768135CC29E6563D51F5277BFF75BE0ADA707E08D322568EC16E7A5D53230127C9510237F596E649FE0160D5D72938D7B4F039E40CE51B30ADA9D78D1C33331B86DE78078D37C813C254BAA645DFD89A24844960DDFDED054110DE879AAC3E4817F9A0995987558E37B42A5BB4751CDD6A095058757056454D69C9924B4B9CA6F4FF520BFD086B38E4B6A69919E9B029065CC689FBE85684A17C4',
  },
  {
    site: 'fijiwebapple',
    user: 'fijiweb',
    rcSession:
      '10366-1B01CB47422F91EE1E26DD5DB95E120A-DE413CD8F36CEE51FCFE8A65E72B9BE4263BDABEAE48AD5337EE96F637CA2575783B652354FC1E03A55C46C1429BBB316460C4C1E0EE8B53CA396A43B5840B522519FE8646398E228ED85BA9F1B90A3F44C86C001BA86D6A7A99BB514F8743B79F82A4F562BD82F9CA72F521F620B6AC450F8DA8322A009E567AF3BA2B59E645D7C5D4669A720D076A500247123B5E2D0A19227B53963CD0C82DD2E6F2A82408',
  },
]
