import { FlightImage } from '@etta/components/flight-image'
import { Container, FirstLogo, SecondLogo } from './flight-details-airlines-logos-components'

type Props = {
  airlinesLogos: string[]
}

export function FlightDetailsAirlinesLogos({ airlinesLogos }: Props) {
  if (airlinesLogos.length > 1) {
    return (
      <Container>
        <FirstLogo>
          <FlightImage key={airlinesLogos[0]} image={airlinesLogos[0]} />
        </FirstLogo>
        <SecondLogo>
          <FlightImage key={airlinesLogos[1]} image={airlinesLogos[1]} />
        </SecondLogo>
      </Container>
    )
  }

  return (
    <Container>
      <FlightImage key={airlinesLogos[0]} image={airlinesLogos[0]} />
    </Container>
  )
}
