import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const DatesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`

export const HotelDetailsDescriptionTitle = styled(Text)`
  margin-bottom: 8px;
`
