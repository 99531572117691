import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { usePreSearch } from '@fiji/hooks/use-custom-fields/use-pre-search'
import { PreSearchCustomFields } from '@etta/components/pre-search-custom-fields/pre-search-custom-fields'
import { DetailsHeaderLayout } from './layout'
import type { Props } from './types'

export const DetailsHeader = observer(function DetailsHeader(props: Props) {
  const { postBookingTripStore, bookTripAgainService } = usePostBookingContext()
  const {
    isOnHold,
    isLoading,
    isError,
    isTripNameChangable,
    trip,
    isTripExist,
    hasTrainOpenReturnSegment,
  } = postBookingTripStore
  const { type, rollUpEndTime, rollUpStartTime } = trip

  // Add pre search custom fields modal for book again
  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  } = usePreSearch({ onSubmit: () => bookTripAgainService.bookTripAgain() })

  return (
    <>
      <DetailsHeaderLayout
        isTripExist={isTripExist}
        isLoading={isLoading}
        isError={isError}
        isTripOnHold={isOnHold}
        tripStatus={type}
        startTime={rollUpStartTime}
        endTime={rollUpEndTime}
        isTripNameChangable={isTripNameChangable}
        isOpenReturn={hasTrainOpenReturnSegment}
        {...props}
      />

      <PreSearchCustomFields
        customFields={customFields}
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onBack={onPreSearchBack}
        isVisible={isOpenPreSearchCustomFields}
        onSubmit={onSubmitCustomFieldsHandler}
      />
    </>
  )
})
