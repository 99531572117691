import styled, { css } from 'styled-components'
import type { SpinnerVariant } from './types'

export const Container = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SpinnerWrapper = styled.div<{ spinnerVariant: SpinnerVariant }>`
  display: flex;
  justify-content: center;
  height: 64px;
  padding-bottom: 10px;

  ${({ spinnerVariant }) => {
    switch (spinnerVariant) {
      case 'hotel':
        return css`
          margin-top: 30px;
          height: 128px;

          > * {
            align-items: flex-start;
          }
        `
      case 'default':
      default:
        return css`
          margin-bottom: 24px;
        `
    }
  }};
`
