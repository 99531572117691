import type {
  RailLegSubSegmentStation,
  SearchFlightLegSubSegment,
  TrainStationInfo,
} from '@fiji/graphql/types'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates'

export type TravelRailLocation = {
  fullDate: string
  date: string
  time: string
  city?: string | null
  stationName?: string | null
  stationCode?: string | null
  terminal?: SearchFlightLegSubSegment['origin']['terminal']
}

type Station = RailLegSubSegmentStation | TrainStationInfo

const isTrainStationInfo = (station: Station): station is TrainStationInfo =>
  'phoneNumber' in station

const mapDate = (date: string): Omit<TravelRailLocation, 'fullDate' & 'date' & 'time'> => {
  return {
    fullDate: date,
    date: tripReviewFormatDate(TripReviewDateType.DayAndDate, date) as string,
    time: tripReviewFormatDate(TripReviewDateType.TimePWA, date) as string,
  }
}

const mapRailLegSubSegmentStation = (
  station: RailLegSubSegmentStation,
  date: string,
): TravelRailLocation => ({
  ...mapDate(date),
  city: station.city,
  stationName: station.name,
  stationCode: station.code,
  terminal: station.terminal,
})

const mapTrainStationInfo = (station: TrainStationInfo, date: string): TravelRailLocation => ({
  ...mapDate(date),
  city: station.address.city,
  stationName: station.stationName,
  stationCode: station.stationCode,
})

export function formatTravelRailLocation(station: Station, date: string): TravelRailLocation {
  if (isTrainStationInfo(station)) {
    return mapTrainStationInfo(station, date)
  }

  return mapRailLegSubSegmentStation(station, date)
}
