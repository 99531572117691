import { css } from 'styled-components'
import { MODAL_TRANSITION_TIMEOUT, MODAL_ANIMATION } from './constants'

export const MODAL_TRANSITION_SLIDE_RIGHT = 'MODAL_SLIDE_UP'

export const slideRight = css`
  transition: transform ${MODAL_TRANSITION_TIMEOUT}ms ${MODAL_ANIMATION};

  transform: translateX(100%);

  &.${MODAL_TRANSITION_SLIDE_RIGHT}-appear, &.${MODAL_TRANSITION_SLIDE_RIGHT}-enter {
    transform: translateX(100%);
  }

  &.${MODAL_TRANSITION_SLIDE_RIGHT}-appear-done,
    &.${MODAL_TRANSITION_SLIDE_RIGHT}-enter-done,
    &.${MODAL_TRANSITION_SLIDE_RIGHT}-appear-active,
    &.${MODAL_TRANSITION_SLIDE_RIGHT}-enter-active {
    transform: translateX(0%);
  }

  &.${MODAL_TRANSITION_SLIDE_RIGHT}-exit-done, &.${MODAL_TRANSITION_SLIDE_RIGHT}-exit {
    transform: translateX(0%);
  }

  &.${MODAL_TRANSITION_SLIDE_RIGHT}-exit-active {
    transform: translateX(100%);
  }
`
