import moment from 'moment'
import type { FocusedInputShape } from 'react-dates'
import type { Dates } from '@etta/ui/date-picker'
import { useControlsLabels } from '@fiji/hooks/use-date-picker'
import { ControlsLayout } from '@etta/ui/date-picker-temp/controls/layout'

type ControlsProps = Dates & {
  focusedInput: FocusedInputShape
  isRange?: boolean
  startTitle?: string
  endTitle?: string
  isOpen: boolean
}

function formatDate(date: Date, isMobile?: boolean) {
  const format = isMobile ? 'MMM DD' : 'ddd, MMM DD'
  return moment(date).format(format)
}

export default function Controls({
  focusedInput,
  date,
  dateEnd,
  isRange,
  startTitle,
  endTitle,
  isOpen,
}: ControlsProps) {
  const { startDateAriaLabel, startDateTitle, endDateTitle, endDateAriaLabel } = useControlsLabels({
    date,
    dateEnd,
    isRange,
    startTitle,
    endTitle,
    focusedInput,
    titleFormatter: formatDate,
  })

  return (
    <ControlsLayout
      isOpen={isOpen}
      isRange={isRange}
      focusedInput={focusedInput}
      date={date}
      dateEnd={dateEnd}
      startDateAriaLabel={startDateAriaLabel}
      startDateTitle={startDateTitle}
      endDateTitle={endDateTitle}
      endDateAriaLabel={endDateAriaLabel}
    />
  )
}
