import { Store } from '@etta/di'
import type { RideHailExpenseCodeEntity } from '@etta/modules/ride-hail/core/value-objects/ride-hail-expense-code.entity'

@Store()
export class SelectExpenseCodeStore {
  // filtered expense codes by search text field
  private _searchExpenseCodeValue: string = ''

  // expense code to be shown on the list
  private _expenseCodes: RideHailExpenseCodeEntity[] = []

  // selected expense code by user
  private _selectedExpenseCodeId: string = ''

  // is the continue button disabled
  private _isContinueButtonDisabled: boolean = true

  get searchExpenseCodeValue() {
    return this._searchExpenseCodeValue
  }

  get expenseCodes() {
    return this._expenseCodes
  }

  get selectedExpenseCodeId() {
    return this._selectedExpenseCodeId
  }

  get isContinueButtonDisabled() {
    return this._isContinueButtonDisabled
  }

  setSearchExpenseCodeValue(value: string) {
    this._searchExpenseCodeValue = value
  }

  setExpenseCodes(value: RideHailExpenseCodeEntity[]) {
    this._expenseCodes = value
  }

  setSelectedExpenseCodeId(value: string) {
    this._selectedExpenseCodeId = value
  }

  setIsContinueButtonDisabled(value: boolean) {
    this._isContinueButtonDisabled = value
  }

  dropStore() {
    this._searchExpenseCodeValue = ''
    this._expenseCodes = []
    this._selectedExpenseCodeId = ''
    this._isContinueButtonDisabled = true
  }
}
