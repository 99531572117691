import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { CheckoutPageModalBodyMobile } from './mobile'
import { CheckoutPageModalBodyDesktop } from './desktop'

export function CheckoutPageModalBodyLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CheckoutPageModalBodyMobile {...props} />}
      desktopSlot={<CheckoutPageModalBodyDesktop {...props} />}
    />
  )
}
