import { useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { ViewState } from '@etta/ui/view-state-modal'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { PreferenceOptions } from '@etta/modules/booking/core/value-objects/purchase-info-details.value-object'
import type { FlightTravelPreferencesValueObject } from '@etta/modules/travel-preferences/core/value-objects/travel-preferences.value-object'
import type { GazooMembership, LoyaltyProgram } from '@fiji/graphql/hooks'
import { usePreferenceStates } from './use-preferences-states'
import {
  mapAirMembershipOption,
  mapAirSpecialRequestOption,
  mapMealOption,
  mapMealRequestValue,
  mapAirSpecialRequestValue,
  mapAirLoyaltyProgram,
  toAirMembership,
  toMealValue,
  toSpecialRequestValues,
} from './mappers'

type Args = {
  onChangeViewState: (viewState: ViewState) => void
}

type FlightSubmitArgs = {
  specialRequestsValues: string[]
  mealValue?: string
  airMembership: GazooMembership[]
  loyaltyFlightValue?: LoyaltyProgram[]
}

export function useCheckoutFlightPreferences({ onChangeViewState }: Args) {
  const flightModalToggle = useTogglePopup()
  const { reviewTripStore } = useReviewTripContext()
  const segments = reviewTripStore.segments
  const { travelPreferencesStore } = useTravelPreferencesContext()
  const { checkoutInfoStore, checkoutInfoPreferencesActions } = useCheckoutInfoContext()

  const airMealOptions = useMemo<PreferenceOptions[] | null>(() => {
    if (!checkoutInfoStore.meal.mealRequestOptions) {
      return null
    }
    return checkoutInfoStore.meal.mealRequestOptions.map(mapMealOption).filter((item) => item.label)
  }, [checkoutInfoStore.meal.mealRequestOptions])

  const airMembershipOptions = useMemo<PreferenceOptions[] | null>(() => {
    if (!checkoutInfoStore.memberships.airMembershipOptions) {
      return null
    }
    return checkoutInfoStore.memberships.airMembershipOptions
      .map(mapAirMembershipOption)
      .filter((item) => item.label)
  }, [checkoutInfoStore.memberships.airMembershipOptions])

  const airSpecialRequestOptions = useMemo<PreferenceOptions[] | null>(() => {
    if (!checkoutInfoStore.specialRequests.airSpecialRequestOptions) {
      return null
    }
    return checkoutInfoStore.specialRequests.airSpecialRequestOptions
      .map(mapAirSpecialRequestOption)
      .filter((item) => item.label)
  }, [checkoutInfoStore.specialRequests.airSpecialRequestOptions])

  const {
    loyaltyFlightValue,
    loyaltyFlightToRender,
    onChangeLoyaltyFlight,
    onChangeLoyaltyFlightToRender,
    flightLoyaltyErrors,
    hasErrors,
  } = usePreferenceStates({
    loyaltyProgramOptions: checkoutInfoStore.memberships.airMembershipOptions,
    airMemberships: checkoutInfoStore.memberships.airMembershipValue,
    segments,
  })

  const composedFlightPreferences = useMemo(() => {
    const meal = checkoutInfoStore.meal.mealRequestValue
      ? mapMealRequestValue(checkoutInfoStore.meal.mealRequestValue)
      : null

    const specialRequest =
      checkoutInfoStore.specialRequests.airSpecialRequestValue?.map(mapAirSpecialRequestValue) || []
    return {
      meal,
      loyaltyProgram: loyaltyFlightToRender || [],
      specialRequest,
    }
  }, [
    checkoutInfoStore.meal.mealRequestValue,
    checkoutInfoStore.specialRequests.airSpecialRequestValue,
    loyaltyFlightToRender,
  ])

  const flightTravelPreferences = useMemo<FlightTravelPreferencesValueObject>(() => {
    return {
      loyaltyProgram:
        checkoutInfoStore.memberships.airMembershipValue?.map(mapAirLoyaltyProgram) || [],
      specialRequest:
        checkoutInfoStore.specialRequests.airSpecialRequestValue?.map(mapAirSpecialRequestValue) ||
        [],
      meal: checkoutInfoStore.meal.mealRequestValue
        ? mapMealRequestValue(checkoutInfoStore.meal.mealRequestValue)
        : null,
      specialRequestOptions:
        checkoutInfoStore.specialRequests.airSpecialRequestOptions?.map(
          mapAirSpecialRequestOption,
        ) || [],
    }
  }, [
    checkoutInfoStore.memberships.airMembershipValue,
    checkoutInfoStore.specialRequests.airSpecialRequestOptions,
    checkoutInfoStore.specialRequests.airSpecialRequestValue,
    checkoutInfoStore.meal.mealRequestValue,
  ])

  const onFlightSubmit = async ({
    loyaltyFlightValue = [],
    specialRequestsValues,
    mealValue,
  }: FlightSubmitArgs) => {
    onChangeViewState('saving')
    await delayForSuccess()
    checkoutInfoPreferencesActions.setAirPreferences({
      meal: toMealValue(mealValue, checkoutInfoStore.meal.mealRequestOptions || []),
      membership: toAirMembership(
        loyaltyFlightValue,
        checkoutInfoStore.memberships.airMembershipOptions || [],
      ),
      specialRequest: toSpecialRequestValues(
        specialRequestsValues,
        checkoutInfoStore.specialRequests.airSpecialRequestOptions || [],
      ),
    })
    if (loyaltyFlightValue) {
      onChangeLoyaltyFlightToRender(loyaltyFlightValue)
    }
    onChangeViewState('saved')
    await delayForSuccess()
    flightModalToggle.handleClose()
    onChangeViewState('hidden')
  }

  const { knownTravelerNumber, knownTravelerIssuingCountry, redressNumber } =
    travelPreferencesStore.travelPreferences.flight || {}

  return {
    onFlightSubmit,
    mealOptions: airMealOptions,
    airMemberships: airMembershipOptions,
    airSpecialRequests: airSpecialRequestOptions,
    flightTravelPreferences,
    flightModalToggle,
    loyaltyFlightValue,
    onChangeLoyaltyFlight,
    onChangeLoyaltyFlightToRender,
    composedFlightPreferences,
    knownTravelerNumber,
    knownTravelerIssuingCountry,
    redressNumber,
    isDisabled: hasErrors,
    flightLoyaltyErrors,
  }
}
