import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { getSeatFeature } from '@etta/modules/seat-map/utils'
import type { SegmentSeatNumber } from '../../air-seat-map.types'
import { removeSeat } from './remove-seat'

type AddSeatArgs = {
  seat: SeatMapRowSeatValueObject
  selectedSegmentId: string
  segmentsSeatNumbers: SegmentSeatNumber[]
}

export function addSeat({
  seat,
  selectedSegmentId,
  segmentsSeatNumbers,
}: AddSeatArgs): SegmentSeatNumber[] {
  return [
    ...removeSeat({ seat, selectedSegmentId, segmentsSeatNumbers }),
    {
      segmentId: selectedSegmentId,
      seatNumber: seat.number,
      seatFeature: getSeatFeature(seat),
    },
  ].filter((seatItem) => {
    // seat should be single for one segment
    const isCurrentSegment = seatItem.segmentId === selectedSegmentId

    if (!isCurrentSegment) {
      return true
    }

    return seatItem.seatNumber === seat.number
  })
}
