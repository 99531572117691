import { useMemo } from 'react'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { useBaggageModal } from './use-baggage-modal'
import { useCabinClass } from './use-cabin-class'
import { useFlightPosition } from './use-flight-position'
import { useHeader } from './use-header'
import { useLayover } from './use-layover'

type Props = {
  segment: TripDetailsFlightLegSegment
  nextSegment?: TripDetailsFlightLegSegment
  legNumber: number
  legs: number
  nextFlightStart: string
}

export function useFlightDetails({
  segment,
  nextSegment,
  legs,
  legNumber,
  nextFlightStart,
}: Props) {
  const { amenities } = segment

  const { isMultipleFlights, flightNumber, isLastSegment } = useFlightPosition({ legs, legNumber })

  const { aircraftNumber, airlineTitle, isDelayed, airlineLogo, flightStatus } = useHeader({
    segment,
  })

  const { cabinClass, seat, baggageClaim } = useCabinClass({ segment })

  const { layoverTime } = useLayover({ nextFlightStart, segment })

  const { baggageInfo, baggageModalToggle, isBaggageShow, firstBaggageCost } = useBaggageModal({
    segment,
  })

  const isAmenitiesShown = !!amenities?.codes?.length

  const flightLegSegments = useMemo(() => {
    if (!nextSegment) {
      return [segment]
    }
    return [segment, nextSegment]
  }, [segment, nextSegment])

  return {
    isMultipleFlights,
    flightNumber,
    airlineTitle,
    aircraftNumber,
    isDelayed,
    airlineLogo,
    flightStatus,
    cabinClass,
    seat,
    baggageClaim,
    isBaggageShow,
    baggageModalToggle,
    firstBaggageCost,
    isAmenitiesShown,
    amenities,
    baggageInfo,
    layoverTime,
    isLastSegment,
    flightLegSegments,
  }
}
