import styled from 'styled-components'
import { footnoteMedium, headline } from '@fiji/style'

export const Section = styled.section`
  max-width: 576px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 14px;
  padding: 24px;
  background-color: ${(p) => p.theme.colors.white};
`

export const Rows = styled.div``

export const Title = styled.div`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
  margin-bottom: 12px;
`

export const PaymentContainer = styled.div`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
  margin-top: 12px;
  display: flex;
`

export const PaymentTitle = styled.div`
  flex: 1;
`

export const PaymentDetails = styled.div`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  margin-top: 4px;
  margin-right: 4px;
`
