import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { FlightDetailsEcoCheckModal } from '@etta/components/flight-details-eco-check-modal'
import { tonnesToKg } from '@etta/components/trip-details-eco-check-summary/use-trip-details-eco-check-summary/utils'
import { Icon } from '@etta/ui/icon'
import { KgOfCo2 } from '@etta/components/kg-of-co2'
import { Link } from '@etta/ui/link'
import { estimatedEmissionsLinks } from '../../constants'
import {
  Container,
  EcoCheckBackground,
  Title,
  Body,
  BodyBottom,
  LearnMore,
  BodyBottomDescription,
  BodyTop,
  EmissionInformationValue,
  EstimatedEmissionsHeader,
} from './flight-details-eco-check-mobile-new-styled'
import EcoCheckAverage from './assets/eco-check-average.svg?url'
import EcoCheckMore from './assets/eco-check-more.svg?url'
import EcoCheckLess from './assets/eco-check-less.svg?url'

type Props = {
  tonnesOfEmissions: number
  averageRouteEmissionTonnes: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function FlightDetailsEcoCheckMobileComponentNew({
  tonnesOfEmissions,
  equivalences,
  averageRouteEmissionTonnes,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.FlightDetailsNew'
  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions: tonnesOfEmissions,
    averageEmissionsTonnes: averageRouteEmissionTonnes,
  })
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const bodyBottomDescription = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + '.AverageEmissions')
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + '.LowerEmissions', {
          percentDeviation,
        })
      case EmissionsDeviationLevel.More:
        return t(i18nBase + '.HigherEmissions', {
          percentDeviation,
        })
    }
  }, [deviationLevel, percentDeviation, t])

  const ecoCheckBackground = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return EcoCheckAverage
      case EmissionsDeviationLevel.Less:
        return EcoCheckLess
      case EmissionsDeviationLevel.More:
        return EcoCheckMore
    }
  }, [deviationLevel])

  const emissionsCloudIcon = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
      case EmissionsDeviationLevel.More:
        return 'AverageEmissionsCloudsPWA'
      case EmissionsDeviationLevel.Less:
        return 'lowerEmissionsCloudsPWA'
    }
  }, [deviationLevel])

  return (
    <>
      <Container data-tracking-id="flight-details-eco-check-action">
        <EstimatedEmissionsHeader>
          <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
            {t('Emissions.DetailsModal.EstimatedEmissions')}{' '}
          </Title>
          <Link
            aria-label={`${t('Emissions.DetailsModal.ViewSource')} - ${t(
              'Accessibility.OpenLink',
            )}`}
            target="_blank"
            size="small"
            href={estimatedEmissionsLinks.viewSourceLink}>
            {t('Emissions.DetailsModal.ViewSource')}
            <Icon name={'openWindowPWA'} size={16} color="primary" />
          </Link>
        </EstimatedEmissionsHeader>
        <Body>
          <BodyTop>
            <EmissionInformationValue>
              <KgOfCo2 kgOfEmissions={tonnesToKg(tonnesOfEmissions)} />
              <Icon name={emissionsCloudIcon} />
            </EmissionInformationValue>
          </BodyTop>
          <BodyBottom>
            <BodyBottomDescription
              aria-label={bodyBottomDescription}
              dangerouslySetInnerHTML={{ __html: bodyBottomDescription }}
            />
            <LearnMore
              tabIndex={0}
              onClick={handleOpen}
              aria-label={
                t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
              }>
              {t('Emissions.DetailsModal.LearnMore')}
            </LearnMore>
          </BodyBottom>
        </Body>
        <EcoCheckBackground src={ecoCheckBackground} />
      </Container>
      <FlightDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={tonnesOfEmissions}
        averageEmissionsTonnes={averageRouteEmissionTonnes}
      />
    </>
  )
}
