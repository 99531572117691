import { MediaLayout } from '@etta/ui/media-layout'
import { PurchasePaymentInformationFormMobile } from './mobile'
import { PurchasePaymentInformationFormDesktop } from './desktop'
import type { LayoutProps } from './types'

export function PurchasePaymentInformationFormLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<PurchasePaymentInformationFormMobile {...props} />}
      desktopSlot={<PurchasePaymentInformationFormDesktop {...props} />}
    />
  )
}
