import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'

export const Container = styled(Block)`
  flex-direction: column;
  max-width: 310px;
`

export const StyledButton = styled(Button)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`
