import { useEffect } from 'react'
import { trackAction } from '@fiji/utils/tracking/track-action'

const ACTION_NAME = 'mobility-pick-up-confirmation-page'
const ACTION_NAME_ERROR = 'mobility-pick-up-confirmation-error-page'

export type Props = {
  isOpen: boolean
  isError: boolean
}

export function useTrackMobilityPickUpConfirmation({ isOpen, isError }: Props) {
  useEffect(() => {
    if (isOpen) {
      trackAction(!isError ? ACTION_NAME : ACTION_NAME_ERROR)
    }
  }, [isOpen, isError])
}
