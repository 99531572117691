import styled, { css } from 'styled-components'

export const TooltipContainer = styled.div<{ isOpen: boolean }>`
  width: 100px;
  height: 100px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-color: rgba(38, 38, 38, 0.8);
  color: ${(p) => p.theme.colors.mainText2};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in;

  ${(p) =>
    p.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const TooltipLabel = styled.div`
  margin-top: 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`
