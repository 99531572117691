import { MediaLayout } from '@etta/ui/media-layout'
import { CarRentalOfficeMobileSelector, CarRentalOfficeDesktopSelector } from './layout'
import type { LayoutProps } from './types'

export function CarRentalOfficeSelectorLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalOfficeMobileSelector {...props} />}
      desktopSlot={<CarRentalOfficeDesktopSelector {...props} />}
    />
  )
}
