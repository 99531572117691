import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { dateFormat } from '@fiji/utils/dates'
import { RecentSearchErrors } from '../core/errors'
import type { CarRentalRecentSearch } from '../core/value-objects/get-car-rental-searches.value-object'
import type { FlightRecentSearch } from '../core/value-objects/get-flight-searches.value-object'
import type { HotelRecentSearch } from '../core/value-objects/get-hotel-searches.value-object'
import type { RailRecentSearch } from '../core/value-objects/get-rail-searches.value-object'
import type { CarRentalRecentSearchWithoutDates } from '../core/value-objects/get-car-rental-searches-without-dates.value-object'
import type { HotelRecentSearchWithoutDates } from '../core/value-objects/get-hotel-searches-without-dates.value-object'
import type { FlightRecentSearchWithoutDates } from '../core/value-objects/get-flight-searches-without-dates.value-object'
import { RecentSearchDataProvider } from './data-providers/recent-searches.data-provider'
import type { RecentSearchesAdapterResult } from './types'

@Adapter()
export class GetRecentSearchesAdapter {
  constructor(
    @Inject()
    private recentSearchDataProvider: RecentSearchDataProvider,
  ) {}

  async getCarRentalRecentSearches(): RecentSearchesAdapterResult<CarRentalRecentSearch[]> {
    try {
      const { data, error } = await this.recentSearchDataProvider.getRecentCarRentalSearches({
        startDate: this.getCurrentDate(),
      })
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(error))
      }

      return Result.Ok(data.recentCarRentalSearches.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async getFlightRecentSearches(): RecentSearchesAdapterResult<FlightRecentSearch[]> {
    try {
      const { data, error } = await this.recentSearchDataProvider.getRecentFlightSearches({
        startDate: this.getCurrentDate(),
      })
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(error))
      }

      return Result.Ok(data.recentFlightSearches.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async getHotelRecentSearches(): RecentSearchesAdapterResult<HotelRecentSearch[]> {
    try {
      const { data, error } = await this.recentSearchDataProvider.getRecentHotelSearches({
        startDate: this.getCurrentDate(),
      })
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(error))
      }

      return Result.Ok(data.recentHotelSearches.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async getRailRecentSearches(): RecentSearchesAdapterResult<RailRecentSearch[]> {
    try {
      const { data, error } = await this.recentSearchDataProvider.getRecentRailSearches({
        startDate: this.getCurrentDate(),
      })
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(error))
      }

      return Result.Ok(data.recentRailSearches.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async getCarRentalRecentSearchesWithoutDates(): RecentSearchesAdapterResult<
    CarRentalRecentSearchWithoutDates[]
  > {
    try {
      const {
        data,
        error,
      } = await this.recentSearchDataProvider.getRecentCarRentalSearchesWithoutDates()
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchWithoutDatesFail(error))
      }

      return Result.Ok(data.recentCarRentalSearchesWithoutDates.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchWithoutDatesFail(e))
    }
  }

  async getHotelRecentSearchesWithoutDates(): RecentSearchesAdapterResult<
    HotelRecentSearchWithoutDates[]
  > {
    try {
      const {
        data,
        error,
      } = await this.recentSearchDataProvider.getRecentHotelSearchesWithoutDates()
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewHotelSearchWithoutDatesFail(error))
      }

      return Result.Ok(data.recentHotelSearchesWithoutDates.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewHotelSearchWithoutDatesFail(e))
    }
  }

  async getFlightRecentSearchesWithoutDates(): RecentSearchesAdapterResult<
    FlightRecentSearchWithoutDates[]
  > {
    try {
      const {
        data,
        error,
      } = await this.recentSearchDataProvider.getRecentFlightSearchesWithoutDates()
      if (error) {
        return Result.Err(new RecentSearchErrors.SubmitNewFlightSearchWithoutDatesFail(error))
      }

      return Result.Ok(data.recentFlightSearchesWithoutDates.items)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewFlightSearchWithoutDatesFail(e))
    }
  }

  private getCurrentDate() {
    return dateFormat(new Date(), 'yyyy-MM-dd')
  }
}
