import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'

export function TicketExchangeAcknowledgementSkeleton() {
  return (
    <Block marginTop={28} data-tracking-id={'skeleton'}>
      <Skeleton width="100%" height="24px">
        <rect width="100%" height="24px" />
      </Skeleton>
    </Block>
  )
}
