import styled from 'styled-components'

export const TabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Dash = styled.span`
  display: inline-flex;
  margin: auto 4px;
  width: 11px;
  height: 2px;
  background-color: currentColor;
`

export const Separator = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.background};
  margin-top: -1px;
`

export const Container = styled.div`
  margin-top: 8px;
  padding-bottom: 1px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
