import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace HotelSearchErrors {
  export const CreateSearchError = createErrorClass('Create search error')
  export const UnexpectedErrorCreateSearch = createErrorClass(
    'Unexpected error while creating search',
  )
  export const RestrictedCountryError = createErrorClass<{ message: string }>(
    'Restricted country error',
  )
  export const GetHotelsError = createErrorClass('Get hotels error')
  export const GetHotelByIdError = createErrorClass('Get hotel by id error')
  export const UnexpectedErrorGetHotels = createErrorClass('Unexpected error while getting hotels')
  export const UnexpectedErrorGetHotelById = createErrorClass(
    'Unexpected error while getting hotel by id',
  )
}

export type HotelSearchErrorsInstances = InstanceType<
  typeof HotelSearchErrors[keyof typeof HotelSearchErrors]
>

export function isRestrictedCountryError(
  err: HotelSearchErrorsInstances,
): err is InstanceType<typeof HotelSearchErrors.RestrictedCountryError> {
  return err instanceof HotelSearchErrors.RestrictedCountryError
}
