import { useEffect, useState } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { trackMobilitySearchError } from '@etta/screens/mobility-search-page/track-mobility-search-error'
import { trackAction } from '@fiji/utils/tracking/track-action'
import type { MobilitySearchResultErrorType } from '../mobility-search-result-error'

const ACTION_NAME = 'mobility-search-result'

export type Props = {
  isOpen: boolean
  searchResultError?: MobilitySearchResultErrorType | null
  complexPickUpType?: MobilitySearchResultErrorType | null
}

export function useTrackMobilitySearchResult({
  isOpen,
  complexPickUpType,
  searchResultError,
}: Props) {
  const [isTracked, setIsTracked] = useState(false)
  const { searchRideHailStore } = useRideHailSearchContext()

  useEffect(() => {
    if (searchResultError) {
      trackMobilitySearchError(searchResultError)
    }
  }, [searchResultError])

  useEffect(() => {
    const isResultLoaded =
      !complexPickUpType &&
      !searchResultError &&
      !searchRideHailStore.isLoading &&
      searchRideHailStore.sortedSearchRideHailResults.length > 0

    if (isOpen && isResultLoaded && !isTracked) {
      trackAction(ACTION_NAME)
      setIsTracked(true)
    }
  }, [
    searchResultError,
    complexPickUpType,
    searchRideHailStore.sortedSearchRideHailResults,
    searchRideHailStore.isLoading,
    isOpen,
    isTracked,
  ])
}
