import { Action, Inject } from '@etta/di'
import type { Place } from '@fiji/types'
import { PlacesStorageStore } from '../stores/places-storage.store'
import type { PlacesStorageType } from '../../core/value-objects/places-storage.value-object'

@Action()
export class PlacesStorageAction {
  constructor(
    @Inject()
    private placesStorageStore: PlacesStorageStore,
  ) {}

  addNewPlace(type: PlacesStorageType) {
    return (place: Place) => this.placesStorageStore.addNewPlace(type, place)
  }
}
