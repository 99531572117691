import { Action, Inject } from '@etta/di'
import { AlertService } from '@etta/modules/alert/interface/services/alert.service'
import type { AlertMessageValueObject } from '@etta/modules/alert/core/value-objects/alert.value-object'
import { AlertKind } from '@etta/modules/alert/core/enums/alert-kind.enum'
import { TravelDocumentsActions } from '@etta/modules/settings/interface/actions/travel-documents.actions'
import { PaymentInformationActions } from '@etta/modules/settings/interface/actions/payment-information.action'
import { SettingsActions } from '@etta/modules/settings/interface/actions/settings.actions'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { AlertStore } from '../stores/alert.store'

@Action()
export class AlertActions {
  private readonly alertActionsMap: Record<AlertKind, (alert: AlertMessageValueObject) => void> = {
    [AlertKind.Passport]: this.handlePassportAlert.bind(this),
    [AlertKind.CreditCard]: this.handleCreditCardAlert.bind(this),
    [AlertKind.NationalId]: this.handleNationalIdAlert.bind(this),
    [AlertKind.Visa]: this.handleVisaAlert.bind(this),
    [AlertKind.KTN]: this.handleKTNAlert.bind(this),
    [AlertKind.Unspecified]: function (_alert: AlertMessageValueObject): void {
      throw new Error('Function not implemented.')
    },
  }

  constructor(
    @Inject()
    private readonly alertService: AlertService,

    @Inject()
    private readonly alertStore: AlertStore,

    @Inject()
    private readonly settingsActions: SettingsActions,

    @Inject()
    private readonly travelDocumentsActions: TravelDocumentsActions,

    @Inject()
    private readonly paymentInformationActions: PaymentInformationActions,
  ) {}

  async handleLoadAlert() {
    await this.alertService.loadAlert()
  }

  async handleRefetchAlert() {
    await this.alertService.loadAlert()
  }

  openAlertModal() {
    this.handleLoadAlert()
    this.alertStore.alertModalToggle.handleOpen()
  }

  handleAlertClick(alert: AlertMessageValueObject) {
    // We only have settings page implemented on Desktop, so we don't want to handle mobile devices
    if (screenMatcher.getScreenType() !== ScreenType.Desktop) {
      return
    }

    if (!alert.kind) {
      return
    }
    this.alertActionsMap[alert.kind]?.(alert)
  }

  private handleKTNAlert(alert: AlertMessageValueObject) {
    this.hadleAlert(
      FieldSettingsCategory.NationalIdInfo,
      alert.docId,
      this.travelDocumentsActions.handleModalOpen,
    )
  }

  private handleNationalIdAlert(alert: AlertMessageValueObject) {
    this.hadleAlert(
      FieldSettingsCategory.NationalIdInfo,
      alert.docId,
      this.travelDocumentsActions.handleModalOpen,
    )
  }

  private handleVisaAlert(alert: AlertMessageValueObject) {
    this.hadleAlert(
      FieldSettingsCategory.VisaInfo,
      alert.docId,
      this.travelDocumentsActions.handleModalOpen,
    )
  }

  private handlePassportAlert(alert: AlertMessageValueObject) {
    this.hadleAlert(
      FieldSettingsCategory.PassportInfo,
      alert.docId,
      this.travelDocumentsActions.handleModalOpen,
    )
  }

  private handleCreditCardAlert(alert: AlertMessageValueObject) {
    this.hadleAlert(
      FieldSettingsCategory.PaymentCards,
      alert.docId,
      this.paymentInformationActions.handleModalOpen,
    )
  }

  private hadleAlert(
    fieldSettingsCategory: FieldSettingsCategory,
    docId?: number | null,
    handleOpen?: () => void,
  ) {
    if (!docId) {
      return
    }

    this.alertStore.alertModalToggle.handleClose()

    this.settingsActions.navigateToSettingsPage()
    this.settingsActions.handleSetHighlightedElement({
      fieldSettingsCategory,
      elementId: docId,
    })

    handleOpen?.()
  }
}
