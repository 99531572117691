import { useTranslation } from 'react-i18next'
import type { OverlappingTrip } from '@fiji/graphql/types'
import { NameTrip, ViewTrip, Content } from './duplicated-list-styled'

type Props = {
  trips?: OverlappingTrip[]
  onClick: (id?: number) => void
}

export function DuplicatedList({ trips, onClick }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Content>{t('ReviewTrip.ErrorsList.OverlappingTripContent')}</Content>
      {trips?.map((trip, i) => (
        <div key={trip.name + i}>
          <NameTrip>{trip.name}</NameTrip>
          <ViewTrip
            aria-label={t('ReviewTrip.ButtonsLabel', {
              label: t('ReviewTrip.ErrorsList.ViewBookedTrip'),
            })}
            onClick={() => onClick(trip.id)}>
            {t('ReviewTrip.ErrorsList.ViewBookedTrip')}
          </ViewTrip>
        </div>
      ))}
    </>
  )
}
