export default function OdometerlIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7661 14.569L29.7552 16.2931L28.4074 18.5345L24.5324 25.2586C25.2063 26.1207 25.7118 27.1552 25.7118 28.1897C25.7118 30.6034 23.8585 32.5 21.4998 32.5C19.1412 32.5 17.2879 30.6034 17.2879 28.1897C17.2879 25.7759 19.1412 23.8793 21.4998 23.8793C21.6683 23.8793 22.3422 24.0167 22.3422 24.0517L26.2172 17.3276L27.565 15.0862L28.5759 13.1897C28.9129 12.5 29.5868 12.3276 30.2607 12.6724C30.7661 13.0172 30.9346 13.3621 30.9346 13.8793C30.9346 14.2241 30.9346 14.3966 30.7661 14.569ZM19.8151 28.1897C19.8151 29.2241 20.489 29.9138 21.4998 29.9138C22.3422 29.9138 23.1846 29.2241 23.1846 28.1897C23.1846 27.1552 22.5107 26.4655 21.4998 26.4655C20.489 26.4655 19.8151 27.1552 19.8151 28.1897ZM12.2337 27.8449H8.86412C8.80612 27.8449 8.76809 27.8449 8.73628 27.8309C8.67567 27.8041 8.63764 27.7262 8.52716 27.5001C9.36955 21.2932 14.9293 16.638 21.4999 16.638C22.6793 16.638 23.8586 16.8105 24.8695 17.1553L26.2173 14.7415C24.701 14.2242 23.1847 14.0518 21.4999 14.0518C13.5815 14.0518 7.01086 19.7415 6 27.1553C6 28.0174 6.16848 28.707 6.67391 29.3967C7.17934 30.0863 8.02173 30.4311 8.86412 30.4311H12.2337C12.9076 30.4311 13.5815 29.9139 13.5815 29.0518C13.413 28.3622 12.9076 27.8449 12.2337 27.8449ZM37.0001 27.1551C36.4947 23.0171 34.136 19.3965 30.7665 16.9827L29.4186 19.224C32.1143 21.293 33.9675 24.224 34.3045 27.6723C34.3045 27.6723 34.136 27.8447 33.9675 27.8447H30.598C29.9241 27.8447 29.2502 28.362 29.2502 29.224C29.2502 29.9137 29.7556 30.6034 30.598 30.6034H33.9675C34.8099 30.6034 35.6523 30.2585 36.1577 29.5689C36.8316 28.7068 37.0001 28.0171 37.0001 27.1551Z"
        fill="currentColor"
      />
    </svg>
  )
}
