import type { KeyToIcon } from '@etta/ui/icon/types'

export const amenityToPwaIconMap: KeyToIcon = {
  TV: 'tvPWA',
  SV: 'tvPWA',
  AU: 'audioPWA',
  NS: 'nonSmokingPWA',
  PW: 'outletsAndUSBPWA',
  UP: 'outletsAndUSBPWA',
  AP: 'powerPWA',
  MV: 'theatresPWA',
  VP: 'ondemandVideoPWA',
  OD: 'ondemandVideoPWA',
  LF: 'seatSelectionPWA',
  WF: 'wifiPWA',
  DF: 'dutyFreePWA',
  TL: 'telephonePWA',
  BG: 'goodsPWA',
  BR: 'goodsPWA',
  BF: 'mealPWA',
  DI: 'mealPWA',
  HF: 'hotFoodPWA',
  LO: 'loungePWA',
  LA: 'loungePWA',
  ML: 'mealPWA',
  IN: 'wifiPWA',
  NP: 'newsPaperPWA',
  PK: 'pickUpPointFilledPWA',
  QU: 'quiteZonePWA',
  RS: 'restaurantPWA',
  R1: 'restaurantPWA',
  SN: 'snackPWA',
  TX: 'carServicePWA',
  TY: 'snackPWA',
  WC: 'specialRequestPWA',
  WO: 'specialRequestPWA',
  WI: 'wifiPWA',
  info: 'infoOutlinePWA',
}
