import { useTranslation } from 'react-i18next'
import { RailCollectTicketDisabled } from '@etta/components/rail-collect-ticket-disabled'
import { TicketMethod } from '../rail-preferences-modal/types'
import type { RailEticketSelectorProps, SelectedBlockProps } from './types'
import eTicketImage from './images/eTicket.png'
import stationImage from './images/station.png'
import eTicketGreyImage from './images/eTicket-grey.png'

const i18nBase = 'RailPreferences.'
const i18nBaseDescriptions = 'RailPreferences.Descriptions.'

export function useRailEticketSelector({
  selectedMethod,
  selectMethodHandler,
  isEticketDisabled,
  isStationTicketMachineDisabled,
  departureStationName,
}: RailEticketSelectorProps) {
  const { t } = useTranslation()

  const preferencesLabel = t(i18nBase + 'TicketDeliveryMethod')

  const selectedBlocks: SelectedBlockProps[] = [
    {
      id: '1',
      imageUrl: isEticketDisabled ? eTicketGreyImage : eTicketImage,
      title: t(i18nBase + 'Eticket'),
      descriptions: isEticketDisabled
        ? t(i18nBaseDescriptions + 'Unavailable')
        : t(i18nBaseDescriptions + 'EticketDescription'),

      isSelected: selectedMethod === TicketMethod.ETicket,
      onClick: () => selectMethodHandler(TicketMethod.ETicket),
      isDisabled: isEticketDisabled,
      footerSlot: null,
    },
    {
      id: '2',
      imageUrl: stationImage,
      title: t(i18nBase + 'CollectFromStation'),
      descriptions: t(i18nBaseDescriptions + 'CollectDescription'),
      isSelected: selectedMethod === TicketMethod.Collect || isEticketDisabled,
      onClick: () => selectMethodHandler(TicketMethod.Collect),
      footerSlot: isStationTicketMachineDisabled ? (
        <RailCollectTicketDisabled stationName={departureStationName} />
      ) : null,
    },
  ]

  const sortedSelectedBlocks = isEticketDisabled ? selectedBlocks.reverse() : selectedBlocks

  return {
    preferencesLabel,
    sortedSelectedBlocks,
  }
}
