import * as yup from 'yup'
import type BaseSchema from 'yup/lib/schema'
import { makeAutoObservable } from 'mobx'
import { getInitialFields } from './get-initial-fields'
import type { ValidationScheme, ValidatorType } from './types'
import { handleSubmit } from './handle-submit'

export class Validator<T extends ValidationScheme = ValidationScheme> implements ValidatorType<T> {
  private readonly yupScheme: BaseSchema
  private readonly initialFields: Record<keyof T, any>
  private readonly initialErrorFields: Record<keyof T, any>

  public errors: Record<keyof T, any>
  public values: Record<keyof T, any>

  static scheme = yup

  constructor(scheme: T) {
    this.yupScheme = yup.object().shape(scheme)

    const defaults = this.yupScheme.getDefault()
    this.initialFields = getInitialFields<T>(defaults, scheme)
    this.initialErrorFields = getInitialFields<T>(defaults, scheme, '')
    this.errors = { ...this.initialErrorFields }
    this.values = { ...this.initialFields }

    makeAutoObservable(this)
  }

  reset() {
    this.errors = { ...this.initialErrorFields }
    this.values = { ...this.initialFields }
  }

  setError(fieldName: keyof T, message?: string) {
    this.errors[fieldName] = message
  }

  async submit() {
    this.errors = { ...this.initialErrorFields }
    const result = await handleSubmit({ yupScheme: this.yupScheme, values: this.values })
    if (!result.isValid) {
      this.errors = result.errors || {}
    }
    return result
  }

  onFieldChange(fieldName: keyof T, fieldValue: any) {
    this.setError(fieldName, this.initialErrorFields[fieldName])
    if (process.env.NODE_ENV === 'development' && this.values[fieldName] === undefined) {
      throw new Error(
        `You are trying to set not defined field "${String(
          fieldName,
        )}" in scheme, please check scheme definition`,
      )
    }
    this.values[fieldName] = fieldValue
  }
}
