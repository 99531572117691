import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import { Icon } from '@etta/ui/icon'
import { DescendantItem } from '@etta/components/descendant'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useRecentSearchesContext } from '../../../../../interface/use-recent-searches.context'
import {
  ListItem,
  LeftBlock,
  RightBlock,
  Location,
  TopLineWithoutDates,
} from '../recent-search-items-styled'
import { TitleContainer } from '../../recent-searches-list-mobile-styled'

type Props = {
  onModalClose?: () => void
}

export function RecentCarRentalSearchListWithoutDates({ onModalClose }: Props) {
  const { t } = useTranslation()
  const title = t('RecentSearches.Title')
  const { featureFlags } = useFeatureFlags()
  const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()
  const recentSearchesListWithoutDates =
    recentSearchesStore.recentSearchesWithoutDates.carRental.items

  const [recentSearches, setRecentSearches] = useState(recentSearchesListWithoutDates)
  const { autocompleteStore } = useAutocompleteContext()

  useEffect(() => {
    if (!featureFlags.isRecentSearchesFilteringEnabled) {
      return
    }

    const filteredSearhes = recentSearchesListWithoutDates.filter((rs) => {
      const { locationName } = recentSearchDataAction.getRecentCarRentalSearchesWithoutDates(
        rs.carRentalSearchParams,
      )
      const query = autocompleteStore.query?.toLowerCase() || ''
      const location = locationName.toLowerCase()

      return location.includes(query)
    })

    setRecentSearches(filteredSearhes)
  }, [
    autocompleteStore.query,
    recentSearchesListWithoutDates,
    featureFlags,
    recentSearchDataAction,
  ])

  if (
    recentSearchesStore.isShowCarRentaltRecentSearch ||
    !recentSearchesStore.isShowCarRentalRecentSearchWithoutDates
  ) {
    return null
  }

  return (
    <>
      <TitleContainer aria-label={title}>{title}</TitleContainer>
      {recentSearches.map((item) => {
        const {
          locationName,
          fillSearchForm,
        } = recentSearchDataAction.getRecentCarRentalSearchesWithoutDates(
          item.carRentalSearchParams,
        )
        return (
          <DescendantItem
            key={item.id}
            onSelect={() => {
              onModalClose && onModalClose()
              fillSearchForm()
            }}>
            <ListItem
              onClick={() => {
                onModalClose && onModalClose()
                fillSearchForm()
              }}
              data-tracking-id="car-rental-search-mechanism-recent-searches-list">
              <LeftBlock>
                <Icon name="carRentalPWA" size={35} />
              </LeftBlock>
              <RightBlock>
                <TopLineWithoutDates>
                  <Location>{locationName}</Location>
                </TopLineWithoutDates>
              </RightBlock>
            </ListItem>
          </DescendantItem>
        )
      })}
    </>
  )
}
