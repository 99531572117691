import * as R from 'ramda'
import type { FlightAmenity, FlightLegSubSegment } from '@fiji/graphql/types'
import { getAmenities } from './get-amenities'

export function getGeneralAmenities(segments: FlightLegSubSegment[]): FlightAmenity[] {
  const amenitiesList = segments.reduce((prev: FlightAmenity[], curr) => {
    if (!curr.amenities) {
      return prev
    }
    return [...prev, ...getAmenities(curr.amenities)]
  }, [])

  return R.uniqBy(R.path(['name']), amenitiesList)
}
