import type { LayoutProps } from '../../types'
import { RailDetailsEcoCheckMobileComponent } from './rail-details-eco-check-mobile-component'
import { RailDetailsEcoCheckMobileSkeleton } from './rail-details-eco-check-mobile-skeleton'

export function RailDetailsEcoCheckMobile({
  tonnesOfEmissions,
  averageRouteEmissionTonnes,
  equivalences,
  kgOfActualEmission,
  isLoading,
}: LayoutProps) {
  if (isLoading) {
    return <RailDetailsEcoCheckMobileSkeleton />
  }

  if (!tonnesOfEmissions || !averageRouteEmissionTonnes || !kgOfActualEmission) {
    return null
  }

  return (
    <RailDetailsEcoCheckMobileComponent
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      averageRouteEmissionTonnes={averageRouteEmissionTonnes}
      kgOfActualEmission={kgOfActualEmission}
    />
  )
}
