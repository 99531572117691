import { useRef } from 'react'
import { useHandlers } from './handlers/use-handlers'
import { useContainer } from './use-container'

const DEFAULT_ANCHOR_POSITION = 60
const DEFAULT_AFTER_SCROLL = 0.7
const DEFAULT_AFTER_OFFSET = 0

type Args = {
  anchorPosition?: number
  scrollAfterValue?: number
  scrollAfterOffset?: number
}

export function useHeaderScroll(args?: Args) {
  const {
    anchorPosition = DEFAULT_ANCHOR_POSITION,
    scrollAfterValue = DEFAULT_AFTER_SCROLL,
    scrollAfterOffset = DEFAULT_AFTER_OFFSET,
  } = args || {}

  const baseRef = useRef<HTMLElement | null>(null)
  const anchorValueRef = useRef(0)
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const offsetRef = useRef<HTMLDivElement | null>(null)

  const { onDelaySet, onTapEnd, onTapStart } = useHandlers({
    baseRef,
    headerRef,
    anchorRef,
    offsetRef,
    anchorValueRef,
    defaultAnchorPosition: anchorPosition,
    scrollAfterValue,
    scrollAfterOffset,
  })

  const scrollContainerRef = useContainer(baseRef, {
    scroll: onDelaySet,
    mouseDown: onTapStart,
    mouseUp: onTapEnd,
    touchstart: onTapStart,
    touchend: onTapEnd,
  })

  return {
    scrollContainerRef,
    anchorRef,
    headerRef,
    offsetRef,
  }
}
