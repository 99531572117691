import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { SearchRailLegEntity } from '@etta/modules/rail/core/entities/search-rail-leg.entity'
import type { AdaptiveRail } from '../types'
import { getGeneralAmenities } from './getters/get-general-amenities'
import { getHeaderTitle } from './getters/get-header-title'
import { getRailLabels } from './getters/get-rail-labels'
import { getRailLogos } from './getters/get-rail-logos'
import { getAdaptedSegments } from './getters/get-adapted-segments'
import { getRailStops } from './getters/get-rail-stops'
import { getTotalRailDuration } from './getters/get-total-rail-duration'
import { getPercentageOnTimeLabels } from './getters/get-percentage-on-time-labels'

export function useSearchAdaptiveRail(railLeg: SearchRailLegEntity): { rail: AdaptiveRail } {
  const { t } = useTranslation()

  const rail = useMemo(() => {
    const { segments, legId, ecoInfo, platform } = railLeg
    const fares = [...railLeg.standardClassFares, ...railLeg.firstClassFares]
    const squareRailLogo = segments[0].carrier?.logoImageUrl
    const adaptedSegments = getAdaptedSegments(segments)
    const isPreferred = adaptedSegments.every((segment) => segment.isPreferredAny)

    return {
      id: legId,
      headerTitle: getHeaderTitle(segments),
      railLogo: squareRailLogo || '',
      squareRailLogos: getRailLogos(segments),
      railLabels: getRailLabels(segments),
      adaptedSegments,
      railStops: getRailStops(segments),
      totalRailDuration: getTotalRailDuration(railLeg?.railTime),
      railStopsLabel: railLeg.railStops
        ? t('SearchSegments.Stops.Stop', { count: railLeg.railStops })
        : t('SearchSegments.Stops.Nonstop'),
      percentageOnTimeLabels: getPercentageOnTimeLabels(segments),
      fares,
      generalAmenities: getGeneralAmenities(segments),
      isPreferred,
      ecoInfo,
      platform,
    }
  }, [railLeg, t])

  return {
    rail,
  }
}
