import { Icon } from '@etta/ui/icon'
import type { CalculateEmissionsResultHotelCertification } from '@fiji/graphql/types'
import { useHotelSustainability } from './use-hotel-sustainability'
import {
  Container,
  SustainabilityScoreContainer,
  SustainabilityScore,
  EcoCertifiedContainer,
  Separator,
  Label,
} from './hotel-list-sustainability-level-styled'

type Props = {
  averageLocationSustainabilityScore: number
  sustainabilityScore: number
  certifications: CalculateEmissionsResultHotelCertification[]
  isEcoFriendly?: boolean | null
}

export function HotelListSustainabilityLevel(props: Props) {
  const {
    deviationLevel,
    sustainabilityDescription,
    sustainablityConditionColor,
    sustainabilityAdditionalDescription,
  } = useHotelSustainability({
    ...props,
    averageSustainabilityScore: props.averageLocationSustainabilityScore,
  })

  return (
    <Container aria-label={sustainabilityDescription + ` ${sustainabilityAdditionalDescription}`}>
      <SustainabilityScoreContainer>
        <Icon name="leafPWA" size={18} color={sustainablityConditionColor} />
        <SustainabilityScore deviationLevel={deviationLevel}>
          {sustainabilityDescription}
        </SustainabilityScore>
      </SustainabilityScoreContainer>
      {sustainabilityAdditionalDescription && (
        <EcoCertifiedContainer>
          {sustainabilityDescription && <Separator deviationLevel={deviationLevel}>•</Separator>}
          <Label>{sustainabilityAdditionalDescription}</Label>
        </EcoCertifiedContainer>
      )}
    </Container>
  )
}
