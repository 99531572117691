import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'

export function getSegmentsState(segments: TripSegmentValueObject[]) {
  const typeCheck = SegmentTypeCheckActions.getInstance()
  // FIXME: add segmentState to TrainLeg query in graphQL
  return {
    flightState: segments?.find(typeCheck.isFlightSegment)?.segmentState,
    hotelState: segments.find(typeCheck.isHotelSegment)?.segmentState,
    carRentalState: segments.find(typeCheck.isCarRentalSegment)?.segmentState,
    railState: segments?.find(typeCheck.isTrainSegment)?.segmentState,
  }
}
