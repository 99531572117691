import { css } from 'styled-components'
import { HEADER_SCROLL_DELAY } from '../constants'

export const headerScrollAnimationStyles = css`
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-play-state: paused;
  animation-delay: calc(var(${HEADER_SCROLL_DELAY}) * -1s);
  animation-iteration-count: 1;
  animation-fill-mode: both;
`

export function createHeaderScrollAnimation(name: string, keyframes: any) {
  return css`
    ${headerScrollAnimationStyles};
    animation-name: ${name};
    @keyframes ${name} ${keyframes};
  `
}
