import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 0px;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`
export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
  flex: 1;
`
export const DoneButton = styled(Button)`
  cursor: pointer;
`
export const MainRow = styled.div`
  margin: 0px 0px 0px 0px;
  display: flex;
  flex: 1;
  gap: 20px;
`
