import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useScreenType, ScreenType } from '@fiji/modes'
import { Button } from '@etta/ui/button'
import type { Actions as ActionsType } from '../../types'
import { Wrapper } from './actions-styled'

type Props = ActionsType & {
  isTripHold: boolean
  isBookTripAgainAllowed: boolean
  onBookAgainClick: () => void
}

const i18nBase = 'PostBooking.TripDetails'

export const Actions = observer(function Actions({
  isTripHold,
  onBookAnotherTripClick,
  onSeeUpcomingTrips,
  onSeeHeldTrips,
  isBookTripAgainAllowed,
  onBookAgainClick,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const buttonType = isMobile ? 'primary' : 'secondary'

  return (
    <Wrapper isMobile={isMobile}>
      <Button fullWidth variant="outline" btnType={buttonType} onClick={onBookAnotherTripClick}>
        {t(`${i18nBase}.BookAnotherTrip`)}
      </Button>

      <Button
        fullWidth
        variant="outline"
        btnType={buttonType}
        onClick={onBookAgainClick}
        disabled={!isBookTripAgainAllowed}>
        {t(`${i18nBase}.BookThisTripAgain`)}
      </Button>

      {isTripHold ? (
        <Button fullWidth onClick={onSeeHeldTrips}>
          {t(`${i18nBase}.SeeHeldTrips`)}
        </Button>
      ) : (
        <Button fullWidth onClick={onSeeUpcomingTrips}>
          {t(`${i18nBase}.SeeUpcomingTrips`)}
        </Button>
      )}
    </Wrapper>
  )
})
