import { Action, Inject } from '@etta/di'
import { CarRentalCarDetailsStore } from '../stores/car-rental-car-details.store'
import { CarRentalSearchStore } from '../stores/car-rental-search.store'

@Action()
export class CarRentalsResetStoreActions {
  constructor(
    @Inject()
    private readonly carRentalCarDetails: CarRentalCarDetailsStore,
    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,
  ) {}

  handleDropCarDetails() {
    this.carRentalCarDetails.dropCarDetails()
  }

  handleDropCarRentalSearch() {
    this.carRentalSearchStore.dropCarRentalSearch()
  }
}
