import { useTranslation } from 'react-i18next'
import type { Address } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { formatNational } from '@fiji/utils/phone/format-national'
import { HotelDetailsMap } from './hotel-details-map'
import { useHotelAddress } from './use-hotel-address'
import { HotelAddressCardLayout } from './layout'

type Props = {
  hotelName?: string | null
  phone?: string | null
  address?: Address | null
  isTitleVisible?: boolean
}

const i18nBase = 'PostBooking.HotelDetails'

export function HotelAddressCard({ hotelName, phone, address, isTitleVisible = true }: Props) {
  const { addressRow, googleUrl, handleAddressClick } = useHotelAddress(address)
  const { t } = useTranslation()

  return (
    <HotelAddressCardLayout
      titleSlot={
        isTitleVisible && (
          <Block marginBottom={16}>
            <Text variant="title3" aria-label={t(i18nBase + '.LocationTitle')}>
              {t(i18nBase + '.LocationTitle')}
            </Text>
          </Block>
        )
      }
      mapSlot={
        <HotelDetailsMap
          data-tracking-id="hotel-trip-segment-map-component"
          hotelCoords={{
            lat: address?.geocode?.lat ?? 0,
            lng: address?.geocode?.long ?? 0,
          }}
          hotelName={hotelName || ''}
          handleAddressClick={handleAddressClick}
        />
      }
      addressSlot={
        address && (
          <Text
            variant="subHeadMedium"
            color="mainText"
            data-tracking-id="hotel-trip-segment-address-component">
            {addressRow}
          </Text>
        )
      }
      phoneSlot={
        phone && (
          <Text
            variant="linkNormal"
            color="primary1"
            data-tracking-id="hotel-trip-segment-phone-component">
            {formatNational(phone || '')}
          </Text>
        )
      }
      phone={phone}
      googleUrl={googleUrl}
      address={addressRow}
    />
  )
}
