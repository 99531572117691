import type { ValueType } from '@etta/ui/drop-down-list'
import { ListTypes, OptionViewTypes } from '@etta/ui/drop-down-list'
import type { ProfileOption } from '../../../types'

type Args = {
  fieldName: string
  options: ProfileOption<any>[]
  onFieldChange: (fieldName: any) => (fieldValue: any) => void
}

export function getDropDownProps({ fieldName, options, onFieldChange }: Args) {
  return {
    options: options.map((item) => ({ ...item, viewType: OptionViewTypes.OptionWithCheckboxIcon })),
    onChange: (value: ValueType[]) => onFieldChange(fieldName)(value[0]),
    type: ListTypes.Radio,
    withListBodyPadding: false,
    withListHeader: false,
    withThinLabel: true,
    withVisibleLabel: true,
  }
}
