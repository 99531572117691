import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import type { AppState } from '@fiji/store'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { FilterCategorySection } from './filter-category-layout'
import { HotelGroupFilter } from './hotel-group-filter'
import { SearchHotel } from './search-hotel'
import { SearchBrands } from './search-brands'
import { AmenitiesFilter } from './amenities-filter'
import { Section } from './filters-styled'
import { StarFilter } from './star-filter'
import { Footer } from './footer'
import { HotelDistance } from './hotel-distance'

const i18nBase = 'HotelFilters'
export const HotelFiltersContent = observer(function HotelFiltersContent() {
  const {
    hotelFiltersStore,
    hotelFiltersAction,
    hotelSearchResultsStore,
  } = useHotelSearchResultsContext()
  const { t } = useTranslation()
  const {
    hotels: { isStarRatingEnabled },
  } = useSelector((state: AppState) => state.displayConfiguration)

  const isBrandsVisible = Boolean(
    hotelSearchResultsStore.filterOptions.brands.brands.length ||
      hotelSearchResultsStore.filterOptions.brands.superBrands.length,
  )

  const isAmenitiesVisible = Boolean(
    hotelSearchResultsStore.filterOptions.amenities.amenities.length,
  )

  const isGroupsVisible = Boolean(hotelSearchResultsStore.filterOptions.groups.length)

  return (
    <>
      <Modal.Title withBorder>{t(i18nBase + '.Title')}</Modal.Title>
      <Modal.Body>
        <SearchHotel />

        {isStarRatingEnabled && (
          <FilterCategorySection
            label={t(i18nBase + '.Hotel.StarRating')}
            selectedNumber={hotelFiltersStore.selectedFilters.starRatings.length}
            isClearHidden={hotelFiltersStore.selectedFilters.starRatings.length < 1}
            onClear={hotelFiltersAction.clearStarRatingFilter}>
            <StarFilter isModal />
          </FilterCategorySection>
        )}

        <Section withBorder>
          <HotelDistance />
        </Section>

        <FilterCategorySection
          label={t(i18nBase + '.Hotel.HotelCategories')}
          isHidden={!isGroupsVisible}>
          <HotelGroupFilter isModal />
        </FilterCategorySection>

        <FilterCategorySection
          data-tracking-id="amenities-filters"
          label={t(i18nBase + '.Hotel.Amenities')}
          selectedNumber={hotelFiltersStore.selectedFilters.amenityIds.length}
          isClearHidden={hotelFiltersStore.selectedFilters.amenityIds.length < 1}
          onClear={hotelFiltersAction.clearAmenities}
          isHidden={!isAmenitiesVisible}>
          <AmenitiesFilter isModal />
        </FilterCategorySection>

        <FilterCategorySection
          label={t(i18nBase + '.Hotel.Brands')}
          selectedNumber={hotelFiltersStore.selectedFilters.brandIds.length}
          isClearHidden={hotelFiltersStore.selectedFilters.brandIds.length < 1}
          onClear={hotelFiltersAction.clearBrandIds}
          isHidden={!isBrandsVisible}>
          <SearchBrands isModal />
        </FilterCategorySection>
      </Modal.Body>
      <Modal.Footer>
        <Footer />
      </Modal.Footer>
    </>
  )
})
