import styled from 'styled-components'
import { body, subHeadMedium, subHeadStrong } from '@fiji/style'
import { Text } from '@etta/ui/text'

export const PageBlockWrapper = styled.div`
  margin-bottom: 42px;
`

export const TravelSupportContainer = styled.div`
  ${body};
  color: ${({ theme }) => theme.colors.mainText};
  background: ${({ theme }) => theme.colors.background};
  min-height: 256px;
  display: flex;
  flex-direction: column;
`

export const ContactsWrapper = styled(PageBlockWrapper)`
  width: 100%;
  padding: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`

export const ContactsContent = styled.div`
  padding: 0 16px;
  width: 992px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContactsText = styled.div`
  width: 535px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TitleText = styled(Text).attrs(() => ({
  variant: 'largeTitle',
  color: 'mainText',
}))`
  font-size: 3.4rem;
  line-height: 4.1rem;
`

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
`

export const BlockWrapper = styled.section`
  padding: 0 16px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 992px;

  & + & {
    margin-top: 20px;
  }
`

export const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  padding: 24px 32px;
  /* styles for texts containing html */
  & a,
  & a * {
    color: ${({ theme }) => theme.colors.primary} !important;
    ${subHeadStrong}
  }
  * {
    ${subHeadMedium}
  }
`

export const ContentContainerParagraph = styled.div`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`
