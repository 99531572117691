import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { GetUnusedTicketsAdapterResult } from '../infra/types'
import { UnusedTicketsErrors } from '../core/errors/unused-tickets.errors'
import type { UnusedTicketValueObject } from '../core/value-objects/unused-ticket.value-object'
import { UnusedTicketProvider } from './unused-tickets.data-provider/unused-ticket.data-provider'
import { UnusedTicketsMapper } from './mapper/unused-tickets.mapper'

@Adapter()
export class UnusedTicketAdapter {
  constructor(
    @Inject()
    private unusedTicketProvider: UnusedTicketProvider,
  ) {}

  async getUnusedTickets(
    forceUpdate: boolean = false,
  ): GetUnusedTicketsAdapterResult<UnusedTicketValueObject[]> {
    try {
      const { data, errors } = await this.unusedTicketProvider.getUnusedTickets(forceUpdate)

      if (errors) {
        return Result.Err(new UnusedTicketsErrors.GetUnusedTicketsErrorResponse(errors))
      }

      if (!data?.unusedTickets.unusedTickets) {
        return Result.Err(new UnusedTicketsErrors.GetUnusedTicketsErrorResponse('No data'))
      }

      return Result.Ok(UnusedTicketsMapper.toValueObject(data.unusedTickets.unusedTickets))
    } catch (e) {
      return Result.Err(new UnusedTicketsErrors.GetUnusedTicketsUnexpectedError(e))
    }
  }
}
