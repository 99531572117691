import { useCallback, useState } from 'react'
import type { ViewState } from '@etta/ui/view-state-modal'

export function useViewState() {
  const [viewState, setViewState] = useState<ViewState>('hidden')
  const onChangeViewState = useCallback((viewState: ViewState) => {
    setViewState(viewState)
  }, [])

  const onClose = useCallback(() => {
    setViewState('hidden')
  }, [])

  return {
    viewState,
    onChangeViewState,
    onClose,
  }
}
