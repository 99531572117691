import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { TripCarTraveler } from '@etta/components/trip-car-traveler/trip-car-traveler'
import { ChangeTripNotAllowedModal } from '@etta/components/change-trip-not-allowed-modal/change-trip-not-allowed-modal'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { SegmentType } from '@etta/core/enums'
import type { TripCarServiceLayoutProps } from './types'
import { useTripCarService, useActionButtons } from './hooks'
import { Headline } from './headline'
import { CarInfo } from './car-info'
import { ServiceCompany } from './service-company'
import { Price } from './price'
import { DetailsContainer } from './trip-car-service-styled'
import { PickupAndDropOff } from './pickup-and-dropoff'
import { DetailsAndRulesModal } from './details-and-rules'
import { CancelTripNotAllowedModal } from './cancel-trip-not-allowed-modal'

export function TripCarServiceContent({
  onClose,
  carService,
  tripName,
  tripType,
  travelerName,
}: TripCarServiceLayoutProps) {
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const { handleShare } = useActionButtons({ tripName, carService })
  const {
    isCancelled,
    isCompleted,
    isOnHold,
    headline,
    detailsModal,
    modifyTripModal,
    cancelTripModal,
    onViewSupport,
  } = useTripCarService({
    tripType,
    carService,
  })

  const headerBackgroundColor = getTripDetailsSegmentBackground({ tripStatus: tripType })

  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor={headerBackgroundColor}
            animation="opacity"
            leftSlot={<Header.BackButton color="mainText2" onClick={onClose} />}>
            <Header.Title title={tripName} align="left" color="mainText2" />
          </Header>
        </Modal.Header>
        <DetailsContainer color={headerBackgroundColor}>
          <Headline headline={headline} carService={carService} />
          <CarInfo car={carService.car} />
        </DetailsContainer>
        <div ref={anchorRef} />
        <Block paddingHorizontal={16} marginTop={-14}>
          <TripCarTraveler
            confirmationNumber={carService.confirmationNumber}
            recordLocator={carService.recordLocator}
            isCancelled={isCancelled}
            isCompleted={isCompleted}
            rentalVendorName={carService.serviceCompany?.name || ''}
            travelerName={travelerName || '-'}
          />
          <Block marginTop={16}>
            <PickupAndDropOff
              dropOff={carService.dropOff}
              pickUp={carService.pickUp}
              stops={carService.stops}
            />
          </Block>
          <Block marginTop={16}>
            <ServiceCompany serviceCompany={carService.serviceCompany} />
          </Block>
          <Block marginTop={16}>
            <Price price={carService.price} />
          </Block>
        </Block>
        <Block marginTop={28} marginBottom={32}>
          <ActionButtons
            isPostBooking
            type={SegmentType.CarService}
            onDetailsClick={detailsModal.handleOpen}
            onShareClick={handleShare}
            onCancelClick={cancelTripModal.handleOpen}
            onChangeClick={modifyTripModal.handleOpen}
            isChange={!isCancelled && !isOnHold}
            isCancel={!isCancelled && !isOnHold}
            isChangeAllowed={!isCancelled && !isOnHold}
          />
          <DetailsAndRulesModal
            isVisible={detailsModal.isOpen}
            onClose={detailsModal.handleClose}
            detailsRules={carService.detailsRules}
          />
        </Block>
      </Modal.Body>
      <ChangeTripNotAllowedModal
        isOpen={modifyTripModal.isOpen}
        onClose={modifyTripModal.handleClose}
        onViewSupport={onViewSupport}
      />
      <CancelTripNotAllowedModal
        isOpen={cancelTripModal.isOpen}
        onClose={cancelTripModal.handleClose}
        onViewSupport={onViewSupport}
      />
    </>
  )
}
