import { Trans, useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { ImageContainer } from '@etta/components/image-container/image-container'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import { RailSegmentEmission } from '@etta/components/rail-segment-emission/rail-segment-emission'
import { Tooltip } from '@etta/ui/tooltip'
import type { RailSearchSegmentDetailsProps } from '../../types'
import {
  DurationInfo,
  TotalDurationTimeContainer,
  ErrorContainer,
  Label,
} from '../../rail-search-segment-details-styled'
import {
  Container,
  DurationContainer,
  ImageColumn,
  Disruptions,
} from './rail-search-segment-details-desktop-styled'

const i18Base = 'RailDetails'

const TUBE_ICON_NAME = 'tubePWA'

export function RailSearchSegmentDetailsDesktop({
  fromLocation,
  startTime,
  toLocation,
  endTime,
  travelChanges,
  travelTime,
  isLimited,
  carriersInfo,
  iconNames,
  isFastest,
  isDisruptions,
  actualRailEmission,
  onViewDetailsClick,
  changeStations,
  isSoldOut,
}: RailSearchSegmentDetailsProps) {
  const { t } = useTranslation()
  const filteredIconNames = iconNames.filter((iconName) => iconName !== TUBE_ICON_NAME)
  const fastestText = t(i18Base + '.Labels.Fastest')
  const departingLabel = t(i18Base + '.AriaLabels.DepartingLabel', {
    name: fromLocation,
    time: startTime,
  })
  const arivingLabel = t(i18Base + '.AriaLabels.ArivingLabel', { name: toLocation, time: endTime })
  const durationLabel = t(i18Base + '.AriaLabels.TrainDurationLabel', {
    time: travelTime.trim(),
    changes: travelChanges,
    coNumber: actualRailEmission && actualRailEmission.kgOfActualEmission,
  })
  const carrierLabel = (name: string) =>
    t(i18Base + '.AriaLabels.CarrierLabel', {
      name,
    })

  return (
    <Container>
      <Block isFullWidth>
        <DurationContainer>
          <DurationInfo aria-label={departingLabel}>
            <Text
              aria-label={startTime}
              variant="bodyLarge"
              color="mainText"
              data-tracking-id="departure-time">
              {startTime}
            </Text>
            <Block marginTop={12}>
              <Text
                aria-label={t(i18Base + '.AriaLabels.DepartingTrainLabel', { name: fromLocation })}
                variant="footnoteMedium"
                color="bodyText"
                data-tracking-id="departure-location">
                {fromLocation}
              </Text>
            </Block>
          </DurationInfo>
          <Block>
            <Icon name="flightArrowPWA" size="normal" />
          </Block>
          <DurationInfo aria-label={arivingLabel}>
            <Text
              aria-label={endTime}
              variant="bodyLarge"
              color="mainText"
              data-tracking-id="arrival-time">
              {endTime}
            </Text>
            <Block marginTop={12}>
              <Text
                aria-label={t(i18Base + '.AriaLabels.ArrivingTrainLabel', { name: toLocation })}
                variant="footnoteMedium"
                color="bodyText"
                data-tracking-id="arrival-location">
                {toLocation}
              </Text>
            </Block>
          </DurationInfo>
        </DurationContainer>
        <TotalDurationTimeContainer aria-label={durationLabel}>
          {travelTime && <Block>{travelTime}</Block>}
          {travelChanges && !!changeStations?.length && (
            <Tooltip
              slot={
                <Text variant="subHeadMedium">
                  {changeStations?.map((station, index) => (
                    <Fragment key={index}>
                      <Trans i18nKey="RailDetails.ChangeAt">{{ station: station }}</Trans>
                      <br />
                    </Fragment>
                  ))}
                </Text>
              }
              place="bottom">
              <Block>{travelChanges}</Block>
            </Tooltip>
          )}
          {actualRailEmission && actualRailEmission.kgOfActualEmission && (
            <RailSegmentEmission {...actualRailEmission} />
          )}
          {isFastest && (
            <Block>
              <Label aria-label={fastestText} type="fastest">
                {fastestText}
              </Label>
            </Block>
          )}
        </TotalDurationTimeContainer>
        {!!filteredIconNames.length && (
          <Block isFlexBlock marginTop={5}>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18Base + '.NonRailLegs')}
            </Text>
            <Block isFlexBlock marginLeft={10}>
              {filteredIconNames.map((name, index) => (
                <Block key={index} isFlexBlock>
                  <Icon name={name} size={20} color="mainText" />
                  {filteredIconNames.length > 1 && index !== filteredIconNames.length - 1 && (
                    <Block paddingHorizontal={3}>
                      <Icon name="chevronRightPWA" size={12} color="mainText" />
                    </Block>
                  )}
                </Block>
              ))}
            </Block>
          </Block>
        )}

        {isLimited && <ErrorContainer>{t(i18Base + '.Errors.LimitedAvailability')}</ErrorContainer>}

        {isDisruptions && (
          <Disruptions marginTop={10}>
            <Icon name="warningCirclePWA" size={16} color="warning" />
            <Block marginLeft={4}>
              <Text variant="footnoteMedium" color="bodyText">
                {t(i18Base + '.Disruptions')}
              </Text>
            </Block>
          </Disruptions>
        )}
        {!isSoldOut && (
          <Block
            aria-label={t(i18Base + '.AriaLabels.TapToView', { label: t(i18Base + '.Details') })}
            marginTop={10}>
            <Link size="small" onClick={onViewDetailsClick}>
              {t(i18Base + '.Details')}
            </Link>
          </Block>
        )}
      </Block>
      <ImageColumn>
        {carriersInfo.map((carrier, index) => (
          <Tooltip
            isIndependentOfParentWidth
            place="bottom"
            key={carrier.name}
            slot={<Text variant="footnoteMedium">{carrier.name}</Text>}>
            <ImageContainer
              aria-label={carrierLabel(carrier.name)}
              key={index}
              url={carrier.logoUrl}
              errorIconName="railPWA"
            />
          </Tooltip>
        ))}
      </ImageColumn>
    </Container>
  )
}
