import type { CarRentalFragment, CarRentalSegment } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { isSameMonth } from '@fiji/utils/dates/is-same-month'

type Props = {
  segment?: CarRentalFragment | CarRentalSegment | null
}

export function useCarRentalCostLabelAndName({ segment }: Props) {
  if (!segment) {
    return
  }

  const cost =
    (segment as CarRentalFragment).totalBaseCost || (segment as CarRentalSegment)?.baseRate
  const carRentalClass =
    (segment as CarRentalFragment).carClass || (segment as CarRentalSegment)?.carDetails.class

  if (!segment.pickUpTime || !segment.dropOffTime) {
    return
  }

  const isHidingRightMonth = isSameMonth(
    new Date(deleteTimezone(segment.pickUpTime)),
    new Date(deleteTimezone(segment.dropOffTime)),
  )
  const rentalPeriod = `${dateFormat(
    new Date(deleteTimezone(segment.pickUpTime)),
    'MMM d',
  )} - ${dateFormat(
    new Date(deleteTimezone(segment.dropOffTime)),
    `${isHidingRightMonth ? '' : 'MMM '}d`,
  )}`
  const label = [carRentalClass, rentalPeriod].filter(Boolean).join(' • ')

  return {
    label,
    cost,
  }
}
