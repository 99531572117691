import moment from 'moment'

export enum TripReviewDateType {
  FullDate = 'ddd, MMM DD YYYY', // Wed, Sep 23 2020
  DateAndTime = 'DD MMM, ddd - hh:mm a', // 23 Sep 2020, Wed - 06:07 am
  DateAndDay = 'DD MMM, ddd', // 23 Sep, Wed
  DayAndDate = 'ddd, MMM DD', // Wed, Sep 23
  Time = 'h:mm A', // 6:07 AM'
  TimeWithoutSpace = 'h:mmA', // 6:07AM
  TimePWA = 'h:mm a', // 6:07 am'
  TimeMilitary = 'HH:mm', // 06:07'
  MonthAndDay = 'MMM DD', // Sep 23
  Month = 'MMM', // Sep
  Day = 'DD', // 23
  DayOfTheWeek = 'ddd', // Wed
  DateSlash = 'MM/DD/YY', // 09/23/20
  DateSlashFullYear = 'MM/DD/YYYY', // 09/23/2020
  DateSlashAndTime = 'MM/DD/YY h:mm A', // 09/23/20 6:07 AM
  DayMonthDateAndTime = 'ddd, MMM DD, h:mm a', // Wed, Sep 23, 6:07 am
  MonthAndDayAndTime = 'MMM DD, h:mm A', // Sep 23, 6:07 AM
  MonthAndDayAndTimeMilitary = 'MMM DD, HH:mm', // Sep 23, 6:07
  FullDateAndTime = 'ddd, MMM DD [at] h:mm A', // Sep 23, 6:07 AM,
  FullDateAndTimeMilitary = 'ddd, MMM DD [at] HH:mm', // Sep 23, 6:07,
  FullMonthDate = 'MMMM DD, YYYY', // September 1, 2021
  MonthAndDayAndYear = 'MMM DD, YYYY', // Sep 1, 2021
  RulesAndRestrictionsTimeDate = 'MMM DD, YYYY [at] h:mm A [PST]',
  RulesAndRestrictionsTimeDateMilitary = 'MMM DD, YYYY [at] HH:mm [PST]',
}

export function tripReviewFormatDate(
  type: TripReviewDateType,
  date?: string | null,
): string | undefined {
  if (date) {
    /*
      We replace dots "." because auto add "." for some languages(es, pt)
     */
    return moment(date).parseZone().format(type).replace(/\./g, '')
  }
}
