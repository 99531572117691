import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import { isWithinContainerView } from '@fiji/utils/is-within-container-view'
import { HEADER_FOOTER_HEIGHT, SPACING } from '@etta/ui/drop-down-list/constants'
import type { OptionType, ValueType, ListConfig, RenderMode } from '../types'
import { ListTypes } from '../types'
import { joinValuesByLabel } from './join-values-by-label'
import { findLabelsByValues } from './find-labels-by-values'

type Args<T = ValueType> = {
  isDisabled?: boolean
  label: string
  defaultLabel?: string
  value?: T[]
  options: OptionType<T>[]
  onChange: (value: T[]) => void
  type: ListTypes
  isFixedPositioned?: boolean
  scrollParentRef?: React.RefObject<HTMLElement>
  shouldCloseOnScroll?: boolean
  renderMode: RenderMode
  customAriaLabel?: string
  withNoneOption?: boolean
}

export function useDropDownList<T extends ValueType>({
  isDisabled,
  label,
  value,
  options,
  defaultLabel,
  onChange,
  type,
  isFixedPositioned,
  scrollParentRef,
  shouldCloseOnScroll,
  renderMode,
  customAriaLabel,
  withNoneOption,
}: Args<T>) {
  const dropdownWrapperRef = useRef<HTMLDivElement | null>(null)
  const listWrapperRef = useRef<HTMLDivElement | null>(null)
  const [listConfig, setListConfig] = useState<ListConfig | null>(null)
  const listToggle = useTogglePopup()
  const { t } = useTranslation()
  const [overflowContentHeight, setOverflowContentHeight] = useState<number>(0)

  function getListConfig() {
    if (!dropdownWrapperRef.current) {
      return
    }

    const { x, width, bottom } = dropdownWrapperRef.current.getBoundingClientRect()

    setListConfig({
      x,
      y: bottom,
      width,
      excludeHeight: bottom,
    })
  }

  function handleOpenFixedPositioned() {
    if (!dropdownWrapperRef.current) {
      return
    }

    if (!isWithinContainerView(scrollParentRef?.current, dropdownWrapperRef.current)) {
      dropdownWrapperRef.current.scrollIntoView()
    }

    getListConfig()
  }

  function handleOpenList() {
    if (isDisabled) {
      return
    }
    if (isFixedPositioned) {
      handleOpenFixedPositioned()
    }
    getListConfig()
    listToggle.handleOpen()
  }

  const displayOptions = useMemo(() => {
    if (withNoneOption && options.some((option) => typeof option.value === 'string')) {
      return [
        {
          label: t(`DropdownList.None`),
          value: '' as T,
          viewType: options.at(0)?.viewType,
        },
        ...options,
      ]
    }
    return options
  }, [withNoneOption, options, t])

  const selectedLabel = useMemo(() => {
    if (renderMode === 'simple') {
      if (type === ListTypes.Select && value?.length === options?.length) {
        return defaultLabel || ''
      }

      return joinValuesByLabel({ values: value, options }) || defaultLabel || ''
    }

    return ''
  }, [defaultLabel, value, options, type, renderMode])

  const selectedComponent = useMemo(() => {
    if (renderMode === 'component') {
      const selected = findLabelsByValues({ values: value, options })
      return selected?.length ? selected : null
    }

    return null
  }, [value, options, renderMode])

  const ariaLabel = useMemo(() => {
    return (
      customAriaLabel ||
      t(`Accessibility.LabelAndValueButton`, { label, value: selectedLabel || '-' })
    )
  }, [customAriaLabel, label, selectedLabel, t])

  const handleChange = useCallback(
    (newValue: T[]) => {
      listToggle.handleClose()
      if (!checkIsEqual(value, newValue)) {
        onChange(newValue)
      }
    },
    [listToggle, onChange, value],
  )

  useEffect(() => {
    if (!listToggle.isOpen) {
      return
    }
    if (!shouldCloseOnScroll) {
      return
    }

    const handleCloseOnScroll = (e: Event) => {
      if (listWrapperRef.current && !listWrapperRef.current.contains(e.target as Node)) {
        listToggle.handleClose()
      }
    }

    window.addEventListener('scroll', handleCloseOnScroll, true)
    return () => {
      window.removeEventListener('scroll', handleCloseOnScroll, true)
    }
  }, [listToggle, shouldCloseOnScroll])

  useEffect(() => {
    const handleOpenListUpward = (
      distanceFromTop: number,
      listWrapperHeight: number,
      dropDownWrapperHeight: number,
    ) => {
      if (distanceFromTop < listWrapperHeight) {
        // Open upward & adjusting dropdown list height
        if (listConfig) {
          setListConfig({
            ...listConfig,
            height: distanceFromTop - HEADER_FOOTER_HEIGHT - dropDownWrapperHeight,
          })
        }
        setOverflowContentHeight(distanceFromTop - HEADER_FOOTER_HEIGHT + SPACING)
      } else {
        // Open upward but not adjusting height
        setOverflowContentHeight(listWrapperHeight + SPACING)
      }
    }

    const handleOpenListDownward = (
      distanceFromBottom: number,
      listWrapperHeight: number,
      dropDownWrapperHeight: number,
    ) => {
      if (distanceFromBottom < listWrapperHeight) {
        // Open downward & adjusting dropdown list height
        if (listConfig) {
          setListConfig({
            ...listConfig,
            height: distanceFromBottom - HEADER_FOOTER_HEIGHT - dropDownWrapperHeight,
          })
        }
      }
      setOverflowContentHeight(0)
    }

    if (listWrapperRef.current && dropdownWrapperRef.current && listToggle.isOpen) {
      const { height: listWrapperHeight } = listWrapperRef?.current?.getBoundingClientRect()
      const {
        top: distanceFromTop,
        bottom,
        height: dropDownWrapperHeight,
      } = dropdownWrapperRef?.current?.getBoundingClientRect()
      const distanceFromBottom = window.innerHeight - bottom

      if (distanceFromTop >= distanceFromBottom) {
        handleOpenListUpward(distanceFromTop, listWrapperHeight, dropDownWrapperHeight)
      } else {
        handleOpenListDownward(distanceFromBottom, listWrapperHeight, dropDownWrapperHeight)
      }
    }
  }, [listToggle.isOpen, listConfig])

  return {
    listToggle,
    handleOpenList,
    handleChange,
    selectedLabel,
    selectedComponent,
    dropdownWrapperRef,
    listConfig,
    listWrapperRef,
    ariaLabel,
    overflowContentHeight,
    displayOptions,
  }
}
