import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { IconButton } from '@etta/ui/icon-button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import {
  ModalHeaderWrapper,
  HeaderContainer,
  SeparatorLine,
  ModalBodyContainer,
  Content,
  Message,
} from './dynamic-site-messages-lightbox-modal-desktop-styled'

const i18nBase = 'DynamicSiteMessage.Lightbox'

export const DynamicSiteMessagesboxModalDesktop = observer(
  function DynamicSiteMessagesboxModalDesktop({ messages, isVisible, onClose }: LayoutProps) {
    const { t } = useTranslation()

    return (
      <Modal isVisible={isVisible} handleModalVisibility={onClose}>
        <ModalHeaderWrapper>
          <HeaderContainer>
            <Text aria-label={messages[0]?.header} variant="title3" color="mainText">
              {messages[0]?.header}
            </Text>
            <IconButton
              aria-label={t(`${i18nBase}.AllyCloseModal`)}
              icon="closeFilledPWA"
              size="medium"
              color="bodyText"
              onClick={onClose}
            />
          </HeaderContainer>
          <SeparatorLine />
        </ModalHeaderWrapper>
        <Block marginBottom={36}>
          <Modal.Body>
            <ModalBodyContainer>
              {messages.map(({ id, text, hasDesktopBannerImage }) => (
                <Content key={id}>
                  <Message
                    aria-label={text}
                    hasDesktopBannerImage={hasDesktopBannerImage}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Content>
              ))}
            </ModalBodyContainer>
          </Modal.Body>
        </Block>
      </Modal>
    )
  },
)
