import type { Rate } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import { usePrice } from './use-price'

type Props = {
  originAirport: string
  destinationAirport: string
  rate?: Rate | null
  taxes?: Rate | null
  total?: Rate | null
  transactionFees?: Rate | null
  unusedTicketRate?: Rate | null
  totalWithUnusedTicket?: Rate | null
}

export function Price({
  originAirport,
  destinationAirport,
  rate,
  taxes,
  total,
  transactionFees,
  unusedTicketRate,
  totalWithUnusedTicket,
}: Props) {
  const { priceDetailsList, formattedTotal } = usePrice({
    originAirport,
    destinationAirport,
    rate,
    taxes,
    total,
    transactionFees,
    unusedTicketRate,
    totalWithUnusedTicket,
  })

  return (
    <PriceDetails
      priceDetailsList={priceDetailsList}
      totalCost={formattedTotal}
      type="price-breakdown"
    />
  )
}
