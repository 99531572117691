import { useBackSearch } from '@fiji/hooks/search-queries/use-back-search'
import { RailSearchForm } from '@etta/components/rail-search-form'
import { RailSearchPageLayout } from './layout/rail-search-page-layout'

export const RailSearchPage = () => {
  const { onBack } = useBackSearch()

  return (
    <RailSearchPageLayout onBack={onBack}>
      <RailSearchForm />
    </RailSearchPageLayout>
  )
}
