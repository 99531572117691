import { v4 as uuid } from 'uuid'
import { Inject, Service } from '@etta/di'
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { ROUTES } from '@fiji/routes'
import { PostBookingAction } from '@fiji/enums'
import { dateToTime, getDateWithoutTimezoneOffset, deleteTimezone } from '@fiji/utils/dates'
import type { AddressValueObject } from '@etta/core/value-objects'
import { HistoryService } from '@etta/interface/services/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { AirSearchQueryStore } from '@etta/modules/air-search/interface/stores/air-search-query.store'
import type { TripMetadataValueObject } from '@etta/modules/review-trip/core/value-objects/trip-metadata.value-object'
import { AirSearchDefaultValuesStore } from '@etta/modules/air-search/interface/stores/air-search-default-values.store'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CarRentalStore } from '@etta/modules/car-rental-search/interface/stores/car-rental.store'
import { DEFAULT_HOTEL_FILTERS } from '@etta/modules/hotel-search/interface/stores/constants'
import { HotelQueryStore } from '@etta/modules/hotel-search/interface/stores/hotel-search-query.store'

@Service()
export class BookTripAgainSearchService {
  constructor(
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly historyStore: HistoryStore,
    @Inject() private readonly airSearchQueryStore: AirSearchQueryStore,
    @Inject() private readonly airSearchDefaultValuesStore: AirSearchDefaultValuesStore,
    @Inject() private readonly hotelSearchQueryStore: HotelQueryStore,
    @Inject() private readonly carRentalStore: CarRentalStore,
    @Inject() private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  handleSearchFlight(
    metadata: NonNullable<TripMetadataValueObject['flightSearch']>,
    itineraryId: string,
  ) {
    if (metadata.length > 2 || !metadata.at(0)?.serviceClass) {
      return
    }

    const params = {
      flightType: metadata.length < 2 ? FlightType.OneWay : FlightType.Round,
      flights: this.mapToFlights(metadata),
      itineraryId,
    }

    const nextPath = this.airSearchQueryStore.airSearchQueryParams.getNextPath({
      route: ROUTES.air.results({ legNumber: 0 }),
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.push(nextPath)
  }

  handleSearchHotel(
    metadata: NonNullable<TripMetadataValueObject['hotelSearch']>,
    startDate: string,
    endDate: string,
    itineraryId: string,
    bookingId?: string,
  ) {
    const checkInDate = getDateWithoutTimezoneOffset(new Date(startDate))
    const checkOutDate = getDateWithoutTimezoneOffset(new Date(endDate))

    const hotelSearchDistance = this.displayConfigurationStore.hotelSearchRadius.default

    const address = metadata.address && this.resolveHotelAddress(metadata.address)

    const params = {
      itineraryId,
      checkInDate,
      checkOutDate,
      location: address,
      bookingId,
      postBookingAction: PostBookingAction.Add,
      hotelFilters: hotelSearchDistance
        ? {
            ...DEFAULT_HOTEL_FILTERS,
            distance: hotelSearchDistance,
            initialDistance: hotelSearchDistance,
          }
        : DEFAULT_HOTEL_FILTERS,
    }

    const nextPath = this.hotelSearchQueryStore.hotelSearchQueryParams.getNextPath({
      route: ROUTES.hotel.results,
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.push(nextPath)
  }

  handleSearchCarRental(
    metadata: NonNullable<TripMetadataValueObject['carRentalSearch']>,
    startDate: string,
    endDate: string,
    itineraryId: string,
    bookingId?: string,
  ) {
    const pickUpDate = this.toNewDateTime(metadata.pickUp.dateTime, startDate)
    const dropOffDate = this.toNewDateTime(metadata.dropOff.dateTime, endDate)
    const pickUpLocationId = metadata.pickUp.locationId || undefined
    const dropOffLocationId = metadata.dropOff.locationId || undefined
    const pickUpPlace = {
      placeId: '',
      name: metadata.pickUp.address ? metadata.pickUp.address.city : '',
    }
    const dropOffPlace = {
      placeId: '',
      name: metadata.dropOff.address ? metadata.dropOff.address.city : '',
    }

    const params = {
      itineraryId,
      bookingId,
      pickUpDate,
      pickUpAddress: undefined,
      pickUpTime: dateToTime(pickUpDate),
      pickUpPlace,
      dropOffDate,
      dropOffAddress: undefined,
      dropOffTime: dateToTime(dropOffDate),
      dropOffPlace,
      pickUpLocationId: pickUpLocationId,
      dropOffLocationId: dropOffLocationId,
      postBookingAction: PostBookingAction.Add,
    }

    const nextPath = this.carRentalStore.carSearchQueryParams.getNextPath({
      route: ROUTES.carRental.results,
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.push(nextPath)
  }

  private toNewDateTime(oldDateTimeStr: string, newDate: string): Date {
    const dateFormat = 'yyyy-MM-dd'

    if (newDate.length !== dateFormat.length) {
      return new Date(newDate)
    }

    const time = oldDateTimeStr.slice(dateFormat.length)

    return new Date(deleteTimezone(newDate + time))
  }

  private mapToFlights(metadata: NonNullable<TripMetadataValueObject['flightSearch']>): Flight[] {
    switch (metadata.length) {
      case 1: {
        return [
          {
            id: -1,
            originPlace: {
              name: metadata[0].originAirportCode,
              airportCode: metadata[0].originAirportCode,
              placeId: uuid(),
            },
            destinationPlace: {
              name: metadata[0].destinationAirportCode,
              airportCode: metadata[0].destinationAirportCode,
              placeId: uuid(),
            },
            departureDate: new Date(metadata[0].departureDate),
            cabinClass: { id: metadata[0].serviceClass },
            timeRange: this.airSearchDefaultValuesStore.initialTimeRange,
            returnTimeRange: this.airSearchDefaultValuesStore.initialReturnTimeRange,
          },
          {
            id: -2,
            cabinClass: { id: metadata[0].serviceClass },
            timeRange: this.airSearchDefaultValuesStore.initialTimeRange,
            returnTimeRange: this.airSearchDefaultValuesStore.initialReturnTimeRange,
          },
        ]
      }
      case 2: {
        return [
          {
            id: -1,
            originPlace: {
              name: metadata[0].originAirportCode,
              airportCode: metadata[0].originAirportCode,
              placeId: uuid(),
            },
            destinationPlace: {
              name: metadata[0].destinationAirportCode,
              airportCode: metadata[0].destinationAirportCode,
              placeId: uuid(),
            },
            departureDate: new Date(metadata[0].departureDate),
            cabinClass: { id: metadata[0].serviceClass },
            timeRange: this.airSearchDefaultValuesStore.initialTimeRange,
            returnDate: new Date(metadata[1].departureDate),
            returnTimeRange: this.airSearchDefaultValuesStore.initialReturnTimeRange,
          },
          {
            id: -2,
            originPlace: {
              name: metadata[1].originAirportCode,
              airportCode: metadata[1].originAirportCode,
              placeId: uuid(),
            },
            destinationPlace: {
              name: metadata[1].destinationAirportCode,
              airportCode: metadata[1].destinationAirportCode,
              placeId: uuid(),
            },
            departureDate: new Date(metadata[1].departureDate),
            cabinClass: { id: metadata[1].serviceClass },
            timeRange: this.airSearchDefaultValuesStore.initialTimeRange,
            returnTimeRange: this.airSearchDefaultValuesStore.initialReturnTimeRange,
          },
        ]
      }
      default:
        return []
    }
  }

  private resolveHotelAddress(address: AddressValueObject) {
    return {
      placeId: '',
      name: [address.city, address.stateCode ?? address.countryCode].filter(Boolean).join(', '),
      latitude: address.geocode?.lat,
      longitude: address.geocode?.long,
    }
  }
}
