import type { MemberShipPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/memberships-persist.value-object'
import type { CheckoutItineraryInputValueObject } from '@etta/modules/booking/core/value-objects/checkout-itinerary.input.value-object'

export class MembershipMapper {
  static toMembershipInput(
    memberships?: MemberShipPersistValueObject,
  ): CheckoutItineraryInputValueObject['memberships'] {
    return {
      air: memberships?.air?.map((item) => ({ code: item.carrierCode, number: item.number })),
      carRental: memberships?.carRental?.map((item) => ({
        code: item.vendorCode,
        number: item.number,
      })),
      hotel: memberships?.hotel?.map((item) => ({
        code: item.vendorCode,
        number: item.number,
        appliesToChains: item.appliesToChain,
      })),
    }
  }
}
