import type { UnusedTicket } from '@fiji/graphql/types'

export type Props = {
  unusedTicket: UnusedTicket
}

export enum UnusedTicketStatus {
  AVAILABLE = 'AVAILABLE',
  PENDING_REFUND = 'PENDING_REFUND',
  EXPIRED = 'EXPIRED',
  LOCKED = 'LOCKED',
}
