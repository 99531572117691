import { useEffect, useRef } from 'react'

type Props = {
  messageId: string
  anchorId: string | null
  handleSetAnchor: (node: HTMLDivElement | null) => void
}

export function Anchor({ messageId, anchorId, handleSetAnchor }: Props) {
  const elementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!elementRef.current || !anchorId) {
      return
    }
    if (anchorId === messageId) {
      handleSetAnchor(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        elementRef.current = null
      }
    }
  }, [messageId, anchorId, handleSetAnchor])

  return <div ref={elementRef} />
}
