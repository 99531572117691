import type {
  MobilityMultiModalLocation,
  MobilityMultiModalRoutes,
  SearchMobilityMultiModalQuery,
} from '@fiji/graphql/hooks'
import type {
  MultiModalRouteList,
  MultiModalRouteValueObject,
} from '../../core/value-objects/multi-modal-route.value-object'
import type { MultiModalLocationValueObject } from '../../core/value-objects/multi-modal-location.value-object'

export class MultiModalResponseMapper {
  static mapToMultiModalRouteValueObject(
    route: SearchMobilityMultiModalQuery['searchMobilityMultiModal'],
  ): MultiModalRouteList {
    if (route.__typename !== 'MobilityMultiModalSearchResponse') {
      return []
    }
    return route.routes.map(MultiModalResponseMapper.mapRouteObject)
  }

  static mapRouteObject(route: MobilityMultiModalRoutes): MultiModalRouteValueObject {
    return {
      routeType: route.routeType,
      routeName: route.routeName,
      routeDescription: route.routeDescription,
      routeDistance: route.routeDistance,
      estimatedDuration: route.estimatedDuration,
      startLocation: MultiModalResponseMapper.mapLocation(route.startLocation),
      endLocation: MultiModalResponseMapper.mapLocation(route.endLocation),
      estimatePrice: route.estimatePrice,
    }
  }

  static mapLocation(route: MobilityMultiModalLocation): MultiModalLocationValueObject {
    return {
      address: (route.address ??= undefined),
      geocode: (route.geocode ??= undefined),
      placeId: (route.placeId ??= undefined),
    }
  }
}
