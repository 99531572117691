/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import * as R from 'ramda'
import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import { filterUniqueValues } from '@fiji/utils/arrays'
import type { CarrierInfo } from '../types'
import { getNameAndTrainNumber, getCarrierName, mapTwoNameAndTrainNumber } from '.'

export function useHeaderTitle(
  railwayCarriersInfo: CarrierInfo[],
  segments: SearchRailLegSubSegmentEntity[],
): string {
  const railImages = railwayCarriersInfo.map((carrier) => carrier.logoUrl)
  const isMultipleCarriers = useMemo(() => {
    if (railImages && railImages.length > 1) {
      return filterUniqueValues(railImages as string[]).length > 1
    }
  }, railImages)

  return useMemo(() => {
    if (isMultipleCarriers) {
      const CarriersCount = railImages.length
      const nameAndFlightNumber = mapTwoNameAndTrainNumber(segments)
      const isTwoSegmentSameCarriers = nameAndFlightNumber[0][0] === nameAndFlightNumber[1][0]
      // MVP is not including multiply cities. We can stay with that implementation for now
      if (isTwoSegmentSameCarriers) {
        const firstAirline = getCarrierName(segments[0])
        const flightNumbers = R.pipe(
          mapTwoNameAndTrainNumber,
          R.map(([, flightNumber]) => flightNumber),
          R.join(', '),
        )(segments)
        const firstAirlineTitle = `${firstAirline} ${flightNumbers}`
        const secondAirlineTitle = R.pipe(getNameAndTrainNumber, R.join(' '))(segments[2])
        return (
          firstAirlineTitle +
          ', ' +
          secondAirlineTitle +
          (CarriersCount > 2 ? ` +${CarriersCount - 2}` : '')
        )
      }
      const titles = R.pipe(R.map(R.join(' ')), R.join(', '))(nameAndFlightNumber)
      return titles + (CarriersCount > 2 ? ` +${CarriersCount - 2}` : '')
    }
    const name = getCarrierName(segments[0])
    const flightNumbers = R.pipe(
      R.map(getNameAndTrainNumber),
      R.map(([, flightNumber]) => flightNumber),
      R.join(', '),
    )(segments)
    return `${name} ${flightNumbers}`
  }, [isMultipleCarriers, segments])
}
