import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PaginationWrapper, Ellipsis, ArrowButton } from './pagination-styled'
import { PaginationButton } from './pagination-button'

type Props = {
  value: number
  total: number
  onChange: (nextValue: number) => void
}

export function Pagination({ total, value, onChange }: Props) {
  const usedValue = useMemo(() => {
    if (value < 1) {
      return 1
    }
    if (value > total) {
      return total
    }
    return value
  }, [value, total])
  const handleChange = useCallback(
    (nextValue: number) => {
      if (nextValue === value) {
        return
      }
      if (nextValue < 0) {
        return
      }
      if (nextValue > total) {
        return
      }

      onChange(nextValue)
    },
    [onChange, total, value],
  )

  const showFirstButtons = usedValue <= 4
  const showLastButtons = total - usedValue <= 3
  const { t } = useTranslation()
  const i18nBase = `Pagination`

  if (total <= 7) {
    return (
      <PaginationWrapper>
        <ArrowButton
          aria-label={t(`${i18nBase}.PreviousPage`)}
          isDisabled={usedValue === 1}
          onClick={() => handleChange(usedValue - 1)}
          icon="chevronLeftPWA"
          size="normal"
        />
        {[...Array(total)].map((_, index) => (
          <PaginationButton
            page={index + 1}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
        ))}
        <ArrowButton
          aria-label={t(`${i18nBase}.NextPage`)}
          onClick={() => handleChange(usedValue + 1)}
          isDisabled={usedValue === total}
          icon="chevronRightPWA"
          size="normal"
        />
      </PaginationWrapper>
    )
  }

  return (
    <PaginationWrapper>
      <ArrowButton
        aria-label={t(`${i18nBase}.PreviousPage`)}
        isDisabled={usedValue === 1}
        onClick={() => handleChange(usedValue - 1)}
        icon="chevronLeftPWA"
        size="normal"
      />

      <PaginationButton
        page={1}
        selectedPage={usedValue}
        total={total}
        handleChange={handleChange}
      />

      {showFirstButtons ? (
        <PaginationButton
          page={2}
          selectedPage={usedValue}
          total={total}
          handleChange={handleChange}
        />
      ) : (
        <Ellipsis>...</Ellipsis>
      )}

      {showFirstButtons && (
        <>
          <PaginationButton
            page={3}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
          <PaginationButton
            page={4}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
          <PaginationButton
            page={5}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
        </>
      )}

      {!showFirstButtons && !showLastButtons && (
        <>
          <PaginationButton
            page={usedValue - 1}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
          <PaginationButton
            page={usedValue}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
          <PaginationButton
            page={usedValue + 1}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
        </>
      )}

      {showLastButtons && (
        <>
          <PaginationButton
            page={total - 4}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
          <PaginationButton
            page={total - 3}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
          <PaginationButton
            page={total - 2}
            selectedPage={usedValue}
            total={total}
            handleChange={handleChange}
          />
        </>
      )}

      {showLastButtons ? (
        <PaginationButton
          page={total - 1}
          selectedPage={usedValue}
          total={total}
          handleChange={handleChange}
        />
      ) : (
        <Ellipsis>...</Ellipsis>
      )}

      <PaginationButton
        page={total}
        selectedPage={usedValue}
        total={total}
        handleChange={handleChange}
      />

      <ArrowButton
        aria-label={t(`${i18nBase}.NextPage`)}
        onClick={() => handleChange(usedValue + 1)}
        isDisabled={usedValue === total}
        icon="chevronRightPWA"
        size="normal"
      />
    </PaginationWrapper>
  )
}
