import { useState } from 'react'

export function useThumbnailImage() {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const [hasImageError, setHasImageError] = useState(false)
  const updateImageLoaded = () => {
    setIsImageLoaded(true)
  }
  const updateImageError = () => {
    setHasImageError(true)
  }

  const isReadyForRender = isImageLoaded && !hasImageError

  return {
    isImageLoaded,
    hasImageError,
    isReadyForRender,
    updateImageLoaded,
    updateImageError,
  }
}
