import { useSelector } from 'react-redux'
import { useSearchParams } from '@fiji/hooks/use-search-params/use-search-params'
import type { AppState } from '@fiji/store'
import { useGetHotelRoomDetailsQuery } from '@fiji/graphql/hooks'
import { buildMetadataLogs } from '@fiji/utils/build-metadata-logs'
import type { SearchId } from '@fiji/store/search-id'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'

type Props = {
  searchId: SearchId
  hotelId: string
  roomId: string
  checkIn: string
  checkOut: string
  processId?: string
}

export function useRoomDetails({ hotelId, roomId, checkIn, checkOut, processId }: Props) {
  const { hotelSearchResultsStore } = useHotelSearchResultsContext()
  const { searchParams } = useSearchParams()
  const { isPhoenixHotelSearchEnabled } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const { loading, data, error, refetch } = useGetHotelRoomDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        hotelId,
        roomId,
        hotelResultId: hotelSearchResultsStore.searchId,
        checkIn,
        checkOut,
        processId: processId ? parseInt(processId) : undefined,
      },
      logsMetadata: buildMetadataLogs({
        isPhoenixHotelSearchEnabled,
        itineraryId: searchParams.itineraryId,
      }),
    },
  })

  const details = data?.hotelRoomDetails.rateDetails

  return {
    isLoading: loading,
    details,
    error,
    refetch: () => refetch(),
  }
}
