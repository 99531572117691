export default function CalendarIcon() {
  return (
    <svg viewBox="0 0 44 44" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5238 6H14V8.60464L15.5238 9.72092L17.0476 8.60464V6H15.5238ZM18.1905 7.11627H25.8095V9.34882L28.4761 11.2093L31.1429 9.34882V7.11627H38V34.3162C38 36.3626 36.307 38 34.1905 38H9.80952C7.69311 38 6 36.3626 6 34.3162V7.11627H12.8571V9.34882L15.5238 11.2093L18.1905 9.34882V7.11627ZM34.5713 35.0233C34.9524 35.0233 34.9524 34.6511 34.9524 34.2788V15.3023H9.04762V34.3162C9.04762 34.7256 9.04762 34.837 9.42857 35.0233H34.5713ZM26.9523 6H28.4761H29.9999V8.60464L28.4761 9.72092L26.9523 8.60464V6ZM19.8667 21.5284L21.9999 19.3951L24.1333 21.5284L22.0001 23.6617L19.8667 21.5284ZM12.4 21.5284L14.5333 19.3951L16.6667 21.5284L14.5333 23.6618L12.4 21.5284ZM29.4665 19.3951L27.3333 21.5284L29.4668 23.6617L31.6 21.5284L29.4665 19.3951ZM12.4 28.9704L14.5333 26.837L16.6667 28.9704L14.5333 31.1036L12.4 28.9704ZM21.9999 26.837L19.8667 28.9704L22.0001 31.1036L24.1333 28.9704L21.9999 26.837ZM27.3333 28.9704L29.4665 26.837L31.6 28.9704L29.4668 31.1036L27.3333 28.9704Z"
        fill="currentColor"
      />
    </svg>
  )
}
