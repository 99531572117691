import { default as Marker } from './confirmation-pin.svg'
import { Container } from './mobility-pickup-confirmation-marker-styled'

type Prop = {
  lat: number
  lng: number
}

export function MobilityPickupConfirmationMarker({ lat, lng }: Prop) {
  return (
    // @ts-ignore
    <Container {...lat} {...lng}>
      <Marker />
    </Container>
  )
}
