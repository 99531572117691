import type { ReactElement } from 'react'
import type { Moment } from 'moment'
import type { Coords } from '@etta/modules/etta-map/core/coords'
import type {
  Address,
  AddressInput,
  Amenity,
  CommonTravelPreference,
  Distance,
  FareTierFilter,
  FlightFare,
  Gender,
  Geocode,
  Maybe,
  OnTheFlyContentAssessment,
  OutOfPolicyData,
  OutOfPolicyEnum,
  PreferenceOptions,
  PrivateRate,
  Room,
  Scalars,
  SegmentType,
  StopsFilter,
  UserSuffix,
  UserTitle,
  PlaceAutocompleteType,
  SeatmapCabinClass,
  TripDetailsFlightLegSegment,
  Hotel,
  Phone,
  TrainCompanyFilter,
  StationFilterStation,
  PersonalContactAddress,
  Passport,
  CalculateEmissionsBatchResultEquivalence,
  CalculateEmissionsResultHotelCertification,
  SeatMapRow,
  SearchFlightLeg,
  FlightSearchTimeRange,
  CheckoutInfoTravelerRedressNumber,
  CheckoutInfoTravelerKnownTravelerNumber,
} from '@fiji/graphql/types'
import type { IconNames } from '@etta/ui/icon'
import type {
  PurchaseErrorList,
  RecommendedItemSlot,
  TimeRangeDirection,
  TimeRangeOption,
} from '@fiji/enums'
import type { AdaptiveFlight } from '@fiji/hooks/use-adaptive-flight/types'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import type { CalculateEmissionsEquivalenceValueObject } from '@etta/core/value-objects/calculate-emissions-equivalence.object-value'
import type { SegmentSeatNumber } from '@etta/modules/seat-map'

export type { HotelFiltersType } from '@fiji/hooks/search-queries/use-hotel-search-query/types'

// WARN: DO NOT EXPORT GRAPHQL TYPES FROM CLIENT TYPES
// export * from '@fiji/graphql/types'

export type GeoLocation = {
  name: string
  lat: number
  lng: number
  radius?: number
}

export enum SearchBy {
  Address = 'Address',
  Airport = 'Airport',
  Geocode = 'Geocode',
  Station = 'Station',
}

export type HotelMarker = {
  geocode: Geocode
  searchResultListIdx: number
  hotelId: string
  logoPhotoUrl: string
  chainCode: string
  privateRate: Maybe<PrivateRate>
  isEcoFriendly: boolean
}

export type ReduxHotel = {
  id: string
  url: string
}

export type SelectItem = {
  value: any
  name: string
  count?: number
}

export type NestedSelectItem = SelectItem & {
  items: SelectItem[]
}

export type SelectItemProps = {
  selected: SelectItem[]
  items: SelectItem[]
  action: 'add' | 'remove'
}

export type GeoCodeLocation = {
  latitude: number
  longitude: number
  query: string
}

export type GalleryPhoto = {
  photo: string
  number: number
}

export type SeatMapPalette = {
  white: string
  charcoal1: string
  charcoal2: string
  charcoal3: string
  charcoal4: string
  charcoal5: string
  charcoal6: string
  charcoal61: string
  charcoal7: string
  charcoal8: string
  charcoal9: string
  charcoal10: string
  charcoal11: string
  charcoal12: string
  tangerine1: string
  tangerine2: string
  tangerine3: string
  tangerine4: string
  gradiant1: string
  gradiant2: string
  red1: string
  dark1: string
  dark2: string
  dark3: string
}

export type ColorPalette = {
  primary: string
  primary1: string
  primary2: string
  primary3: string
  secondary: string
  mainText: string
  mainText1: string
  mainText2: string
  bodyText: string
  bodyText1: string
  bodyText2: string
  borderDark: string
  borderLight: string
  background: string
  background1: string
  background2: string
  background3: string
  background4: string
  white: string
  error: string
  error1: string
  warning: string
  success: string
  segmentBus: string
  duskBlue: string
  glacierBlue: string
  plum: string
  seatMap: SeatMapPalette
  transparent: string
  ecoCheck: string
  ecoCheck1: string
  ecoCheck2: string
  thulianPink: string
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface Theme {
  name: string
  primaryColor: string
  secondaryColor: string
  dateRangeColor: string
  dateRangeHoverColor: string
  dateRangeSelectedColor: string
  btnBackground: string
  btnBackgroundHover: string
  inputLabelColor: string
  inputColor: string
  inputBorderColor: string
  inputBackgroundColor: string
  helperText: string
  fontFamily?: string
  colors: ColorPalette
}

export type ShortlistHotel = {
  id: Scalars['ID']
  name: Scalars['String']
  address?: Address
  distance: Distance
  amenities: Array<Amenity>
  exclusiveAmenities?: Array<Amenity>
  starRating: Scalars['Int']
  rooms?: Maybe<Array<Room>>
  medianReviewRating?: Maybe<Scalars['Float']>
  logoPhotoUrl?: Maybe<Scalars['String']>
  chainCode: Scalars['String']
}

export type MapSnapshot = {
  center: Geocode
  zoom?: number
  region?: any
}

export type MapData = {
  lat: number
  lng: number
  radius: number
  latitudeDelta?: number
  longitudeDelta?: number
}

export type MapDataRadius = {
  latitudeDelta: number
  longitudeDelta: number
  unit?: string
}

export type RulesAndRestrictionsSummary = {
  flight: RulesAndRestrictionsSummaryData[]
  carrental: RulesAndRestrictionsSummaryData[]
  hotel: RulesAndRestrictionsSummaryData[]
  train: RulesAndRestrictionsSummaryData[]
  carservice: RulesAndRestrictionsSummaryData[]
}

export type RulesAndRestrictionsSummaryData = {
  data: Array<string | ReactElement>
  label?: string | null
}

export type SearchParamsPageData = {
  first: number
  offset: number
  orderBy: string | undefined
  sortBy: string
}

export type OOPGenericData = {
  type: SegmentType
  oopReasons: OutOfPolicyEnum[]
  oopData?: OutOfPolicyData[]
}

export type RecommendationAddress = AddressInput & {
  name?: string
}

export type RecommendationGeocode = {
  lat: number
  lng: number
}

export type RecommendationTravelInformationItem = {
  name?: string | null
  info?: string
  readableAddress: boolean
  segmentPosition?: number
  uuid: string | null
  isWayForHomeToAirport?: boolean
  isWayFromAirportToHome?: boolean
  shouldHideDriveTab?: boolean
  type?: SegmentType
  originAirportLatLng?: RecommendationGeocode
  destinationAirportLatLng?: RecommendationGeocode
}

export type RecommendationTravelInformation = {
  departure: RecommendationTravelInformationItem
  departureDateTime?: string
  destination: RecommendationTravelInformationItem
  slot: RecommendedItemSlot
}

export type RecommendationUuids = {
  fromUuid: string | null
  toUuid: string | null
}

export type OptionType = {
  name: string
  value: string | number
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export type ContinuityMessageSegmentKeys = {
  carRental: string[]
  hotel: string[]
}

export type CarServiceInstructions = {
  type: string
  isOpen: boolean
  pickupText: string | null
  dropoffText: string | null
}

export type SeatMapPosition = {
  segmentNumber: number
  legNumber: number
}

export type TripPlannerHotel = {
  query: string
  checkIn: Moment
  checkOut: Moment
  nameSearch: boolean
  cluster: string
  nextPage: string
  skip: boolean
  geoCode: Geocode
}

export type Place = {
  type?: 'history'
  placeId: string
  name: string
  shortName?: string
  isHotelAddress?: boolean
  airportCode?: string
  latitude?: number
  longitude?: number
  isNameSearch?: boolean
  placeType?: PlaceAutocompleteType
  locationType?: string
  uniqueCode?: string
  isGrouped?: boolean | null
  subPlaces?: Place[]
  countryCode?: string
  countryName?: string
  stateCode?: string
  city?: string
  timezone?: string
}

export type TimeRangeTypeOptions = {
  [key in TimeRangeOption]: TimeRange
}

export type TimeRange = {
  id: TimeRangeOption
  timeRangeBy?: TimeRangeDirection
  i18next: string
  endTimeRange?: string
  startTimeRange?: string
  startTimeHours?: number
  endTimeHours?: number
  customTimeHours?: number
  formattedStartTimeHours?: string
  formattedEndTimeHours?: string
  formattedCustomTimeHours?: string
}

export type CabinClasses = {
  [key in SeatmapCabinClass]: CabinClassType
}

export type CabinClassType = {
  id: SeatmapCabinClass
}

export type PlainTime = {
  hours: number
  minutes: number
  seconds?: number
}

export type Dates = {
  date?: Date | null
  dateEnd?: Date | null
}

export type TicketAttributes = {
  id: string
  name: string
  assessment: OnTheFlyContentAssessment
}

export type FareWithFareAttributes = FlightFare & {
  ticketAttributes?: TicketAttributes[]
  title?: string
}

export type CheckboxOption = {
  label: string
  value: string
}

export type RailFiltersType = {
  stopFilters?: StopsFilter[]
  trainCompanyFilters?: TrainCompanyFilter[]
  takeoffLandingRanges?: TakeoffLandingRanges
  filterFareTiers?: FareTierFilter[]
  originStationFilters?: StationFilterStation[]
  destinationStationFilters?: StationFilterStation[]
  excludeOutOfPolicy?: boolean
}

export type TakeoffLandingRanges = {
  landing?: TakeoffLandingTimeRange
  takeoff?: TakeoffLandingTimeRange
}

export type TakeoffLandingTimeRange = {
  start: Date
  end: Date
}

export type PersonalInfo = {
  firstName: string
  lastName: string
  middleName?: string
  suffix?: UserSuffix
  rawSuffix?: string
  title?: UserTitle
  email?: string
  phone?: Phone
  personalMobile?: Phone
  landline?: Phone
  gender?: Gender
  dateOfBirth?: Date
  customerId: number
  companyId: number
  address?: PersonalContactAddress | null
  office?: PersonalContactAddress | null
  passport?: Passport[] | null
  knownTravelerNumbers?: CheckoutInfoTravelerKnownTravelerNumber[]
  redressNumbers?: CheckoutInfoTravelerRedressNumber[]
}

export type PreferenceContent =
  | (CommonTravelPreference & { meal?: PreferenceOptions | null } & {
      isETicket?: boolean | null
    } & { railCard?: string | null })
  | null

export type ModalPosition = 'center' | 'right' | 'top'

export type BaggageInfoSegment = Pick<TripDetailsFlightLegSegment, 'baggageFees' | 'name'> & {
  imageUrl: string | null | undefined
}

export type HotelUpdateArgs = {
  isError: boolean
  isLoading: boolean
  refetch?: () => void
  hotel: Hotel
}

export type BillingError = {
  oop: PurchaseErrorList | null
  address: PurchaseErrorList | null
  brandType: PurchaseErrorList | null
  brandTypeBookingCom: PurchaseErrorList | null
}

export type DisabledMembership = {
  name: string
  code: string
}

export type TimelineType = {
  title: string
  locationName?: string
  time?: string
  duration?: number
  iconName: IconNames
}

export type SafetyCheckFlight = {
  airline: string
  airlineLogo: string
  headLines: FlightSafetyCheckHeadline[]
}

export type FlightSafetyCheckHeadline = {
  iconName: IconNames
  title: string
}

export type FlightSafetyCheckDetails = {
  imageUrl: string
  headline: string
  description: string
  url: string
}

export type FlightCarrierData = {
  carrierName: string
  carrierLogo: string
  flightNumber: number
}

export type CarRentalFilterType = {
  value: number
  code: string
}

type CarLocation = {
  date: string
  location: string
}

export type CarLocations = {
  geocode?: Coords | null
  pickUp: CarLocation
  dropOff: CarLocation
}

export type FlightEmission = {
  loading: boolean
  averageRouteEmissionTonnes?: number
  customRef?: string | null
  equivalences?: CalculateEmissionsBatchResultEquivalence[]
  tonnesOfEmissions?: number
}

export type BrandedFare = {
  fareKey: string
  label: string
}

export type CarEmission = {
  loading: boolean
  averageEmissionsTonnes?: number
  customRef?: string | null
  equivalences?: CalculateEmissionsBatchResultEquivalence[]
  tonnesOfEmissions?: number
}

export type HotelEmission = {
  averageLocationEmissionsTonnes?: number
  averageLocationSustainabilityScore?: number
  tonnesOfEmissions?: number
  sustainabilityScore?: number | null
  certifications?: CalculateEmissionsResultHotelCertification[]
  customRef?: string | null
  equivalences?: CalculateEmissionsBatchResultEquivalence[]
  loading: boolean
}

export type RailEmission = {
  averageRouteEmissionTonnes?: number
  customRef?: string | null
  equivalences?: CalculateEmissionsEquivalenceValueObject[]
  tonnesOfEmissions?: number
  kgOfActualEmission?: number
  isLoading?: boolean
}

export type EntrancePoint = 'fromRTP' | 'fromTripDetails' | null

export type NoResultsReason =
  | 'restricted-country'
  | 'last-permitted-date'
  | 'too-many-filters'
  | 'no-first-results'
  | 'is-error'
  | 'no-results-flight-default'
  | 'no-results-hotel-default'

export type FareSegmentSeatRows = {
  segmentId: string
  origin: string
  destination: string
  seatRows: Array<SeatMapRow>
  readOnly: boolean
  isSeatMapAvailable?: boolean
  meta?: {
    errorOccurred: boolean
  }
}

export type FareDetails = {
  isFareAttributesLoading: boolean
  isFareAttributesError: boolean
  flight: AdaptiveFlight
  refetchFlightFareAttributes: () => Promise<void>
  retryFetchSeatMap: (id: string) => Promise<void>
  isFlightSafetyCheckEnabled: boolean
  shouldDisplayTabs?: boolean
  displayServiceClass: string
  isLoading: boolean
  fareSegmentSeatRows: FareSegmentSeatRows[]
  selectedSegmentId: string
  onSelectedSegmentIdChange: (segmentId: string) => void
}

export type FareData = Record<string, boolean>

export type SelectFareSeatsArgs = {
  selectedFare: FareWithFareAttributes
  seats: SegmentSeatNumber[]
  selectedLegId: string
  selectedLeg?: SearchFlightLeg
  brandedFare: string
}

export type FlightStatusDetails = {
  status: TripStateStatus
  activeLeg?: SearchFlightLeg
  onSelectFareSeats: ({ selectedFare, seats, selectedLegId }: SelectFareSeatsArgs) => void
  onResetFlightStatus: () => void
}

export type ActualFlightEmission = {
  loading: boolean
  averageRouteEmissionTonnes: number
  customRef?: string | null
  equivalences?: CalculateEmissionsBatchResultEquivalence[]
  tonnesOfEmissions?: number
}

export type FareFromExternalSource = {
  flight: AdaptiveFlight
  isFareAttributesLoading: boolean
  isFareAttributesError: boolean
  refetchFlightFareAttributes: () => Promise<void>
}

export type InvalidTravelErrorDetailParameter = {
  name: string
  value: string
}

export type InvalidTravelErrorDetailFieldDescriptor = {
  name: string
  value: string
}

export type InvalidTravelErrorDetail = {
  severity: 'ERROR' | 'WARNING' | 'INFO'
  code: string
  parameter?: InvalidTravelErrorDetailParameter[]
  fieldName: string
  fieldDescriptor?: InvalidTravelErrorDetailFieldDescriptor[]
}

export type InvalidTravelErrorDetailInfo = {
  errorDetail?: InvalidTravelErrorDetail[]
}

export type SwitchPlacesProps = {
  legNumber: number
  flightSearchTimeRange?: FlightSearchTimeRange
  flightSearchTimeRangeNonUS?: FlightSearchTimeRange
}
