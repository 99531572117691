import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import { addMinutes, deleteTimezone, subMinutes, dateToStringWithoutTZ } from '@fiji/utils/dates'

type Args = {
  flightStartTime: string
  flightEndTime: string
  alignSegmentTypes: AlignTripTravelVerticalValueObject[]
}

const CAR_RENTAL_PICKUP_OFFSET = 30
const CAR_RENTAL_DROP_OFF_OFFSET = 90

export const getTimeRangeForAlignment = ({
  alignSegmentTypes,
  flightEndTime,
  flightStartTime,
}: Args) => {
  if (alignSegmentTypes.includes('Carrental')) {
    const startDate = new Date(deleteTimezone(flightStartTime))
    const endDate = new Date(deleteTimezone(flightEndTime))
    const startTimeWithOffset = addMinutes(startDate, CAR_RENTAL_PICKUP_OFFSET)
    const endTimeWithOffset = subMinutes(endDate, CAR_RENTAL_DROP_OFF_OFFSET)

    return {
      startDate: dateToStringWithoutTZ(startTimeWithOffset),
      endDate: dateToStringWithoutTZ(endTimeWithOffset),
    }
  }

  return {
    startDate: deleteTimezone(flightStartTime),
    endDate: deleteTimezone(flightEndTime),
  }
}
