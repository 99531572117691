import type { ReactNode } from 'react'
import { ChildContainer } from './switch-screen-styled'
import { ChildWithAnimation } from './child-with-animation'

type Props = {
  children: ReactNode
}

export function SwitchScreen({ children }: Props) {
  return (
    <ChildWithAnimation>
      <ChildContainer>{children}</ChildContainer>
    </ChildWithAnimation>
  )
}
