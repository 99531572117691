import styled from 'styled-components'

export const UnusedTicketLabelWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  gap: 10px;
`
