import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import type { BaseTextFieldProps } from '../base-text-field'
import { PasswordTextField } from './password-styles'

export function Password({ value, ...props }: BaseTextFieldProps) {
  const { t } = useTranslation()
  const [isValueVisible, updateValueVisible] = useState(false)
  const type = isValueVisible ? 'text' : 'password'

  const isEmptyValue = !value

  const iconLabel = isValueVisible
    ? t('Accessibility.TextField.Password.Hide')
    : t('Accessibility.TextField.Password.Show')

  return (
    <PasswordTextField
      {...props}
      isValueVisible={isValueVisible}
      value={value}
      type={type}
      endSlot={
        !isEmptyValue && (
          <IconButton
            aria-label={iconLabel}
            onClick={() => updateValueVisible(!isValueVisible)}
            icon="eyeHidePWA"
          />
        )
      }
    />
  )
}
