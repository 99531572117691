import type { Place } from '@fiji/types'
import { getAirportCode } from '@fiji/utils/get-airport-code'

export function getAirportCodeFromPlace(place?: Place) {
  if (place && place.airportCode) {
    return place?.airportCode
  }
  if (place && place.name) {
    return getPureAirportCode(place.name)
  }
}

function getPureAirportCode(airportName: string) {
  const result = airportName?.replace(',', '').split(' ')
  if (result && result.length === 2) {
    return result.find((str) => str.match('^[A-Z]{3}$'))
  }
  return getAirportCode(airportName)
}
