import { useTranslation } from 'react-i18next'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Text } from '@etta/ui/text'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'
import { formatRate } from '@fiji/utils/money'
import { Badge } from '@etta/ui/badge'
import {
  Caption,
  SegmentWrapper,
  SegmentLogo,
  TableRow,
  TableHead,
  TableBody,
  Table,
  TableH,
  TableD,
} from './print-itinerary-modal.styled'

type Props = {
  segment: CarRentalPostBookingValueObject
}

export function CarRentalSegment({ segment }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  const {
    vendor,
    pickUpLocation,
    dropOffLocation,
    carTransmission,
    carClass,
    carType,
    isCarAirConditioned,
    pickUpTime,
    dropOffTime,
    dailyRate,
    isUnlimitedMileage,
    totalCost,
    confirmationNumber,
    extraCharge,
    membership,
    specialRequests,
    preferredDescription,
    status,
  } = segment

  const { carRentalTitle } = translateCarNameDetails({
    carClass,
    carType,
  })
  const airConditioning = isCarAirConditioned ? t(i18nBase + 'AirConditioning') : ''

  const carDetailsLine = [
    carRentalTitle,
    capitalizeFirstLetter(String(carTransmission || '')),
    airConditioning,
  ]
    .filter(Boolean)
    .join(' • ')

  const pickUpAddress = [
    pickUpLocation?.address?.street1,
    pickUpLocation?.address?.street2,
    pickUpLocation?.address?.city,
    pickUpLocation?.address?.stateCode,
    pickUpLocation?.address?.postalCode,
  ]
    .filter(Boolean)
    .join(', ')

  const pickUpWorkingHours = pickUpLocation?.workingHours?.isAllDay
    ? t(i18nBase + 'OpenAllDay')
    : pickUpLocation?.workingHours?.range

  const pickUpLine = [pickUpAddress, pickUpLocation?.phone?.number, pickUpWorkingHours]
    .filter(Boolean)
    .join(' • ')

  const dropOffAddress = [
    dropOffLocation?.address?.street1,
    dropOffLocation?.address?.street2,
    dropOffLocation?.address?.city,
    dropOffLocation?.address?.stateCode,
    dropOffLocation?.address?.postalCode,
  ]
    .filter(Boolean)
    .join(', ')

  const dropOffWorkingHours = dropOffLocation?.workingHours?.isAllDay
    ? t(i18nBase + 'OpenAllDay')
    : dropOffLocation?.workingHours?.range

  const dropOffLine = [dropOffAddress, dropOffLocation?.phone?.number, dropOffWorkingHours]
    .filter(Boolean)
    .join(' • ')

  const vendorAddress = [pickUpLocation?.address?.city, pickUpLocation?.address?.stateCode]
    .filter(Boolean)
    .join(', ')
  const vendorLine = [vendor?.name, vendorAddress].filter(Boolean).join(' · ')

  const unlimitedMileage = isUnlimitedMileage ? t(i18nBase + 'UnlimitedMileage') : ''
  const unlimited = isUnlimitedMileage ? t(i18nBase + 'Unlimited') : ''
  const rate = dailyRate
    ? t(i18nBase + 'PerDay', { rate: formatRate(dailyRate, { morpheme: 'prefix' }).mainCost })
    : ''
  const rateLine = [rate, unlimitedMileage].filter(Boolean).join('/')

  const total = formatRate(totalCost, { morpheme: 'prefix' }).mainCost

  const customBadge = preferredDescription?.customBadge.name

  return (
    <SegmentWrapper>
      {vendor?.imageUrl && (
        <SegmentLogo src={vendor?.imageUrl} alt={t(i18nBase + 'Logo', { label: vendor.name })} />
      )}
      <Text variant="subHeadStrong" color="mainText">
        {vendorLine}
      </Text>
      {customBadge && <Badge variant="customPreferred" iconSize="small" label={customBadge} />}
      {!customBadge && preferredDescription?.code && (
        <Badge variant="preferredVendor" iconSize="small" />
      )}
      <Text variant="footnoteMedium" color="mainText">
        {carDetailsLine}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {pickUpTime &&
          t(i18nBase + 'PickUp', {
            date: dateFormat(new Date(deleteTimezone(pickUpTime)), 'ccc LLL d yyyy, h:mm a'),
          })}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {pickUpLine}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {dropOffTime &&
          t(i18nBase + 'DropOff', {
            date: dateFormat(new Date(deleteTimezone(dropOffTime)), 'ccc LLL d yyyy, h:mm a'),
          })}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {dropOffLine}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {rateLine}
      </Text>
      <Text variant="footnoteMedium" color="bodyText">
        {total && t(i18nBase + 'ApproxTotal', { total: total })}
      </Text>
      {membership?.map(({ number }, index) => (
        <Text key={number} variant="footnoteMedium" color="mainText">
          {index === 0 && <>{t(i18nBase + 'Membership')}:&nbsp;</>}
          {vendor?.name} - {number}
        </Text>
      ))}
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'SpecialRequests')}: {specialRequests?.join(', ')}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'ReservationNo')}:&nbsp;
        <Text variant="footnoteStrong" color="mainText">
          {confirmationNumber}
        </Text>
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'Status')}: {status && capitalizeFirstLetter(status.split('_').join(' '))}
      </Text>
      <Table>
        <Caption>
          <Text variant="subHeadStrong" color="mainText">
            {t(i18nBase + 'MileageAndRates')}
          </Text>
        </Caption>
        <TableHead>
          <TableRow>
            <TableH></TableH>
            <TableH>{t(i18nBase + 'DailyRate')}</TableH>
            <TableH>{t(i18nBase + 'ExtraDay')}</TableH>
            <TableH>{t(i18nBase + 'ExtraHour')}</TableH>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableH>{t(i18nBase + 'Price')}</TableH>
            <TableD>
              {formatRate(extraCharge?.perDayRate, { morpheme: 'prefix' }).mainCost || '--'}
            </TableD>
            <TableD>
              {formatRate(extraCharge?.perDayCharge, { morpheme: 'prefix' }).mainCost || '--'}
            </TableD>
            <TableD>
              {formatRate(extraCharge?.perHourCharge, { morpheme: 'prefix' }).mainCost || '--'}
            </TableD>
          </TableRow>
          <TableRow>
            <TableH>{t(i18nBase + 'Mileage')}</TableH>
            <TableD>{extraCharge?.perDayRate && unlimited ? unlimited : '--'}</TableD>
            <TableD>{extraCharge?.perDayCharge && unlimited ? unlimited : '--'}</TableD>
            <TableD>{extraCharge?.perHourCharge && unlimited ? unlimited : '--'}</TableD>
          </TableRow>
          <TableRow>
            <TableH>{t(i18nBase + 'ChargePerMile')}</TableH>
            <TableD>{'--'}</TableD>
            <TableD>{'--'}</TableD>
            <TableD>{'--'}</TableD>
          </TableRow>
        </TableBody>
      </Table>
    </SegmentWrapper>
  )
}
