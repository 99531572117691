export default function ICloudExclamationIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.2358 20.5502L34.2351 20.8813L34.5396 21.0112C36.9845 22.0544 38.5 24.4064 38.5 27.0955C38.5 30.6117 35.5081 33.4998 31.6479 33.5C31.6478 33.5 31.6476 33.5 31.6475 33.5L13.1604 33.4856H13.16C8.75716 33.4856 5.5 30.262 5.5 26.4901C5.5 23.2937 7.28366 20.7753 10.0304 20.2943L10.4334 20.2237L10.444 19.8147C10.535 16.2938 13.8175 13.9753 16.8487 14.8342L17.196 14.9326L17.3987 14.6339C18.9719 12.3157 21.4661 10.5 24.8617 10.5C30.115 10.5 34.2494 14.6767 34.2358 20.5502ZM20.3102 23.6519L20.3103 23.6542C20.3276 24.1276 20.4687 24.5653 20.7861 24.8854C21.1067 25.2087 21.5422 25.3468 22.0142 25.3468C22.4725 25.3468 22.9018 25.212 23.219 24.8892C23.5325 24.5702 23.6726 24.1326 23.6897 23.6539L23.6897 23.6531L23.888 17.8452C23.9276 16.7576 23.0639 15.9577 22 15.9577C20.919 15.9577 20.0873 16.7457 20.1261 17.8295C20.1261 17.83 20.1262 17.8304 20.1262 17.8308L20.3102 23.6519ZM19.9275 28.4072C19.9275 29.5961 20.9228 30.4207 22 30.4207C23.074 30.4207 24.0725 29.6136 24.0725 28.4072C24.0725 27.2036 23.0911 26.3937 22 26.3937C20.9056 26.3937 19.9275 27.2211 19.9275 28.4072Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
