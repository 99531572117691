import { Store } from '@etta/di'
import type { UnusedTicketValueObject } from '../../core/value-objects/unused-ticket.value-object'

@Store()
export class UnusedTicketsStore {
  private _isLoading = false
  private _isError = false

  private _unusedTickets: UnusedTicketValueObject[] | null = null

  get unusedTickets() {
    return this._unusedTickets
  }

  get isLoading() {
    return this._isLoading
  }

  get isError() {
    return this._isError
  }

  setUnusedTickets(tickets: UnusedTicketValueObject[]) {
    this._unusedTickets = tickets
  }

  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this._isError = isError
  }
}
