import { useMemo } from 'react'
import { Text } from '@etta/ui/text'

type Props = {
  description?: string
  isCut?: boolean
  isUpperCase?: boolean
}

const ELLIPSIS_ROOM_DESCRIPTION_LENGTH = 100

export function Description({ description, isCut, isUpperCase }: Props) {
  const updatedDescription = useMemo(() => {
    const upperCaseDescription = isUpperCase ? description?.toUpperCase() : description

    if (!isCut) {
      return upperCaseDescription || ''
    }

    return upperCaseDescription
      ? upperCaseDescription.slice(0, ELLIPSIS_ROOM_DESCRIPTION_LENGTH) + '...'
      : ''
  }, [description, isUpperCase, isCut])

  return (
    <Text aria-label={updatedDescription} variant="footnoteMedium" color="bodyText">
      {updatedDescription}
    </Text>
  )
}
