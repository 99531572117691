import { Action, Inject } from '@etta/di'
import type { StoredCustomFieldBooking } from '@fiji/hooks/use-custom-fields/get-stored-custom-fields-booking-list'
import type { PaymentStructure } from '@etta/screens/payment-summary/use-payment-summary/types'
import { PaymentSummaryStore } from '../stores/payment-summary.store'

@Action()
export class PaymentSummaryActions {
  constructor(
    @Inject()
    private readonly paymentSummaryStore: PaymentSummaryStore,
  ) {}

  updateCustomFields(customFields: StoredCustomFieldBooking[]) {
    this.paymentSummaryStore.updateCustomFields(customFields)
  }

  updatePaymentStructure(paymentStructure: PaymentStructure) {
    this.paymentSummaryStore.updatePaymentStructure(paymentStructure)
  }

  handleOpenTravelerAcknowledgeModal(type?: 'hold' | 'regular') {
    this.paymentSummaryStore.setIsTravelerAcknowledgeModalToggled(true)
    this.paymentSummaryStore.setBookType(type)
    this.paymentSummaryStore.travelerAcknowledgeModal.handleOpen()
  }

  handleCloseTravelAcknowledgmentModal() {
    this.paymentSummaryStore.setIsTravelerAcknowledgeModalToggled(false)
    this.paymentSummaryStore.travelerAcknowledgeModal.handleClose()
  }

  handleOpenNDCFareAcknowledgeModal(type?: 'hold' | 'regular') {
    this.paymentSummaryStore.setNDCFareAcknowledgeModalToggled(true)
    this.paymentSummaryStore.setBookType(type)
    this.paymentSummaryStore.ndcFareAcknowledgeModal.handleOpen()
  }

  handleCloseNDCFareAcknowledgmentModal() {
    this.paymentSummaryStore.setNDCFareAcknowledgeModalToggled(false)
    this.paymentSummaryStore.ndcFareAcknowledgeModal.handleClose()
  }

  handleAcceptAirTermsToggle() {
    if (this.paymentSummaryStore.paymentSummaryValidator.errors.acceptAirTicketTerms) {
      this.paymentSummaryStore.paymentSummaryValidator.reset()
    }
    this.paymentSummaryStore.paymentSummaryValidator.onFieldChange(
      'acceptAirTicketTerms',
      !this.paymentSummaryStore.isAcceptAirTicketTerms,
    )
  }
}
