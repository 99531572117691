import type {
  PaymentDetailsValueObject,
  PaymentStructureValueObject,
} from '@etta/modules/booking/core/value-objects'
import type { BillingPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/billing-persist.value-object'
import type { CheckoutItineraryInputValueObject } from '@etta/modules/booking/core/value-objects/checkout-itinerary.input.value-object'
import { SINGLE_USE_CREDIT_CARD_ID, VIRTUAL_PAY } from '@fiji/constants'
import type {
  BillingInfoInputDto,
  CheckoutInfoCreditCard,
  CheckoutInfoSiteCard,
  CreditCardInputDto,
  SiteCardInputDto,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import { addressMapper } from './address.mapper'

export class CheckoutItineraryBillingMapper {
  static toBillingInput(
    billing?: BillingPersistValueObject,
    payment?: PaymentStructureValueObject,
  ): CheckoutItineraryInputValueObject['billing'] {
    return {
      air: this.toSegmentCards({
        creditCard: billing?.air?.creditCard,
        siteCard: billing?.air?.siteCard,
        payment: payment?.AIR,
      }),
      carRental: this.toSegmentCards({
        creditCard: billing?.carRental?.creditCard,
        siteCard: billing?.carRental?.siteCard,
        payment: payment?.CARRENTAL,
      }),
      hotel: this.toSegmentCards({
        creditCard: billing?.hotel?.creditCard,
        siteCard: billing?.hotel?.siteCard,
        payment: payment?.HOTEL,
      }),
      rail: this.toSegmentCards({
        creditCard: billing?.rail?.creditCard,
        siteCard: billing?.rail?.siteCard,
        payment: payment?.RAIL,
      }),
    }
  }

  static toSegmentCards(
    args?: {
      creditCard?: CheckoutInfoCreditCard
      siteCard?: CheckoutInfoSiteCard
      payment?: PaymentDetailsValueObject | null
    } | null,
  ): BillingInfoInputDto | undefined {
    const creditCardInput = this.toCreditCardInput(args?.creditCard, args?.payment)
    const siteCardInput = this.toSiteCardInput(args?.siteCard)

    if (!creditCardInput && !siteCardInput) {
      return
    }
    return {
      creditCard: creditCardInput,
      siteCard: siteCardInput,
    }
  }

  private static toCreditCardInput(
    creditCard?: CheckoutInfoCreditCard,
    payment?: PaymentDetailsValueObject | null,
  ): CreditCardInputDto | undefined {
    if (!creditCard) {
      return
    }

    if (creditCard.id === VIRTUAL_PAY) {
      return
    }
    const cvv =
      payment?.creditCard?.id === creditCard.data.id ? payment?.creditCard?.cvv : undefined
    return {
      data: {
        cvv: cvv ?? creditCard.data.cvv,
        expirationDate: creditCard.data.expirationDate,
        id: creditCard.data.id,
        isSingleUseCreditCard: creditCard.data.id === SINGLE_USE_CREDIT_CARD_ID,
        label: creditCard.data.label,
        nameOnTheCard: creditCard.data.nameOnTheCard,
        number: creditCard.data.number,
        type: creditCard.data.type,
      },
      id: creditCard.id || '',
      isCorporateCard: creditCard.isCorporateCard,
      address: addressMapper(creditCard.address),
    }
  }

  private static toSiteCardInput(siteCard?: CheckoutInfoSiteCard): SiteCardInputDto | undefined {
    if (!siteCard) {
      return
    }

    return {
      data: {
        id: siteCard.data?.id || '',
      },
      id: siteCard.id || '',
      address: addressMapper(siteCard.address),
    }
  }
}
