import { Action, Inject } from '@etta/di'
import type { TripType } from '@etta/core/enums/trip-type.enum'
import { PostBookingTripPreviewStore } from '../stores/trip-preview/post-booking-trip-preview.store'

@Action()
export class PostBookingTripPreviewActions {
  constructor(@Inject() private postBookingTripPreviewStore: PostBookingTripPreviewStore) {}

  updateTitle(title: string) {
    this.postBookingTripPreviewStore.updateTitle(title)
  }

  updateTimes({ startTime, endTime }: { startTime: string; endTime: string }) {
    this.postBookingTripPreviewStore.updateTimes({ startTime, endTime })
  }

  updateTripType(tripType: TripType) {
    this.postBookingTripPreviewStore.updateTripType(tripType)
  }

  updateCityImageUrl(cityImageUrl: string) {
    this.postBookingTripPreviewStore.updateCityImageUrl(cityImageUrl)
  }

  resetTrip() {
    this.postBookingTripPreviewStore.resetTrip()
  }
}
