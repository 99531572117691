import styled from 'styled-components'
import { Modal } from '@etta/ui/modal'
import { headline } from '@fiji/style'

export const Container = styled.div`
  padding: 32px 20px;
`
export const Title = styled.h2`
  ${headline};
  margin: 0 0 16px;
`

export const FieldsStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`

export const BillingAddressSection = styled.div`
  margin-top: 40px;
`

export const CheckBoxWrapper = styled.div`
  margin-top: 24px;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`
