import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { RailStopsFilter } from './stops-filter'
import { RailStationsFilter } from './stations-filter'
import { RailTimeFilter } from './time-filter'
import { Footer } from './footer'
import { RailPolicyFilter } from './policy-filter'

const i18Base = 'RailFilters'

export function RailFiltersContent() {
  const { t } = useTranslation()

  return (
    <>
      <Modal.Title withBorder>{t(i18Base + '.Title')}</Modal.Title>
      <Modal.Body>
        <RailStopsFilter />
        <RailStationsFilter />
        <RailTimeFilter />
        <RailPolicyFilter />
      </Modal.Body>
      <Modal.Footer>
        <Footer />
      </Modal.Footer>
    </>
  )
}
