import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type { GetCarRulesByTransactionIdQuery } from '@fiji/graphql/hooks'
import { GetCarRulesByTransactionIdDocument } from '@fiji/graphql/hooks'
import type { GetCarRulesByTransactionIdQueryVariables } from '@fiji/graphql/types'

@DataProvider()
export class CarRentalRulesDataProvider extends GraphqlDataProvider {
  handleLoadCarRules(input: GetCarRulesByTransactionIdQueryVariables['input']) {
    return this.client.query<
      GetCarRulesByTransactionIdQuery,
      GetCarRulesByTransactionIdQueryVariables
    >({
      query: GetCarRulesByTransactionIdDocument,
      variables: { input },
    })
  }
}
