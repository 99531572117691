import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  gap: 42px;
`

export const MonthTitle = styled(Text)`
  margin-bottom: 16px;
  color: ${(p) => p.theme.colors.mainText1};
`

const listContainer = css`
  max-width: 800px;
`

export const ContentContainer = styled.div<{ view?: 'list' | 'grid' }>`
  ${(p) => {
    return p.view !== 'grid' && listContainer
  }}
  margin: 0 auto 40px;
`

export const CardsList = styled.div<{ view?: 'list' | 'grid' }>`
  ${(p) => {
    const listCss = css`
      display: flex;
      flex-direction: column;
      margin: auto;
    `

    switch (p.view) {
      case 'grid':
        return css`
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          margin: 0px 3px;
        `
      case 'list':
        return listCss
      default:
        return listCss
    }
  }}
  gap: 24px;
`
