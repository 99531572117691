import styled, { css } from 'styled-components'
import { subHeadStrong, footnoteMedium, KeyboardNavigationStyle } from '@fiji/style'

const disabledStyle = css`
  filter: opacity(0.5);
`

export const Wrapper = styled.button<{ isDisabled: boolean }>`
  display: flex;
  flex: 1;
  border-radius: 6px;
  align-items: center;
  border: none;
  background-color: ${(p) => p.theme.colors.background};
  padding: 6px 20px 6px 10px;
  cursor: ${(p) => (p.isDisabled ? 'default' : 'pointer')};
  width: 100%;
  height: 56px;
  overflow-x: auto;

  ${(p) => (p.isDisabled ? disabledStyle : null)}

  ${KeyboardNavigationStyle}
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.mainText};
  margin-right: 12px;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const LocationsParamsBlock = styled.div<{ isDisabled: boolean }>`
  ${subHeadStrong};
  line-height: 20px;
  color: ${(p) => (p.isDisabled ? p.theme.colors.bodyText1 : p.theme.colors.mainText)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const DatesParamsBlock = styled.div<{ isDisabled: boolean }>`
  ${footnoteMedium};
  line-height: 19px;
  color: ${(p) => (p.isDisabled ? p.theme.colors.bodyText1 : p.theme.colors.bodyText)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
