import { MediaLayout } from '@etta/ui/media-layout'
import { CarRentalLoadingPlaceholderDesktop, CarRentalLoadingPlaceholderMobile } from './layout'

export function CarRentalLoadingPlaceholder() {
  return (
    <MediaLayout
      mobileSlot={<CarRentalLoadingPlaceholderMobile />}
      desktopSlot={<CarRentalLoadingPlaceholderDesktop />}
    />
  )
}
