import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'
import { EditTripNameDialogDesktopContent } from './edit-trip-name-dialog-desktop-content'

export function EditTripNameDialogDesktop({ isOpen, onClose, ...props }: LayoutProps) {
  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <EditTripNameDialogDesktopContent {...props} onClose={onClose} />
    </Modal>
  )
}
