import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Container, Button, MiniPill } from './scrollable-bar-styled'
import { Slider } from './slider'

type Props = {
  isLoading: boolean
  totalHotels: number
  currentHotels?: number
  slideSize: number
  onViewListClick: () => void
  onNextSlide: () => void
  onPrevSlide: () => void
}

const i18nBase = 'SearchResults.Hotels.Map'

export function ScrollableBar({
  isLoading,
  totalHotels,
  slideSize,
  onViewListClick,
  onNextSlide,
  onPrevSlide,
  currentHotels,
}: Props) {
  const { t } = useTranslation()

  return (
    <Container data-tracking-id="scrollable-bar">
      <MiniPill />
      <Slider
        isLoading={isLoading}
        totalHotels={totalHotels}
        slideSize={slideSize}
        onNextSlide={onNextSlide}
        onPrevSlide={onPrevSlide}
        currentHotels={currentHotels}
      />
      <Button
        aria-label={t(`${i18nBase}.ViewListAriaLabel`)}
        data-tracking-id="view-list"
        onClick={isLoading ? undefined : onViewListClick}>
        <Icon color="primary" name="viewListPWA" />
        <Text variant="linkSmall" color="primary">
          {t(`${i18nBase}.ViewList`)}
        </Text>
      </Button>
    </Container>
  )
}
