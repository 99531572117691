export function isWithinContainerView(
  container?: HTMLElement | null,
  el?: HTMLElement | null,
): boolean {
  if (!container || !el) {
    return false
  }
  const {
    x: containerX,
    y: containerY,
    height: containerHeight,
    width: containerWidth,
  } = container.getBoundingClientRect()

  const containerXWidth = containerX + containerWidth
  const containerYHeight = containerY + containerHeight

  const { x, y, height, width } = el.getBoundingClientRect()

  const elXWidth = x + width
  const elYHeight = y + height

  if (
    x >= containerX &&
    elXWidth <= containerXWidth &&
    y >= containerY &&
    elYHeight <= containerYHeight
  ) {
    return true
  }
  return false
}
