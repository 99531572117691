import { Inject, Service } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { PlacesStorageAction } from '@etta/modules/places-storage/interface/actions/places-storage.action'
import type { Place, Dates, PlainTime as Time } from '@fiji/types'
import type { AddressInputValueObject } from '@etta/core/value-objects/address-input.value-object'
import { CarSearchFormStore } from '../stores/car-search-form.store'
import { CarSearchFormQueryService } from './car-search-form-query.service'

@Service()
export class CarSearchFormService {
  constructor(
    @Inject()
    private readonly carSearchFormStore: CarSearchFormStore,
    @Inject()
    private readonly carSearchFormQueryService: CarSearchFormQueryService,
    @Inject()
    private readonly placesStorageAction: PlacesStorageAction,
  ) {}

  changePickUpPlace(place: Place, address?: AddressInputValueObject) {
    this.placesStorageAction.addNewPlace('carRental')(place)
    const form = this.carSearchFormStore.carForm

    if (this.carSearchFormStore.isEditMode) {
      this.carSearchFormStore.updateEditCache({
        pickUpPlace: place,
        pickUpAddress: address,
        ...(form && {
          dropOffPlace: place,
          dropOffAddress: address,
        }),
      })
      return
    }

    this.carSearchFormQueryService.appendQueryParams({
      pickUpPlace: place,
      pickUpAddress: address,
      ...(form && {
        dropOffPlace: place,
        dropOffAddress: address,
      }),
    })
  }

  changeDropOffPlace(place: Place, address?: AddressInputValueObject) {
    this.placesStorageAction.addNewPlace('carRental')(place)

    if (this.carSearchFormStore.isEditMode) {
      this.carSearchFormStore.updateEditCache({
        dropOffPlace: place,
        dropOffAddress: address,
      })
      return
    }

    this.carSearchFormQueryService.appendQueryParams({
      dropOffPlace: place,
      dropOffAddress: address,
    })
  }

  changeDates({ date, dateEnd }: Dates) {
    if (this.carSearchFormStore.isEditMode) {
      this.carSearchFormStore.updateEditCache({
        ...(date && { pickUpDate: date }),
        ...(dateEnd && { dropOffDate: dateEnd }),
      })
      return
    }
    this.carSearchFormQueryService.appendQueryParams({
      ...(date && { pickUpDate: date }),
      ...(dateEnd && { dropOffDate: dateEnd }),
    })
  }

  changePickUpTime(time: Time) {
    if (this.carSearchFormStore.isEditMode) {
      this.carSearchFormStore.updateEditCache({
        pickUpTime: time,
      })
      return
    }
    this.carSearchFormQueryService.appendQueryParams({
      pickUpTime: time,
    })
  }

  changeDropOffTime(time: Time) {
    if (this.carSearchFormStore.isEditMode) {
      this.carSearchFormStore.updateEditCache({
        dropOffTime: time,
      })
      return
    }
    this.carSearchFormQueryService.appendQueryParams({
      dropOffTime: time,
    })
  }

  switchLocations() {
    const {
      pickUpPlace,
      pickUpAddress,
      dropOffPlace,
      dropOffAddress,
    } = this.carSearchFormStore.carForm

    if (this.carSearchFormStore.isEditMode) {
      this.carSearchFormStore.updateEditCache({
        pickUpAddress: dropOffAddress,
        dropOffPlace: pickUpPlace,
        dropOffAddress: pickUpAddress,
        pickUpPlace: dropOffPlace,
      })
      return
    }

    this.carSearchFormQueryService.appendQueryParams({
      pickUpAddress: dropOffAddress,
      dropOffPlace: pickUpPlace,
      dropOffAddress: pickUpAddress,
      pickUpPlace: dropOffPlace,
    })
  }
}
