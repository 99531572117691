import { CarSafetyCheck } from '@etta/components/car-safety-check/car-safety-check'
import { CarRentalDetailsLocation } from '@etta/components/car-rental-details-location/car-rental-details-location'
import { CarRentalDetailsEcoCheck } from '@etta/components/car-rental-details-eco-check/car-rental-details-eco-check'
import { Modal } from '@etta/ui/modal'
import { Swap } from '@etta/ui/swap'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import type { LayoutProps } from '../../types'
import {
  Container,
  Section,
  AmenitiesContainer,
  AmenitiesWrapper,
  CarImageContainer,
  CarImage,
} from './car-rental-desktop-details-styled'
import { CarRentalDetailsErrorState } from './car-rental-details-error-state'
import { CarDetailsDesktopHeader } from './car-rental-details-desktop-header'
import { CarRentalDetailsAmenities } from './car-rental-details-amenities'
import { CarRentalDetailsVendorInformation } from './car-rental-details-vendor-information'
import { CarRentalDetailsFooter } from './car-rental-details-footer'
import { CarRentalPriceDetails } from './car-rental-price-details'

export function CarRentalDesktopDetails({
  carRentalTitle,
  totalDays,
  isOutOfPolicy,
  isError,
  isLoading,
  isSelectable,
  onClose,
  onRetry,
  onAddCar,
  carEmission,
  carImage,
  amenities,
  locations,
  vendorInformation,
  priceDetailsList,
  vendorCode,
  vendorName,
  isPreferred,
  safetyCheckMessageCode,
  safetyCheckLink,
  handleAddressClick,
  totalCostRate,
  estTotalCost,
  car,
  customBadge,
}: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <Swap is={isError} isSlot={<CarRentalDetailsErrorState onClose={onClose} onRetry={onRetry} />}>
      <CarDetailsDesktopHeader
        carRentalTitle={carRentalTitle}
        onClose={onClose}
        isOutOfPolicy={isOutOfPolicy}
        isPreferred={isPreferred}
        carPolicy={car?.policy}
        customBadge={customBadge}
      />
      <Modal.Body>
        <Container>
          <AmenitiesContainer>
            <AmenitiesWrapper>
              <CarRentalDetailsAmenities amenities={amenities} />
            </AmenitiesWrapper>
            <CarImageContainer>
              {carImage && <CarImage src={carImage} alt={carRentalTitle} />}
            </CarImageContainer>
          </AmenitiesContainer>
          <Section>
            <CarRentalDetailsLocation locations={locations} onAddressClick={handleAddressClick} />
          </Section>
          <Section>
            <CarRentalDetailsVendorInformation
              vendorInformation={vendorInformation}
              isPreferred={isPreferred}
              isInPolicy={car?.policy.isInPolicy}
            />
          </Section>
          <Section>
            <CarSafetyCheck
              type={safetyCheckMessageCode}
              viewType="info-rtp-car-details"
              vendorCode={vendorCode}
              readMoreLink={safetyCheckLink}
              vendorName={vendorName}
            />
          </Section>
          {featureFlags.isSustainabilityEnabled && (
            <Section>
              <CarRentalDetailsEcoCheck {...carEmission} />
            </Section>
          )}
          <Section>
            <CarRentalPriceDetails
              priceDetailsList={priceDetailsList}
              estTotalCost={estTotalCost}
              isLoading={isLoading}
            />
          </Section>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <CarRentalDetailsFooter
          totalDays={totalDays}
          totalCost={totalCostRate}
          isSelectable={isSelectable}
          isLoading={isLoading}
          onButtonClick={onAddCar}
        />
      </Modal.Footer>
    </Swap>
  )
}
