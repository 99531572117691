import { Skeleton } from '@etta/ui/skeleton'
import { Wrapper } from './filter-skeleton-styled'

export function FilterSkeleton() {
  return (
    <Wrapper>
      <Skeleton width="100%" height="100%">
        <rect width="51px" height="39px" />
        <rect width="51px" height="11px" x="56" y="5" />
        <rect width="92px" height="11px" x="56" y="23" />
        <rect width="51px" height="39px" />
      </Skeleton>
    </Wrapper>
  )
}
