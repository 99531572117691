import { CookieStore } from '@etta/modules/cookie'
import { Validator } from '@etta/interface/services/validator'
import { LocalStorageItem } from '@etta/interface/services/local-storage-item'
import { appMode } from '@fiji/modes'
import { Toggle } from '@etta/interface/services/toggle'
import { Inject, Store } from '@etta/di'
import type { SiteEntity } from '../../core/entities/site.entity'

@Store()
export class AuthStore {
  private onboardingStorage = new LocalStorageItem<boolean>('@is-redirected-to-onboarding', {
    initialValue: false,
  })

  expiredDialogToggle = new Toggle()

  expiringDialogToggle = new Toggle()

  passwordExpirationDays?: number

  resetPasswordDialogToggle = new Toggle()

  isLoading: boolean = false

  isJustLogged: boolean = false

  sites: SiteEntity[] = []

  selectedSite: SiteEntity | null = null

  emailValidator = new Validator({
    email: Validator.scheme.string().email(`Login.StepEmail.InvalidEmail`).required(),
  })

  passwordValidator = new Validator({
    password: Validator.scheme.string().required(),
  })

  constructor(
    @Inject()
    private cookieStore: CookieStore,
  ) {}

  get isOnboardingAllowed() {
    return appMode.isEttaStandaloneAll
  }

  get isOnboardingPassed() {
    return this.onboardingStorage.value
  }

  get isSitesMoreThanOne() {
    return this.sites.length > 1
  }

  get isSitesExist() {
    return this.sites.length !== 0
  }

  get isSingleSite() {
    return this.sites.length === 1
  }

  get isLoggedIn() {
    return this.cookieStore.isLoggedIn()
  }

  get isEmailSubmitDisabled() {
    return this.emailValidator.values.email.trim().length < 3
  }

  get isPasswordSubmitDisabled() {
    return this.passwordValidator.values.password.trim().length < 3
  }

  get email() {
    return this.emailValidator.values.email
  }

  get password() {
    return this.passwordValidator.values.password
  }

  get isJustLoggedIn() {
    return this.isJustLogged
  }

  setSites(sites: SiteEntity[]) {
    this.sites = sites
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setSelectedSite(site: SiteEntity) {
    this.selectedSite = site
  }

  setOnboardingDone() {
    this.onboardingStorage.set(true)
  }

  setIsJustLogged(value: boolean) {
    this.isJustLogged = value
  }

  dropData() {
    this.sites = []
    this.selectedSite = null
    this.emailValidator.reset()
    this.passwordValidator.reset()
    this.isLoading = false
    this.isJustLogged = false
  }
}
