import { useEffect } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { trackAction } from '@fiji/utils/tracking/track-action'

const ACTION_NAME = 'mobility-booking-summary-page'

export type Props = {
  isOpenDialog: boolean
}

export function useTrackMobilityRideReview({ isOpenDialog }: Props) {
  const { searchRideHailStore } = useRideHailSearchContext()

  useEffect(() => {
    if (isOpenDialog && searchRideHailStore.selectedSearchRideHail) {
      trackAction(ACTION_NAME)
    }
  }, [isOpenDialog, searchRideHailStore.selectedSearchRideHail])
}
