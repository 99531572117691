import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTripsListContext } from '@etta/modules/post-booking-list/interface/user-orders.context'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { Block } from '@etta/ui/block'
import { PullToRefresh } from '@etta/components/pull-to-refresh'
import { TripNoContent } from '@etta/components/trip-no-content/trip-no-content'
import { TripListReloadError } from './trip-list-reload-error'
import { TripListSkeleton } from './trip-list-skeleton'
import { TripListContent } from './trip-list-content'
import type { Props } from './types'

export const TripList = observer(function TripList({
  tripType,
  view,
  isOnlyTrips,
  headerSlot,
  bannerSlot,
}: Props) {
  const { tripsListActions, tripsListStore: ordersStore } = useTripsListContext()

  const { orders, isError, isLoading } = ordersStore

  useEffect(() => {
    tripsListActions.load(tripType)
  }, [tripsListActions, tripType])

  useEffect(() => {
    return () => {
      tripsListActions.dropAllTrips()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onReload = useCallback(() => {
    tripsListActions.reload(tripType)
  }, [tripsListActions, tripType])

  if (isOnlyTrips && !!orders[tripType]?.groups.length) {
    return (
      <Block>
        {headerSlot}
        <Block marginTop={16}>
          <PullToRefresh onReload={onReload}>
            <TripListContent tripType={tripType} view={view} />
          </PullToRefresh>
        </Block>
      </Block>
    )
  }

  if (isOnlyTrips && !orders[tripType]?.groups.length) {
    return null
  }

  if (!orders[tripType] && (isLoading[tripType] || isLoading[TripType.OnHold])) {
    return <TripListSkeleton />
  }

  if (!orders[tripType]?.groups.length && !isError[tripType] && !isLoading[tripType]) {
    return <TripNoContent tripType={tripType} />
  }

  if (isError[tripType]) {
    return <TripListReloadError onReload={onReload} tripType={tripType} />
  }

  return (
    <PullToRefresh onReload={onReload}>
      <TripListContent bannerSlot={bannerSlot} tripType={tripType} view={view} />
    </PullToRefresh>
  )
})
