import { useTranslation } from 'react-i18next'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import {
  UpperBody,
  EmissionInformation,
  EmissionInformationRow,
  EmissionInformationDescription,
  EmissionInformationValue,
} from '../rail-details-eco-check-modal-mobile-styled'

type Props = {
  kgOfActualEmission: number
}

export function RailDetailsEcoCheckModalMobileUpperBody({ kgOfActualEmission }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.RailDetails'

  return (
    <UpperBody>
      <EmissionInformation>
        <EmissionInformationRow>
          <EmissionInformationValue variant="title2">
            <KgOfCo2 kgOfEmissions={kgOfActualEmission} />
          </EmissionInformationValue>
          <EmissionInformationDescription>
            {t(`${i18nBase}.RailEmissionInfo`)}
          </EmissionInformationDescription>
        </EmissionInformationRow>
      </EmissionInformation>
    </UpperBody>
  )
}
