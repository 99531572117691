export default function DoorsIcon() {
  return (
    <svg
      viewBox="0 0 44 45"
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4 19.9286V13.0714H22.4157C22.2089 13.0714 22.0088 13.1446 21.8517 13.2778L14.0062 19.9286H32.4ZM35 21.6429V12.2143C35 11.2675 34.224 10.5 33.2667 10.5H22.4157C21.5882 10.5 20.788 10.7928 20.1597 11.3254L10.8159 19.2463C9.66333 20.2234 9 21.6498 9 23.1511V23.3572V32.7857V32.7858C9 33.7325 9.77604 34.5001 10.7333 34.5001H33.2667C34.1043 34.5001 34.8032 33.9124 34.9648 33.1312C34.9879 33.0196 35 32.9041 35 32.7858C35 32.7857 35 32.7857 35 32.7857V21.6429ZM32.4 22.5001H12.4667C11.988 22.5001 11.6 22.8838 11.6 23.3572V31.9286H32.4V22.5001ZM25.4666 24.6428L28.9332 24.6428C29.6512 24.6428 30.2332 25.2185 30.2332 25.9285C30.2332 26.6386 29.6512 27.2143 28.9332 27.2143L25.4666 27.2143C24.7486 27.2143 24.1666 26.6386 24.1666 25.9285C24.1666 25.2185 24.7486 24.6428 25.4666 24.6428Z"
        fill="currentColor"
      />
    </svg>
  )
}
