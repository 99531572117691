import type { ReactNode } from 'react'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { Layout } from '@etta/ui/layout'
import { useThemeColor } from './use-theme-color'

type ScreenProps = {
  children: ReactNode
  backgroundColor?: LayoutBackgroundColor
  statusBarColor?: LayoutBackgroundColor
  className?: string
}

function ScreenMobile({
  children,
  backgroundColor,
  statusBarColor = backgroundColor,
  className,
}: ScreenProps) {
  useThemeColor(statusBarColor)

  return (
    <Layout className={className} backgroundColor={backgroundColor}>
      {children}
    </Layout>
  )
}

const ScreenMobileNamespace = Object.assign(ScreenMobile, {
  Header: Layout.Header,
  Footer: Layout.Footer,
  Container: Layout.Container,
})

export { ScreenMobileNamespace as ScreenMobile }
